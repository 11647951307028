<template>
  <div class="header">
    <div class="header__content">
        <slot name="content"></slot>
    </div>
    <div class="header__actions">
      <slot name="actions"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.header {
    background-color: #fff;
    height: 60px;
    padding: 0 30px 0 40px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.04);
    border: 1px solid var(--color-grey-outline);
    border-top: none;
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: translateX(-6px);
    width: calc(100% - 30px - 40px + 4px);
    &__actions {
      height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
    }
}
</style>
