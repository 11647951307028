<template>
  <div class="sections" v-if="carLogos">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Absences</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div class="sections__content" v-for="absence in data" :key="absence">
      <div></div>
      <div class="sections__data">
        <div class="sections__table">
          <table>
            <tr v-if="absence.absence_start">
              <td>Absence Start</td>
              <td>{{ absence.absence_start }}</td>
            </tr>
            <tr v-if="absence.absence_end">
              <td>Absence End</td>
              <td>{{ absence.absence_end }}</td>
            </tr>
            <tr v-if="absence.absence_reason">
              <td>Absence Reason</td>
              <td>{{ absence.absence_reason }}</td>
            </tr>
            <tr v-if="absence.absence_request_status">
              <td>Absence Request Status</td>
              <td>{{ absence.absence_request_status }}</td>
            </tr>
            <tr v-if="absence.days_taken">
              <td>Days Taken</td>
              <td>{{ absence.days_taken }}</td>
            </tr>
            <tr v-if="absence.employee">
              <td>Employee</td>
              <td>{{ absence.employee }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { usersStore } from '@/store/users';
import RComboButton from '@/components/RComboButton.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['download']);

const data = computed(() => props.data);

const carLogos = computed(() => usersStore().getCarLogos);

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'absences' });
};

</script>
