<template>
    <div class="input"
      @click="onMenu" @keydown="onMenu"
      :class="{
        'input--cursor': type === 'color',
        'input--icon-left': leftIcon,
        'input--icon-right': rightIcon,
        'input--error': error,
        'input--rounded': rounded,
        'input--disabled': disabled}">
      <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
      <label v-if="label" :for="labelWithoutSpace">
        {{label}}
        <r-tool-tip class="tool-tip" v-if="tooltipText">
          <template #tooltip-text>
              <span>
                {{tooltipText}}
              </span>
          </template>
        </r-tool-tip>
      </label>
      <input
        type="text"
        :disabled="disabled"
        :id="labelWithoutSpace"
        :name="labelWithoutSpace"
        :placeholder="placeholder"
        readonly
        ref="input"
        :value="currentValue">
      <i v-if="rightIcon"
        class="input__icon input__icon--right noselect"
        :class="rightIcon">
      </i>
      <i v-if="leftIcon"
        class="input__icon input__icon--left noselect"
        :class="leftIcon">
      </i>
      <i
        class="input__icon input__icon--right noselect ri-arrow-down-s-fill">
      </i>
      <OnClickOutside @trigger="menuOpen = false" :options="{ ignore: ['.header__profile'] }">
      <div
        v-show="menuOpen"
        class="input-menu">
        <ul class="input-items">
          <li :key="item" v-for="item in items"
          :class="{'active': modelValue === item.value}"
          @click="onChange(item)"
          @keydown="onChange(item)">
          {{ item.text }}
          <CheckOutlineIcon fill="#8FB571" v-if="modelValue === item.value"></CheckOutlineIcon>
        </li>
        </ul>
      </div>
    </OnClickOutside>
      <p class="input__error">{{error}}</p>
    </div>
  </template>
<script>
import RToolTip from '@/components/RToolTip.vue';
import { OnClickOutside } from '@vueuse/components';
import CheckOutlineIcon from '@/components/icons/CheckOutlineIcon.vue';

export default {
  name: 'RInput',
  components: {
    RToolTip,
    OnClickOutside,
    CheckOutlineIcon,
  },
  props: {
    label: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    rightIcon: String,
    leftIcon: String,
    error: String,
    rounded: Boolean,
    tooltipText: String,
    items: Array,
    type: {
      type: String,
      default: 'text',
      validator: (value) => 'text|number|email|password|color|search|url|tel'.split('|').indexOf(value) > -1,
    },
    modelValue: {
      default: '',
    },
  },
  data() {
    return {
      currentValue: this.defaultColor,
      menuOpen: false,
    };
  },
  emits: ['update:modelValue'],
  computed: {
    labelWithoutSpace() {
      return this.label ? this.label.replace(/\s/g, '').toLowerCase() : '';
    },
  },
  mounted() {
    this.$refs.input.type = this.type;
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.setDefault();
      },
    },
  },
  methods: {
    onChange(item) {
      this.currentValue = item.text;
      this.$emit('update:modelValue', item.value);
    },
    setDefault() {
      const findSelected = this.items.find((item) => item.value === this.modelValue);
      this.currentValue = findSelected.text;
    },
    onMenu() {
      if (this.disabled) {
        return;
      }
      this.menuOpen = !this.menuOpen;
    },
  },
};
</script>
  <style scoped lang="scss">
  .input {
    width: 100%;
    position: relative;
    margin-bottom: 32px;
    input {
      width: inherit;
      min-height: var(--dim-input-height);
      padding: 13px 16px 11px 16px;
      font-family: inherit;
      font-size: 16px;
      font-weight: 500;
      color: var(--color-black);
      border: 1px solid var(--color-grey-light);
      border-radius: 8px;
      outline: none;
      box-sizing: border-box;
      cursor: pointer;
      &::placeholder {
        color: var(--color-grey-light);
        font-weight: 500;
      }
      &:disabled {
        background-color: var(--color-grey-disabled);
        border-color: var(--color-grey-light);
        color: var(--color-grey-light);
      }
      &[type=color] {
        appearance: none;
        -webkit-appearance: none;
        min-height: var(--dim-input-height);
        background: var(--color-input-background);
        &::-webkit-color-swatch-wrapper {
          padding: 0;
        }
        &::-webkit-color-swatch {
          border: none;
          display: none;
        }
      }
    }
    label {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-dark-main);
      padding-bottom: 6px;
      & .tool-tip {
        margin-left: 4px;
      }
    }
    i {
      font-size: 18px;
    }
    &__error {
      position: absolute;
      width: inherit;
      font-size: 12px;
      font-weight: 600;
      color: var(--color-red-error);
      padding-top: 6px;
    }
    &__icon {
      &--left {
        position: absolute;
        bottom: 15px;
        left: 16px;
        z-index: 2;
        color: var(--color-grey-light);
      }
      &--right {
        position: absolute;
        bottom: 15px;
        z-index: 2;
        right: 16px;
        color: var(--color-grey-dark);
      }
    }
    &--error {
      input {
        border-color: var(--color-red-error);
      }
    }
    &--rounded {
      margin-bottom: 4px;
      input {
        border-radius: 999px;
      }
    }
    &--icon {
      &-left {
        input {
          padding-left: 44px;
        }
      }
      &-right {
        input {
          padding-right: 44px;
        }
      }
    }
    &--cursor {
      user-select: none;
      cursor: pointer;
      input {
        cursor: pointer;
        padding-left: 52px;
      }
      i {
        bottom: 20px;
      }
    }
    &:hover, &:active, &:focus, &:focus-within {
      &:not(.input--error):not(.input--disabled) {
        input {
          border: 1px solid var(--color-blue-main);
        }
      }
    }
    &--disabled {
      input {
        cursor: not-allowed;
      }
      .input{
        &__icon{
          &--left{
            color: var(--color-grey-light);
          }
          &--right{
            color: var(--color-grey-light);
          }
        }
      }
    }
    &__color {
      position: absolute;
      bottom: 17px;
      left: 18px;
      width: 20px;
      height: 20px;
      border: 1px solid var(--color-grey-outline);
      border-radius: 4px;
      &-name {
        position: absolute;
        bottom: 17px;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-black);
        left: 52px;
        text-transform: uppercase;
      }
    }

    &-menu{
        position: absolute;
        width: 100%;
        z-index: 10;
    }
    &-items {
      width: calc(100% - 16px);
      list-style: none;
      display: inline-block;
      border-radius: 8px;
      background: var(--color-white);
      box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
      padding: 8px;
      cursor: pointer;
      max-height: 50vh;
      overflow: auto;
      & li {
        padding: 16px;
        color: var(--color-grey-dark);
        font-family: 'Gilroy', sans-serif;
        font-size: 15px;
        font-weight: 500;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover{
          background: var(--color-blue-background);
          color: var(--color-blue-main);
        }
        &.active {
          color: var(--color-dark-main);
          font-size: 15px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        svg {
            height: 24px !important;
            width: 24px !important;
        }
      }
    }
  }
  </style>
