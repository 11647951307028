<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M15.0001 5.83333H17.5001C17.7211 5.83333 17.9331 5.92113 18.0893
6.07741C18.2456 6.23369 18.3334 6.44565 18.3334 6.66667V16.6667C18.3334 16.8877
18.2456 17.0996 18.0893 17.2559C17.9331 17.4122 17.7211 17.5 17.5001 17.5H2.50008C2.27907
17.5 2.06711 17.4122 1.91083 17.2559C1.75455 17.0996 1.66675 16.8877 1.66675 16.6667V3.33333C1.66675
3.11232 1.75455 2.90036 1.91083 2.74408C2.06711 2.5878 2.27907 2.5 2.50008
2.5H15.0001V5.83333ZM3.33341 7.5V15.8333H16.6667V7.5H3.33341ZM3.33341
4.16667V5.83333H13.3334V4.16667H3.33341ZM12.5001 10.8333H15.0001V12.5H12.5001V10.8333Z"
fill="#253858"/>
</g>
</svg>
</template>
