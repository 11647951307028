<template>
<div v-if="!isLoading" class="page--details">
  <div class="card">
    <app-driver-summary
    v-if="currentPerformance?.metrics"
      :data="currentPerformance?.metrics"
      :currency="currency"
    ></app-driver-summary>
  </div>
  <div class="card">
    <app-driver-performence
    v-if="currentPerformance?.metrics && currentPerformance?.plots"
      :data="currentPerformance.metrics"
      :graphs="currentPerformance?.plots"
      :currency="currency"
    ></app-driver-performence>
  </div>
  <div class="card up">
    <app-driver-income
      v-if="currentPerformance?.metrics && currentPerformance?.plots"
      :data="currentPerformance?.metrics"
      :graphs="currentPerformance?.plots"
      :currency="currency"
    ></app-driver-income>
  </div>
</div>
<RLoading v-else></RLoading>
</template>

<script setup>
import { insightsStore } from '@/store/insights';
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import AppDriverIncome from '@/components/Insights/DriverIncome.vue';
import AppDriverSummary from '@/components/Insights/DriverSummary.vue';
import AppDriverPerformence from '@/components/Insights/DriverPerformance.vue';
import RLoading from '@/components/RLoading.vue';
import useEmitter from '@/composables/useEmitter';

const useInsights = insightsStore();
const router = useRouter();
const emitter = useEmitter();

const currentPerformance = computed(() => useInsights.getDriverCurrentPerformance);
const currency = computed(() => useInsights.getCurrencySymbol);
const isLoading = computed(() => useInsights.getIsLoading);

onMounted(() => {
  // Check if the driver details are already fetched if not fetch them
  useInsights.fetchDriverDetails(router.currentRoute.value.params.id).then(() => {
    useInsights.setDriverName(router.currentRoute.value.params.name);
  });

  emitter.on('insightSampleData', () => {
    useInsights.fetchDriverDetails(router.currentRoute.value.params.id).then(() => {
      useInsights.setDriverName(router.currentRoute.value.params.name);
    });
  });
});

onBeforeUnmount(() => {
  emitter.off('insightSampleData');
});
</script>
