<template>
  <div class="sections">
    <div class="sections__header">
      <p class="sections__header-title">Documents</p>
    </div>
    <div class="sections__content" v-for="doc in props.data" :key="doc.id">
      <div class="sections__image sections__image--small">
        <img :src="props.platform.logo" alt="company logo">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Platform</h3>
          <h1>{{ props.platform.name }}</h1>
        </div>
        <div class="sections__table">
          <table>
            <tr>
              <td>Name</td>
              <td>{{ doc.name || '-' }}</td>
            </tr>
            <tr>
              <td>Type</td>
              <td>{{ doc.type || '-' }}</td>
            </tr>
            <tr>
              <td>Document Number</td>
              <td>{{ doc.number || '-' }}</td>
            </tr>
            <tr>
              <td>Document ID</td>
              <td>{{ doc.id || '-' }}</td>
            </tr>
            <tr>
              <td>Retrieved Date</td>
              <td>{{ covertDate(doc.retrieved_date) || '-' }}</td>
            </tr>
            <tr>
              <td>Document Date</td>
              <td>{{ covertDate(doc.document_date) || '-' }}</td>
            </tr>
            <tr>
              <td>File</td>
              <td>
                <a :href="doc.link" target="_blank">
                  <i class="ri-download-fill color-blue-main"></i>
                  Download
                </a>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});
const covertDate = (date) => {
  const d = new Date(date);
  const dateSlice = {
    year: d.getFullYear(),
    month: d.toLocaleString('default', { month: 'long' }),
    day: d.getDate(),
  };
  return `${dateSlice.month} ${dateSlice.day}, ${dateSlice.year}`;
};

</script>
