<template>
    <div>
        <vue-apex-charts height="540px"
            type="line" :options="chartOptions" :series="chartSeries"></vue-apex-charts>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { percentile } from '@/util/helpers';

/* eslint-disable vue/no-setup-props-destructure */
const { metricsPerDay } = defineProps(['metricsPerDay']);

/* eslint-disable */
const chartOptions = ref({
  chart: {
    id: 'basic-line-chart',
  },
  xaxis: {
    categories: ['01 Jul', '01 Aug', '01 Sep'],
  },
  stroke: {
      colors: ['#0061FF'],
      width: 3.4
  },
  toolbar: {
    show: false,
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2 // Stroke width of the marker when hovering
    }
  },
  annotations: {
    yaxis: [
        {
            y: 10,  // The y-axis value where you want the line
            borderColor: '#EDF2F8',
            label: {
                show: false // Hide the label
            }
        },
        {
            y: 30,  // The y-axis value where you want the line
            borderColor: '#EDF2F8',
            label: {
                show: false // Hide the label
            }
        },
        {
            y: 50,  // The y-axis value where you want the line
            borderColor: '#EDF2F8',
            label: {
                show: false // Hide the label
            }
        },
        {
            y: 70,  // The y-axis value where you want the line
            borderColor: '#EDF2F8',
            label: {
                show: false // Hide the label
            }
        },
        {
            y: 90,  // The y-axis value where you want the line
            borderColor: '#EDF2F8',
            label: {
                show: false // Hide the label
            }
        },
    ],
  },
  yaxis: {
    min: 0,
    max: 100,
    tickAmount: 5,
    labels: {
      formatter: function (val) {
        return val + "%";
      }
    }
  },
  tooltip: {
    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
      const xValue = w.globals.categoryLabels[dataPointIndex]; // Corrected this line
      const currentData = metricsPerDay[dataPointIndex]
      return `
        <div class="custom-tooltip">
          <div class="custom-tooltip__header">${xValue}</div>
          <div class="custom-tooltip__item">
            <div>
                <span></span>
                <h6>Connected users</h6>
            </div>
            <h6>${currentData.connected_users}</h6>
          </div>
          <div class="custom-tooltip__item">
            <div>
                <span class="transparent"></span>
                <h6>Conversion rate</h6>
            </div>
            <h6>${percentile(currentData.conversion_rate)}%</h6>
          </div>
        </div>
      `;
    }
  }
});


const chartSeries = ref([
  {
    name: "Conversion Rate",
    data: [], // Initialize as empty
  },
]);

const formatDate = (dateString) => {
  const options = { day: '2-digit', month: 'short' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const populateChart = () => {
  const dates = metricsPerDay?.map(item => formatDate(item.date));
  const conversionRates = metricsPerDay?.map(item => percentile(item.conversion_rate)); // Use connected_users as conversion rate for now

  chartSeries.value[0].data = conversionRates;
  chartOptions.value.xaxis.categories = dates;
};

populateChart();
</script>

<style lang="scss" scoped>
:deep {
    .vue-apexcharts {
        padding-left: 32px;
        .apexcharts-toolbar {
            display: none;
        }
        .apexcharts-gridline {
            stroke-dasharray: 1, 1; /* Dash length, Gap length */
            stroke-width: 1; /* Stroke width */
            stroke: #EDF2F8; /* Stroke color */
        }
        .apexcharts-yaxis-texts-g, .apexcharts-xaxis-texts-g {
            tspan {
                fill: var(--color-grey-light, #A8AFBC);
                font-size: 12px;
                font-weight: 600;
                font-family: Gilroy;
            }
        }
        .apexcharts-tooltip {
            top: 100px !important; /* Position it at the top */
        }
        .apexcharts-xcrosshairs {
            stroke: #A8AFBC;
        }
    }
    .tab-container__content {
        padding-top: 20px;
    }
    .custom-tooltip {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        width: 180px;

        &__header {
            border-radius: 4px 4px 0px 0px;
            border: 1px solid #E2E3E3;
            background: #EDEEF1;
            color:var(--color-black, #141414);
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 500;
        }
        &__item {
            display: flex;
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            span {
                display: flex;
                height: 12px;
                width: 12px;
                border-radius: 12px;
                background: var(--color-blue-main, #0061FF);

                &.transparent {
                    background: transparent;
                }
            }
            div {
                display: flex;
                gap: 8px;
                align-items: center;
                h6 {
                    color: var(--color-black, #141414);
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            h6 {
                color: var(--color-black, #141414);
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}
</style>
