<!-- eslint-disable radix -->
<template>
  <div v-if="chartSeries">
    <div class="card__content">
      <vue-apex-charts :options="options" :series="chartSeries"></vue-apex-charts>
    </div>
    <div class="card__footer">
        <p>{{ props.text }}</p>
        <span>{{ props.percent || '-' }}%</span>
    </div>
  </div>
  <NoDataAvailable v-else/>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['data', 'percent', 'text', 'bars', 'color', 'currency']);

const chartSeries = ref(null);

const options = {
  chart: {
    type: 'bar',
    height: 350,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '100%',
      endingShape: 'rounded',
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ['transparent'],
  },
  xaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        // eslint-disable-next-line radix
        return parseInt(val);
      },
    },
    title: {
      text: `Earnings in ${props.currency}`,
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    formatter(val) {
      // eslint-disable-next-line radix
      return parseInt(val);
    },
  },
  yaxis: {
    title: {
      text: 'Number of users',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    labels: {
      formatter(val) {
        // eslint-disable-next-line radix
        return parseInt(val);
      },
    },
  },
  colors: [props.color],
  fill: {
    opacity: 1,
  },
  tooltip: {
    x: {
      formatter(val, ctx) {
        return ctx.w.config?.series[0]?.label[ctx.dataPointIndex];
      },
    },
  },
};

const mapDataForChart = (data) => {
  options.xaxis.categories = data.data.x;

  chartSeries.value = [
    {
      name: data.name,
      data: data.data.y,
      label: data.data.label,
    },
  ];
};
onMounted(() => {
  if (props.data?.data) {
    mapDataForChart(props.data);
  }
});
</script>

<style scoped>
.card__footer{
    border-top: 1px solid var(--color-grey-outline);
    padding-top: 24px;
    margin-top: 24px;
}
.card__footer p {
    color: var(--color-grey-dark, #7A808B);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    margin-bottom: 12px;
}
.card__footer span {
    color: var(--dark-main, #253858);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
}
</style>
