<template>
  <div
    class="loadmore"
    :class="{ rotating: loading }"
    @click="loadMore()"
    @keyup.enter="loadMore()">
    <div v-if="!loading">
      <LoadSpinner />
      Load More
    </div>
    <div v-else>
      <LoadSpinner />
      Loading now
    </div>
  </div>
</template>
<script>
import LoadSpinner from './icons/LoadSpinner.vue';

export default {
  name: 'RLoadMoreButton',
  components: {
    LoadSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    loadMore() {
      this.$emit('loadMore');
    },
  },
};
</script>
<style lang="scss" scoped>
.loadmore {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 146px;
  height: 40px;
  color: var(--color-dark-main);
  font-size: 14px;
  line-height: 40px;
  font-weight: 600;
  border: 1px solid var(--color-grey-outline);
  box-shadow: 0px 4px 8px rgba(20, 20, 20, 0.12);
  border-radius: 1000px;
  cursor: pointer;
  user-select: none;
  background: #fff;
  transition: var(--transition-default);
  opacity: 0.7;
  &.rotating {
    svg {
      filter: none;
      animation: rotate 1s ease-in-out infinite;
    }
  }
  svg {
    vertical-align: middle;
    margin-right: 12px;
    filter: grayscale(1);
  }
  &:hover {
    transform: scale(1.02);
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
