<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5240_47348)">
          <!-- eslint-disable-next-line max-len -->
            <path d="M6.53045 2C5.75725 2 5.13045 2.6268 5.13045 3.4V7.63447V23.42C5.13075 23.7166 5.24877 24.0009 5.4586 24.2105C5.66842 24.4202 5.95287 24.5379 6.24945 24.5379H24.2955C24.5915 24.5358 24.8747 24.4174 25.0841 24.2083C25.2935 23.9991 25.4122 23.716 25.4146 23.42V3.11788C25.4146 2.50034 24.9131 2 24.2955 2H6.53045ZM15.2725 12.7055C14.5253 12.7055 13.8087 12.4087 13.2804 11.8803C12.7521 11.352 12.4553 10.6354 12.4553 9.88826C12.4553 9.14108 12.7521 8.42451 13.2804 7.89618C13.8087 7.36784 14.5253 7.07103 15.2725 7.07103C16.0197 7.07103 16.7363 7.36784 17.2646 7.89618C17.7929 8.42451 18.0897 9.14108 18.0897 9.88826C18.0897 10.6354 17.7929 11.352 17.2646 11.8803C16.7363 12.4087 16.0197 12.7055 15.2725 12.7055ZM20.3131 18.9034H10.2319C10.3685 17.662 10.9584 16.5147 11.8885 15.6813C12.8187 14.8479 14.0236 14.3871 15.2725 14.3871C16.5214 14.3871 17.7263 14.8479 18.6565 15.6813C19.5866 16.5147 20.1765 17.662 20.3131 18.9034Z" fill="url(#paint0_linear_5240_47348)" />
            <!-- eslint-disable-next-line max-len -->
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.625 5.30415C2.625 4.58618 3.20703 4.00415 3.925 4.00415H21.7901C22.4076 4.00415 22.9091 4.50449 22.9091 5.12203V25.4242C22.9067 25.7201 22.7881 26.0032 22.5787 26.2124C22.3693 26.4216 22.086 26.54 21.7901 26.542H3.74401C3.44742 26.542 3.16297 26.4243 2.95315 26.2147C2.74332 26.0051 2.6253 25.7207 2.625 25.4242V9.63862V5.30415Z" fill="url(#paint1_linear_5240_47348)" />
            <!-- eslint-disable-next-line max-len -->
            <path d="M16.6889 17.7754L12.7998 21.583L8.9107 17.7754C8.14151 17.0223 7.6177 16.0628 7.40548 15.0183C7.19327 13.9737 7.30219 12.891 7.71848 11.9071C8.13476 10.9232 8.83971 10.0822 9.74418 9.4905C10.6486 8.89882 11.712 8.58301 12.7998 8.58301C13.8876 8.58301 14.951 8.89882 15.8554 9.4905C16.7599 10.0822 17.4648 10.9232 17.8811 11.9071C18.2974 12.891 18.4063 13.9737 18.1941 15.0183C17.9819 16.0628 17.4581 17.0223 16.6889 17.7754V17.7754ZM12.7998 15.1644C13.124 15.1644 13.4348 15.0383 13.664 14.8139C13.8933 14.5895 14.022 14.2851 14.022 13.9677C14.022 13.6504 13.8933 13.346 13.664 13.1216C13.4348 12.8972 13.124 12.7711 12.7998 12.7711C12.4757 12.7711 12.1648 12.8972 11.9356 13.1216C11.7064 13.346 11.5776 13.6504 11.5776 13.9677C11.5776 14.2851 11.7064 14.5895 11.9356 14.8139C12.1648 15.0383 12.4757 15.1644 12.7998 15.1644Z" fill="url(#paint2_linear_5240_47348)" />
        </g>
        <defs>
          <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint0_linear_5240_47348" x1="51.0416" y1="33.2083" x2="53.1045" y2="1.53392" gradientUnits="userSpaceOnUse">
                <stop stop-color="#017BFE" />
                <stop offset="1" stop-color="#0061FF" stop-opacity="0.28" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint1_linear_5240_47348" x1="6" y1="6.5" x2="22.1423" y2="26.2027" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="#0061FF" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint2_linear_5240_47348" x1="19.1592" y1="29.8557" x2="14.6206" y2="7.34934" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
            <clipPath id="clip0_5240_47348">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>
