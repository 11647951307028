<!-- eslint-disable consistent-return -->
<!-- eslint-disable no-promise-executor-return -->
<template>
  <div v-if="props.id">
    <svg class="overlay">
    <defs>
      <mask id="cutout" x="0" y="0" width="100%" height="100%" maskUnits="userSpaceOnUse">
        <rect x="0" y="0" width="100%" height="100%" fill="white" />
        <rect ref="higlight" />
      </mask>
    </defs>
  </svg>
  <div ref="wrapper" class="overlay__wrapper">
    <slot name="component"></slot>
    <div ref="content" class="overlay__content">
      <slot name="tooltip"></slot>
    </div>
  </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';

const higlight = ref(null);
const wrapper = ref(null);
const content = ref(null);

const targetElementForHighlight = ref(null);

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
  offsetX: {
    type: Number,
    default: 20,
  },
  offsetY: {
    type: Number,
    default: 0,
  },
  position: {
    type: String,
  },
  target: {
    type: String,
    default: 'p',
  },
  interactive: {
    type: Boolean,
    default: false,
  },
});

const targetElementForArrow = () => {
  const targetElement = targetElementForHighlight.value.getElementsByTagName(props.target)[0];
  if (targetElement) {
    // check if arrow exists and remove it
    if (content.value.getElementsByClassName('arrow').length > 0) {
      content.value.getElementsByClassName('arrow')[0].remove();
    }
    const targetRect = targetElement.getBoundingClientRect();
    const highlightedRect = targetElementForHighlight.value.getBoundingClientRect();
    const arrow = document.createElement('div');
    arrow.classList.add('arrow');
    arrow.style.position = 'absolute';
    arrow.style.width = '0';
    arrow.style.height = '0';
    arrow.style.borderLeft = '10px solid transparent';
    arrow.style.borderRight = '10px solid transparent';
    arrow.style.borderBottom = '10px solid #FFF';
    switch (props.position) {
      case 'right':
        arrow.style.top = `${(targetRect.top - highlightedRect.top + (targetRect.height / 2)) - props.offsetY}px`;
        arrow.style.left = '-15px';
        arrow.style.transform = 'rotate(-90deg)';
        break;
      case 'left':
        arrow.style.top = `${(targetRect.top - highlightedRect.top + (targetRect.height / 2)) - props.offsetY}px`;
        arrow.style.right = '-15px';
        arrow.style.transform = 'rotate(90deg)';
        break;
      case 'bottom':
        arrow.style.top = '-10px';
        arrow.style.left = `${(targetRect.left - highlightedRect.left + (targetRect.width / 2)) - props.offsetX}px`;
        break;
      default:
        arrow.style.bottom = '-10px';
        arrow.style.left = `${(targetRect.width / 2) - 5}px`;
        arrow.style.transform = 'rotate(180deg)';
        break;
    }
    content.value.appendChild(arrow);
  }
};

const calculateTooltipPosition = () => {
  // Calculate the position of the tooltip based on the highlight element
  const contentTooltip = content.value.getBoundingClientRect();
  content.value.style.top = null;
  content.value.style.right = null;
  content.value.style.bottom = null;
  content.value.style.left = null;
  switch (props.position) {
    case 'right':
      content.value.style.top = `${props.offsetY}px`;
      content.value.style.right = `-${contentTooltip.width + props.offsetX}px`;
      break;
    case 'left':
      content.value.style.top = `${props.offsetY}px`;
      content.value.style.left = `-${contentTooltip.width + props.offsetX}px`;
      break;
    case 'bottom':
      content.value.style.bottom = `-${contentTooltip.height + props.offsetY}px`;
      content.value.style.left = `- ${props.offsetX}px`;
      break;
    case 'top':
      content.value.style.top = `-${contentTooltip.height + props.offsetY}px`;
      content.value.style.left = `${props.offsetX}px`;
      break;
    default:
      content.value.display = 'none';
      break;
  }
  targetElementForArrow();
};

const hightlightElement = () => {
  // find the id of the global element you want to target and get its bounding client rect
  const targetRect = targetElementForHighlight.value.getBoundingClientRect();
  const targetCss = getComputedStyle(targetElementForHighlight.value);
  // set the position of the highlight element to the same position as the target element
  if (props.interactive) {
    targetElementForHighlight.value.classList.add('interactive');
  }
  higlight.value.style.y = targetRect.top;
  higlight.value.style.x = targetRect.left;
  higlight.value.style.width = targetRect.width;
  higlight.value.style.height = targetRect.height;
  higlight.value.style.fill = 'black';
  higlight.value.style.rx = targetCss?.borderRadius;
  // set the position of the wrapper to the same position as the target element
  wrapper.value.style.top = `${targetRect.top}px`;
  wrapper.value.style.left = `${targetRect.left}px`;
  wrapper.value.style.width = `${targetRect.width}px`;
  wrapper.value.style.height = `${targetRect.height}px`;
  calculateTooltipPosition();
};

function waitForElm(selector) {
  // eslint-disable-next-line consistent-return
  return new Promise((resolve) => {
    if (document.getElementById(selector)) {
      resolve(document.getElementById(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.getElementById(selector)) {
        observer.disconnect();
        resolve(document.getElementById(selector));
      }
      if (document?.getElementsByClassName(selector).length > 0) {
        observer.disconnect();
        resolve(document?.getElementsByClassName(selector)[0]);
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

watch(() => props.id, () => {
  waitForElm(props.id).then((elm) => {
    targetElementForHighlight?.value?.classList?.remove('interactive');
    targetElementForHighlight.value = elm;
    hightlightElement();
  });
});
</script>
<style lang="scss" scoped>
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 998;
  background: rgba(37, 56, 88,0.8);
  mask: url(#cutout);
  &__wrapper{
    z-index: 999;
    position: fixed;
  }
  &__content{
    position: absolute;
    border-radius: 10px;
    width: 400px;
    padding: 20px 20px 12px 20px;
    background-color: #FFF;
  }
}
</style>
