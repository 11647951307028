<template>
  <div class="sections">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Activity</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div v-if="activity"
      class="sections__content">
      <div class="sections__image sections__image--small">
        <img :src="platform.logo" alt="">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Platform</h3>
          <h1>{{ platform.name }}</h1>
        </div>
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr v-if="activity['online_duration'] !== undefined">
                <td>Online</td>
                <td>{{ activity.online_duration }}</td>
              </tr>
              <tr v-if="activity['time_on_trips'] !== undefined">
                <td>Time On Trips</td>
                <td>{{ activity.time_on_trips }}</td>
              </tr>
              <tr v-if="activity['trips_total'] !== undefined">
                <td>Total Trips</td>
                <td>{{ activity.trips_total }}</td>
              </tr>
              <tr v-if="activity['acceptance_rate'] !== undefined">
                <td>Acceptance Rate</td>
                <td>
                  {{ activity.acceptance_rate }}%
                </td>
              </tr>
              <tr v-if="activity['cancellation_rate'] !== undefined">
                <td>Cancelation Rate</td>
                <td>
                  {{ activity.cancellation_rate }}%
                </td>
              </tr>
              <tr v-if="activity['distance_total'] !== undefined">
                <td>Distance</td>
                <td>
                  {{ activity.distance_total.toFixed(2) }} km
                </td>
              </tr>

            </table>
          </div>
          <div class="sections__table-part">
            <table>
              <tr v-if="activity['hours_most_active'] !== undefined">
                <td>Hours Most Active</td>
                <td>
                  {{
                    activity
                      .hours_most_active.toLowerCase()
                      .replace(/^\w|\s\w/g, (l) => l.toUpperCase())
                  }}
                </td>
              </tr>
              <tr v-if="activity['price_per_online_hour'] !== undefined">
                <td>Hourly Earning</td>
                <td>{{ numberFormat(activity.price_per_online_hour) }} {{ currencySign }}</td>
              </tr>
              <tr v-if="activity['price_per_driving_hour'] !== undefined">
                <td>Hourly Earning</td>
                <td>{{ numberFormat(activity.price_per_driving_hour) }} {{ currencySign }}</td>
              </tr>
              <tr v-if="activity['time_on_trips'] !== undefined &&
                        activity['trips_total'] !== undefined">
                <td>Trips / Hour</td>
                <td>
                  {{ tripsPerHour(activity.trips_total, activity.time_on_trips).toFixed(2) }}
                </td>
              </tr>
              <tr v-if="activity['price_total'] !== undefined &&
                        activity['trips_total'] !== undefined">
                <td>Price / Trip</td>
                <td>
                  {{ numberFormat(activity.price_total / activity.trips_total) }}
                  {{ currencySign }}
                </td>
              </tr>
              <tr v-if="activity['price_per_km'] !== undefined ">
                <td>Price / Kilometer</td>
                <td>
                  {{ numberFormat(activity.price_per_km)}} {{ currencySign }}
                </td>
              </tr>
              <tr v-if="activity['currency'] !== undefined ">
                <td>currencySign</td>
                <td>
                  {{ currencySign }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="sections__no-data" v-else>
      No data
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { numberFormat } from '@/util/helpers';
import RComboButton from '@/components/RComboButton.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const activity = computed(() => props.data);

const emits = defineEmits(['download']);
const tripsPerHour = (trips, hours) => {
  if (trips === 0 || hours === 0) return 0;
  const stringHours = hours.toString().split(':');
  const calculatedHours = parseInt(stringHours[0], 10)
    + (parseInt(stringHours[1], 10) / 60)
    + (parseInt(stringHours[2], 10) / 3600);
  return trips / calculatedHours;
};

const currencySign = computed(() => {
  if (activity.value.currency === 'EUR') return '€';
  if (activity.value.currency === 'USD') return '$';
  return activity.value.currency;
});

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'activity' });
};

</script>
<style lang="scss" scoped>
.chart {
  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__total {
    display: grid;
    justify-items: end;
    text-align: end;
    padding-right: 60px;
    table {
      min-width: 260px;
      td {
        &:first-child {
          text-align: left;
        }
      }
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-grey-dark);
      line-height: 15px;
      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 160%;
        color: var(--color-dark-main);
      }
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 12px;
        vertical-align: top;
      }
      &.gross {
        &:before {
          background: #66A0FF;
        }
      }
      &.net {
        &:before {
          background: var(--color-blue-main);
        }
      }
    }
  }
  &__selector {
    font-size: 15px;
    line-height: 22px;
    padding-left: 16px;
    cursor: pointer;
    i {
      font-size: 22px;
      line-height: 22px;
      vertical-align: middle;
      color: var(--color-dark-main);
    }
    &-option {
      justify-self: end;
    }
  }
};
</style>
