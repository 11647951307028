// eslint-disable-next-line import/prefer-default-export
export const errorsMap = {
  'credentials-password': 'Credentials Errors',
  'credentials-code': 'Credentials Errors',
  'invalid-phone': 'Credentials Errors',
  credentials: 'Credentials Errors',
  'credentials-email': 'Credentials Errors',
  'account-disabled': 'Credentials Errors',
  'credentials-phone': 'Credentials Errors',
  'blocked-account': 'Other Errors',
  'unsupported-login': 'Credentials Errors',
  unknown: 'Other Errors',
  'failed-mfa': 'MFA Errors',
  'account-on-hold': 'Credentials Errors',
  'server-error': 'Other Errors',
};

// eslint-disable-next-line no-unused-vars
export const groupErrors = (errName) => (errorsMap[errName] ? errorsMap[errName] : 'Other Errors');
