<template>
  <div class="user-management">
    <div class="user-management__header">
      <div class="user-management__search">
        <RInput
            v-model="search"
            left-icon="ri-search-line"
            compact
            v-on:input="onFilterTextBoxChanged()"
            placeholder="Search by name, status email or integration..."
          />
      </div>
      <div class="user-management__actions">
        <Transition name="fade">
          <RDropdown
            v-if="
            bulkActionsCount && (
              showBulkButtonType === 'disconnected'
              || showBulkButtonType === 'pending'
              )"
            :title="`Bulk Actions (${bulkActionsCount})`"
            class="bulk"
            :items="bulkActionsList"
            @action="bulkDropdownAction"
          />
        </Transition>
        <Transition name="fade">
          <RButton
            v-if="!modalInvite.invitedUsers?.length && !showBulkButton"
            before-icon="ri-add-line"
            @click="modalInvite.show = true"
          >Invite Users</RButton>
        </Transition>
        <Transition name="fade">
          <!-- eslint-disable-next-line -->
          <RButton v-if="(showBulkButtonType === 'connected' && bulkActionsCount > 0) || (showBulkButtonType === 'mixed' && bulkActionsCount > 0)"
            type="warn"
            before-icon="ri-remove-line"
            @click="removeAccountsAction()"
          >Remove accounts ({{ bulkActionsCount }})</RButton>
        </Transition>
      </div>
    </div>
    <div class="user-management__content">
      <ag-grid-vue
        class="ag-theme-alpine"
        :columnDefs="columnDefs.value"
        :rowData="accounts"
        rowHeight="52"
        :animateRows="true"
        @grid-ready="onGridReady"
        rowSelection="multiple"
        @row-selected="onRowSelected"
        :suppressRowClickSelection="true"
        :suppressCellSelection="true"
      ></ag-grid-vue>
    </div>
    <Transition name="fade">
      <RInvitationModal
        v-if="modal.show"
        title="Send request to reconnect users’ accounts"
        :disabled="modal.sendDisabled()"
        @cta="onSubmit"
        @close="modal.show = false">
        <div>
          <br>
          <h3 class="modal__heading">Users to invite</h3>
          <p class="modal__desc">
            Request your users to connect their work accounts by simple invitation via email.</p>
          <br>
          <br>
          <h3 class="modal__heading">
            Invited users
            <span v-if="modal.invitedUsers.length > 0">
              ({{ modal.invitedUsers.length }})</span></h3>
          <div class="modal__invited">
            <div class="modal__invited-users" v-for="user in modal.invitedUsers" :key="user">
              <p>{{ user.email }}</p>
            </div>
          </div>
          <br>
          <div v-if="customizationList.length > 0">
            <hr class="modal__line" />
            <br>
            <h3 class="modal__heading">Connect customization</h3>
            <br>
            <div>
              <RList
                title="Action"
                :items="customizationList"
                @action="dropdownAction"
                :value="item"
              />
            </div>
          </div>
          <br>
        </div>
      </RInvitationModal>
    </Transition>
    <Transition name="fade">
      <RInvitationModal
        v-if="modalPending.show"
        title="Resend invitation to users"
        :disabled="modalPending.sendDisabled()"
        @cta="onPendingSubmit"
        @close="modalPending.show = false">
        <div>
          <br>
          <h3 class="modal__heading">Users to invite</h3>
          <p class="modal__desc">
            Request your users to connect their work accounts by simple invitation via email.</p>
          <br>
          <br>
          <h3 class="modal__heading">
            Invited users
            <span v-if="modalPending.invitedUsers.length > 0">
              ({{ modalPending.invitedUsers.length }})</span></h3>
          <div class="modal__invited">
            <div class="modal__invited-users" v-for="user in modalPending.invitedUsers" :key="user">
              <p>{{ user.email }}</p>
            </div>
          </div>
          <br>
          <div v-if="customizationList.length > 0">
            <hr class="modal__line" />
            <br>
            <h3 class="modal__heading">Connect customization</h3>
            <br>
            <div>
              <RList
                title="Action"
                :items="customizationList"
                @action="dropdownAction"
                :value="item"
              />
            </div>
          </div>
          <br>
        </div>
      </RInvitationModal>
    </Transition>
    <Transition name="fade">
    <RInvitationModal
      v-if="modalInvite.show"
      title="Invite New Users"
      :disabled="modalInvite.sendDisabled()"
      @cta="onNewSubmit()"
      @close="modalInvite.show = false">
      <div>
        <br>
        <h3 class="modal__heading">Users to invite</h3>
        <p class="modal__desc">
          Request your users to connect their work accounts by simple invitation via email.</p>
        <br>
        <RInput
            label="Email Address"
            ref="emailInput"
            type="email"
            :key="singleEmail.key"
            :error="modalInvite.error"
            v-model="singleEmail.value"
            placeholder="Type Enter to add address"
            @keyup.enter="modalInvite.addInvitedUser();"
        />
        <br>
        <h3 class="modal__heading">
          Invited users
          <!-- eslint-disable-next-line -->
          <span v-if="modalInvite.invitedUsers.length > 0"> ({{ modalInvite.invitedUsers.length }})</span></h3>
        <div class="modal__invited">
          <div class="modal__invited-users" v-for="user in modalInvite.invitedUsers" :key="user">
            <p>{{ user }}</p>
            <CloseFilledIcon @click="modal.removeInvitedUser(user)" />
          </div>
        </div>
        <BulkInviteUsers @invited="fileInvite"/>
        <br>
        <div v-if="customizationList.length > 0">
          <hr class="modal__line" />
          <br>
          <h3 class="modal__heading">Connect customization</h3>
          <br>
          <div>
            <RList
              title="Action"
              :items="customizationList"
              @action="dropdownAction"
              :value="item"
            />
          </div>
        </div>
        <br>
      </div>
    </RInvitationModal>
  </Transition>
    <Transition name="fade">
      <RModalRemove
        v-if="modalRemove.show"
        :data="modalRemove.data"
        @submit="onRemove"
        @close="modalRemoveClose()"
      />
    </Transition>
  </div>
</template>
<script setup>
import {
  onMounted, ref, reactive, computed,
  watch,
} from 'vue';
import useEmitter from '@/composables/useEmitter';
import { usersStore } from '@/store/users';
import { appStore } from '@/store/app';
import { validateEmail } from '@/util/validations';
import RInvitationModal from '@/components/RInvitationModal.vue';
import RInput from '@/components/RInput.vue';
// eslint-disable-next-line
import AgGridCustomActions from '@/components/AgGridCustomActions.vue';
import RModalRemove from '@/components/RModalRemove.vue';
import { customizationStore } from '@/store/customizations';
// import { settingsStore } from '@/store/settings';
import RList from '@/components/RList.vue';
// import { format } from 'date-fns';
import { AgGridVue } from 'ag-grid-vue3';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import * as AGGrid from 'ag-grid-enterprise';
import RDropdown from '@/components/RDropdown.vue';
import RButton from '@/components/RButton.vue';
import { debounce } from 'lodash';

// eslint-disable-next-line
AGGrid.LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-053293}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Rollee}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Dashboard}_need_to_be_licensed___{Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_March_2025}____[v3]_[01]_MTc0MTgyNDAwMDAwMA==7bc0d51f7c7de4de44584336d8e22ae0');

const emitter = useEmitter();

const singleEmail = reactive({
  value: '',
  key: 0,
});

const bulkActionsList = ref();

const showBulkButton = ref(false);

const accounts = computed(() => usersStore().getAccounts);

const gridApi = ref(null);

const onGridReady = (params) => {
  gridApi.value = params.api;
};

const search = ref('');

const columnDefs = reactive({
  value: [
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      hide: true,
      checkboxSelection: true,
    },
    {
      field: 'full_name',
      headerName: 'Name',
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      checkboxSelection: true,
      headerCheckboxSelection: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        const currentVal = params.node.data;
        if (currentVal && currentVal.status === 'connected') {
          return `
          <div class="status" style="display:flex; align-items:center; gap:8px">
            <i class="ri-checkbox-circle-line" style="color:var(--color-blue-main)"></i>
            <span>Connected</span>
          </div>
          `;
        }
        if (currentVal && currentVal.status === 'disconnected') {
          return `
          <div class="status" style="display:flex; align-items:center; gap:8px">
            <i class="ri-error-warning-line" style="color:#FE9543"></i>
            <span>Disconnected</span>
          </div>
          `;
        }
        if (currentVal && currentVal.status === 'pending') {
          return `
          <div class="status" style="display:flex; align-items:center; gap:8px">
            <i class="ri-rest-time-line" style="color:#7A808B"></i>
            <span>Pending</span>
          </div>
          `;
        }
        if (currentVal && currentVal.status === 'reconnecting') {
          return `
          <div class="status" style="display:flex; align-items:center; gap:8px">
            <i class="ri-rest-time-line" style="color:#7A808B"></i>
            <span>Pending/Reconnected</span>
          </div>
          `;
        }
        return '-';
      },
    },
    {
      field: 'platform.name',
      headerName: 'Integration',
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
      cellRenderer: (params) => {
        const { value } = params;
        const currentVal = params.node.data;
        if (value) {
          return `
            <div class="ag-grid__integration " v-if="value.id">
              <img class="ag-grid__integration-logo" src="${currentVal.platform.logo}" alt="${currentVal.platform.name}" />
              <span class='ag-grid__integration-title'>${currentVal.platform.name}</span>
            </div>
          `;
        }
        return '-';
      },
    },
    {
      field: 'email',
      headerName: 'Email',
      sortable: true,
      filter: true,
      resizable: true,
      flex: 1,
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 140,
      cellClass: 'ag-grid__cell',
      cellRenderer: AgGridCustomActions,
      cellRendererParams: {
        actionList: bulkActionsList,
      },
    },
  ],
});

const loadingData = ref(true);

const modalRemove = reactive({
  show: false,
  data: {
    name: '',
    description: '',
  },
});

const modalRemoveClose = () => {
  modalRemove.show = false;
  window?.analytics?.track('bulk_user_management_cancel');
};

const getRemoveModal = computed(() => usersStore().getModal);
const getWatchAction = computed(() => usersStore().getWatchAction);

watch(getRemoveModal, () => {
  if (usersStore().getModal.multiple) {
    window?.analytics?.track('bulk_user_management_remove', {
      action: 'remove',
      data: getRemoveModal.value.data,
    });
    modalRemove.data.description = `Are you sure you want to remove <b> ${getRemoveModal.value.data.length} </b> accounts? You will lose connection and all of the user's data`;
  } else {
    window?.analytics?.track('action_user_management', {
      action: 'remove',
      data: getRemoveModal.value.data,
    });
    modalRemove.data.description = `Are you sure you want to remove <b> ${getRemoveModal.value.data[0].full_name}'s </b> account? You will lose connection and all of the user's data`;
  }
  modalRemove.show = usersStore().getModal.show;
});

const onRemove = () => {
  usersStore().removeBulk(usersStore().getModal.data).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'User account removed',
    });
    modalRemove.show = false;
    // eslint-disable-next-line no-use-before-define
    bulkActionsCount.value = 0;
    usersStore().removeAccounts(usersStore().getModal.data);
    window?.analytics?.track('bulk_user_management_remove', {
      data: usersStore().getModal.data,
    });
  }).catch((err) => {
    appStore().setNotification({
      message: err.response?.data.error,
      type: 'error',
    });
    modalRemove.show = false;
  });
};

const modalInvite = reactive({
  show: false,
  loading: false,
  invitedUsers: [],
  fileInvitedUsers: [],
  error: undefined,
  sendDisabled() {
    return !validateEmail(singleEmail.value)
          && this.invitedUsers.length === 0 && this.fileInvitedUsers.length === 0;
  },
  addInvitedUser() {
    if (singleEmail.value) {
      if (!validateEmail(singleEmail.value)) {
        this.error = 'Incorrect email format. Please try again';
        return;
      }

      if (modalInvite.invitedUsers.includes(singleEmail.value)) {
        this.error = 'Email already exists';
        return;
      }
      modalInvite.invitedUsers.push(singleEmail.value);
      singleEmail.key += 1;
      setTimeout(() => {
        document.querySelector('#emailaddress').focus();
      }, 100);
      window.analytics?.track('email_user_management_invite_users');
    }
  },
  removeInvitedUser(email) {
    modalInvite.invitedUsers = modalInvite.invitedUsers.filter((user) => user !== email);
  },
  resetModal() {
    this.show = false;
    this.error = undefined;
    this.loading = false;
    this.invitedUsers = [];
    this.fileInvitedUsers = [];
    singleEmail.value = '';
  },
});

const modal = reactive({
  show: false,
  loading: false,
  invitedUsers: [],
  fileInvitedUsers: [],
  error: undefined,
  sendDisabled() {
    return !validateEmail(singleEmail.value)
      && this.invitedUsers.length === 0 && this.fileInvitedUsers.length === 0;
  },
  addInvitedUser() {
    if (singleEmail.value) {
      if (!validateEmail(singleEmail.value)) {
        this.error = 'Incorrect email format. Please try again';
        return;
      }

      if (modal.invitedUsers.includes(singleEmail.value)) {
        this.error = 'Email already exists';
        return;
      }
      modal.invitedUsers.push(singleEmail.value);
      singleEmail.key += 1;
      setTimeout(() => {
        document.querySelector('#emailaddress').focus();
      }, 100);
    }
  },
  removeInvitedUser(email) {
    modal.invitedUsers = modal.invitedUsers.filter((user) => user !== email);
  },
  resetModal() {
    this.show = false;
    this.error = undefined;
    this.loading = false;
    this.invitedUsers = [];
    this.fileInvitedUsers = [];
    singleEmail.value = '';
  },
});

const showBulkButtonType = ref('false');
const bulkActionsCount = ref(0);

const modalPending = reactive({
  show: false,
  loading: false,
  invitedUsers: [],
  fileInvitedUsers: [],
  error: undefined,
  sendDisabled() {
    return !validateEmail(singleEmail.value)
      && this.invitedUsers.length === 0 && this.fileInvitedUsers.length === 0;
  },
  addInvitedUser() {
    if (singleEmail.value) {
      if (!validateEmail(singleEmail.value)) {
        this.error = 'Incorrect email format. Please try again';
        return;
      }

      if (modalPending.invitedUsers.includes(singleEmail.value)) {
        this.error = 'Email already exists';
        return;
      }
      modalPending.invitedUsers.push(singleEmail.value);
      singleEmail.key += 1;
      setTimeout(() => {
        document.querySelector('#emailaddress').focus();
      }, 100);
    }
  },
  removeInvitedUser(email) {
    modal.invitedUsers = modal.invitedUsers.filter((user) => user !== email);
  },
  resetModal() {
    this.show = false;
    this.error = undefined;
    this.loading = false;
    this.invitedUsers = [];
    this.fileInvitedUsers = [];
    singleEmail.value = '';
  },
});

const onRowSelected = () => {
  modal.resetModal();
  modalPending.resetModal();

  const selectedNodes = gridApi.value.getSelectedNodes().filter((node) => node.displayed);
  const allConnected = selectedNodes.every((node) => node.data.status === 'connected');
  const allDisconnected = selectedNodes.every((node) => node.data.status === 'disconnected');
  const allPending = selectedNodes.every((node) => node.data.status === 'pending');

  window?.analytics?.track('checkbox_user_management', {
    data: selectedNodes.map((node) => node.data),
  });

  bulkActionsCount.value = selectedNodes.length;

  showBulkButton.value = !!selectedNodes.length;
  if (allConnected) {
    showBulkButtonType.value = 'connected';
  } else if (allDisconnected) {
    showBulkButtonType.value = 'disconnected';
    bulkActionsList.value = [
      {
        id: 'reconnect',
        title: 'Reconnect',
        icon: 'ri-links-line',
      },
      {
        id: 'remove',
        title: 'Remove',
        icon: 'ri-delete-bin-7-line',
      },
    ];
  } else if (allPending) {
    showBulkButtonType.value = 'pending';
    bulkActionsList.value = [
      {
        id: 'resend',
        title: 'Resend invite',
        icon: 'ri-mail-send-line',
      },
      {
        id: 'remove',
        title: 'Remove',
        icon: 'ri-delete-bin-7-line',
      },
    ];
  } else {
    showBulkButtonType.value = 'mixed';
  }

  selectedNodes.forEach((node) => {
    if (node.data.email) {
      if (showBulkButtonType.value === 'disconnected') {
        modal.invitedUsers.push({
          id: node.data.id,
          email: node.data.email,
        });
      }
      if (showBulkButtonType.value === 'pending') {
        modalPending.invitedUsers.push({
          id: node.data.id,
          email: node.data.email,
        });
      }
    }
  });
};

watch(getWatchAction, () => {
  window.analytics?.track('user_management_action', {
    action: getWatchAction.value?.action,
    email: getWatchAction.value?.data.email,
  });
  if (getWatchAction.value?.action === 'reconnect') {
    modal.invitedUsers = [];
    modal.invitedUsers.push({
      id: getWatchAction.value.data.id,
      email: getWatchAction.value.data.email,
    });
    modal.show = true;
    window.analytics?.track('bulk_user_management_reconnect');
  }
  if (getWatchAction.value?.action === 'resend') {
    modalPending.invitedUsers = [];
    modalPending.invitedUsers.push({
      id: getWatchAction.value.data.id,
      email: getWatchAction.value.data.email,
    });
    modalPending.show = true;
    window.analytics?.track('bulk_user_management_resend');
  }
});

watch(() => modalInvite.show, (value) => {
  if (value) {
    window.analytics?.track('invite_user_management');
  } else {
    window.analytics?.track('close_user_management_invite_users');
  }
});

const customizationList = computed(() => {
  if (!customizationStore().getAllCustomizations) return [];
  return customizationStore().getAllCustomizations.map((item) => ({
    id: item.id,
    title: item.config?.name,
  }));
});

const customizationID = ref();

// const emailCustomizationId = settingsStore().getEmailCustomizationsFetch
//   && settingsStore().getEmailCustomizationsFetch[0]?.id;

const onSubmit = () => {
  modal.loading = true;
  // Merge manually invited and file invited users and filter out duplicated entries
  // with spread new Set

  const invites = [
    ...new Set([
      ...modal.invitedUsers,
    ]),
  ];

  const allInvitedUsers = {
    customization_id: customizationID.value,
    accounts: invites.map((invite) => ({
      id: invite.id,
    })),
  };

  if (allInvitedUsers.accounts.length === 0) {
    return;
  }

  usersStore().sendAccountRecconect(allInvitedUsers).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Reconnect Invitations sent',
    });
    modal.resetModal();
    usersStore().clearWatchAction();
  }).catch((err) => {
    appStore().setNotification({
      message: err.response?.data.error,
      type: 'error',
    });
    modal.resetModal();
  });
};

const onPendingSubmit = () => {
  modalPending.loading = true;
  // Merge manually invited and file invited users and filter out duplicated entries
  // with spread new Set
  const invites = [
    ...new Set([
      ...modalPending.invitedUsers,
    ]),
  ];

  const allInvitedUsers = {
    // customization_id: customizationID.value,
    customization_id: customizationID.value,
    users: invites.map((invite) => ({
      email: invite.email,
    })),
  };

  if (allInvitedUsers.users.length === 0) {
    return;
  }

  usersStore().sendUsersInvite(allInvitedUsers).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Invitations sent',
    });
    modalPending.resetModal();
    usersStore().clearWatchAction();
  }).catch((err) => {
    appStore().setNotification({
      message: err.response?.data.error,
      type: 'error',
    });
    modalPending.resetModal();
  });
};

const onNewSubmit = () => {
  modalInvite.loading = true;
  // Merge manually invited and file invited users and filter out duplicated entries
  // with spread new Set
  const invites = [
    ...new Set([
      ...modalInvite.invitedUsers,
      ...modalInvite.fileInvitedUsers,
      singleEmail.value,
    ]),
  ];
  window.analytics?.track('send_data_view_invite_users');

  const allInvitedUsers = {
    customization_id: customizationID.value,
    users: invites.map((invite) => ({
      email: invite,
    })),
  };

  if (allInvitedUsers.users.length === 0) {
    return;
  }

  usersStore().sendUsersInvite(allInvitedUsers).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Invitations sent',
    });
    modalInvite.resetModal();
    usersStore().clearWatchAction();
  }).catch((err) => {
    appStore().setNotification({
      message: err.response?.data.error,
      type: 'error',
    });
    modalPending.resetModal();
  });
  window.analytics?.track('Users Invited', {
    users: allInvitedUsers.users,
  });
};

const onFilterTextBoxChanged = debounce(() => {
  gridApi.value.setQuickFilter(search.value);
  onRowSelected();
}, 500);

onMounted(() => {
  usersStore().fetchAccounts().then(() => {
    loadingData.value = false;
  });
  customizationStore().fetchCustomizations();
  appStore().setNotification({
    type: 'success',
    message: 'User accounts loaded.',
  });
  emitter.on('reload', () => {
    loadingData.value = true;
    usersStore().fetchAccounts();

    customizationStore().fetchCustomizations().then(() => {
      appStore().setNotification({
        type: 'success',
        message: 'User accounts loaded.',
      });
      loadingData.value = false;
    });
  });
});

const removeAccountsAction = () => {
  usersStore().setModal({
    show: true,
    multiple: true,
    data: gridApi.value.getSelectedNodes()
      .filter((node) => node.displayed)
      .map((node) => node.data),
  });
};

const dropdownAction = (e) => {
  customizationID.value = e.id;
  window.analytics?.track('customization_user_management_invite_users', {
    customizationID: e.id,
  });
};

const bulkDropdownAction = (item) => {
  if (item.id === 'reconnect') {
    modal.show = true;
  }
  if (item.id === 'resend') {
    modalPending.show = true;
  }
  if (item.id === 'remove') {
    usersStore().setModal({
      show: true,
      multiple: true,
      data: gridApi.value.getSelectedNodes()
        .filter((node) => node.displayed)
        .map((node) => node.data),
    });
  }
};

</script>
<style lang="scss" scoped>
.user-management {
  &__header {
    display: grid;
    grid-template-columns: 500px auto;
    width: 100%;
    background-color: #fff;
    height: var(--dim-input-height);
    border-bottom: 1px solid var(--color-grey-outline);
    height: 62px;
    align-items: center;
    padding: 0 36px;
    gap: 6px;
    box-sizing: border-box;
  }
  &__actions {
    // text-align: right;
    float: right;
    button {
      position: absolute;
      right: 36px;
      top: 6px;
      height: 50px;
    }
  }
  &__content {
    padding: 32px 36px;
    .ag-theme-alpine {
      width: 100%;
      height: calc(100vh - 200px);
      box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.02);
      font-family: inherit;
      :deep div[col-id="action"] {
        text-overflow: unset;
      }
      --ag-grid-size: 8px;
      --ag-list-item-height: 52px;
    }
  }
  &__search {
    :deep(input) {
      background: var(--color-blue-background, #F8FBFD);
    }
  }
  &__send-request {
    display: grid;
    align-items: center;
    grid-template-columns: 24px auto;
    height: 100%;
    cursor: pointer;
    p {
      font-size: 16px;
      color: var(--color-dark-main);
      font-weight: 600;
    }
  }

  .loading {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
    z-index: 999;
  }
}

.bulk {
  float: right;
  :deep() {
    .dropdown {
      &__list {
        z-index: 999;
      }
      background-color: var(--color-blue-main);
      height: 48px;
      line-height: 42px;
      padding: 0 22px;
      border-radius: 8px;
      p {
        line-height: 48px;
        color: #fff;
        font-size: 16px;
      }
      svg path {
        fill: #fff;
      }
    }
  }
}
</style>
