<template>
  <div class="analytics">
    <div class="analytics__header">
        <r-header ref="header">
            <template #content>
               <r-nav :items="navItems"></r-nav>
            </template>
            <template #actions>
              <RComboButton class="analytics__platforms" :text="selectedPlatform" label="Platform">
                <li
                    @keydown.space="onPlatformChange('all', 'All types')"
                    @keydown.enter="onPlatformChange('all', 'All types')"
                    @click="onPlatformChange('all', 'All types')">
                  <FilterAllIcon class="analytics__platforms-icon"/>
                  All platforms
                  <CheckOutlineIcon
                    class="analytics__platforms-selected"
                    fill="#8FB571"
                    v-if="'All types' === selectedPlatform"/>
                </li>
                <li v-for="option in store.getAvailablePlatforms" :key="option.id"
                    @keydown.space="onPlatformChange(option.id, option.text)"
                    @keydown.enter="onPlatformChange(option.id, option.text)"
                    @click="onPlatformChange(option.id, option.text)">
                  <img
                    class="analytics__platforms-icon"
                    :src="option.logo"
                    :alt="option.name"/>
                  {{ option.text }}
                  <CheckOutlineIcon
                    class="analytics__platforms-selected"
                    fill="#8FB571"
                    v-if="option.text === selectedPlatform"/>
                </li>
              </RComboButton>
              <RComboButton :text="selectedOption" label="Date Range">
                <li v-for="option in options" :key="option.value"
                    :class="{'active': option.text === selectedOption }"
                    @keydown.space="onDataChange(option.value)"
                    @keydown.enter="onDataChange(option.value)"
                    @click="onDataChange(option.value, option.text)">
                  {{ option.text }}
                </li>
              </RComboButton>
            </template>
        </r-header>
    </div>
    <div class="analytics__content">
        <router-view></router-view>
    </div>
  </div>
</template>
<script>
import RHeader from '@/components/Layouts/RHeader.vue';
import RNav from '@/components/Layouts/RNav.vue';
import CheckOutlineIcon from '@/components/icons/CheckOutlineIcon.vue';
import {
  ref, provide, onMounted, onUnmounted, reactive,
} from 'vue';
/* eslint-disable vue/no-unused-components */
import ConversionIcon from '@/components/icons/ConversionIcon.vue';
import DataIcon from '@/components/icons/DataIcon.vue';
import RSelect from '@/components/RSelect.vue';
import {
  startOfWeek, endOfWeek, getUnixTime, subDays, startOfMonth, endOfMonth,
} from 'date-fns';
import RComboButton from '@/components/RComboButton.vue';
import { analyticsStore } from '@/store/analytics';
import FilterAllIcon from '@/components/icons/Analytics/FilterAllIcon.vue';

export default {
  name: 'Analytics',
  components: {
    FilterAllIcon,
    RComboButton,
    RHeader,
    RNav,
    ConversionIcon,
    DataIcon,
    RSelect,
    CheckOutlineIcon,
  },
  setup() {
    const store = analyticsStore();
    const analyticsHeader = ref(null);
    const navItems = ref([
      { text: 'Conversion', to: 'Conversion', icon: 'ConversionIcon' },
      // { text: 'Billing and Data', to: 'BillingData', icon: 'DataIcon' },
    ]);
    const today = new Date();
    const before7Days = subDays(today, 7);
    const before14Days = subDays(today, 14);
    const before30Days = subDays(today, 30);
    const before90Days = subDays(today, 90);
    const dateOptions = {
      weekStartsOn: 1,
    };
    const initialStartDate = getUnixTime(startOfWeek(before7Days, dateOptions));
    const initialEndDate = getUnixTime(endOfWeek(today, dateOptions));

    const selectedOption = ref('Last 7 days'); // Default value
    const selectedPlatform = ref('All types');
    // Initialize selectedDate with the calculated values
    const selectedDate = ref({ start_date: initialStartDate, end_date: initialEndDate });
    const state = reactive({
      startDate: initialStartDate,
      endDate: initialEndDate,
    });
    const setDateRanges = (value) => {
      switch (value) {
        case 'prev_2_weeks':
          state.startDate = getUnixTime(startOfWeek(before14Days, dateOptions));
          state.endDate = getUnixTime(endOfWeek(today, dateOptions));
          break;
        case 'prev_month':
          state.startDate = getUnixTime(startOfMonth(before30Days));
          state.endDate = getUnixTime(endOfMonth(today));
          break;
        case 'prev_3_months':
          state.startDate = getUnixTime(startOfMonth(before90Days));
          state.endDate = getUnixTime(endOfMonth(today));
          break;
        default:
          state.startDate = getUnixTime(startOfWeek(before7Days, dateOptions));
          state.endDate = getUnixTime(endOfWeek(today, dateOptions));
      }

      selectedDate.value = {
        start_date: state.startDate,
        end_date: state.endDate,
      };
    };
    const options = [
      { text: 'Last 7 days', value: 'prev_week' },
      { text: 'Last 14 days', value: 'prev_2_weeks' },
      { text: 'Last 30 days', value: 'prev_month' },
      { text: 'Last 90 days', value: 'prev_3_months' },
    ];
    provide('selectedDate', selectedDate);

    const onDataChange = (value, option) => {
      selectedOption.value = option;
      store.clearAvailablePlatforms();
      store.clearSelectedPlatform();
      setDateRanges(value);
      selectedPlatform.value = 'All types';
    };
    const onPlatformChange = (platformId, text) => {
      selectedPlatform.value = text;
      store.setSelectedPlatform(platformId === 'all' ? null : platformId);
    };
    const updateWidth = () => {
      const header = document.querySelector('.header');
      if (header && analyticsHeader.value) {
        const headerWidth = header.clientWidth;
        analyticsHeader.value.style.width = `${headerWidth}px`;
      }
    };
    onMounted(() => {
      updateWidth(); // Initial set
      window.addEventListener('resize', updateWidth);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateWidth);
    });

    return {
      navItems,
      onDataChange,
      onPlatformChange,
      selectedOption,
      selectedPlatform,
      analyticsHeader,
      options,
      store,
    };
  },
};
</script>
<style lang="scss" scoped>
.analytics {
  &__header {
    position: sticky;
    margin: -40px -75px;
    z-index: 2;
    padding: 0 38px;
    .header {
      height: 64px;
    }
  }
  &__content {
      margin-top: 72px;
      height: calc(100vh - 206px);
      overflow-y: auto;
  }

  &__platforms{
    &-icon{
      height: 24px;
      width: 24px;
      border-radius: 999px;
      margin-right: 12px;
      color: var(--color-grey-light) !important;
    }
    &-selected{
      margin-left: auto;
      height: 24px;
      width: 24px;
    }
    li{
      min-width: 164px;
    }
  }
}
:deep {
  .header{
    &__actions{
      display: flex;
    }
  }
  .select__wrapper select{
    border: transparent;
    padding-left: 0;
    height: 20px;
    outline: none;
  }
  .combo__button{
    &-wrapper{
      margin-top: 20px;
      margin-left: 40px;
    }
    &-menu{
      min-width: 145px;
      transform: translateX(-140px);
    }
  }
}

li {
  &.active {
    background: var(--color-blue-background);
    color: var(--color-blue-main);
  }
}
</style>
