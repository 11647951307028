<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5240_47339)">
            <circle cx="15.8335" cy="12.1667" r="11" fill="url(#paint0_linear_5240_47339)" />
            <circle cx="12.833" cy="15.1667" r="9" fill="url(#paint1_linear_5240_47339)" />
            <!-- eslint-disable-next-line max-len -->
            <path d="M12.8332 26.8333C6.38967 26.8333 1.1665 21.6102 1.1665 15.1667C1.1665 8.72317 6.38967 3.5 12.8332 3.5C19.2767 3.5 24.4998 8.72317 24.4998 15.1667C24.4998 21.6102 19.2767 26.8333 12.8332 26.8333ZM13.9998 15.1667V10.5C13.9998 9.85567 13.4775 9.33333 12.8332 9.33333V9.33333C12.1888 9.33333 11.6665 9.85567 11.6665 10.5V16.8636C11.6665 17.2151 11.9514 17.5 12.3029 17.5H17.4998C18.1442 17.5 18.6665 16.9777 18.6665 16.3333V16.3333C18.6665 15.689 18.1442 15.1667 17.4998 15.1667H13.9998Z" fill="url(#paint2_linear_5240_47339)" />
        </g>
        <defs>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint0_linear_5240_47339" x1="39.9585" y1="35" x2="15.704" y2="36.5671" gradientUnits="userSpaceOnUse">
                <stop stop-color="#017BFE" />
                <stop offset="1" stop-color="#0061FF" stop-opacity="0.28" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint1_linear_5240_47339" x1="21.5831" y1="23.3334" x2="13.006" y2="8.08745" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint2_linear_5240_47339" x1="5.83301" y1="6.66675" x2="19.0703" y2="26.8333" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="#0061FF" />
            </linearGradient>
            <clipPath id="clip0_5240_47339">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>
