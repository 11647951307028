import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';

const DOMAIN_SAND_PATH = process.env.VUE_APP_API_SAND_URL || '/api';

const getHeaders = () => {
  const currentPartner = localStorage.getItem('partner_id');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  const clientId = new URLSearchParams(window.location.search).get('client_id') || currentPartner;
  if (clientId) {
    headers['Client-Id'] = clientId;
  }
  return headers;
};

const getAccounts = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.get(
      `${DOMAIN}/user_management`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const removeBulk = async (data) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.post(
      `${DOMAIN}/bulk_delete`,
      data,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const reconnectAccounts = async (data) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.post(
      `${DOMAIN}/accounts/reconnect`,
      data,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPendingAccounts = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.get(
      `${DOMAIN}/users/pending`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPaymentLink = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.post(
      `${DOMAIN}/payments/payment_method_link`,
      null,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPartnerStatus = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.get(
      `${DOMAIN}/partner_status`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getPartnerInformation = async () => {
  const DOMAIN = DOMAIN_PATH;
  const production = localStorage.getItem('production');
  const HEADERS = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  if (production === 'true' && localStorage.getItem('partner_id')) {
    HEADERS['Client-Id'] = localStorage.getItem('partner_id');
  }
  try {
    const response = await http.get(
      `${DOMAIN}/partners_info`,
      {
        headers: HEADERS,
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const removeConnectedAccount = async (id) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.delete(
      `${DOMAIN}/accounts/${id}`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const putUserMetadata = async (id, data) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.put(
      `${DOMAIN}/users/${id}/metadata`,
      {
        attributes: {
          ...data,
        },
      },
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getUsersFleets = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.get(
      `${DOMAIN}/client_fleets`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
const getFleetsDataSources = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.get(
      `${DOMAIN}/client_datasources`,
      {
        headers: getHeaders(),
        params: {
          csv: true,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const uploadFleetCSV = async (data) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  try {
    const response = await http.post(
      `${DOMAIN}/fleet/upload`,
      data,
      {
        headers: getHeaders(),
        'Content-Type': 'multipart/form-data',
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export default {
  getAccounts,
  reconnectAccounts,
  getPendingAccounts,
  removeConnectedAccount,
  getPartnerStatus,
  getPartnerInformation,
  removeBulk,
  putUserMetadata,
  getPaymentLink,
  getUsersFleets,
  getFleetsDataSources,
  uploadFleetCSV,
};
