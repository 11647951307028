<template>
  <div class="connect">
    <div class="connect__iframe"
      id="onboardingConnect">
      <iframe
        v-show="showIframe"
        title="Connect"
        src=""
        frameborder="0"
        ></iframe>

      <ConnectLiveModal :state="modal" v-if="!showIframe"/>
      <SuccessfullConnectionModal
      v-if="showFleetConnectedModal"
      @close="closeFleetConnectedModal"
      @connectOtherFleetAccount="connectOtherFleetAccount"/>
    </div>
  </div>
</template>
<script setup>
import {
  onMounted, ref, onBeforeUnmount, computed,
} from 'vue';
import Data from '@/api/data';
import useEmitter from '@/composables/useEmitter';
import { appStore } from '@/store/app';
import { usersStore } from '@/store/users';
// eslint-disable-next-line import/no-cycle
import { onboardingStepsMap, setOnboardingStep } from '@/util/onboardingControl';
import ConnectLiveModal from '@/components/ConnectLiveModal.vue';
import SuccessfullConnectionModal from '@/components/ConnectLive/SuccessfullConnectionModal.vue';
import { profileStore } from '@/store/profile';
import { debounce } from 'lodash';

const showIframe = ref(true);

const modal = ref('loading');
const showFleetConnectedModal = ref(false);

const profile = profileStore();
const currentUrl = ref('');
const partnerStatus = computed(() => usersStore().getPartnerStatus);
let interval = null;

const createUser = () => {
  Data.createUser().then((res) => {
    const iframe = document.querySelector('iframe');
    currentUrl.value = res.link;
    // add Query params to iframe
    const url = new URL(res.link);
    url.searchParams.append('isMobile', true);
    iframe.src = url;
  });
};

const checkFetchStatus = () => {
  interval = setInterval(() => {
    if (partnerStatus.value === true || partnerStatus.value === null) {
      modal.value = 'loading';
      showIframe.value = false;
      usersStore().fetchPartnerStatus();

      return;
    }
    clearInterval(interval);
    modal.value = 'success';
    showIframe.value = false;
  }, 5000);
};

const loadingTooltip = debounce(() => {
  // Trigger on loading modal
  // if after 5min the user is still on the loading modal
  // show tooltip with next button to skip onboarding
  setOnboardingStep(onboardingStepsMap.onboardingConnectModal);
}, 300000);
const useAppStore = appStore();
const isOnboardingActive = computed(() => useAppStore.getOnboarding);

const closeFleetConnectedModal = () => {
  showFleetConnectedModal.value = false;
};

const connectOtherFleetAccount = () => {
  showFleetConnectedModal.value = false;
  const msg = {
    action: 'setPage',
    value: 'Search',
  };
  const iframe = document.querySelector('iframe');
  iframe.contentWindow.postMessage(msg, '*');
};
window.onmessage = (e) => {
  if (e.data === 'AppLoaded') {
    window.analytics?.track('Clicked on Connect Live', {
      url: currentUrl.value,
    });
  } else if (e.data[0] === '{') {
    const data = JSON.parse(e.data);

    if (data?.onCompleted) {
      if (profile.getProfile.partnerType === 'Fleet') {
        if (isOnboardingActive.value) {
          modal.value = 'loading';
          showIframe.value = false;
          checkFetchStatus();
          setOnboardingStep(onboardingStepsMap.onboardingHiglightConnectLoadingModal);
          loadingTooltip();
        } else {
          showFleetConnectedModal.value = true;
          appStore().setNotification({
            type: 'pending',
            message: 'Collecting account’s data. Once we collect the data will appear in Users tab',
            ttl: 99999,
          });
        }
      }
    }
  }
};

onBeforeUnmount(() => {
  window.onmessage = null;
  appStore().setNotification({});
});

onMounted(() => {
  const emitter = useEmitter();

  createUser();

  emitter.on('reload', () => {
    createUser();
  });
});

</script>
<style lang="scss" scoped>
.connect {
  display: grid;
  height: 100%;
  &__success {
    display: grid;
    place-content: center;
    text-align: center;
    position: absolute;
    width: calc(100% - 72px);
    height: calc(100vh - 180px);
    background: var(--color-app-background);
    svg {
      margin: 10px auto;
    }
    h4 {
      font-size: 24px;
      color: var(--color-black);
      font-weight: 500;
      margin-bottom: 22px;
    }
    p {
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      color: var(--color-grey-dark);
      margin-bottom: 22px;
    }
    button {
      justify-self: center;
    }
  }
  &__iframe {
    justify-self: center;
    align-self: center;
    text-align: center;
    border-radius: 12px;
    .info {
      font-size: 15px;
      font-weight: 500;
      color: var(--color-grey-dark);
      text-shadow: 1px 1px 0px #fff;
      line-height: 160%;
    }
    iframe {
      width: 378px;
      max-height: 640px;
      height: 640px;
      box-shadow: 0px 28px 48px rgba(20, 20, 20, 0.04);
      border-radius: 12px;
    }
  }
  &__title {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-black);
    margin-bottom: 12px;
  }
  &__description {
    font-size: 15px;
    line-height: 160%;
    font-weight: 600;
    color: var(--color-grey-light);
    margin-bottom: 20px;
  }
}
</style>
