<template>
    <div class="card__head">
      <h5>Total earnings & number of connected users evolution</h5>
    </div>
    <div class="card__content">
        <vue-apex-charts
          v-if="props?.data"
          :height="375"
          :options="options"
          :series="chartSeries">
        </vue-apex-charts>
        <NoDataAvailable class="card__empty" v-else/>
    </div>
</template>

<script setup>
import { onBeforeMount, ref } from 'vue';
import { numberFormat } from '@/util/helpers';
import VueApexCharts from 'vue3-apexcharts';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['data', 'currency']);

const formatDate = (val) => {
  if (!val) return '';
  const date = new Date(val);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day} ${month}`;
};

const chartSeries = ref([]);
const options = ref({});

const setOptions = () => {
  if (props?.data) {
    chartSeries.value = props.data;
    options.value = {
      chart: {
        height: 350,
        type: 'line',
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      xaxis: {
        categories: props.data[0]?.data?.x,
        labels: {
          style: {
            colors: '#A8AFBC',
            fontSize: '12px',
            fontFamily: 'Gilroy',
            fontWeight: 500,
          },
          formatter(val) {
            return formatDate(val);
          },
        },
        title: {
          text: 'Time of earnings',
          style: {
            color: '#A8AFBC',
            fontSize: '12px',
            fontFamily: 'Gilroy',
            fontWeight: 500,
          },
        },
      },
      yaxis: [
        {
          seriesName: 'Number of connected driver',
          axisTicks: {
            show: true,
          },
          labels: {
            style: {
              colors: '#A8AFBC',
              fontSize: '12px',
              fontFamily: 'Gilroy',
              fontWeight: 500,
            },
            formatter(val) {
            // eslint-disable-next-line radix
              return parseInt(val);
            },
          },
          title: {
            text: 'Number of connected users',
            style: {
              color: '#A8AFBC',
              fontSize: '12px',
              fontFamily: 'Gilroy',
              fontWeight: 500,
            },
          },
          tooltip: {
            enabled: true,
          },
        },
        {
          seriesName: 'Total net income',
          opposite: true,
          axisTicks: {
            show: true,
          },
          labels: {
            style: {
              colors: '#A8AFBC',
              fontSize: '12px',
              fontFamily: 'Gilroy',
              fontWeight: 500,
            },
            formatter(val) {
            // eslint-disable-next-line radix
              return `${numberFormat(val)} ${props.currency}`;
            },
          },
          title: {
            text: `Total weekly earnings in ${props.currency}`,
            style: {
              color: '#A8AFBC',
              fontSize: '12px',
              fontFamily: 'Gilroy',
              fontWeight: 500,
            },
          },
        },
      ],
      colors: ['#0061FF', '#FE9543'],
      stroke: {
        width: 3.4,
        dashArray: [0, 8],
      },
      legend: {
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
        color: '#253858',
        itemMargin: {
          horizontal: 15,
          vertical: 0,
        },
      },
      markers: {
        size: 0, // Hide markers by default
        hover: {
          size: 6, // Size of the marker when hovering
          sizeOffset: 3, // Additional size to add to the marker when hovering
          fillColor: '#FF0000', // Fill color of the marker when hovering
          strokeColor: '#000000', // Stroke color of the marker when hovering
          strokeWidth: 2, // Stroke width of the marker when hovering
        },
      },
    };
  }
};

onBeforeMount(() => {
  setOptions();
});
</script>

<style scoped>
.card__content{
  transform: translateY(-45px);
}

.card__empty {
  margin-top: 50px;
}
</style>
