<template>
<div>
    <div v-if="isFirstLoad">
      <RLoading></RLoading>
    </div>
    <div v-else class="analytics">
        <div class="analytics__container">
            <div class="analytics__conversion-rate">
                <conversion-rate
                :data="conversionRateData"
                v-if="conversionRateData"
                :isLoading="isLoading"></conversion-rate>
            </div>
            <div class="analytics__connector-stats">
                <connector-stats
                :data="connectorStatsData"
                v-if="connectorStatsData"
                :isLoading="isLoading"></connector-stats>
            </div>
        </div>
        <div class="analytics__key-metrics">
            <key-metrics
            :data="keyMetricsData"
            v-if="keyMetricsData"
            :isLoading="isLoading"></key-metrics>
        </div>
        <div class="analytics__dropoff-rate">
            <dropoff-rate
            :data="dropOffRateData"
            v-if="dropOffRateData"
            :isLoading="isLoading"></dropoff-rate>
        </div>
        <div class="analytics__errors">
          <error-occurrence
            :data="errorOccurrenceData"
            v-if="errorOccurrenceData"
            :isLoading="isLoading">
          </error-occurrence>
          <error-breakdowns
            :data="errorRateData"
            v-if="errorRateData"
            :isLoading="isLoading">
          </error-breakdowns>
        </div>
    </div>
</div>
</template>

<script>
import {
  inject, watch, ref, computed,
} from 'vue';
import { analyticsStore } from '@/store/analytics';
import ConversionRate from '@/components/Analytics/Conversion/Cards/ConversionRate.vue';
import ConnectorStats from '@/components/Analytics/Conversion/Cards/ConnectorStats.vue';
import KeyMetrics from '@/components/Analytics/Conversion/Cards/KeyMetrics.vue';
import DropoffRate from '@/components/Analytics/Conversion/Cards/DropoffRate.vue';
import RLoading from '@/components/RLoading.vue';
import ErrorBreakdowns from '@/components/Analytics/Conversion/Cards/ErrorBreakdowns.vue';
import ErrorOccurrence from '@/components/Analytics/Conversion/Cards/ErrorOccurrence.vue';

export default {
  components: {
    ErrorOccurrence,
    ErrorBreakdowns,
    ConversionRate,
    ConnectorStats,
    KeyMetrics,
    DropoffRate,
    RLoading,
  },
  setup() {
    const store = analyticsStore();
    const selectedDate = inject('selectedDate');
    const isLoading = computed(() => store.isLoading);
    const isFirstLoad = ref(true); // track the first load

    const connectorStatsData = ref(null);
    const conversionRateData = ref(null);
    const keyMetricsData = ref(null);
    const dropOffRateData = ref(null);
    const errorRateData = ref(null);
    const errorOccurrenceData = ref(null);
    const fetchData = async (platformId) => {
      try {
        /* eslint-disable camelcase, max-len */
        const {
          connector_statistics,
          conversion_rate_analysis,
          key_conversion_metrics,
          drop_off_by_screen,
          error_rate,
          error_rate_per_day,
        } = await store.fetchConversionAnalytics(selectedDate.value, platformId);
        connectorStatsData.value = connector_statistics;
        conversionRateData.value = conversion_rate_analysis;
        keyMetricsData.value = key_conversion_metrics;
        dropOffRateData.value = drop_off_by_screen;
        errorRateData.value = error_rate;
        errorOccurrenceData.value = error_rate_per_day;
        isFirstLoad.value = false;
        store.setAvailablePlatforms();
      } catch (err) {
        console.error('Error fetching Conversion Analytics Data:', err);
      }
    };

    watch(selectedDate, () => {
      fetchData(store.getSelectedPlatform);
    }, { immediate: true });
    watch(() => store.getSelectedPlatform, () => {
      fetchData(store.getSelectedPlatform);
    });
    return {
      isLoading,
      connectorStatsData,
      conversionRateData,
      keyMetricsData,
      dropOffRateData,
      errorRateData,
      errorOccurrenceData,
      isFirstLoad,
    };
  },
};
</script>
<style lang="scss" scoped>
.analytics {
    display: grid;
    gap: 28px;
    &__container {
        display: grid;
        gap: 25px;
        grid-template-columns: 60% calc(40% - 25px);
        max-height: 750px;
    }
    &__conversion-rate {
        height: 100%;
        display: grid;
    }
    &__connector-stats {
        height: 100%;
    }
    &__errors{
      display: grid;
      grid-template-columns: calc(60% - 25px) 40%;
      column-gap: 25px;
      .sections {
        position: relative;
      }
    }
}
</style>
