<template>
  <div class="sections">
    <div class="sections__header">
      <p class="sections__header-title">Payouts</p>
    </div>
    <div class="sections__content sections__content--single">
      <div class="sections__data">
        <RSelection
          :options="[{ name: '2022' }, { name: '2021' }]"
          value="2022"
          @update:value="handleChange"
          block
          space
        />
        <RSelection
          :options="[
            { name: 'Jan' }, { name: 'Feb', disabled: true }, { name: 'Mar' }, { name: 'Apr' }]"
          value="Mar"
          @update:value="handleChange"
          block
          space
        />
        <div class="payouts__days">
          <p class="payouts__title">Select Payout Day</p>
          <div class="payouts__days-list">
            <div
              class="payouts__days-item"
              :class="{ 'payouts__days-item--active': state.daySelected === day }"
              v-for="day in state.days"
              :key="day"
              @keyUp="handleChangeDay(day)"
              @click="handleChangeDay(day)"
            >
              {{ day }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sections__content sections__content--no-border">
      <div class="sections__image sections__image--small"></div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Gross</h3>
          <h1><b>€ 2 327.68</b></h1>
        </div>
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr>
                <td><p class="chart__title net">Net</p></td>
                <td>€ 1 327.68</td>
              </tr>
              <tr>
                <td><p class="chart__title taxes">Taxes</p></td>
                <td>€ 227.11</td>
              </tr>
              <tr>
                <td> <p class="chart__title deduction">Deduction</p> </td>
                <td>€ 527.23</td>
              </tr>
              <tr>
                <td> <p class="chart__title reinbursments">Reinbursments</p></td>
                <td>€ 27.43</td>
              </tr>
              <tr>
                <td><p class="chart__title bonuses">Bonuses</p></td>
                <td>€ 41.02</td>
              </tr>
              <tr>
                <td><p class="chart__title fees">Fees</p></td>
                <td>€ 98.94</td>
              </tr>
            </table>
          </div>
          <div class="sections__table-part">
            <apexchart
              type="pie"
              :options="chartData.options"
              :series="chartData.series"
              width="220"
              height="220" />
          </div>
        </div>
      </div>
    </div>
    <div class="sections__content">
      <div class="sections__image sections__image--small"></div>
      <div class="sections__data">
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr>
                <td>Classification</td>
                <td>H</td>
              </tr>
              <tr>
                <td>Total hours</td>
                <td>40</td>
              </tr>
              <tr>
                <td>Regular Hours</td>
                <td>19</td>
              </tr>
              <tr>
                <td>Overtime</td>
                <td>4</td>
              </tr>
              <tr>
                <td>Payout Period Start</td>
                <td>March 25, 2022</td>
              </tr>
              <tr>
                <td>Payout Period End</td>
                <td> March 31, 2022</td>
              </tr>
            </table>
          </div>
          <div class="sections__table-part"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueApexCharts from 'vue3-apexcharts';
import RSelection from '@/components/RSelection.vue';
import { reactive } from 'vue';

export default {
  name: 'UserPayouts',
  components: {
    RSelection,
    apexchart: VueApexCharts,
  },
  setup() {
    const options = ['Weekly', 'Monthly', 'Yearly'];
    const state = reactive({
      selected: options[0],
      daySelected: 31,
      days: [1, 14, 31],
    });
    const handleChange = (value) => {
      state.selected = value;
    };
    const handleChangeDay = (value) => {
      state.daySelected = value;
    };
    const chartData = {
      series: [44, 55, 41, 17, 15, 10],
      options: {
        chart: {
          width: 200,
          type: 'pie',
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
        },
        colors: ['#0061FF', '#FE9543', '#A8AFBC', '#8FB571', '#0052D8', '#F52922'],
        labels: ['Net', 'Deduction', 'Bonuses', 'Taxes', 'Reinbursments', 'Fees'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        }],
      },
    };
    return {
      state,
      handleChange,
      handleChangeDay,
      options,
      chartData,
    };
  },
};
</script>
<style lang="scss" scoped>
.payouts {
  &__title {
    font-size: 14px;
    font-weight: 600;
    color: var(--color-grey-dark);
    margin-bottom: 6px;
  }
  &__days {
    margin-top: 42px;
    &-item {
      display: inline-block;
      width: 36px;
      height: 36px;
      border: 1px solid var(--color-grey-outline);
      background: var(--color-blue-background);
      color: var(--color-dark-main);
      border-radius: 8px;
      text-align: center;
      vertical-align: middle;
      line-height: 36px;
      margin-right: 16px;
      font-size: 14px;
      font-weight: 700;
      cursor: pointer;
      &--active {
        background: var(--color-blue-main);
        color: #fff;
      }
    }
  }
}
.chart {
  &__title {
    &:before {
      content: '';
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      margin-right: 12px;
      vertical-align: text-top;
    }
    &.net {
      &:before {
        background: #0061FF;
      }
    }
    &.deduction {
      &:before {
        background: #FE9543;
      }
    }
    &.bonuses {
      &:before {
        background: #A8AFBC;
      }
    }
    &.taxes {
      &:before {
        background: #8FB571;
      }
    }
    &.reinbursments {
      &:before {
        background: #0052D8;
      }
    }
    &.fees {
      &:before {
        background: #F52922;
      }
    }
  }
}
</style>
