<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Vector" d="M10.8333 14.115V15.8333H14.9999V17.5H4.99992V15.8333H9.16658V14.115C7.5555
    13.9118 6.07393 13.1277 4.99992 11.9098C3.92591 10.6919 3.33328 9.12384 3.33325
    7.5V2.5H16.6666V7.5C16.6666 9.12384 16.0739 10.6919 14.9999 11.9098C13.9259 13.1277
    12.4443 13.9118 10.8333 14.115ZM4.99992 4.16667V7.5C4.99992 8.82608 5.5267 10.0979
    6.46438 11.0355C7.40207 11.9732 8.67384 12.5 9.99992 12.5C11.326 12.5 12.5978 11.9732
    13.5355 11.0355C14.4731 10.0979 14.9999 8.82608 14.9999 7.5V4.16667H4.99992ZM0.833252
    4.16667H2.49992V7.5H0.833252V4.16667ZM17.4999 4.16667H19.1666V7.5H17.4999V4.16667Z"
    fill="#0061FF"/>
    </g>
    </svg>
</template>
