<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6509_48688)">
            <!-- eslint-disable-next-line max-len -->
            <path d="M7.49996 6.61627L10.5937 3.52252L11.4775 4.40627L8.38371 7.50002L11.4775 10.5938L10.5937 11.4775L7.49996 8.38377L4.40621 11.4775L3.52246 10.5938L6.61621 7.50002L3.52246 4.40627L4.40621 3.52252L7.49996 6.61627Z" fill="#A8AFBC"/>
        </g>
        <defs>
        <clipPath id="clip0_6509_48688">
            <rect width="15" height="15" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
