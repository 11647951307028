const metadataRemap = (dynamic_data_points = []) => {
  const object = dynamic_data_points
    .reduce((obj, item) => Object.assign(obj, { [item.name]: item.result }), {});
  return object;
};
// eslint-disable-next-line import/prefer-default-export
export const usersRemap = (users) => {
  const usersReturn = [];
  const checkName = (name, userId) => {
    let count = 1;
    usersReturn.forEach((u) => {
      if (u.name.includes(name) && u.user_id !== userId) {
        count += 1;
      }
    });
    return `${name} ${count === 1 ? '' : `#${count}`}`;
  };
  //  eslint-disable-next-line
  const getPropertyValue = (obj, prop, defaultValue = undefined) => (obj && (prop in obj) ? obj[prop] : defaultValue);

  users.forEach((user) => {
    if (user.accounts && user.accounts.length) {
      user.accounts.forEach((u) => {
        usersReturn.push({
          id: u.id,
          ...user?.metadata,
          user_id: u.user_id,
          name: checkName(user.accounts[0].profile.full_name, u.user_id),
          first_name: getPropertyValue(u.profile, 'first_name'),
          last_name: getPropertyValue(u.profile, 'last_name'),
          phone: getPropertyValue(u.profile, 'phone'),
          email: getPropertyValue(u.profile, 'email'),
          image_url: getPropertyValue(u.profile, 'image_url'),
          street: getPropertyValue(u.profile, 'street'),
          zip: getPropertyValue(u.profile, 'zip'),
          avatar: getPropertyValue(u.profile, 'image_url', ''),
          country_phone_code: getPropertyValue(u.profile, 'country_phone_code'),
          licence_number: getPropertyValue(u.profile, 'licence_number'),
          licence_expire_at: getPropertyValue(u.profile, 'licence_expire_at'),
          gross_earnings: getPropertyValue(u.income, 'gross_earnings'),
          net_amount: getPropertyValue(u.income, 'net_amount'),
          taxes: getPropertyValue(u.income, 'taxes'),
          bonus_amount: getPropertyValue(u.income, 'bonus_amount'),
          platform_fee: getPropertyValue(u.income, 'platform_fee'),
          card_earnings: getPropertyValue(u.income, 'card_earnings'),
          cash_earnings: getPropertyValue(u.income, 'cash_earnings'),
          currency: getPropertyValue(u.income, 'currency'),
          expenses: getPropertyValue(u.income, 'expenses'),
          fare: getPropertyValue(u.income, 'fare'),
          other_amount: getPropertyValue(u.income, 'other_amount'),
          payouts: getPropertyValue(u.income, 'payouts'),
          reimbursements: getPropertyValue(u.income, 'reimbursements'),
          tips: getPropertyValue(u.income, 'tips'),
          platform: u.platform.name,
          platform_logo: u.platform.logo,
          status: u.status,
          custom_attributes: user.custom_attributes,
          last_update: u.last_update,
          afternoon_trips_total: getPropertyValue(u.activity, 'afternoon_trips_total'),
          afternoon_trips_total_perc: getPropertyValue(u.activity, 'afternoon_trips_total_perc'),
          distance_per_trip: getPropertyValue(u.activity, 'distance_per_trip'),
          distance_total: getPropertyValue(u.activity, 'distance_total'),
          morning_trips_total: getPropertyValue(u.activity, 'morning_trips_total'),
          morning_trips_total_perc: getPropertyValue(u.activity, 'morning_trips_total_perc'),
          night_trips_total: getPropertyValue(u.activity, 'night_trips_total'),
          night_trips_total_perc: getPropertyValue(u.activity, 'night_trips_total_perc'),
          price_total: getPropertyValue(u.activity, 'price_total'),
          trips_total: getPropertyValue(u.activity, 'trips_total'),
          price_per_km: getPropertyValue(u.activity, 'price_per_km'),
          price_per_online_hour: getPropertyValue(u.activity, 'price_per_online_hour'),
          price_per_driving_hour: getPropertyValue(u.activity, 'price_per_driving_hour'),
          price_per_trip: getPropertyValue(u.activity, 'price_per_trip'),
          trips_per_hour: getPropertyValue(u.activity, 'trips_per_hour'),
          user_created_at: getPropertyValue(u, 'user_created_at'),
          firstConnection: getPropertyValue(u, 'first_connection'),
          ...metadataRemap(user.total_income?.dynamic_data_points),
        });
      });
    } else {
      console.error(`User ${user.id} has no accounts`);
    }
  });
  return usersReturn;
};

export const accountsRemap = (accounts) => {
  const accountsReturn = [];
  accounts.forEach((account) => {
    switch (account.Status) {
      case 'pending':
        accountsReturn.push({
          full_name: '-',
          status: account.Status,
          id: account.User.id,
          email: account.User.email,
          last_request: account.User.last_seen,
        });
        break;
      default:
        accountsReturn.push({
          full_name: account.Account.profile.full_name,
          status: account.Status,
          id: account.Account.id,
          email: account.Account.profile.email,
          last_request: '-',
          platform: account.Account.platform,
        });
        break;
    }
  });
  return accountsReturn;
};
