<template>
  <OnClickOutside @trigger="openPlugin = false">
    <div class="dpicker">
      <div class="dpicker__input" :class="{'active': openPlugin}">
        <CalendarIcon />
        <!-- eslint-disable-next-line -->
        <RDayPicker v-model="startDate" @enterUpdateDate="enterUpdateStartDate" @updateDate="updateStartDate" @click="openPlugin = true" />
        <span>-</span>
        <!-- eslint-disable-next-line -->
        <RDayPicker v-model="endDate" @enterUpdateDate="enterUpdateEndDate" @updateDate="updateEndDate" @click="openPlugin = true" />
        <div
          class="dpicker__carret"
          @click="openPlugin = !openPlugin"
          @keyup="openPlugin = !openPlugin"
          :class="{'rotate' : openPlugin}">
          <Carret large />
        </div>
      </div>
      <Transition name="fade">
        <div class="dpicker__calendar" v-if="openPlugin">
          <div class="dpicker__options">
            <ul>
              <li @click="selectDates('0d')" @keyup="selectDates('0d')"
                :class="{'active' : checkDate('0d')}">Current week</li>
              <li @click="selectDates('7d')" @keyup="selectDates('7d')"
                :class="{'active' : checkDate('7d')}">Last week</li>
              <li @click="selectDates('30d')" @keyup="selectDates('30d')"
                :class="{'active' : checkDate('30d')}">Last month</li>
              <li @click="selectDates('90d')" @keyup="selectDates('90d')"
                :class="{'active' : checkDate('90d')}">Last 3 months</li>
              <li @click="selectDates('y')" @keyup="selectDates('y')"
                :class="{'active' : checkDate('y')}">Current Year</li>
              <li @click="selectDates('1y')" @keyup="selectDates('1y')"
                :class="{'active' : checkDate('1y')}">Last Year</li>
              <li @click="selectDates('y1y')" @keyup="selectDates('y1y')"
                :class="{'active' : checkDate('1y1')}">Last + Current Year</li>
              <li @click="selectDates('2y')" @keyup="selectDates('2y')"
                :class="{'active' : checkDate('2y')}">Last 2 Years</li>
            </ul>
          </div>
          <div class="dpicker__plugin">
            <DatePicker
              :attributes="calendarAttributes"
              borderless
              transparent
              expanded
              @click="currentChoosenDateOption = 'custom'"
              :value="datepickerDate"
              :update-on-input="true"
              v-model.range="datepickerDate"
              :max-date="new Date()" />
          </div>
          <div class="dpicker__actions">
            <!-- {{ startDate }} - {{ endDate }} -->
            &nbsp;
            <RButton
              class="button--small" type="secondary" @click="openPlugin = false">Close</RButton>
            &nbsp;
            <RButton class="button--small" @click="applyDates">Apply</RButton>
          </div>
        </div>
      </Transition>
    </div>
  </OnClickOutside>
</template>
<script setup>
import {
  ref,
  onMounted, watch,
} from 'vue';
import 'v-calendar/style.css';
import { useRoute } from 'vue-router';
import { DatePicker } from 'v-calendar';
import { addDays, format, parseISO } from 'date-fns';
import { OnClickOutside } from '@vueuse/components';
import CalendarIcon from '@/components/icons/CalenderIcon.vue';
import RDayPicker from './RDayPicker.vue';
import Carret from './icons/Caret.vue';
import RButton from './RButton.vue';

// ******** VARIABLES ********
const calendarAttributes = {
  mode: 'range',
  isInline: true,
  showTransition: 'slide-h',
  hideTransition: 'slide-h',
  color: 'blue',
  locale: 'en',
  inputProps: {
    class: 'vc-bordered',
    placeholder: 'Select Date Range',
  },
};

const route = useRoute();

const props = defineProps(['value']);

const openPlugin = ref(false);

const currentChoosenDateOption = ref('');

const emit = defineEmits(['updateDate']);

// ******** FUNCTIONS ********
const validateDate = (e) => {
  const input = e.target;
  if (input.value.length === 10) {
    const [day, month, year] = input.value.split('/');
    const date = new Date(`${year}-${month}-${day}`);
    const currentDate = new Date();
    const minDate = new Date('1950-01-01');

    if (date.toString() === 'Invalid Date' || date > currentDate || date < minDate) {
      input.classList.add('error');
    } else {
      input.classList.remove('error');
    }
  }
};

const convertDate = (date) => {
  const [day, month, year] = date.split('/');
  return parseISO(`${year}-${month}-${day}`);
};
// ******** INPUTS ********

let startDateDefaults = ref(format(addDays(new Date(), -new Date().getDay() + 1), 'dd/MM/yyyy'));
let endDateDefaults = ref(format(new Date(), 'dd/MM/yyyy'));

if (props.value) {
  startDateDefaults = ref(format(props.value.start, 'dd/MM/yyyy'));
  endDateDefaults = ref(format(props.value.end, 'dd/MM/yyyy'));
}
const startDate = startDateDefaults;
const endDate = endDateDefaults;

// ******** DATEPICKER DEFAULT DATES ********
// const datepickerDate = computed({
//   get: () => ({
//     start: convertDate(startDate.value),
//     end: convertDate(endDate.value),
//   }),
//   set: (newVal) => {
//     startDate.value = format(newVal.start, 'dd/MM/yyyy');
//     endDate.value = format(newVal.end, 'dd/MM/yyyy');
//   },
// });

const datepickerDate = ref({
  start: convertDate(startDate.value),
  end: convertDate(endDate.value),
});

const checkDate = (what) => currentChoosenDateOption.value === what;

// ******** METHODS ********
const selectDates = (range) => {
  const currentDate = new Date();
  let start;
  let end;
  switch (range) {
    case '0d':
      start = addDays(currentDate, -currentDate.getDay() + 1);
      end = currentDate;
      break;
    case '7d':
      start = addDays(currentDate, -currentDate.getDay() - 6);
      end = addDays(currentDate, -currentDate.getDay());
      break;
    case '14d':
      start = addDays(currentDate, -currentDate.getDay() - 13);
      end = addDays(currentDate, -currentDate.getDay());
      break;
    case '30d':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
      end = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      break;
    case '90d':
      start = new Date(currentDate.getFullYear(), currentDate.getMonth() - 3, 1);
      end = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
      break;
    case 'y':
      start = new Date(currentDate.getFullYear(), 0, 1);
      end = currentDate;
      break;
    case 'y1y':
      start = new Date(currentDate.getFullYear() - 1, 0, 1);
      end = currentDate;
      break;
    case '1y':
      start = new Date(currentDate.getFullYear() - 1, 0, 1);
      end = new Date(currentDate.getFullYear() - 1, 11, 31);
      break;
    case '2y':
      start = new Date(currentDate.getFullYear() - 2, 0, 1);
      end = new Date(currentDate.getFullYear() - 1, 11, 31);
      break;
    case '3y':
      start = new Date(currentDate.getFullYear() - 3, 0, 1);
      end = new Date(currentDate.getFullYear() - 1, 11, 31);
      break;
    default:
      start = addDays(currentDate, -7);
      end = currentDate;
  }

  currentChoosenDateOption.value = range;
  datepickerDate.value = { start, end };
  checkDate(range);
};

const applyDates = () => {
  datepickerDate.value = {
    start: new Date(datepickerDate.value.start.setHours(0, 0, 0, 0)),
    end: new Date(datepickerDate.value.end.setHours(23, 59, 59, 999)),
  };
  emit('updateDate', datepickerDate.value);
  openPlugin.value = false;
};

const updateStartDate = (date) => {
  currentChoosenDateOption.value = 'custom';
  startDate.value = date;
  datepickerDate.value = {
    start: convertDate(date),
    end: convertDate(endDate.value),
  };
};

const updateEndDate = (date) => {
  currentChoosenDateOption.value = 'custom';
  endDate.value = date;
  datepickerDate.value = {
    start: convertDate(startDate.value),
    end: convertDate(date),
  };
};

const enterUpdateStartDate = (date) => {
  currentChoosenDateOption.value = 'custom';
  datepickerDate.value = {
    start: convertDate(date),
    end: convertDate(endDate.value),
  };
  applyDates();
};

const enterUpdateEndDate = (date) => {
  currentChoosenDateOption.value = 'custom';
  datepickerDate.value = {
    start: convertDate(startDate.value),
    end: convertDate(date),
  };
  applyDates();
};

onMounted(() => {
  const queryParams = route.query;
  if (queryParams.startDate && queryParams.endDate) {
    datepickerDate.value = {
      start: new Date(queryParams.startDate * 1000),
      end: new Date(queryParams.endDate * 1000),
    };
  }
  applyDates();
  const inputs = document.querySelectorAll('.dpicker__input input');
  inputs.forEach((input) => {
    input.addEventListener('focusout', validateDate);
  });
});

watch(datepickerDate, (newVal) => {
  startDate.value = format(newVal.start, 'dd/MM/yyyy');
  endDate.value = format(newVal.end, 'dd/MM/yyyy');
}, { deep: true, immediate: true });
</script>

<style lang="scss" scoped>
.dpicker {
  position: relative;
  &__input {
    display: grid;
    grid-template-columns: 28px auto 4px auto 28px;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    gap: 3px;
    height: 48px;
    min-width: 260px;
    padding-top: 2px;
    // padding: 10px 6px 10px 12px;
    border-radius: 8px;
    border: 1px solid var(--color-grey-light);
    box-sizing: border-box;
    input {
      font-family: inherit;
      border: none;
      width: fit-content;
      max-width: 96px;
      font-weight: 600;
      font-size: 16px;
      padding: 3px 2px;
      text-align: center;
      line-height: 1;
      border-radius: 6px;
      text-shadow: 1px 1px 1px #fff;
      color: var(--color-dark-main);
      &.error {
        // add red font color and light red background in hex
        color: #ff6666;
        background-color: #ffe6e6;
      }
      &::placeholder {
        color: var(--color-grey-dark);
        font-size: 13px;
      }
      &:focus {
        outline: none;
      }
    }
    &.active {
      border: 1px solid var(--color-blue-main);
    }
  }
  &__carret {
    align-self: flex-end;
    svg {
      transition: var(--transition-default);
      transform: rotate(180deg);
      border-radius: 999px;
      opacity: 0.7;
    }
    &.rotate {
      svg {
        transform: rotate(0deg);
      }
    }
    &:hover {
      cursor: pointer;
      svg {
        background: #eaeaea;
      }
    }
  }
  &__calendar {
    display: grid;
    grid-template-areas:
      'options plugin plugin'
      'actions actions actions';
    grid-template-columns: 180px auto;
    gap: 16px;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    z-index: 100;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    background-color: var(--color-white);
    padding: 12px;
    box-sizing: border-box;
  }
  &__actions {
    grid-area: actions;
    justify-self: end;
  }
  &__options {
    grid-area: options;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 4px 10px;
        line-height: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: var(--color-dark-main);
        border: 1px solid var(--color-grey-outline);
        margin-bottom: 11px;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
          background-color: #e0ecff;
        }
        &:last-child {
          margin-bottom: 0;
        }
        &.active {
          background-color: #e0ecff;
        }
      }
    }
  }
  &__plugin {
    min-width: 340px;
    grid-area: plugin;
    border: 1px solid var(--color-grey-outline);
    border-radius: 12px;
  }
}

:deep {
    .vc {
      &-container {
        border: none !important;
      }
        &-bordered {
            border-color: transparent !important;
        }
        &-disabled {
            color: rgba(122, 128, 139, 0.32);
            pointer-events: none;
        }
        &-highlight-content-solid {
            background: var(--blue-main, #0061FF);
        }
        &-highlight-base-start, &-highlight-base-middle, &-highlight-base-end {
            background: var(--primary-weak, #DDECFD);
            border-top: 0.5px solid var(--primary-border, #80B7F6);
            border-bottom: 0.5px solid var(--primary-border, #80B7F6);
        }
        &-pane-container {
            border: none !important;
        }
        &-title {
            color: var(--dark-main, #253858);
            font-family: 'Gilroy', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;
        }
        &-title, &-arrow {
            background: transparent !important;
        }
        &-base-icon {
            stroke: #253858;
        }
        &-weeks {
            margin-top: 12px;
        }
        &-weekday {
            font-size: 12px;
            font-family: 'Gilroy';
            color: var(--grey-light, #A8AFBC);
        }
        &-day {
            color:var(--grey-dark, #7A808B);
            margin-top: 9px;
        }
        &-dot {
            width: 3px;
            height: 3px;
            display: flex;
        }
        &-day-box-center-bottom {
            margin-top: 40px;
        }
        &-header {
            margin-top: 20px;
        }
        &-popover-caret {
            display: none;
        }
        &-popover-content {
            border: none;
            box-shadow: none;
        }
    }
}
</style>
