import { router } from '../router/index';
import { appStore } from '../store/app';
import { insightsStore } from '../store/insights';

/* eslint-disable import/prefer-default-export */
export const skipOnboarding = () => {
  const useAppStore = appStore();
  const useInsightsStore = insightsStore();
  window.analytics?.track('User cancelled Onboarding', {
    onStep: useAppStore.getOnboardingStep.data.stepName,
  });
  localStorage.removeItem('sampleData');
  useInsightsStore.setSampleData(false);
  useAppStore.setOnboarding(false);
  useAppStore.setOnboardingStep(null);
  useAppStore.setDashboardConfiguration('onboarding', { onboarding: false });
};

const finishedOnboarding = () => {
  const useAppStore = appStore();
  const useInsightsStore = insightsStore();
  window.analytics?.track('User finished Onboarding', {
    onStep: useAppStore.getOnboardingStep.data.stepName,
  });
  localStorage.removeItem('sampleData');
  useInsightsStore.setSampleData(false);
  useAppStore.setOnboarding(false);
  useAppStore.setOnboardingStep(null);
  useAppStore.setDashboardConfiguration('onboarding', { onboarding: false });
  router.push({ name: 'Get Started' });
};
export const setOnboardingStep = (key) => {
  const useAppStore = appStore();
  // eslint-disable-next-line no-use-before-define
  useAppStore.setOnboardingStep(steps[key]);
};

export const onboardingStepsMap = {
  onboardingConnectLive: 'onboardingConnectLive',
  onboardingConnect: 'onboardingConnect',
  onboardingHiglightConnectLoadingModal: 'onboardingHiglightConnectLoadingModal',
  onboardingConnectModal: 'onboardingConnectModal',
  userList: 'userList',
  userListFilters: 'userListFilters',
  userInsights: 'userInsights',
  userInsightsNavigation: 'userInsightsNavigation',
  userInsightsSampleData: 'userInsightsSampleData',
  userInsightsSampleKeyMetrics: 'userInsightsSampleKeyMetrics',
};

const steps = {
  onboardingConnectLive: {
    id: 'onboardingConnectLive',
    offSetX: 20,
    offSetY: 0,
    position: 'right',
    target: 'p',
    interactive: false,
    data: {
      stepNumber: 1,
      stepName: 'onboarding.step.connectLive.name',
      stepTitle: 'onboarding.step.connectLive.title',
      stepDescription: 'onboarding.step.connectLive.description',
    },
    actions: {
      back: null,
      next: {
        label: 'onboarding.next',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.onboardingConnect);
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  onboardingConnect: {
    id: 'onboardingConnect',
    offSetX: 20,
    offSetY: 100,
    position: 'left',
    target: 'iframe',
    interactive: true,
    data: {
      stepNumber: 2,
      stepName: 'onboarding.step.connect.name',
      stepTitle: 'onboarding.step.connect.title',
      stepDescription: 'onboarding.step.connect.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.onboardingConnectLive);
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          router.push({ name: 'Data View' }).then(() => {
            setOnboardingStep(onboardingStepsMap.userList);
          });
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  onboardingHiglightConnectLoadingModal: {
    id: 'onboardingHiglightConnectLoadingModal',
    offSetX: 20,
    offSetY: 0,
    position: 'none',
    target: 'div',
    interactive: true,
    data: {
      stepNumber: 2,
      stepName: 'onboarding.step.connect.name',
      stepTitle: 'onboarding.step.connect.title',
      stepDescription: 'onboarding.step.connect.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.connectLive);
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          router.push({ name: 'Data View' }).then(() => {
            setOnboardingStep(onboardingStepsMap.userList);
          });
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  onboardingConnectModal: {
    id: 'onboardingConnectModal',
    offSetX: 20,
    offSetY: 0,
    position: 'left',
    target: 'div',
    interactive: false,
    data: {
      stepNumber: 2,
      stepName: 'onboarding.step.connectLoading.name',
      stepTitle: 'onboarding.step.connectLoading.title',
      stepDescription: 'onboarding.step.connectLoading.description',
    },
    actions: {
      back: null,
      next: {
        label: 'onboarding.next',
        onClick: () => {
          router.push({ name: 'Data View' }).then(() => {
            setOnboardingStep(onboardingStepsMap.userList);
          });
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userList: {
    id: 'dataView',
    offSetX: 20,
    offSetY: 0,
    position: 'right',
    target: 'p',
    interactive: false,
    data: {
      stepNumber: 3,
      stepName: 'onboarding.step.drivers.name',
      stepTitle: 'onboarding.step.drivers.title',
      stepDescription: 'onboarding.step.drivers.description',
    },
    actions: {
      back: null,
      next: {
        label: 'onboarding.next',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userListFilters);
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userListFilters: {
    id: 'ag-side-bar',
    offSetX: 20,
    offSetY: 0,
    position: 'left',
    target: 'button',
    interactive: false,
    data: {
      stepNumber: 4,
      stepName: 'onboarding.step.driversFilter.name',
      stepTitle: 'onboarding.step.driversFilter.title',
      stepDescription: 'onboarding.step.driversFilter.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userList);
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          router.push({ name: 'Insights Overview' }).then(() => {
            setOnboardingStep(onboardingStepsMap.userInsights);
          });
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userInsights: {
    id: 'userInsights',
    offSetX: 20,
    offSetY: 0,
    position: 'right',
    target: 'p',
    interactive: false,
    data: {
      stepNumber: 5,
      stepName: 'onboarding.step.insights.name',
      stepTitle: 'onboarding.step.insights.title',
      stepDescription: 'onboarding.step.insights.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          router.push({ name: 'Data View' }).then(() => {
            setOnboardingStep(onboardingStepsMap.userListFilters);
          });
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userInsightsNavigation);
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userInsightsNavigation: {
    id: 'onboardingInsightsHeader',
    offSetX: 20,
    offSetY: 20,
    position: 'bottom',
    target: 'li',
    interactive: false,
    data: {
      stepNumber: 6,
      stepName: 'onboarding.step.insightsNavigation.name',
      stepTitle: 'onboarding.step.insightsNavigation.title',
      stepDescription: 'onboarding.step.insightsNavigation.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userInsights);
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userInsightsSampleData);
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userInsightsSampleData: {
    id: 'header__actions',
    offSetX: 20,
    offSetY: 0,
    position: 'left',
    target: 'label',
    interactive: false,
    data: {
      stepNumber: 7,
      stepName: 'onboarding.step.insightsSampleData.name',
      stepTitle: 'onboarding.step.insightsSampleData.title',
      stepDescription: 'onboarding.step.insightsSampleData.description',
    },
    actions: {
      back: {
        label: 'onboarding.back',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userInsightsNavigation);
        },
      },
      next: {
        label: 'onboarding.next',
        onClick: () => {
          setOnboardingStep(onboardingStepsMap.userInsightsSampleKeyMetrics);
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
  userInsightsSampleKeyMetrics: {
    id: 'onboardingInsightsKeyMetrics',
    offSetX: 20,
    offSetY: 20,
    position: 'bottom',
    target: 'h3',
    interactive: false,
    data: {
      stepNumber: 8,
      stepName: 'onboarding.step.insightsSampleData.name',
      stepTitle: 'onboarding.step.insightsSampleData.title',
      stepDescription: 'onboarding.step.insightsSampleData.description',
    },
    actions: {
      back: null,
      next: {
        label: 'Let’s start',
        onClick: () => {
          finishedOnboarding();
        },
      },
      skip: {
        label: 'onboarding.skip',
        onClick: () => {
          skipOnboarding();
        },
      },
    },
  },
};
