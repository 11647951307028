import { defineStore } from 'pinia';
import Analytics from '../api/analytics';

// eslint-disable-next-line import/prefer-default-export
export const analyticsStore = defineStore({
  id: 'analyticsStore',
  state: () => ({
    conversionData: null,
    isLoading: false,
    availablePlatforms: [
    ],
    selectedPlatform: null,
  }),
  getters: {
    getConversionData: (state) => state.conversionData,
    getAvailablePlatforms: (state) => state.availablePlatforms,
    getSelectedPlatform: (state) => state.selectedPlatform,
  },
  actions: {
    fetchConversionAnalytics(params, platformId) {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        Analytics.getConversionAnalytics(params, platformId).then((data) => {
          this.conversionData = data;
          resolve(data);
        }).catch((err) => {
          this.conversionData = null;
          reject(err);
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },
    clearAnalytics() {
      this.conversionData = null;
    },
    setAvailablePlatforms() {
      this.availablePlatforms = this.conversionData.connector_statistics.highest_conversion
        .map((item) => ({
          id: item.platform.id,
          text: item.platform.name,
          logo: item.platform.logo,
        }));
    },
    clearAvailablePlatforms() {
      this.availablePlatforms = [];
    },
    setSelectedPlatform(selected) {
      this.selectedPlatform = selected;
    },
    clearSelectedPlatform() {
      this.selectedPlatform = null;
    },
  },
});
