<template>
  <div class="header__wrapper">
    <div>
      <span>{{ columnName }}</span>
    </div>
    <RToolTip outline>
      <template #tooltip-text>
        <span>
          {{tooltipText}}
        </span>
      </template>
    </RToolTip>
  </div>

</template>
<script setup>
import RToolTip from '@/components/RToolTip.vue';
import { ref } from 'vue';

const cell = defineProps(['params']);

const columnName = ref(cell.params.displayName);

const defaultTooltipText = `The column’s cell values are editable.
  Click a cell to edit`;

const tooltipText = ref(cell.params.colDef?.tooltipText || defaultTooltipText);

</script>
<style scoped lang="scss">
.header{
  &__wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    span{
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>
