<template>
  <div class="modal__backdrop sync">
    <div class="modal">
      <div class="modal__header">
        <h2>{{ title }}</h2>
        <CloseIcon @click="$emit('close')" />
      </div>
      <div class="modal__content">
        <h3 class="modal__heading">Date Range</h3>
        <p class="modal__desc">Choose from which period would you like to collect the data</p>
        <br>
        <RSelect @value="timePeriodChange" />
        <br><br><br>
        <h3 class="modal__heading">Selected Endpoints</h3>
        <p class="modal__desc">Choose which endpoints wouy=ld you like to collect and update</p>
        <br>
        <div class="modal__pills">
          <RPill
            :icon="pill.icon"
            :title="pill.title"
            :active="pill.active"
            @click="clickPill(pill)"
            v-for="pill in pills"
            :key="pill.title">
          </RPill>
        </div>
      </div>
      <div class="modal__actions">
        <div></div>
        <RButton type="secondary" @click="$emit('close')">Cancel</RButton>
        <RButton type="primary"
                 :disabled="disabled"
                 @click="sync()"
        >Update
        </RButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed } from 'vue';
import { usersStore } from '@/store/users';
import { appStore as useAppStore } from '@/store/app';
import CloseIcon from '@/components/icons/CloseIcon.vue';
import RButton from '@/components/RButton.vue';
import RPill from '@/components/RPill.vue';
import RSelect from '@/components/RSelect.vue';

const appStore = useAppStore();
const emit = defineEmits(['close']);

defineProps({
  title: {
    type: String,
    required: true,
  },
});

const pills = reactive(
  [
    {
      id: 0,
      title: 'Income',
      icon: 'Income',
      active: false,
    },
    {
      id: 1,
      title: 'Activity',
      icon: 'Activity',
      active: false,
    },
    {
      id: 2,
      title: 'Trips',
      icon: 'Trips',
      active: false,
    },
    {
      id: 3,
      title: 'Accounts',
      icon: 'Accounts',
      active: false,
    },
    {
      id: 4,
      title: 'Profile',
      icon: 'Profile',
      active: false,
    },
    {
      id: 5,
      title: 'Wallets',
      icon: 'Wallets',
      active: false,
    },
    {
      id: 6,
      title: 'Employment',
      icon: 'Employment',
      active: false,
    },
  ],
);

const period = reactive({
  start_date: 0,
  end_date: 0,
});

const clickPill = (pill) => {
  pills[pill.id].active = !pills[pill.id].active;
};

const disabled = computed(() => !pills.some((pill) => pill.active));

const timePeriodChange = (value) => {
  period.start_date = value.startDate;
  period.end_date = value.endDate;
};

const sync = () => {
  const payload = {
    account_ids: [],
    all: true,
    end_date: period.end_date,
    endpoints: // return array only pills that are active
      pills.filter((pill) => pill.active).map((pill) => pill.title.toLowerCase()),
    start_date: period.start_date,
  };
  usersStore().refreshUsers(payload).then(() => {
    appStore.setNotification({
      type: 'success',
      message: 'Data sync scheduled',
    });
    emit('close');
    localStorage.setItem('lastSync', new Date());
  }).catch((err) => {
    appStore.setNotification({
      type: 'error',
      message: err.message,
    });
    emit('close');
  });
};

</script>
<style lang="scss" scoped>
.sync {
  .modal {
    max-width: 660px;
  }
}
</style>
