import { defineStore } from 'pinia';
import Data from '../api/settings';

// eslint-disable-next-line import/prefer-default-export
export const settingsStore = defineStore({
  id: 'settingsStore',
  state: () => ({
    trialInfo: null,
    emailCustomizationFetch: null,
    emailCustomization: {
      subject: null,
      title: null,
      cover_image: null,
      message: null,
      email: null,
      partnerName: null,
      button_text: null,
      button_color: '#000000',
      button_text_color: '#ffffff',
    },
    placeholderText: {
      subject: 'You are invited to connect your work accounts',
      title: 'We can use connect to offer our services',
      cover_image: null,
      message: 'Welcome, Valuably Bank is using the Rollee to verify your work history. It’s a quick process that takes no longer than a few minutes for you to complete.',
      button_text: 'Connect your accounts',
      button_color: '#000000',
      button_text_color: '#ffffff',
    },
    dataUpdateRobots: {
      day_of_month: 0,
      day_of_week: 0,
      frequency: 'daily',
      first_connection_data_history: '3m',
      endpoints: [],
    },
    dataUpdateAvailableEnpoints: [],
  }),
  getters: {
    getTrialPaymentInfo: (state) => state.trialInfo,
    getEmailCustomization: (state) => state.emailCustomization,
    getEmailCustomizationsFetch: (state) => state.emailCustomizationFetch,
    getPlaceholders: (state) => state.placeholderText,
    getDataUpdateRobots: (state) => state.dataUpdateRobots,
    getDataUpdateAvailableEnpoints: (state) => state.dataUpdateAvailableEnpoints,
    getFirstConnectionDataHistory: (state) => {
      // i = last character of string (d, w, m, y)
      const i = state.dataUpdateRobots.first_connection_data_history.slice(-1);
      const value = state.dataUpdateRobots.first_connection_data_history.slice(0, -1);
      switch (i) {
        case 'd':
          return value > 1 ? `${value} days` : `${value} day`;
        case 'w':
          return value > 1 ? `${value} weeks` : `${value} week`;
        case 'm':
          return value > 1 ? `${value} months` : `${value} month`;
        case 'y':
          return value > 1 ? `${value} years` : `${value} year`;
        default:
          return false;
      }
    },
    getLoading: (state) => state.loading,
  },
  actions: {
    async fetchEmailCustomization() {
      const response = await Data.fetchEmailCustomization();
      this.emailCustomizationFetch = response.data.customizations;
      if (this.emailCustomizationFetch.length > 0) {
        this.emailCustomization = this.emailCustomizationFetch[0].config;
      }
      return response.data;
    },
    setEmailCustomization(customization) {
      this.emailCustomization = customization;
    },
    fetchTrialPaymentInfo() {
      return new Promise((resolve, reject) => {
        Data.getTrialPaymentInfo().then((response) => {
          this.trialInfo = response.data;
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    saveEmailCustomization(data) {
      if (this.emailCustomizationFetch.length === 0) {
        return new Promise((resolve, reject) => {
          Data.saveEmailCustomization({ data }).then((response) => {
            resolve(response.data);
          }).catch((err) => {
            reject(err);
          });
        });
      }
      return new Promise((resolve, reject) => {
        Data.updateEmailCustomization(
          { id: this.emailCustomizationFetch[0].id, data },
        ).then((response) => {
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    discardCustomization() {
      this.emailCustomization = {
        subject: null,
        title: null,
        coverImage: null,
        message: null,
        email: null,
        partnerName: null,
        button: {
          text: null,
          color: '#000000',
          textColor: '#ffffff',
        },
      };
    },
    async fetchEnpointsList() {
      const response = await Data.getEndpointsList();
      this.dataUpdateAvailableEnpoints = response.data;
      return response.data;
    },
    async fetchRobotsList() {
      const response = await Data.getRobotsList();
      const setting = response.data.data_updates[0];

      this.dataUpdateRobots = {
        day_of_month: setting?.day_of_month || 1,
        day_of_week: setting?.day_of_week || 0,
        frequency: setting?.frequency || 'daily',
        endpoints: response.data.data_updates.map((item) => item.endpoint),
        first_connection_data_history: response.data.first_connection_data_history,
      };
      return response.data;
    },
    postRobotsSettings() {
      const mapSettingsUpdate = {
        data_update_params: {
          day_of_month: this.dataUpdateRobots.day_of_month,
          day_of_week: this.dataUpdateRobots.day_of_week,
          frequency: this.dataUpdateRobots.frequency,
        },
        first_connection_data_history: this.dataUpdateRobots.first_connection_data_history,
      };

      return new Promise((resolve, reject) => {
        Data.postRobotsUpdate(mapSettingsUpdate).then((response) => {
          resolve(response.data);
        }).catch((err) => {
          reject(err);
        });
      });
    },

    resetRobotsSettings() {
      this.dataUpdateRobots = {
        day_of_month: 0,
        day_of_week: 0,
        frequency: 'daily',
        first_connection_data_history: '3m',
        endpoints: [],
      };
    },
  },
});
