<template>
  <button class="button"
    :class="`button--${type}`"
  >
    <i v-if="beforeIcon" :class="beforeIcon" class="before"></i>
    <slot></slot>
    <i v-if="afterIcon" :class="afterIcon" class="after"></i>
  </button>
</template>
<script>

export default {
  name: 'RButton',
  props: {
    beforeIcon: String,
    afterIcon: String,
    type: {
      type: String,
      default: 'primary',
      validator: (value) => ['primary', 'secondary', 'secondary', 'flat', 'warn'].includes(value),
    },
  },
};
</script>
<style scoped lang="scss">
.button {
  font-family: inherit;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  outline: none;
  border: none;
  padding: 12px 20px;
  border-radius: 8px;
  min-width: 120px;
  color: #fff;
  cursor: pointer;
  letter-spacing: 0.4px;
  height: var(--dim-button-height);
  transition: var(--transition-default);
  i {
    position: relative;
    vertical-align: bottom;
    &.after {
      right: -3px;
      top: -1px;
    }
    &.before {
      left: -3px;
      top: -1px;
    }
  }
  &--primary {
    background: var(--color-blue-main);
    border: 1px solid var(--color-blue-main);
    &:hover {
      background: var(--color-blue-hover);
      border-color: var(--color-blue-hover);
    }
    &:active {
      filter: brightness(0.8);
      box-shadow: 0px 0px 0px 1px var(--color-blue-main) inset;
    }
  }
  &--secondary {
    background: #fff;
    border: 1px solid var(--color-grey-light);
    color: var(--color-dark-main);
    &:hover {
      background: #fff;
      color: var(--color-blue-main);
      border: 1px solid var(--color-blue-main);
    }
    &:active {
      filter: brightness(0.96);
      box-shadow: 0px 0px 0px 1px var(--color-blue-main) inset;
    }
  }
  &--warn {
    background: var(--color-red-error);
    border: 1px solid var(--color-red-error);
    color: #fff;
    &:hover {
      color: #fff;
      background: var(--color-red-error-hover);
      border: 1px solid var(--color-red-error-hover);
    }
    &:active {
      filter: brightness(0.96);
      box-shadow: 0px 0px 0px 1px #fff inset;
    }
  }
  &--flat {
    color: var(--color-dark-main);
    background: transparent;
    text-align: start;
    padding: 12px 0;
    &:hover {
      background: #fff;
      color: var(--color-blue-main);
      border: unset;
    }
  }
  &--small {
    padding: 8px 16px;
    font-size: 14px;
    height: 40px;
    min-width: 60px;
  }
  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &--rounded {
    border-radius: 99px;
    padding: 0px 8px;
    background-color: #fff;
    color: var(--color-blue-main);
    height: 28px;
    line-height: 28px;
    width: 100%;
    &:hover {
      background-color: var(--color-blue-main);
      color: #fff;
    }
  }
}
</style>
