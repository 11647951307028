<template>
    <div class="coverages">
        <div class="coverages__table">
            <iframe title="" src="https://coverage.getrollee.com/index.html" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

export default {
  name: 'Users',
  setup() {
    const router = useRouter();

    const showLoadMore = ref(false);

    // eslint-disable-next-line
    const textToSlug = (text) => {
      return text.toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/\./g, '-')
        .replace(/[^a-z0-9-]/g, '');
    };

    const onCellClicked = (params) => {
      const coverageID = textToSlug(params?.data?.title);
      router.push(`/coverages/${coverageID}`);
    };

    const loadSpinner = ref(false);

    const receiveMessage = (event) => {
      if (event.data.key === 'coverage_filter') {
        window.analytics.track(event.data.value);
      }
    };

    onMounted(() => {
      window.addEventListener('message', receiveMessage, false);
    });

    return {
      loadSpinner,
      showLoadMore,
      onCellClicked,
    };
  },
};
</script>
<style lang="scss" scoped>
.coverages {
  height: 100%;
  .coverages__table {
    height: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
:deep(#app) {
  section {
    padding: 0;
  }
}
</style>
