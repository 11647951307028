<template>
  <svg width="17" height="16" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.33329 14.8334H15.6666V9.00008H17.3333V15.6667C17.3333 15.8878 17.2455 16.0997 17.0892
      16.256C16.9329 16.4123 16.721 16.5001 16.5 16.5001H1.49996C1.27895 16.5001 1.06698 16.4123
      0.910704 16.256C0.754423 16.0997 0.666626 15.8878 0.666626
      15.6667V9.00008H2.33329V14.8334ZM10.6666 6.50008V11.5001H7.33329V6.50008H3.16663L8.99996
      0.666748L14.8333 6.50008H10.6666Z"
      fill="#253858"/>
  </svg>
</template>
