<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_6509_48782)">
        <!-- eslint-disable-next-line max-len -->
        <path d="M5.06474 1.42859C4.29154 1.42859 3.66474 2.05539 3.66474 2.82859V5.45321V16.7286C3.66495 16.9404 3.74926 17.1435 3.89913 17.2933C4.049 17.443 4.25218 17.5271 4.46403 17.5271H17.3541C17.5655 17.5256 17.7678 17.441 17.9174 17.2916C18.0669 17.1422 18.1517 16.94 18.1534 16.7286V2.22707C18.1534 1.78598 17.7952 1.42859 17.3541 1.42859H5.06474ZM10.9091 9.07537C10.3754 9.07537 9.86352 8.86336 9.48614 8.48598C9.10876 8.1086 8.89675 7.59676 8.89675 7.06306C8.89675 6.52936 9.10876 6.01752 9.48614 5.64014C9.86352 5.26276 10.3754 5.05075 10.9091 5.05075C11.4428 5.05075 11.9546 5.26276 12.332 5.64014C12.7094 6.01752 12.9214 6.52936 12.9214 7.06306C12.9214 7.59676 12.7094 8.1086 12.332 8.48598C11.9546 8.86336 11.4428 9.07537 10.9091 9.07537ZM14.5095 13.5025H7.30863C7.4062 12.6158 7.82755 11.7962 8.49193 11.201C9.15632 10.6057 10.017 10.2765 10.9091 10.2765C11.8011 10.2765 12.6618 10.6057 13.3262 11.201C13.9906 11.7962 14.4119 12.6158 14.5095 13.5025Z" fill="url(#paint0_linear_6509_48782)"/>
        <!-- eslint-disable-next-line max-len -->
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.87506 4.16011C1.87506 3.44214 2.45709 2.86011 3.17506 2.86011H15.5644C16.0055 2.86011 16.3637 3.21749 16.3637 3.65859V18.1601C16.362 18.3715 16.2772 18.5737 16.1277 18.7231C15.9781 18.8726 15.7758 18.9571 15.5644 18.9586H2.67435C2.46251 18.9586 2.25932 18.8745 2.10945 18.7248C1.95958 18.5751 1.87527 18.372 1.87506 18.1601V6.88473V4.16011Z" fill="url(#paint1_linear_6509_48782)"/>
        <!-- eslint-disable-next-line max-len -->
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.20837 11.5C4.8632 11.5 4.58337 11.7798 4.58337 12.125C4.58337 12.4702 4.8632 12.75 5.20837 12.75H13.0417C13.3869 12.75 13.6667 12.4702 13.6667 12.125C13.6667 11.7798 13.3869 11.5 13.0417 11.5H5.20837ZM5.20837 14.0412C4.8632 14.0412 4.58337 14.321 4.58337 14.6662C4.58337 15.0114 4.8632 15.2912 5.20837 15.2912H13.0417C13.3869 15.2912 13.6667 15.0114 13.6667 14.6662C13.6667 14.321 13.3869 14.0412 13.0417 14.0412H5.20837Z" fill="url(#paint2_linear_6509_48782)"/>
        <!-- eslint-disable-next-line max-len -->
        <path fill-rule="evenodd" clip-rule="evenodd" d="M5.20837 6C4.8632 6 4.58337 6.27982 4.58337 6.625C4.58337 6.97018 4.8632 7.25 5.20837 7.25H13.0417C13.3869 7.25 13.6667 6.97018 13.6667 6.625C13.6667 6.27982 13.3869 6 13.0417 6H5.20837ZM5.20837 8.5412C4.8632 8.5412 4.58337 8.82102 4.58337 9.1662C4.58337 9.51138 4.8632 9.7912 5.20837 9.7912H13.0417C13.3869 9.7912 13.6667 9.51138 13.6667 9.1662C13.6667 8.82102 13.3869 8.5412 13.0417 8.5412H5.20837Z" fill="url(#paint3_linear_6509_48782)"/>
    </g>
    <defs>
        <!-- eslint-disable-next-line max-len -->
        <linearGradient id="paint0_linear_6509_48782" x1="36.4584" y1="23.7203" x2="37.9319" y2="1.09567" gradientUnits="userSpaceOnUse">
            <stop stop-color="#017BFE"/>
            <stop offset="1" stop-color="#0061FF" stop-opacity="0.28"/>
        </linearGradient>
        <!-- eslint-disable-next-line max-len -->
        <linearGradient id="paint1_linear_6509_48782" x1="4.28578" y1="4.64286" x2="15.816" y2="18.7162" gradientUnits="userSpaceOnUse">
            <stop stop-color="#02B3FB"/>
            <stop offset="1" stop-color="#0061FF"/>
        </linearGradient>
        <!-- eslint-disable-next-line max-len -->
        <linearGradient id="paint2_linear_6509_48782" x1="13.6386" y1="15.1676" x2="9.8924" y2="7.39737" gradientUnits="userSpaceOnUse">
            <stop stop-color="#02B3FB"/>
            <stop offset="1" stop-color="white"/>
        </linearGradient>
        <!-- eslint-disable-next-line max-len -->
        <linearGradient id="paint3_linear_6509_48782" x1="13.6386" y1="9.6676" x2="9.8924" y2="1.89737" gradientUnits="userSpaceOnUse">
            <stop stop-color="#02B3FB"/>
            <stop offset="1" stop-color="white"/>
        </linearGradient>
        <clipPath id="clip0_6509_48782">
            <rect width="20" height="20" fill="white"/>
        </clipPath>
    </defs>
    </svg>
</template>
