<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path id="Vector"
            d="M6.75 2.5C7.30812 2.5 7.86077 2.60993 8.37641 2.82351C8.89204 3.03709 9.36056
            3.35015 9.75521 3.7448C10.1499 4.13945 10.4629 4.60796 10.6765 5.1236C10.8901
            5.63923 11 6.19188 11 6.75V11H6.75C5.62283 11 4.54183 10.5522 3.7448 9.7552C2.94777
            8.95817 2.5 7.87717 2.5 6.75C2.5 5.62283 2.94777 4.54183 3.7448 3.7448C4.54183
            2.94777 5.62283 2.5 6.75 2.5ZM9 9V6.75C9 6.30499 8.86804 5.86998 8.62081
            5.49997C8.37358 5.12996 8.02217 4.84157 7.61104 4.67127C7.19991 4.50097
            6.74751 4.45642 6.31105 4.54323C5.87459 4.63005 5.47368 4.84434 5.15901
            5.15901C4.84434 5.47368 4.63005 5.87459 4.54323 6.31105C4.45642 6.7475
            4.50098 7.1999 4.67127 7.61104C4.84157 8.02217 5.12996 8.37357 5.49997
            8.62081C5.86998 8.86804 6.30499 9 6.75 9H9ZM6.75 13H11V17.25C11 18.0906
            10.7507 18.9123 10.2837 19.6112C9.81675 20.3101 9.15299 20.8548 8.37641
            21.1765C7.59982 21.4982 6.74529 21.5823 5.92087 21.4183C5.09645 21.2543
            4.33917 20.8496 3.7448 20.2552C3.15042 19.6608 2.74565 18.9036 2.58166
            18.0791C2.41768 17.2547 2.50184 16.4002 2.82351 15.6236C3.14519 14.847
            3.68992 14.1833 4.38883 13.7163C5.08774 13.2493 5.90943 13 6.75
            13ZM6.75 15C6.30499 15 5.86998 15.132 5.49997 15.3792C5.12996
            15.6264 4.84157 15.9778 4.67127 16.389C4.50098 16.8001 4.45642
            17.2525 4.54323 17.689C4.63005 18.1254 4.84434 18.5263 5.15901
            18.841C5.47368 19.1557 5.87459 19.3699 6.31105 19.4568C6.74751
            19.5436 7.19991 19.499 7.61104 19.3287C8.02217 19.1584 8.37358
            18.87 8.62081 18.5C8.86804 18.13 9 17.695 9 17.25V15H6.75ZM17.25
            2.5C18.3772 2.5 19.4582 2.94777 20.2552 3.7448C21.0522 4.54183
            21.5 5.62283 21.5 6.75C21.5 7.87717 21.0522 8.95817 20.2552
            9.7552C19.4582 10.5522 18.3772 11 17.25 11H13V6.75C13 5.62283
            13.4478 4.54183 14.2448 3.7448C15.0418 2.94777 16.1228 2.5 17.25
            2.5ZM17.25 9C17.695 9 18.13 8.86804 18.5 8.62081C18.87 8.37357
            19.1584 8.02217 19.3287 7.61104C19.499 7.1999 19.5436 6.7475
            19.4568 6.31105C19.37 5.87459 19.1557 5.47368 18.841 5.15901C18.5263
            4.84434 18.1254 4.63005 17.689 4.54323C17.2525 4.45642 16.8001 4.50097
            16.389 4.67127C15.9778 4.84157 15.6264 5.12996 15.3792 5.49997C15.132
            5.86998 15 6.30499 15 6.75V9H17.25ZM13 13H17.25C18.0906 13 18.9123
            13.2493 19.6112 13.7163C20.3101 14.1833 20.8548 14.847 21.1765
            15.6236C21.4982 16.4002 21.5823 17.2547 21.4183 18.0791C21.2544
            18.9036 20.8496 19.6608 20.2552 20.2552C19.6608 20.8496 18.9036
            21.2543 18.0791 21.4183C17.2547 21.5823 16.4002 21.4982 15.6236
            21.1765C14.847 20.8548 14.1833 20.3101 13.7163 19.6112C13.2493
            18.9123 13 18.0906 13 17.25V13ZM15 15V17.25C15 17.695 15.132
            18.13 15.3792 18.5C15.6264 18.87 15.9778 19.1584 16.389 19.3287C16.8001
            19.499 17.2525 19.5436 17.689 19.4568C18.1254 19.3699 18.5263
            19.1557 18.841 18.841C19.1557 18.5263 19.37 18.1254 19.4568
            17.689C19.5436 17.2525 19.499 16.8001 19.3287 16.389C19.1584
            15.9778 18.87 15.6264 18.5 15.3792C18.13 15.132 17.695 15 17.25 15H15Z"
            fill="currentColor"></path>
    </g>
  </svg>
</template>
