<template>
  <div class="modal__backdrop">
    <div class="modal">
      <div class="modal__header">
        <h2>{{ title }}</h2>
        <CloseIcon @click="$emit('close')" />
      </div>
      <div class="modal__content">
        <slot />
      </div>
      <div class="modal__actions">
        <div></div>
        <RButton type="secondary" @click="$emit('close')">Cancel</RButton>
        <RButton type="primary"
                 :disabled="disabled"
                 @click="$emit('cta')"
        >Send Invitation(s)
        </RButton>
      </div>
    </div>
  </div>
</template>
<script>
import CloseIcon from '@/components/icons/CloseIcon.vue';
import RButton from './RButton.vue';

export default {
  name: 'RModal',
  components: {
    CloseIcon,
    RButton,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
