<template>
  <div class="page">
    <div class="regular" v-if="isRegular || isBusiness">
      <template :key="section" v-for="section in regularSections">
        <component :is="section">
          <template #contactSectionActions>
            <RButton @click="goto('https://www.getrollee.com/contact-us')">Contact Support Team</RButton>
            <RButton @click="goto('https://meetings.hubspot.com/pierrick-rollee?__hstc=168263934.a10996489f52f5af9d7cf9871de6ecc2.1655369596481.1684331600537.1684502526773.695&__hssc=168263934.1.1684502526773&__hsfp=3795293077')" class="button--secondary">Book a Demo Walkthrough</RButton>
          </template>
        </component>
      </template>
    </div>
    <div class="fleet" v-if="!loading && isFleet">
      <template :key="section" v-for="section in fleetSections">
        <div :class="{
          up: section === AppTotalEarnings,
          card: addCardClass(section)
          }">
          <component
          :is="section"
          :current="insightsMetrics?.this_week"
          :previuos="insightsMetrics?.last_week"
          :currency="currency"
          :data="insightsPlots?.plots_total_earning_and_driver_number">
          <template #contactSectionActions>
            <RButton @click="goto('https://www.getrollee.com/contact-us')">Contact Support Team</RButton>
            <RButton
            v-if="partnerInfo.lang !== 'fr'"
            @click="onOnboarding"
            class="button--secondary">
              Start Product Tour
            </RButton>
          </template>
          </component>
        </div>
      </template>
    </div>
    <r-loading v-if="loading && profile.getProfile.partnerType ==='Fleet'"></r-loading>
  </div>
</template>

<script setup>
import GetStartedSection from '@/components/GetStarted/GetStartedSection.vue';
import KnowledgeBaseSection from '@/components/GetStarted/KnowledgeBaseSection.vue';
import ContactSection from '@/components/GetStarted/ContactSection.vue';
import AppKeyInsights from '@/components/Insights/KeyInsights.vue';
import AppTotalEarnings from '@/components/Insights/TotalEarnings.vue';
import { profileStore } from '@/store/profile';
import { computed, watch } from 'vue';
import { insightsStore } from '@/store/insights';
import RButton from '@/components/RButton.vue';
import RLoading from '@/components/RLoading.vue';
import { appStore } from '@/store/app';

const profile = profileStore();
const useAppStore = appStore();
const regularSections = [GetStartedSection, KnowledgeBaseSection, ContactSection];

const fleetSections = [AppKeyInsights, AppTotalEarnings, ContactSection];
const useInsights = insightsStore();
const insightsMetrics = computed(() => useInsights.getMetrics);
const insightsPlots = computed(() => useInsights.getPlots);
const currency = computed(() => useInsights.getCurrencySymbol);
const loading = computed(() => useInsights.isLoading);
const partnerInfo = computed(() => profile.getPartnerInfo);

const goto = (url) => {
  window.analytics?.track('get_started', { url });
  window.open(url, '_blank');
};

const onOnboarding = () => {
  window.analytics?.track('User started Product Tour');
  useAppStore.setOnboarding(true);
  useAppStore.setDashboardConfiguration('onboarding', { onboarding: true });
};

const addCardClass = (sectionName) => {
  switch (sectionName) {
    case ContactSection:
      return false;
    default:
      return true;
  }
};

const canOnboard = computed(() => profile?.getProfile?.partnerType === 'Fleet' && profile?.getProfile?.canAccess?.driverInsights);

const isRegular = computed(() => profile?.getProfile?.partnerType === 'Regular');
const isFleet = computed(() => profile?.getProfile?.partnerType === 'Fleet');
const isBusiness = computed(() => profile?.getProfile?.partnerType === 'B2B');
watch(canOnboard, (value) => {
  if (value) {
    useInsights.fetchOverview();
  }
});

</script>
<style scoped>
.regular {
  display: flex;
  flex-direction: column;
}

.fleet {
  display: grid;
  grid-template-rows: auto 400px auto;
  row-gap: 24px;
}
.page {
  height: 100%;
}
</style>
