import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';

const getCoverages = async (params) => {
  const DOMAIN = DOMAIN_PATH;

  const response = await http.get(`${DOMAIN}/documentation/datasources`, params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
    },
  });
  return response.data;
};

export default {
  getCoverages,
};
