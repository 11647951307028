<template>
  <div class="email__wrapper">
        <div class="email__form">
          <RInput
            label="Email Subject"
            :placeholder="placeholders.subject"
            v-model="emailForm.subject"
          />
          <RInput
            label="Header Title"
            :placeholder="placeholders.title"
            v-model="emailForm.title"
          />
          <RFileUpload
            label="Cover Image"
            tooltipText="Please click to upload Cover image."
            text="Drop an image here or"
            ref="image"
            :data="emailForm.cover_image"
            info="JPG, PNG supported. Recommended format 600x160px."
            @image="changePartnerLogo" />
          <RTextArea label="Message"
                     :rows="4"
                     :placeholder="placeholders.message"
                     v-model="emailForm.message"/>
          <div class="switch__wrapper">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label class="switch">
              <input type="checkbox" ref="search_input" v-model="toggleSwitch">
              <span class="slider round"></span>
            </label>
            <p
              class="switch__title"
              @keyup="$refs.search_input.click()"
              @click="$refs.search_input.click()">Button Preference</p>
          </div>
          <template v-if="toggleSwitch">
            <RInput
              label="Button text"
              :placeholder="placeholders.button_text"
              v-model="emailForm.button_text"
            />
            <RInput
              label="Button Color"
              :default-color="emailForm.button_color"
              type="color"
              v-model="emailForm.button_color"
            />
            <RInput
              label="Button Text Color"
              :default-color="emailForm.button_text_color"
              type="color"
              v-model="emailForm.button_text_color"
            />
            <RColorChecker
              text="Button Contrast Ratio"
              :colorForeground="emailForm.button_text_color"
              :colorBackground="emailForm.button_color" />
          </template>
        </div>
    <div class="email__preview">
      <EmailPreview ref="emailTemplate" :params="emailForm.value"/>
    </div>
    <Transition name="fade">
      <div class="email__actions" v-if="!isDisabled">
        <RButton class="button--secondary" @click="discardCustomization">Discard</RButton>
        <RButton @click="saveCustomization" :disabled="isDisabled">Save</RButton>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { appStore } from '@/store/app';
import EmailPreview from '@/components/Settings/EmailPreview.vue';
import RFileUpload from '@/components/RFileUpload.vue';
import RInput from '@/components/RInput.vue';
import {
  computed,
  reactive,
  ref,
  watch,
  onMounted,
  onUnmounted,
} from 'vue';
import RTextArea from '@/components/RTextArea.vue';
import RColorChecker from '@/components/RColorChecker.vue';
import { settingsStore } from '@/store/settings';
import RButton from '@/components/RButton.vue';
import { profileStore } from '@/store/profile';

const store = settingsStore();
const profile = profileStore();
const profileData = profile.getProfile;

const emailForm = reactive({
  subject: '',
  sender: null,
  title: '',
  cover_image: null,
  message: '',
  email: profileData.email,
  partnerName: profileData.partnerName,
  extColor: '#ffffff',
  button_text: '',
  button_color: '#000',
  button_text_color: '#ffffff',
});

const isDisabled = computed(() => {
  if (emailForm.subject === ''
    || emailForm.title === ''
    || emailForm.message === '') {
    return true;
  }
  return false;
});

const placeholders = computed(() => store.getPlaceholders);

watch(() => emailForm, (value) => {
  store.setEmailCustomization(value);
}, {
  deep: true,
});

const emailTemplate = ref(null);

const toggleSwitch = ref(false);

const changePartnerLogo = (value) => {
  emailForm.cover_image = value;
  if (value === '/img/part/rollee-logo.png') {
    emailForm.cover_image = null;
  }
};

const image = ref(null);

const discardCustomization = (notification = true) => {
  store.fetchEmailCustomization().then((res) => {
    if (res.customizations.length) {
      emailForm.subject = res.customizations[0].config.subject;
      emailForm.title = res.customizations[0].config.header_title;
      emailForm.cover_image = res.customizations[0].config.cover_image;
      emailForm.message = res.customizations[0].config.message;
      emailForm.button_text = res.customizations[0].config.button_text;
      emailForm.button_color = res.customizations[0].config.button_color;
      emailForm.button_text_color = res.customizations[0].config.button_text_color;
      if (notification) {
        appStore().setNotification({
          type: 'success',
          message: 'Changes discarded',
        });
      }
    }
    window.analytics?.track('discard_settings_email');
  });
};

const saveCustomization = () => {
  const payload = {
    button_color: emailForm.button_color || placeholders.value.button_color,
    button_text_color: emailForm.button_text_color || placeholders.value.button_text_color,
    button_text: emailForm.button_text || placeholders.value.button_text,
    cover_image: emailForm.cover_image,
    header_title: emailForm.title,
    initials: emailForm.partnerName.split(' ').map((n) => n[0]).join(''),
    message: emailForm.message,
    subject: emailForm.subject,
    email: emailForm.email,
  };

  window.analytics?.track('save_settings_email');

  if (emailTemplate.value) {
    store.saveEmailCustomization(payload).then(() => {
      appStore().setNotification({
        type: 'success',
        message: 'Reconnect Invitations sent',
      });
    });
  }
};

onMounted(() => {
  discardCustomization(false);
});

onUnmounted(() => {
  store.setEmailCustomization(emailForm.value);
});

</script>

<style scoped lang="scss">
.email {
  &__wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 620px auto;
  }
  &__form {
    padding: 32px 24px 64px 36px;
    max-width: 600px;
    width: 100%;
    justify-self: start;
    box-sizing: border-box;
  }
  &__preview {
    background: var(--grey-outline, #EDF2F8);
    display: grid;
    padding-top: 52px;
  }
  &__actions {
    display: grid;
    gap: 12px;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    width: calc(100% - 220px);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
    padding: 8px 30px;
    border: 1px solid var(--grey-outline, #EDF2F8);
    background: var(--white, #FFF);
    box-shadow: 0px 4px 40px 0px rgba(20, 20, 20, 0.04);
  }
}
</style>
