<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="daypicker">
    <input type="text"
      @input="validateOnInput"
      @blur="validateOnBlur"
      v-model="day"
      maxlength="2"
      max="31"
      min="1"
      name="day"
      autocomplete="off"
      ref="dayRef"
      @keyup.enter="sendDate"
    >
    <span>/</span>
    <input
      type="text"
      v-model="month"
      @input="validateOnInput"
      @blur="validateOnBlur"
      maxlength="2"
      max="12"
      min="1"
      name="month"
      autocomplete="off"
      ref="monthRef"
      @keyup.enter="sendDate"
    >
    <span>/</span>
    <input
      type="text"
      v-model="year"
      @input="validateOnInput"
      @blur="validateOnBlur"
      maxlength="4"
      :max="new Date().getFullYear()"
      min="2000"
      autocomplete="off"
      name="year"
      ref="yearRef"
      @keyup.enter="sendDate"
    >
  </div>
</template>
<script setup>
import { ref, watch } from 'vue';

// read v-model
const props = defineProps(['modelValue']);

const modelDate = props.modelValue.split('/');

const day = ref(modelDate[0]);
const month = ref(modelDate[1]);
const year = ref(modelDate[2]);

const dayRef = ref(null);
const monthRef = ref(null);
const yearRef = ref(null);

const emit = defineEmits(['updateStartDate', 'enterUpdateDate', 'updateEndDate', 'updateDate']);

watch(() => props.modelValue, (newValue) => {
  const newDate = newValue.split('/');
  // eslint-disable-next-line
  day.value = newDate[0];
  // eslint-disable-next-line
  month.value = newDate[1];
  // eslint-disable-next-line
  year.value = newDate[2];
});

const sendDate = () => {
  emit('enterUpdateDate', `${day.value}/${month.value}/${year.value}`);
};

const validateOnBlur = (e) => {
  const iValue = Number(e.target.value);
  const iMax = Number(e.target.max);
  const iMin = Number(e.target.min);
  const el = e.target.name;

  if (iValue > iMax) {
    if (el === 'day') {
      day.value = e.target.max;
    } else if (el === 'month') {
      month.value = e.target.max;
    } else {
      year.value = e.target.max;
    }
  } else if (iValue < iMin) {
    if (el === 'day') {
      day.value = e.target.min;
    } else if (el === 'month') {
      month.value = e.target.min;
    } else {
      year.value = e.target.min;
    }
  }
  if (el === 'year' && e.target.value.length === 4) {
    emit('updateDate', `${day.value}/${month.value}/${year.value}`);
  }
};

const validateOnInput = (e) => {
  // prevent enter letters
  e.target.value = e.target.value.replace(/\D/g, '');
  const el = e.target.name;

  // check if input has 2 digits then move to next input
  if (e.target.value.length === 2) {
    if (el === 'day') {
      monthRef.value.focus();
      // select all text
      monthRef.value.select();
    } else if (el === 'month') {
      yearRef.value.focus();
      yearRef.value.select();
    }
  }
};

</script>

<style scoped lang="scss">
.daypicker {
  display: flex;
  padding: 2px 0px 1px 0px;
  transition: var(--transition-default);
  input {
    font-family: inherit;
    font-weight: 600;
    font-size: 14px;
    width: 18px;
    border: none;
    text-align: center;
    background: transparent;
    &:focus {
      outline: none;
    }
    &:last-child {
      width: 36px;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &[type=number] {
      -moz-appearance: textfield;
    }
  }
  &:hover {
    background: #f9f9f9;
  }
}
</style>
