<template>
  <OnClickOutside @trigger="openMenu = false" :options="{ ignore: ['.dropdown__list'] }">
    <div class="dropdown__wrapper">
      <!-- eslint-disable-next-line -->
      <label>{{ label }}</label>
      <div class="dropdown" @click="openMenu = !openMenu" @keyDown="openMenu = !openMenu">
        <p>{{ currentItem.title }}</p>
        <div class="dropdown__icon">
          <ArrowIcon />
        </div>
      </div>
      <Transition name="fade">
        <div class="dropdown__list" v-if="openMenu">
          <ul>
            <li v-for="(item, idx) in props.items"
              :key="item.title" @click="callAction(item, idx)" @keydown="callAction(item, idx)">
              <i :class="item.icon"></i>
              {{ item.title }}
            </li>
          </ul>
        </div>
      </Transition>
    </div>
  </OnClickOutside>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import {
  ArrowIcon,
} from '@/components/icons';
import { OnClickOutside } from '@vueuse/components';

const props = defineProps({
  title: {
    type: String,
    required: false,
  },
  items: {
    type: Array,
    required: true,
  },
  label: {
    type: String,
    required: false,
  },
  value: {
    type: String,
    required: false,
  },
});

const choosenItem = ref(null);

const emit = defineEmits(['action']);

const currentItem = computed(() => {
  if (choosenItem.value === null) {
    return { title: '--' };
  }
  return props.items[choosenItem.value];
});

const openMenu = ref(false);

const callAction = (item, idx) => {
  choosenItem.value = idx;
  emit('action', item, props.value);
  openMenu.value = false;
};

onMounted(() => {
  if (props.value) {
    const idx = props.items.findIndex((item) => item.id === props.value);
    choosenItem.value = idx;
  }
});
</script>
<style lang="scss" scoped>
.dropdown {
  width: calc(100% - 32px);
  display: grid;
  padding: 12px 16px;
  grid-template-columns: auto 8px;
  cursor: pointer;
  background: #fff;
  border-radius: 8px;
  border: 1px solid var(--color-grey-light);
  &__wrapper {
    position: relative;
    label {
      display: flex;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-dark-main);
      padding-bottom: 6px;
    }
  }
  p {
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin-right: 12px;
    color: var(--color-black);
    user-select: none;
  }
  &__icon {
    transform: translateY(0px) scale(1.18);
  }
  :deep svg path {
    fill: var(--color-grey-dark);
  }
  &__list {
    &--hidden {
      display: none;
    }
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: 100%;
    max-width: calc(100% - 16px);
    min-width: 120px;
    background: #fff;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
    padding: 8px;
    z-index: 999;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: grid;
        grid-template-columns: 2px auto;
        gap: 2px;
        padding: 12px 16px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        color: var(--color-grey-dark);
        cursor: pointer;
        user-select: none;
        border-radius: 8px;
        &:hover {
          background: var(--color-blue-background);
          color: var(--color-blue-main);
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
