<template>
  <div class="select__wrapper"
    :class="{
      'select__wrapper--no-outline': noOutline,
      'select__wrapper--margin': margin}">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label v-if="label" for="select_0">{{ label }}</label>
    <select name="select_0" id="select_0" @change="handleChange">
      <option value="prev_week">Previous Week</option>
      <option value="prev_2_weeks">Previous 2 Weeks</option>
      <option value="prev_month">Previous Month</option>
      <option value="prev_3_months">Previous 3 Months</option>
      <option value="curr_week">Current Week</option>
      <option value="curr_month">Current Month</option>
    </select>
  </div>
</template>
<script>
import { reactive, onMounted, onUnmounted } from 'vue';
import { appStore } from '@/store/app';
import {
  startOfWeek, subDays, endOfWeek, getUnixTime, subMonths, startOfMonth, endOfMonth,
} from 'date-fns';

export default {
  name: 'RSelect',
  props: {
    options: {
      type: Array,
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    margin: {
      boolean: Boolean,
      required: false,
    },
    noOutline: {
      boolean: Boolean,
      required: false,
    },
    canModify: {
      boolean: Boolean,
      required: false,
    },
  },
  setup(props, { emit }) {
    const before7Days = subDays(new Date(), 7);
    const before14Days = subDays(new Date(), 14);

    const state = reactive({
      startDate: getUnixTime(startOfWeek(before7Days, { weekStartsOn: 1 })),
      endDate: getUnixTime(endOfWeek(before7Days, { weekStartsOn: 1 })),
    });
    const setDateRanges = (value) => {
      switch (value) {
        case 'prev_week':
          state.startDate = getUnixTime(startOfWeek(before7Days, { weekStartsOn: 1 }));
          state.endDate = getUnixTime(endOfWeek(before7Days, { weekStartsOn: 1 }));
          break;
        case 'prev_2_weeks':
          state.startDate = getUnixTime(startOfWeek(before14Days, { weekStartsOn: 1 }));
          state.endDate = getUnixTime(endOfWeek(before7Days, { weekStartsOn: 1 }));
          break;
        case 'prev_month':
          state.startDate = getUnixTime(startOfMonth(subMonths(new Date(), 1)));
          state.endDate = getUnixTime(endOfMonth(subMonths(new Date(), 1)));
          break;
        case 'prev_3_months':
          state.startDate = getUnixTime(startOfMonth(subMonths(new Date(), 3)));
          state.endDate = getUnixTime(endOfMonth(subMonths(new Date(), 1)));
          break;
        case 'curr_week':
          state.startDate = getUnixTime(startOfWeek(new Date(), { weekStartsOn: 1 }));
          state.endDate = getUnixTime(endOfWeek(new Date(), { weekStartsOn: 1 }));
          break;
        case 'curr_month':
          state.startDate = getUnixTime(startOfMonth(new Date()));
          state.endDate = getUnixTime(endOfMonth(new Date()));
          break;
        default:
          state.startDate = getUnixTime(startOfWeek(before7Days, { weekStartsOn: 1 }));
          state.endDate = getUnixTime(endOfWeek(before7Days, { weekStartsOn: 1 }));
      }
    };
    const handleChange = (e) => {
      setDateRanges(e.target.value);
      emit('value', state);
      emit('selectedDate', e.target.value);
    };

    onMounted(() => {
      const mounted = true;
      const defaultValue = 'prev_week';
      const selectValue = appStore().getDateSelectValue || defaultValue;
      // check query param exist and set value of select
      const urlParams = new URLSearchParams(window.location.search);
      const queryParam = urlParams.get('selected');
      if (queryParam && queryParam !== 'null') {
        document.getElementById('select_0').value = queryParam;
      } else {
        document.getElementById('select_0').value = selectValue;
      }
      setDateRanges(selectValue);
      emit('value', { ...state, mounted });
    });

    onUnmounted(() => {
      appStore().setUsersDateSelectValue(null);
    });
    return {
      state,
      handleChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.select {
  &__wrapper {
    position: relative;
    label {
      display: block;
      font-size: 12px;
      line-height: 160%;
      font-weight: 600;
      color: var(--color-grey-light);
    }
    i {
      position: absolute;
      left: 16px;
      bottom: 14px;
      font-size: 20px;
      color: var(--color-dark-main);
      z-index: 9;
    }
    select {
      font-family: inherit;
      appearance: none;
      background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M7 10l5 5 5-5H7z"/></svg>') no-repeat right 10px center;
      border: 1px solid var(--color-grey-light);
      border-radius: 8px;
      color: var(--color-dark-main);
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      height: 48px;
      min-width: 272px;
      padding-left: 16px;
    }
    &--margin {
      margin-bottom: 32px;
    }
    &--no-outline {
      select {
        border: none;
        outline: none;
        min-width: initial;
        height: 24px;
        padding-right: 42px;
        padding-left: 0;
      }
    }
  }
}
</style>
