import { defineStore } from 'pinia';
import { usersRemap, accountsRemap } from '@/util/usersRemap';
import { cloneDeep } from 'lodash';
import Data from '../api/data';
import UsersData from '../api/users';

// eslint-disable-next-line import/prefer-default-export
export const usersStore = defineStore({
  id: 'usersStore',
  state: () => ({
    data: null,
    current_user: null,
    carLogos: null,
    accounts: null,
    pendingAccounts: null,
    partnerStatus: null,
    dynamicColumns: [],
    modal: {
      show: false,
      data: null,
      multiple: false,
    },
    watchAction: {
      action: null,
      data: null,
    },
    invitations: null,
  }),
  getters: {
    getModal: (state) => state.modal,
    getUsers: (state) => state.data,
    getCurrentUser: (state) => state.current_user,
    getCarLogos: (state) => state.carLogos,
    getUsersLength: (state) => (state.data ? state.data.users.length : 0),
    getAccounts: (state) => state.accounts,
    getPendingAccounts: (state) => state.pendingAccounts,
    getPartnerStatus: (state) => state.partnerStatus,
    getWatchAction: (state) => state.watchAction,
    getDynamicColumns: (state) => state.dynamicColumns,
    getInvitations: (state) => state.invitations,
  },
  actions: {
    fetchCarLogos() {
      return new Promise((resolve, reject) => {
        Data.getCarLogos().then((data) => {
          this.carLogos = data;
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    removeAccounts(ids) {
      const IDS = ids.map((item) => item.id.toString());
      this.accounts = this.accounts.filter((account) => !IDS.includes(account.id));
    },
    setModal(data) {
      this.modal = data;
    },
    setPartnerStatus(data) {
      this.partnerStatus = data;
    },
    reconnectUser(params) {
      this.watchAction = {
        action: 'reconnect',
        data: params,
      };
    },
    resendInvite(params) {
      this.watchAction = {
        action: 'resend',
        data: params,
      };
    },
    clearWatchAction() {
      this.watchAction = null;
    },
    fetchUsers(params) {
      return new Promise((resolve, reject) => {
        Data.getUsers(params).then((data) => {
          if (data.users[0]?.total_income?.dynamic_data_points) {
            this.dynamicColumns = cloneDeep(data.users[0].total_income.dynamic_data_points);
          }
          if (!this.data || params.cursor === 0) {
            this.data = data;
            this.data.users = usersRemap(data.users);
          } else {
            this.data.start_date = data.start_date;
            this.data.end_date = data.end_date;
            this.data.size = data.size;
            this.data.next_cursor = data.next_cursor;
            this.data.users = [...this.data.users, ...usersRemap(data.users, this.data.users)];
          }
          resolve(data.users.length);
        }).catch((err) => {
          this.data = null;
          reject(err);
        });
      });
    },
    sendUsersInvite(params) {
      const state = this;
      let totalSent = 0;
      return new Promise((resolve, reject) => {
        function chunkArray(array, chunkSize) {
          const chunks = [];
          for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
          }
          return chunks;
        }

        function sendInviteUsersInBatches(data) {
          const chunkSize = 20;
          const userChunks = chunkArray(data.users, chunkSize);

          function sendChunk(index) {
            if (index >= userChunks.length) {
              setTimeout(() => {
                state.invitations = null;
              }, 1000);
              resolve();
              return;
            }

            totalSent += userChunks[index].length;
            state.invitations = {
              total: data.users.length,
              current: totalSent,
            };

            const chunkParams = { ...data, users: userChunks[index] };
            // eslint-disable-next-line consistent-return
            return Data.postInviteUsers(chunkParams)
            // eslint-disable-next-line
              .then(() => {
                // eslint-disable-next-line
                return new Promise((resolve1) => setTimeout(resolve1, 2000));
              })
              .then(() => sendChunk(index + 1)).catch((err) => {
                console.error(`Error sending chunk ${index + 1}`, err);
                reject(err);
              });
          }

          return sendChunk(0);
        }
        sendInviteUsersInBatches(params);
      });
    },
    sendAccountRecconect(params) {
      return new Promise((resolve, reject) => {
        UsersData.reconnectAccounts(params).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    refreshUsers(params) {
      return new Promise((resolve, reject) => {
        Data.usersRefresh(params).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchUserById(id, params) {
      return new Promise((resolve, reject) => {
        Data.getUserById(id, params).then((data) => {
          this.current_user = data;
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    clearUsers() {
      this.data = null;
      this.current_user = null;
    },
    setUserCustomAttribute(id, params) {
      return new Promise((resolve, reject) => {
        const PARAMS = {
          id,
          params,
        };
        Data.setUserCustomAttribute(PARAMS).then((res) => {
          this.data.users = this.data.users.map((user) => {
            if (user.user_id === id) {
              return {
                ...user,
                custom_attributes: PARAMS,
              };
            }
            return user;
          });
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchAccounts() {
      return new Promise((resolve, reject) => {
        UsersData.getAccounts().then((data) => {
          this.accounts = accountsRemap(data.data);
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    removeBulk(data) {
      const DATA = {
        account_ids: [],
        user_ids: [],
      };
      data.forEach((item) => {
        if (item.status === 'pending') {
          DATA.user_ids.push(item.id);
        } else {
          DATA.account_ids.push(item.id);
        }
      });
      return new Promise((resolve, reject) => {
        UsersData.removeBulk(DATA).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    removeConnectedAccount(id) {
      return new Promise((resolve, reject) => {
        UsersData.removeConnectedAccount(id).then((res) => {
          this.accounts = [];
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchPartnerStatus() {
      return new Promise((resolve, reject) => {
        UsersData.getPartnerStatus().then((data) => {
          this.partnerStatus = data.bool;
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    putUserMetadata(id, data) {
      return new Promise((resolve, reject) => {
        UsersData.putUserMetadata(id, data).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchUsersFleets() {
      return new Promise((resolve, reject) => {
        UsersData.getUsersFleets().then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchFleetsDataSources() {
      return new Promise((resolve, reject) => {
        UsersData.getFleetsDataSources().then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    uploadFleetCSV(data) {
      return new Promise((resolve, reject) => {
        UsersData.uploadFleetCSV(data).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
  },
});
