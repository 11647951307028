import { defineStore } from 'pinia';
import { profileStore } from './profile';

import Data from '../api/data';

// eslint-disable-next-line import/prefer-default-export
export const appStore = defineStore({
  id: 'appStore',
  state: () => ({
    production: true,
    headerTitleInfo: '',
    notification: {},
    sandboxSwitch: true,
    dateSelectValue: null,
    usersDateSelectValue: null,
    currentMenu: null,
    config: null,
    pollingFetchStatus: false,
    onboarding: null,
    onboardingStep: null,
  }),
  getters: {
    getState: (state) => state.production,
    getHeaderTitleInfo: (state) => state.headerTitleInfo,
    getNotification: (state) => state.notification,
    getSandboxSwitch: (state) => state.sandboxSwitch,
    getDateSelectValue: (state) => state.dateSelectValue,
    getUsersDateSelectValue: (state) => state.usersDateSelectValue,
    getCurrentMenu: (state) => state.currentMenu,
    getConfig: (state) => {
      const profile = profileStore().getProfile;
      return state.config ? state.config[profile.email] : null;
    },
    getPollingFetchStatus: (state) => state.pollingFetchStatus,
    getOnboarding: (state) => state.onboarding,
    getOnboardingStep: (state) => state.onboardingStep,
  },
  actions: {
    setCurrentMenu(data) {
      this.currentMenu = data;
    },
    setState(data) {
      this.production = data;
      localStorage.setItem('production', data);
      if (data) {
        localStorage.setItem('partner_id', profileStore().getPartnerInfo.id);
      } else {
        localStorage.setItem('partner_id', profileStore().getPartnerInfo.sandbox_id);
      }
    },
    setHeaderTitleInfo(data) {
      this.headerTitleInfo = data;
    },
    setNotification(data) {
      this.notification.message = data.message;
      this.notification.type = data.type || 'success';
      this.notification.ttl = data.ttl || 5000;
      this.notification.isPolling = data.isPolling || false;
      setTimeout(() => {
        this.notification = {};
      }, this.notification.ttl);
    },
    setSandboxSwitch(data) {
      this.sandboxSwitch = data;
    },
    setDateSelectValue(data) {
      this.dateSelectValue = data;
    },
    setUsersDateSelectValue(data) {
      this.usersDateSelectValue = data;
    },
    setPollingFetchStatus(data) {
      this.pollingFetchStatus = data;
    },
    getDashboardConfiguration() {
      return new Promise((resolve, reject) => {
        Data.getDashboardConfiguration().then((res) => {
          this.config = res.data;
          localStorage.removeItem('reload_unauthorized');
          resolve(res);
        }).catch((err) => {
          if (localStorage.getItem('reload_unauthorized') !== '1') {
            localStorage.removeItem('partner_id');
            localStorage.setItem('reload_unauthorized', 1);
            window.location.reload();
          }
          reject(err);
        });
      });
    },
    setDashboardConfiguration(what, data) {
      const profile = profileStore().getProfile;
      const patchData = {
        nested_keys: [profile.email, what],
        value: data[what],
      };

      if (!this.config[profile.email]) {
        const payload = { [profile.email]: data };
        return new Promise((resolve, reject) => {
          Data.setDashboardConfiguration(payload).then((res) => {
            this.config = { ...this.config, ...res.data };
            resolve(res);
          }).catch((err) => {
            reject(err);
          });
        });
      }

      return new Promise((resolve, reject) => {
        Data.updateDashboardConfiguration(patchData).then((res) => {
          this.config[profile.email] = { ...this.config[profile.email], [what]: data[what] };
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    setOnboarding(data) {
      if (data) {
        localStorage.setItem('sampleData', true);
      }
      this.onboarding = data;
    },
    setOnboardingStep(data) {
      this.onboardingStep = data;
    },
  },
});
