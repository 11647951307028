<template>
  <div class="datasource" v-if="propertiesArray.length > 0">
    <div class="datasource__title">
      <component :is="icon" height="20" width="20" />
      <span>{{ name }}</span>
    </div>
    <div class="datasource__content">
      <ul>
        <li v-for="(property, key) in propertiesArray" :key="key">
          <span>
            <span class="ds-icon">
              <CheckOutlineIcon v-if="property.status > 0" :fill="getFillColor(property.status)"/>
              <CancelIcon v-else/>
            </span>
            <span :class="['ds-property', getStatusClass(property.status)]">
              {{ property.key }}
            </span>
          </span>
          <span class="ds-type">
            {{ property.type || 'string' }}
            <r-tool-tip v-if="data[property.key]" outline>
              <template #tooltip-text>
               {{ data[property.key] }}
              </template>
            </r-tool-tip>
          </span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import RToolTip from '@/components/RToolTip.vue';
import CheckOutlineIcon from '@/components/icons/CheckOutlineIcon.vue';
import CancelIcon from '@/components/icons/CancelIcon.vue';
/* eslint-disable vue/no-unused-components */
import ProfileIcon from '@/components/icons/ProfileIcon.vue';
import IncomeIcon from '@/components/icons/IncomeIcon.vue';
import DocumentIcon from '@/components/icons/DocumentIcon.vue';
import TripsIcon from '@/components/icons/TripsIcon.vue';
import ActivityIcon from '@/components/icons/ActivityIcon.vue';

export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    properties: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: false,
    },
  },
  components: {
    CheckOutlineIcon,
    ProfileIcon,
    IncomeIcon,
    DocumentIcon,
    ActivityIcon,
    TripsIcon,
    CancelIcon,
    RToolTip,
  },
  computed: {
    propertiesArray() {
      return Object.entries(this.properties).map(([key, value]) => ({
        key,
        status: value,
        type: this.properties[key].type || '',
      }));
    },
  },
  methods: {
    getStatusClass(status) {
      if (status >= 0.7) return 'ds-property--available';
      if (status > 0 && status < 0.7) return 'ds-property--sometimes';
      if (status === 0) return 'ds-property--never';
      return '';
    },
    getFillColor(status) {
      if (status >= 0.7) return '#0061FF';
      if (status > 0 && status < 0.7) return '#FE9543';
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.datasource {
    min-width: 258px;
    &__title {
        display: flex;
        align-items: center;
        gap: 12px;
        border-bottom: 1px solid #EDF2F8;
        padding-bottom: 12px;
        text-transform: capitalize;

        span {
            color: var(--color-dark-main, #253858);
            font-family: Gilroy;
            font-size: 15px;
            font-style: normal;
            font-weight: 600;
            line-height: 160%;
        }
    }
    &__content {
        padding: 12px 0px;

        ul {
            list-style-type: none;
            display: flex;
            flex-direction: column;
            gap: 16px;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;
                span {
                    font-size: 14px;
                    color: var(--color-dark-main, #253858);
                    font-weight: 500;

                    &:nth-child(1) {
                        display: flex;
                        align-items: center;
                        gap: 9px;
                    }
                    &.ds-type {
                      display: inline-flex;
                      gap: 4px;
                      color: var(--color-grey-dark, #7A808B);
                      :deep svg {
                        position: relative;
                        top: 1px;
                      }
                    }
                    &.ds-property {
                      &--never {
                        color: var(--color-grey-light, #A8AFBC);
                      }
                    }
                }
            }
        }
    }
    &__empty-state {
      font-size: 12px;
      color: var(--color-dark-main, #253858);
      font-weight: 500;
    }
}
</style>
