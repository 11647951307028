<template>
  <div v-if="!isLoading" class="page--drivers">
    <div class="page__actions">
       <RInput
       placeholder="Search a user by name"
       v-model="search"
       lefticon="ri-search-line"
       />
   </div>
   <div class="page__content">
       <div class="table">
           <div class="table__head">
               <span>Name</span>
               <span>User ID</span>
               <span>This Week Earnings</span>
               <span>Last Week Earnings</span>
               <span>Actions</span>
           </div>
           <div class="table__content">
               <div class="table__item" :key="driver" v-for="driver in filteredDrivers">
                   <p>{{ driver?.full_name }}</p>
                   <p>{{ nullToDash(driver?.driver_id) }}</p>
                   <p>{{ numberFormat(driver?.this_week_earnings)}}
                    <span v-if="driver?.this_week_earnings !== null">{{ currency }}</span>
                  </p>
                   <p>{{ numberFormat(driver?.last_week_earnings) }}
                    <span v-if="driver?.last_week_earnings !== null">{{ currency }}</span>
                  </p>
                   <div>
                       <button
                       @click="onDetails(driver.person_id, driver.full_name)"
                       class="table__button">
                        See Details
                      </button>
                   </div>
               </div>
               <div class="table__not-found" v-if="filteredDrivers.length === 0">
                       No User found
                   </div>
           </div>
       </div>
   </div>
  </div>
  <r-loading v-else></r-loading>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
} from 'vue';
import { useRouter } from 'vue-router';
import { insightsStore } from '@/store/insights';
import RLoading from '@/components/RLoading.vue';
import { nullToDash, numberFormat } from '@/util/helpers';
import useEmitter from '@/composables/useEmitter';
import RInput from '../../components/RInput.vue';

const useInsights = insightsStore();
const router = useRouter();
const drivers = computed(() => useInsights.getDrivers);
const isLoading = computed(() => useInsights.getIsLoading);
const currency = computed(() => useInsights.getCurrencySymbol);
const search = ref('');
const emitter = useEmitter();

const filteredDrivers = computed(() => {
  if (search.value.length === 0) {
    return drivers.value;
  }

  return drivers.value.filter((driver) => driver?.full_name
    .toLowerCase().match(search.value.toLowerCase()));
});

const onDetails = (personId, driverName) => {
  window.analytics?.track('details_insights_users');
  useInsights.fetchDriverDetails(personId).then(() => {
    router.push({
      name: 'Insights Details',
      params: {
        id: personId,
        name: driverName,
      },
    }).catch((err) => {
      console.error(err);
    });
    useInsights.setDriverName(driverName);
  });
};
onMounted(() => {
  if (drivers.value.length === 0) {
    useInsights.fetchOverview();
  }
  emitter.on('insightSampleData', () => {
    useInsights.fetchOverview();
  });
});

onBeforeUnmount(() => {
  emitter.off('insightSampleData');
});

</script>

<style scoped>
.page--drivers{
   margin-top: 20px;
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   column-gap: 24px;
   grid-template-rows: 50px auto;
   row-gap: 20px;
   padding: 0 30px 30px 36px;
}

.page__actions{
   align-items: center;
   width: 400px;
}

.page__actions h1{
   color: var(--color-dark-main);
   font-size: 24px;
   font-style: normal;
   font-weight: 500;
   line-height: 38.5;
}

.page__content{
   grid-column: 1/-1;
}
.table{
   border-radius: 8px;
   border: 1px solid var(--color-grey-outline);
   background: var(--white, #FFF);
   box-shadow: 0px 8px 20px 0px rgba(20, 20, 20, 0.02);
}

.table__head{
   display: grid;
   grid-template-columns: 1fr 1fr 1fr 1fr 115px;
   column-gap: 20px;
   border-bottom: 1px solid var(--color-grey-outline);
   background: var(--blue-background, #F8FBFD);
   padding: 12px 16px;
}

.table__head span{
   color: var(--color-dark-main);
   font-size: 14px;
   font-style: normal;
   font-weight: 600;
   line-height: 22.5px;
}

.table__item{
   display: grid;
   align-items: center;
   grid-template-columns: 1fr 1fr 1fr 1fr 115px;
   column-gap: 20px;
   padding: 16px;
   border-bottom: 1px solid var(--color-grey-outline);
}

.table__item p {
   color: var(--color-dark-main);
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
   word-break: break-all;
}

.table__button {
 appearance: none;
 outline: none;
 border: none;
 border-radius: 4px;
 font-family: inherit;
 background: var(--color-grey-outline);
 color: var(--color-blue-main);
 font-size: 14px;
 font-weight: 600;
 line-height: 20px;
 padding: 6px 20px;
 cursor: pointer;
}

.table__not-found{
   height: 200px;
   display: flex;
   justify-content: center;
   align-items: center;
   color: var(--color-dark-main);
   font-size: 14px;
   font-style: normal;
   font-weight: 500;
   line-height: normal;
}
</style>
