<template>
  <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_9990_73262)">
      <circle cx="33" cy="27" r="24" fill="white" />
      <circle cx="33" cy="27" r="24.5" stroke="#EDF2F8" />
    </g>
    <g clip-path="url(#clip0_9990_73262)">
      <!-- eslint-disable-next-line max-len -->
      <path d="M33 42C24.7155 42 18 35.2845 18 27C18 18.7155 24.7155 12 33 12C41.2845 12 48 18.7155 48 27C48 35.2845 41.2845 42 33 42ZM31.5045 33L42.1095 22.3935L39.9885 20.2725L31.5045 28.758L27.261 24.5145L25.14 26.6355L31.5045 33Z"
        fill="#0061FF" />
    </g>
    <defs>
      <filter id="filter0_d_9990_73262" x="0" y="0" width="66" height="66"
        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="6" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix"
          values="0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0 0.0784314 0 0 0 0.08 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9990_73262" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9990_73262"
          result="shape" />
      </filter>
      <clipPath id="clip0_9990_73262">
        <rect width="36" height="36" fill="white" transform="translate(15 9)" />
      </clipPath>
    </defs>
  </svg>

</template>
