<!-- eslint-disable import/no-extraneous-dependencies -->
<template>
  <div class="customization__wrapper" v-if="rerenderComponent">
    <div class="customization__header">
      <nav>
        <ul>
          <li
            v-for="item in menu"
            :key="item.id"
            :class="{'active': selectedMenu === item.id}"
            @keydown.enter="selectMenu(item.id)"
            @click="selectMenu(item.id)">
            <i :class="`ri-${item.icon}`"></i>
            {{ item.name }}
          </li>
        </ul>
      </nav>
    </div>
    <div class="customization">
      <div class="customization__action" v-show="selectedMenu === 0">
        <RInput
          label="Customization Name*"
          placeholder="Connect for Use Case 1"
          :tooltipText="tooltips.customization"
          v-model="connectParams.name"
        />
        <h2 class="customization__title">Global Appearance</h2>
        <RFileUpload :tooltipText="tooltips.branding"
          @image="changePartnerLogo" :data="connectParams.partner_logo" />
        <RList
          label="Language"
          title="Action"
          :items="languageList"
          :value="connectParams.lang"
          @action="langSelected"
        />
        <br><br>
        <h2 class="customization__title">Button Appearance</h2>
        <RInput
          label="Button Color"
          :default-color="connectParams.cta_button_backcolor"
          type="color"
          v-model="connectParams.cta_button_backcolor"
        />
        <RInput
          label="Button Text Color"
          :default-color="connectParams.cta_button_forecolor"
          type="color"
          v-model="connectParams.cta_button_forecolor"
        />
        <RColorChecker
          text="Button Contrast Ratio"
          :tooltipText="tooltips.contrast"
          :colorForeground="connectParams.cta_button_forecolor"
          :colorBackground="connectParams.cta_button_backcolor" />
        <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
        <label class="checkbox">Hidden Close Button on all steps
          <input type="checkbox" v-model="connectParams.remove_close_button">
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="customization__action" v-show="selectedMenu === 1">
        <h2 class="customization__title">Intro Screen</h2>
        <!-- <div class="switch__wrapper">
          <label class="switch">
            <input type="checkbox" ref="intro_screen" v-model="connectParams.skip_first_page">
            <span class="slider round"></span>
          </label>
          <p
            class="switch__title"
            @keyup="$refs.intro_screen.click()"
            @click="$refs.intro_screen.click()">Show Intro Screen</p>
          <p class="switch__description">Turn on to start customizing the Intro Screen</p>
        </div> -->
        <div class="customization__block">
          <RInput
            label="Intro Text"
            placeholder="Let’s link your work accounts"
            :tooltipText="tooltips.intro"
            :maxlength="80"
            v-model="connectParams.terms_description_text" />
          <div class="customization__rich">
            <div v-show="!connectParams.richtext" class="customization__rich-info">
              <p @click="addAdditionalInfo()" @keydown="addAdditionalInfo()">
                <i class="ri-add-line"></i>
                Add content below Intro Text
              </p>
              <small>Add more text to show additional information for your users</small>
            </div>
            <div v-show="connectParams.richtext">
              <div class="customization__rich-header">
                <p class="label">Additional Info</p>
                <DeleteIcon @click="removeAdditionalIinfo()" />
              </div>
              <!-- eslint-disable-next-line max-len -->
              <QuillEditor v-model:content="connectParams.richtext" content-type="html" theme="snow" :toolbar="[['bold', 'italic', 'underline'],  [{ 'indent': '-1'}, { 'indent': '+1' }], [{ 'list': 'ordered'}, { 'list': 'bullet' }]]"/>
              <br>
            </div>
          </div>
        </div>
      </div>
      <div class="customization__action" v-show="selectedMenu === 2">
        <h2 class="customization__title">Search Screen</h2>
        <div class="switch__wrapper">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label class="switch">
            <input type="checkbox" ref="search_input" v-model="connectParams.search_input_show">
            <span class="slider round"></span>
          </label>
          <p
            class="switch__title"
            @keyup="$refs.search_input.click()"
            @click="$refs.search_input.click()">Show the Search Input</p>
        </div>
        <RInput
          label="Search Hint Text"
          v-model="connectParams.search_page_title"
          :tooltipText="tooltips.searchInput"
          placeholder="Search for you income source" />
        <!-- <div class="switch__wrapper">
          <label class="switch">
            <input type="checkbox" ref="options_search"
                  v-model="connectParams.search_page_categories">
            <span class="slider round"></span>
          </label>
          <p class="switch__title"
            @keyup="$refs.options_search.click()"
            @click="$refs.options_search.click()">Show Source Filter Categories</p>
          <p class="switch__description">Popular, Gig, Freelance, Payroll</p>
        </div> -->
      </div>
      <div class="customization__action customization__action--table" v-show="selectedMenu === 3">
        <h2 class="customization__title">Connect Sources</h2>
        <p class="customization__description">
          Connect Sources available in search results for your users
        </p>
        <div class="customization__options">
          <div class="switch__wrapper" v-for="item in filters" :key="item.id">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label class="switch">
              <input
                type="checkbox"
                :checked="item.selected"
                v-model="item.selected"
                @change="tableFilter()"
              >
              <span class="slider round"></span>
            </label>
            <p
              class="switch__title"
              @click="item.selected = !item.selected;tableFilter()"
              @keyup="item.selected = !item.selected"
              >
                {{ item.title }}
            </p>
          </div>
        </div>
        <div class="customization__table">
          <ag-grid-vue
            class="ag-theme-alpine"
            :columnDefs="columnDefs"
            :rowData="filteredList"
            :rowDragManaged="true"
            :animateRows="true"
            :rowHeight="46"
            :sideBar="sideBar"
            rowSelection="multiple"
            :suppressRowClickSelection="true"
            :suppressCellSelection="true"
            @first-data-rendered="onFirstDataRendered"
            @row-selected="onRowSelected"
            @grid-ready="onGridReady"
            @row-drag-end="onRowDragEnd"
            @sortChanged="onSortChanged"
          ></ag-grid-vue>
        </div>

      </div>
      <div class="customization__action" v-show="selectedMenu === 4">
        <h2 class="customization__title">Success Screen</h2>
        <RInput
            label="Completion Button Text"
            placeholder="Back to App"
            v-model="connectParams.success_button_text" />

        <div class="switch__wrapper">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label class="switch">
            <input type="checkbox" ref="success_desc"
              v-model="connectParams.success_show_description">
            <span class="slider round"></span>
          </label>
          <p
            @keyup="$refs.success_desc.click()"
            @click="$refs.success_desc.click()"
            class="switch__title">
              Show Description Text
          </p>
        </div>
        <RInput
            v-if="connectParams.success_show_description"
            label="Description Text"
            placeholder="Your account’s been successfully connected"
            v-model="connectParams.success_description" />
        <div class="switch__wrapper">
          <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
          <label class="switch">
            <input type="checkbox" ref="success_available_datasources"
              v-model="connectParams.success_available_datasources">
            <span class="slider round"></span>
          </label>
          <p
            @keyup="$refs.success_available_datasources.click()"
            @click="$refs.success_available_datasources.click()"
            class="switch__title">
              Show available Platforms
          </p>
        </div>
      </div>
      <div
        class="customization__demo"
        :class="{'customization__demo--disabled': disableIframe}">
        <iframe
          name="customization"
          id="customization-demo"
          title="Connect"
          :src="iframeHost"
          frameborder="0"
          ></iframe>
      </div>
    </div>
    <div class="customization__footer">
      <RButton
        @click="saveCustomization()"
        :disabled="!connectParams.name">
          Save Customization
        </RButton>
    </div>
  </div>
</template>
<script>
import RInput from '@/components/RInput.vue';
import RList from '@/components/RList.vue';
import RButton from '@/components/RButton.vue';
import RFileUpload from '@/components/RFileUpload.vue';
import RColorChecker from '@/components/RColorChecker.vue';
import { customizationStore } from '@/store/customizations';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import * as AGGrid from 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue3';
import { useRouter } from 'vue-router';
import { appStore } from '@/store/app';
import { profileStore } from '@/store/profile';
// eslint-disable-next-line import/no-extraneous-dependencies
import { QuillEditor } from '@vueup/vue-quill';
// eslint-disable-next-line import/no-extraneous-dependencies
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';

AGGrid.LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-053293}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Rollee}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Dashboard}_need_to_be_licensed___{Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_March_2025}____[v3]_[01]_MTc0MTgyNDAwMDAwMA==7bc0d51f7c7de4de44584336d8e22ae0');

export default {
  name: 'ConnectCustomize',
  components: {
    RInput, RFileUpload, RColorChecker, RButton, AgGridVue, RList, QuillEditor, DeleteIcon,
  },
  data() {
    return {
      editor: null,
      editMode: false,
      rerenderComponent: true,
      connectQuery: '',
      selectedMenu: 0,
      introScreen: false,
      gridApi: null,
      firstTime: false,
      languageList: [
        {
          id: 'en',
          title: '🇬🇧 English',
          icon: '🇬🇧',
        },
        {
          id: 'fr',
          title: '🇫🇷 French',
          icon: '🇫🇷',
        },
        {
          id: 'es',
          title: '🇪🇸 Spanish',
          icon: '🇪🇸',
        },
        {
          id: 'ar',
          title: '🇸🇦 Arabic',
          icon: '🇸🇦',
        },
        {
          id: 'pl',
          title: '🇵🇱 Polish',
          icon: '🇵🇱',
        },
        {
          id: 'ro',
          title: '🇷🇴 Romanian',
          icon: '🇷🇴',
        },
      ],
      columnDefs: [
        {
          headerName: 'Name',
          field: 'title',
          rowDrag: true,
          width: 350,
          flex: 1,
          filter: true,
          sortable: true,
          cellRenderer: (params) => {
            const { data } = params;
            return `
              <div class="table__row">
                <img class="table__avatar" src="${data.logo}" onerror="this.style.visibility='hidden'" />
                <span class="table__text"> ${data.title}</span>
              </div>
            `;
          },
        },
        {
          headerName: 'Type',
          field: 'type',
          hide: false,
          sortable: true,
          filter: true,
        },
        {
          headerName: '',
          field: 'id',
          width: 80,
          align: 'right',
          checkboxSelection: true,
          headerCheckboxSelection: true,
          filter: false,
          cellRenderer: () => '',
        },
      ],
      rowData: null,
      connectParams: {
        redirect: null,
        name: null,
        skip_first_page: true,
        terms_description_text: '',
        terms_button_text: 'Continue',
        cta_button_forecolor: '#ffffff',
        cta_button_backcolor: '#000000',
        partner_logo: null,
        search_page_categories: null,
        search_page_title: null,
        show_success_screen: true,
        search_input: true,
        remove_close_button: false,
        search_input_show: true,
        success_button_text: null,
        success_show_description: true,
        success_description: null,
        success_available_datasources: true,
        search_available_datasources: [],
        datasource: null,
        lang: null,
        richtext: null,
        partner_title: null,
      },
      filters: [],
      customization: {
        name: null,
      },
      options_0: {
        name: 'gig',
        value: true,
      },
      options_1: {
        name: 'payroll',
        value: true,
      },
      options_2: {
        name: 'employer',
        value: true,
      },
      options_3: {
        name: 'freelance',
        value: true,
      },
      menu: [
        {
          id: 0,
          name: 'Global Appearance',
          icon: 'smartphone-line',
          page: 'Terms',
        },
        {
          id: 1,
          name: 'Intro Screen',
          icon: 'information-line',
          page: 'Terms',
        },
        {
          id: 2,
          name: 'Search Screen',
          icon: 'search-2-line',
          page: 'Search',
        },
        {
          id: 3,
          name: 'Connect Sources',
          icon: 'list-unordered',
          page: 'Search',
        },
        {
          id: 4,
          name: 'Success Screen',
          icon: 'checkbox-circle-line',
          page: 'Success',
        },
      ],
      tooltips: {
        customization: 'The name will not be visible your users It is only for you to easily find it within other customizations in Customization List.',
        branding: 'It is recommended to upload a high resolution PNG or SVG with a transparent background.',
        contrast: 'Contrast ratio measure the difference in lightness or darkness between text and button color. A high contrast ratio improves visibility and readability of your button. It is recommended if contrast is high enough to pass AAA WCAG standard.',
        intro: 'You can use {company} variable in curly brackets to display your company name between the text.',
        searchInput: 'Change the placeholder text in the search bar',
      },
      datasourceList: [],
      filteredList: [],
    };
  },
  computed: {
    iframeHost() {
      if (appStore().getState) {
        // eslint-disable-next-line max-len
        return `${process.env.VUE_APP_CONNECT_PATH}00000000-0000-0000-0000-000000000000?demo=true`;
      }
      return `${process.env.VUE_APP_CONNECT_SAND_PATH}00000000-0000-0000-0000-000000000000?demo=true`;
    },
    disableIframe() {
      if (this.selectedMenu === 1 && !this.connectParams.skip_first_page) {
        return true;
      }
      if (this.selectedMenu === 4 && !this.connectParams.show_success_screen) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.debouncedWatch = this.debounce(() => {
      this.updateConnect();
    }, 500);
  },
  mounted() {
    this.editor = this.$refs.editor;
    customizationStore().fetchDatasources().then(() => {
      this.datasourceList = customizationStore().getDatasources;
      // get filters array from datasource list, and remove duplicates
      this.datasourceList.forEach((item, idx) => {
        // no duplicates
        if (!this.filters.find((el) => el.title === item.type)) {
          if (item.type === '') return;
          this.filters.push({
            id: idx,
            title: item.type,
            selected: true,
          });
        }
      });
      this.tableFilter();
    });
    this.router = useRouter();
    // detect is thee a customization id
    if (this.router.currentRoute.value.params.id) {
      this.editMode = true;
      customizationStore()
        .fetchCustomization(this.router.currentRoute.value.params.id).then(() => {
          if (!customizationStore().getCurrentCustomization.name) {
            this.firstTime = true;
          }

          this.connectParams = {
            ...this.connectParams,
            ...customizationStore().getCurrentCustomization,
          };
          this.forceRerender();
        });
    }
    this.connectParams.partner_title = profileStore().getPartnerInfo.name;

    window.onmessage = (e) => {
      if (e.data === 'AppLoaded') {
        this.updateConnect();
      }
    };
  },
  methods: {
    addAdditionalInfo() {
      this.connectParams.richtext = '<p>Additional Information</p>';
    },
    removeAdditionalIinfo() {
      this.connectParams.richtext = '';
    },
    tableFilter() {
      // loop this.filters and get the selected ones in a new array and map just title
      // eslint-disable-next-line max-len
      const selectedFilters = this.filters.filter((item) => item.selected === true).map((item) => item.title);
      this.filteredList = this.datasourceList.filter((item) => selectedFilters.includes(item.type));
      setTimeout(() => {
        this.gridApi.forEachNode((node) => {
          if (this.connectParams.search_available_datasources
            && this.connectParams.search_available_datasources.includes(node.data.id)) {
            node.setSelected(true);
          }
        });
      }, 100);
    },
    langSelected(value) {
      this.connectParams.lang = value.id;
      this.updateConnect();
    },
    onRowDragEnd() {
      this.onRowSelected();
    },
    onRowSelected() {
      this.connectParams.search_available_datasources = [];

      this.gridApi.getSelectedNodes()
        .sort((a, b) => a.rowIndex - b.rowIndex)
        .forEach((node) => {
          this.connectParams.search_available_datasources.push(node.data.id);
        });
    },
    onSortChanged() {
      this.onRowSelected();
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridApi.sizeColumnsToFit();
    },
    onFirstDataRendered(params) {
      if (this.editMode) {
        setTimeout(() => {
          params.api.forEachNode((node) => {
            if (this.connectParams.search_available_datasources
              && this.connectParams.search_available_datasources.includes(node.data.id)) {
              node.setSelected(true);
            }
          });
        }, 100);
      } else {
        setTimeout(() => {
          params.api.forEachNode((node) => {
            node.setSelected(true);
          });
        }, 100);
      }
    },
    async forceRerender() {
      this.rerenderComponent = false;
      await this.$nextTick();
      this.rerenderComponent = true;
    },
    selectMenu(id) {
      this.selectedMenu = id;
      const msg = {
        action: 'setPage',
        value: this.menu[id].page,
      };
      const iframe = document.getElementById('customization-demo');
      iframe.contentWindow.postMessage(msg, '*');
    },
    changePartnerLogo(e) {
      if (e) {
        this.connectParams.partner_logo = e;
      } else {
        this.connectParams.partner_logo = '/img/part/rollee-logo.png';
      }
    },
    connectParamsToQueryString() {
      Object.keys(this.connectParams).forEach((key) => {
        if (this.connectParams[key] === null) {
          delete this.connectParams[key];
        }
      });
      return this.connectParams;
    },
    updateConnect() {
      const iframe = document.getElementById('customization-demo');
      const msg = {
        action: 'setConfig',
        value: JSON.stringify(this.connectParamsToQueryString()),
      };
      iframe?.contentWindow.postMessage(msg, '*');
    },
    setDatasources(list) {
      const msg = {
        action: 'setDatasources',
        value: JSON.stringify(list),
      };
      const iframe = document.getElementById('customization-demo');
      iframe.contentWindow.postMessage(msg, '*');
    },
    debounce(callback, wait) {
      let timeoutId = null;
      return (...args) => {
        window.clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
          // eslint-disable-next-line prefer-spread
          callback.apply(null, args);
        }, wait);
      };
    },
    gotoIndex() {
      this.router.push('/connect/customization', {
        params: {
          reload: true,
        },
      });
    },
    saveCustomization() {
      this.connectParams.datasource = null;
      if (this.editMode) {
        customizationStore().updateCustomization({
          id: this.router.currentRoute.value.params.id,
          config: this.connectParams,
        }).then(() => {
          appStore().setNotification({
            type: 'success',
            message: 'Customization updated successfully',
          });
          window.analytics?.track('save_customization');
          this.gotoIndex();
        });
      } else {
        customizationStore().createCustomization({
          config: this.connectParams,
        }).then(() => {
          appStore().setNotification({
            type: 'success',
            message: 'Customization created successfully',
          });
          this.gotoIndex();
        });
      }
      window.analytics?.track('save_customization', {
        customization: this.connectParams.name,
      });
    },
  },
  beforeUnmount() {
    customizationStore().clearCurrentCustomization();
    customizationStore().clearDatasources();
  },
  watch: {
    connectParams: {
      handler() {
        this.debouncedWatch();
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.customization {
  display: grid;
  grid-template-columns: 580px auto;
  gap: 70px;
  height: calc(100vh - 204px);
  &__wrapper {
    margin: -40px -30px;
    height: 100%;
  }
  &__title {
    font-size: 20px;
    margin-bottom: 20px;
    font-weight: 400;
  }
  &__action {
    max-width: 520px;
    width: 100%;
    justify-self: start;
    padding: 40px 30px;
    padding-bottom: 120px;
    &--wide {
      max-width: 100%;
    }
    &--table {
      display: grid;
      grid-template-rows: 30px 30px minmax(200px, max-content) auto;
      padding-bottom: initial;
    }
  }
  &__rich {
    .label {
      display: inline-block;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-dark-main);
      padding-bottom: 6px;
    }
    &-info {
      margin-bottom: 32px;
      p {
        position: relative;
        font-size: 16px;
        font-weight: 600;
        color: var(--color-dark-main);
        cursor: pointer;
        i {
          position: relative;
          top: 3px;
          margin-right: 4px;
        }
      }
      small {
        font-size: 15px;
        font-weight: 600;
        color: var(--color-grey-light);
      }
    }
    &-header {
      display: grid;
      grid-template-columns: 1fr auto;
      margin-bottom: 3px;
    }
    svg {
      cursor: pointer;
      transition: var(--transition-default);
      &:hover {
        opacity: 0.8;
      }
    }
  }
  &__footer {
    background: #fff;
    position: fixed;
    left: 220px;
    bottom: 0;
    width: calc(100% - 280px);
    z-index: 9;
    padding: 12px 30px;
    display: grid;
    justify-content: end;
    box-shadow: 0px 4px 40px 0px rgba(20, 20, 20, 0.04);
  }
  &__demo {
    background: var(--color-grey-outline);
    padding: 60px 32px;
    text-align: center;
    iframe {
      width: 100%;
      max-width: 375px;
      min-width: 320px;
      max-height: 722px;
      min-height: 640px;
      height: 100%;
      box-shadow: 0px 28px 48px rgba(20, 20, 20, 0.04);
      border-radius: 12px;
      position: relative;
      margin-bottom: 96px;
    }
    &--disabled {
      user-select: none;
      pointer-events: none;
      iframe {
        opacity: .2;
      }
    }
  }
  &__block {
    margin-top: 40px;
  }
  &__description {
    font-size: 15px;
    font-weight: 600;
    color: var(--color-grey-light);
  }
  &__options {
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr;
    gap: 0px;
  }
  &__table {
    margin-top: 10px;
    width: 100%;
    height: calc(100% - 36px);
  }
  &__header {
    display: block;
    width: 100%;
    background-color: #fff;
    height: var(--dim-input-height);
    border-bottom: 1px solid var(--color-grey-outline);
    height: 62px;
    nav {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      &::-webkit-scrollbar {
        display: none;
      }
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 9;
        li {
          display: inline-block;
          min-width: 120px;
          padding: 6px 32px;
          font-size: 15px;
          font-weight: 600;
          text-align: center;
          line-height: var(--dim-input-height);
          color: var(--color-grey-dark);
          line-height: 42px;
          cursor: pointer;
          border-bottom: 2px solid transparent;
          i {
            position: relative;
            font-size: 20px;
            padding-right: 10px;
            top: 5px;
          }
          &:hover {
            border-color: var(--color-grey-light);
          }
          &.active {
            color: var(--color-dark-main);
            border-color: var(--color-blue-main);
            i {
              color: var(--color-blue-main);
            }
          }
        }
      }
    }
  }
}
:deep .ag-theme-alpine {
  height: 100%;
  margin-bottom: 40px;
  box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.02);
  font-family: inherit;
  .table {
    &__avatar {
      width: 32px;
      height: 32px;
      border-radius: 999px;
    }
    &__row {
      width: 100%;
      display: grid;
      grid-template-columns: 38px 1fr;
      align-items: center;
      img {
        object-fit: contain;
        appearance: none;
        border: none;
        &::before {
          content: '';
          width: 32px;
          height: 32px;
          background-color: #ccc;
          border-radius: 999px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

:deep(.ql-toolbar.ql-snow) {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-color: #EDF2F8;
}

:deep(.ql-editor) {
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  font-family: 'Gilroy', sans-serif;
  font-size: 14px;
  font-weight: 500;
}

:deep(.ql-container) {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border-color: #EDF2F8;
}
</style>
