<template>
  <div class="user__wrapper">
    <div>
      <div class="user__header">
        <ul v-if="state.user">
          <li
            class="user__header-items"
            v-for="(account, index) in state.user.accounts" :key="account.id"
            :class="{ active: index === state.currentAccount }"
            @click="state.currentAccount = index"
            @keydown="state.currentAccount = index"
          >
            <img :src="account.platform.logo" alt="" />
            <div class="user__header-text">
              <p>
                {{ account.platform.name }}
              </p>
              <p class="small" v-if="account?.start_date_of_data">
                from {{ formatDate(account?.start_date_of_data) }}
              </p>
            </div>
          </li>
        </ul>
        <div class="user__header-actions">
          <RDatePicker label="Date Range" @updateDate="setSelectedDate" @value="handleEvent"/>
        </div>
      </div>
      <div class="user" v-if="state.user">
        <div class="user__aside">
          <ul class="user__nav">
            <li
              v-for="item in filterSections"
              :key="item"
              @click="scrollTo(item.key)"
              @keydown.enter="scrollTo(item.key)"
              :class="{ active: state.activeSection === item.key }"
              >
              {{item.name}}
            </li>
          </ul>
        </div>
        <div class="user__sections" @scroll="onScroll">
          <component
            :id="section.key"
            ref="sectionsRef"
            v-for="section in filterSections"
            :key="section.id"
            :is="`User${section.key}`"
            :data="section.data"
            :platform="section.platform"
            @value="handleEvent"
            @download="onDownload"
          />
        </div>
      </div>
    </div>
    <div v-if="state.error" class="user__error">
      {{ state.error }}
      <br />
      <br />
      <RButton
        type="secondary"
        class="user__error-button"
        @click="goBack()"
      >GO BACK</RButton>
    </div>
    <RLoading v-show="loading" />
  </div>
</template>
<script>
import {
  ref, reactive, onMounted, computed, watch, onUpdated,
} from 'vue';
import { useRouter } from 'vue-router';
import { usersStore } from '@/store/users';
import { appStore } from '@/store/app';
import { profileStore } from '@/store/profile';

import RButton from '@/components/RButton.vue';
import RDatePicker from '@/components/RDatePicker.vue';
import { getUnixTime, format } from 'date-fns';
import UserProfile from '@/components/sections/UserProfile.vue';
import UserEmployment from '@/components/sections/UserEmployment.vue';
import UserVehicles from '@/components/sections/UserVehicles.vue';
import UserDocuments from '@/components/sections/UserDocuments.vue';
import UserTrips from '@/components/sections/UserTrips.vue';
import UserIncome from '@/components/sections/UserIncome.vue';
import UserActivity from '@/components/sections/UserActivity.vue';
import UserPayouts from '@/components/sections/UserPayouts.vue';
import RLoading from '@/components/RLoading.vue';
import RComboButton from '@/components/RComboButton.vue';
import UserEmployee from '@/components/sections/UserEmployee.vue';
import UserAccounts from '@/components/sections/UserAccounts.vue';
import UserAbsences from '@/components/sections/UserAbsences.vue';
import UserIncomeByOrganization from '@/components/sections/UserIncomeByOrganization.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'User',
  components: {
    RComboButton,
    UserProfile,
    UserEmployment,
    UserEmployee,
    UserAccounts,
    UserVehicles,
    UserDocuments,
    UserTrips,
    UserIncome,
    UserIncomeByOrganization,
    UserActivity,
    UserPayouts,
    UserAbsences,
    RButton,
    RDatePicker,
    RLoading,
  },
  // eslint-disable-next-line no-unused-vars
  setup() {
    const state = reactive({
      user: null,
      currentAccount: 0,
      activeSection: 'Profile',
      apiCalls: 0,
      params: {
        start_date: null,
        end_date: null,
      },
      error: null,
    });

    const useAppStore = appStore();
    const sectionsRef = ref(null);
    const loading = ref(true);
    const scrollState = reactive({
      scroll: [],
    });
    // eslint-disable-next-line
    const currentUser = computed(() => state.user && state.user.accounts[state.currentAccount]);

    // watch currentUser and fill up store with user data
    watch(currentUser, (newVal) => {
      if (newVal) {
        profileStore().setCurrentAccount(newVal);
      }
    });

    const formatDate = (date) => {
      if (!date) return '';
      return format(new Date(date * 1000), 'dd/MM/yyyy');
    };

    const filterSections = computed(() => {
      const userSections = [
        {
          id: 0,
          name: 'Profile',
          key: 'Profile',
          visible: true,
          data: currentUser,
        },
        {
          id: 1,
          name: 'Employment',
          key: 'Employment',
          data: currentUser.value.employment,
          visible: currentUser.value.employment
            && Object.keys(currentUser.value.employment).length > 0,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 2,
          name: 'Income',
          key: 'Income',
          visible: currentUser.value.income && Object.keys(currentUser.value.income).length > 0,
          data: currentUser.value.income,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 3,
          name: 'Payouts',
          key: 'Payouts',
          visible: false,
        },
        {
          id: 4,
          name: 'Accounts',
          key: 'Accounts',
          visible: currentUser.value.bank_accounts && currentUser.value.bank_accounts.length > 0,
          data: currentUser.value.bank_accounts,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 5,
          name: 'Documents',
          key: 'Documents',
          visible: currentUser.value.documents && currentUser.value.documents.length > 0,
          data: currentUser.value.documents,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 6,
          name: 'Activity',
          key: 'Activity',
          visible: currentUser.value.activity && Object.keys(currentUser.value.activity).length > 0,
          data: currentUser.value.activity,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 7,
          name: 'Vehicles',
          key: 'Vehicles',
          visible: currentUser.value.vehicles && currentUser.value.vehicles.length > 0,
          data: currentUser.value.vehicles,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 8,
          name: 'Trips',
          key: 'Trips',
          visible: currentUser.value.trips && currentUser.value.trips.length > 0,
          data: currentUser.value.trips,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 9,
          name: 'Absences',
          key: 'Absences',
          visible: currentUser.value.absences && currentUser.value.absences.length > 0,
          data: currentUser.value.absences,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 10,
          key: 'Vacations',
          name: 'Vacations',
          visible: currentUser.value.vacations && currentUser.value.vacations.length > 0,
          data: currentUser.value.vacations,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 11,
          name: 'Employee',
          key: 'Employee',
          visible: currentUser.value.employee && Object.keys(currentUser.value.employee).length > 0,
          data: currentUser.value?.employee,
          platform: state.user.accounts[state.currentAccount].platform,
        },
        {
          id: 12,
          key: 'IncomeByOrganization',
          name: 'Income description',
          visible: currentUser.value.income_by_organization_name
            && Object.keys(currentUser.value.income_by_organization_name).length > 0,
          data: currentUser.value.income_by_organization_name,
          platform: state.user.accounts[state.currentAccount].platform,
        },
      ];
      // return filtered array by visible property
      return userSections.filter((section) => section.visible
        && section.data && Object.keys(section.data).length > 0);
    });

    const router = useRouter();
    const goBack = () => {
      router.back();
    };

    const mounted = ref(true);

    const scrollTo = (e) => {
      const el = document.getElementById(e);
      el.scrollIntoView({ behavior: 'smooth' });
      state.activeSection = e;
      window.analytics?.track(`${e.toLowerCase()}_user_data_view`, {
        section: e,
      });
    };

    const getUserById = async (startDate = null, endDate = null) => {
      const START_DATE = mounted.value ? router.currentRoute.value.query.startDate : startDate;
      const END_DATE = mounted.value ? router.currentRoute.value.query.endDate : endDate;

      const { id } = router.currentRoute.value.params;
      loading.value = true;
      usersStore().fetchUserById(id, {
        start_date: START_DATE,
        end_date: END_DATE,
      }).then(() => {
        state.user = usersStore().getCurrentUser.user;
        loading.value = false;
        mounted.value = false;
      }).catch((err) => {
        loading.value = false;
        useAppStore.setNotification({
          type: 'error',
          message: err.message,
        });
        state.error = err.message;
      });
    };

    const setSelectedDate = (selectedDate) => {
      useAppStore.setDateSelectValue(selectedDate);
      const startDate = getUnixTime(selectedDate.start);
      const endDate = getUnixTime(selectedDate.end);
      getUserById(startDate, endDate);
      window.analytics?.track('date_picker_user_data_view', {
        start_date: selectedDate.start,
        end_date: selectedDate.end,
      });
    };

    const convertForDownload = (payload) => {
      const sectionData = cloneDeep(state.user.accounts[state.currentAccount][payload.section]);
      const replacer = (key, value) => (value === null ? '' : value);

      if (payload.section === 'income_by_organization_name') {
        let formated = [];
        const organizations = Object.keys(sectionData);
        organizations.forEach((organization) => {
          if (sectionData[organization].distribution) {
            delete sectionData[organization].distribution;
          }
          const header = Object.keys(sectionData[organization]);
          const csv = [
            header.join(','), // header row first
            header.map((key) => JSON.stringify(
              sectionData[organization][key],
              replacer,
            )).join(','),
          ].join('\r\n');
          formated = csv;
        });
        return formated;
      }
      if (Array.isArray(sectionData) && sectionData.length > 0) {
        const header = Object.keys(sectionData[0]);
        // eslint-disable-next-line no-case-declarations
        const csv = [
          header.join(','), // header row first
          ...sectionData.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(',')),
        ].join('\r\n');
        return csv;
      }
      const header = Object.keys(sectionData);
      const csv = [
        header.join(','), // header row first
        header.map((fieldName) => JSON.stringify(sectionData[fieldName], replacer)).join(','),
      ].join('\r\n');
      return csv;
    };

    const convertIncomeDistribution = (payload) => {
      const sectionData = cloneDeep(state.user.accounts[state.currentAccount][payload.section]);
      const replacer = (key, value) => (value === null ? '' : value);
      const organizations = Object.keys(sectionData);
      let formated = [];
      organizations.forEach((organization) => {
        const header = Object.keys(sectionData[organization].distribution[0]);
        // eslint-disable-next-line no-case-declarations
        const csv = [
          header.join(','), // header row first
          ...sectionData[organization].distribution.map((row) => header.map((key) => JSON.stringify(row[key], replacer)).join(',')),
        ].join('\r\n');
        formated = csv;
      });
      return formated;
    };
    const onDownload = (payload) => {
      // data per section
      const userName = state.user?.accounts[state.currentAccount]?.profile?.full_name;
      const platformName = state.user?.accounts[state.currentAccount].platform.name;
      const sectionName = `${payload.section.charAt(0).toUpperCase()}${payload.section.slice(1)}`;
      const filename = `${userName} - ${sectionName} - ${platformName}.${payload.fileType}`;
      let convertedData;
      window.analytics?.track(`download_${sectionName.toLowerCase()}_user_data_view`, {
        type: payload.fileType,
        section: payload.section,
        platform: state.user.accounts[state.currentAccount].platform.name,
      });
      switch (payload.fileType) {
        case 'csv':
        case 'xls':
          convertedData = convertForDownload(payload);
          // eslint-disable-next-line no-case-declarations
          const blob = new Blob([convertedData], { type: 'text/csv' });
          // eslint-disable-next-line no-case-declarations
          const url = window.URL.createObjectURL(blob);
          // eslint-disable-next-line no-case-declarations
          const linkCSV = document.createElement('a');
          linkCSV.setAttribute('href', url);
          linkCSV.setAttribute('download', filename);

          // Performing a download with click
          linkCSV.click();
          window.analytics?.track('User Download', {
            type: 'csv',
            section: payload.section,
            platform: state.user.accounts[state.currentAccount].platform.name,
          });
          break;
        default:
          // eslint-disable-next-line no-case-declarations
          const convert = `data:text/json;charset=utf-8,${JSON.stringify(state.user.accounts[state.currentAccount][payload.section])}`;
          convertedData = encodeURI(convert);
          // eslint-disable-next-line no-case-declarations
          const link = document.createElement('a');
          link.setAttribute('href', convertedData);
          link.setAttribute('download', filename);
          link.click();
          break;
      }

      if (payload.section === 'income_by_organization_name' && payload.fileType !== 'json') {
        const name = state.user?.accounts[state.currentAccount]?.profile?.full_name;
        convertedData = convertIncomeDistribution(payload);
        // eslint-disable-next-line no-case-declarations
        const blob = new Blob([convertedData], { type: 'text/csv' });
        // eslint-disable-next-line no-case-declarations
        const url = window.URL.createObjectURL(blob);
        // eslint-disable-next-line no-case-declarations
        const linkCSV = document.createElement('a');
        linkCSV.setAttribute('href', url);
        linkCSV.setAttribute('download', `${name} - Income Distribution.csv`);

        // Performing a download with click
        linkCSV.click();
      }
    };
    const onScroll = (el) => {
      const activeSection = scrollState.scroll
        .find((section) => section.position > (el.target.scrollTop + 40));
      if (activeSection) {
        state.activeSection = activeSection.sectionName;
      }
    };
    onMounted(() => {
      appStore().setSandboxSwitch(false);
    });
    onUpdated(() => {
      scrollState.scroll = [];
      if (!sectionsRef.value) return;
      sectionsRef.value.forEach((section) => {
        scrollState.scroll.push({
          sectionName: section.$el?.id,
          // eslint-disable-next-line
          position: section.$el?.offsetTop + section.$el?.offsetHeight,
        });
      });
      scrollState.scroll
        .sort((a, b) => a.position - b.position);
    });
    return {
      state,
      sectionsRef,
      scrollTo,
      onScroll,
      currentUser,
      filterSections,
      goBack,
      onDownload,
      setSelectedDate,
      loading,
      formatDate,
    };
  },
};
</script>
<style lang="scss" scoped>
.user {
  display: grid;
  grid-template-columns: 220px auto;
  min-height: calc(100vh - 130px);
  gap: 20px;
  &__wrapper {
    height: inherit;
  }
  &__nav {
    list-style: none;
    li {
      position: relative;
      font-size: 15px;
      font-weight: 600;
      color: var(--color-grey-dark);
      line-height: 24px;
      padding: 16px 12px 16px 36px;
      cursor: pointer;
      transition: var(--transition-default);
      &:hover {
        color: var(--color-blue-main);
        box-shadow: inset 2px 0px 0px var(--color-blue-main);
      }
      &.active {
        color: var(--color-blue-main);
        box-shadow: inset 2px 0px 0px var(--color-blue-main);
      }
    }
  }
  &__aside {
    margin-top: 40px;
  }
  &__header {
    background-color: #fff;
    position: sticky;
    height: 60px;
    padding: 0 30px 0 20px;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.04);
    border: 1px solid var(--color-grey-outline);
    border-top: none;
    z-index: 9;
    display: grid;
    grid-template-columns: auto 260px;
    align-items: center;
    justify-content: space-between;
    ul {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    &-actions {
      display: flex;
      align-items: flex-end;
      justify-self: end;
      & .divider {
        margin: 0px 20px 0px 25px;
      }
    }
    &-items {
        list-style: none;
        padding: 14px 8px;
        display: inline-grid;
        grid-template-columns: 34px auto;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        line-height: 50px;
        font-weight: 600;
        color: var(--color-grey-dark);
        min-width: 132px;
        text-align: center;
        cursor: pointer;
        user-select: none;
        &.active {
          border-bottom: 2px solid var(--color-blue-main);
          color: var(--color-dark-main);
        }
        &:hover:not(.active) {
          border-bottom: 2px solid var(--color-grey-light);
        }
        img {
          width: 24px;
          height: 24px;
          vertical-align: middle;
          margin-bottom: 5px;
          margin-right: 12px;
          border-radius: 999px;
          object-fit: contain;
        }
        p {
          margin: 0;
          padding: 0;
          line-height: 1.2;
          text-align: left;
          &.small {
            font-size: 12px;
            color: var(--color-grey-light);
          }
        }
      }

  }
  &__sections {
    padding-right: 30px;
    height: calc(100vh - 170px);
    overflow-y: auto;
    padding-top: 40px;
    box-sizing: border-box;
  }
  &__error {
    display: grid;
    place-items: center;
    width: 100%;
    height: inherit;
    align-content: center;
  }
}
:deep(.loading) {
  background: rgba(255, 255, 255, 0.8);
  position: fixed;
  top: 0;
  left: var(--dim-aside-width);
  right: 0;
  bottom: 0;
  width: calc(100% - var(--dim-aside-width));
  z-index: 10;
}
</style>
