<template>
  <div class="metrics__wrapper">
    <RSection title="Health metric analysis">
      <div class="metrics__content">
        <div class="metrics__content-indicator">
          <h3>Financial Indicators</h3>
          <div class="metrics__content-item">
              <p>Consistent Reporting</p>
              <div class="metrics__content__icon">
                <div
                  class="metrics__content__icon-background"
                  :style="{backgroundColor: store.verificationStatusColors(20)}">
                </div>
                <component
                  :is="displayIcon(20)"
                  :fill="store.verificationStatusColors(20)">
                </component>
              </div>
          </div>
          <div class="metrics__content-item">
            <p>No Unusual Financial Ratios</p>
            <div class="metrics__content__icon">
              <div
                class="metrics__content__icon-background"
                :style="{backgroundColor: store.verificationStatusColors(20)}"
              ></div>
              <component
                :is="displayIcon(20)"
                :fill="store.verificationStatusColors(20)">
              </component>
            </div>
          </div>
        </div>
      </div>
      <div class="metrics__content">
        <div class="metrics__content-indicator">
            <h3>Ownership & Structure</h3>
            <div class="metrics__content-item">
              <p>No Rapid Changes in Ownership</p>
              <div class="metrics__content__icon">
                <div
                  class="metrics__content__icon-background"
                  :style="{backgroundColor: store.verificationStatusColors(20)}">
                </div>
                <component
                  :is="displayIcon(20)"
                  :fill="store.verificationStatusColors(20)">
                </component>
              </div>
            </div>
            <div class="metrics__content-item">
              <p>Nominee Directors or Shareholders</p>
              <div class="metrics__content__icon">
                <div
                  class="metrics__content__icon-background"
                  :style="{backgroundColor: store.verificationStatusColors(20)}"
                ></div>
                <component
                  :is="displayIcon(20)"
                  :fill="store.verificationStatusColors(20)">
                </component>
              </div>
            </div>
            <div class="metrics__content-item">
              <p>Healthy Ownership Structures</p>
              <div class="metrics__content__icon">
                <div
                  class="metrics__content__icon-background"
                  :style="{backgroundColor: store.verificationStatusColors(85)}"
                ></div>
                <component
                  :is="displayIcon(85)"
                  :fill="store.verificationStatusColors(85)">
                </component>
              </div>
            </div>
          </div>
      </div>
      <div class="metrics__content">
        <div class="metrics__content-indicator">
          <h3>Business Operations</h3>
          <div class="metrics__content-item">
            <p>Visibility of Physical Presence</p>
            <div class="metrics__content__icon">
              <div
                class="metrics__content__icon-background"
                :style="{backgroundColor: store.verificationStatusColors(20)}"
              ></div>
              <component
                :is="displayIcon(20)"
                :fill="store.verificationStatusColors(20)">
              </component>
            </div>
          </div>
          <div class="metrics__content-item">
            <p>Visibility of Business Activity</p>
            <div class="metrics__content__icon">
              <div
                class="metrics__content__icon-background"
                :style="{backgroundColor: store.verificationStatusColors(85)}"
              ></div>
              <component
                :is="displayIcon(85)"
                :fill="store.verificationStatusColors(85)">
              </component>
            </div>
          </div>
          <div class="metrics__content-item">
            <p>Visibile Digital Footprint</p>
            <div class="metrics__content__icon">
              <div
                class="metrics__content__icon-background"
                :style="{backgroundColor: store.verificationStatusColors(20)}">
              </div>
              <component
                :is="displayIcon(20)"
                :fill="store.verificationStatusColors(20)">
              </component>
            </div>
          </div>
        </div>
      </div>
    </RSection>
  </div>
</template>

<script setup>

import RSection from '@/components/RSection.vue';
import { verificationStore } from '@/store/verification';
import { defineAsyncComponent } from 'vue';

const store = verificationStore();

const displayIcon = (score) => {
  switch (store.verificationStatus(score)) {
    case 'danger':
      return defineAsyncComponent(() => import('@/components/icons/Verify/DangerIcon.vue'));
    default:
      return defineAsyncComponent(() => import('@/components/icons/CheckIcon.vue'));
  }
};

</script>

<style scoped lang="scss">

.metrics{
  &__wrapper{
    position: sticky;
    top: 0px;
    height: 75vh;
    overflow-y: auto;
  }
  &__content{
    padding: 28px 33px 31px 28px;
    border-bottom: 1px solid var(--color-grey-outline);
    &-indicator{
      h3 {
        color: var(--color-grey-dark);
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 19.5px;
        margin-bottom: 16px;
      }
    }
    &-item{
      display: grid;
      grid-template-columns: auto 28px;
      margin-bottom: 8px;
      justify-content: space-between;
      align-items: center;
      p {
        color: var(--color-dark-main);
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: 21px;
      }
    }
    &__icon{
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 28px;
      width: 28px;
      svg{
        height: 18px;
        width: 18px;
      }
      &-background{
        position: absolute;
        top: 0;
        left: 0;
        width: 28px;
        height: 28px;
        opacity: 0.08;
        border-radius: 999px;
      }
    }
  }
}
</style>
