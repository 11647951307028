import { defineStore } from 'pinia';
import Data from '@/api/users';

// eslint-disable-next-line import/prefer-default-export
export const profileStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'profileStore',
  state: () => ({
    data: null,
    currentAccount: null,
    partnerInfo: null,
    partners: null,
    paymentLink: null,
    trial: false,
  }),
  getters: {
    getProfile: (state) => state.data,
    getCurrentAccount: (state) => state.currentAccount,
    getPartnerInfo: (state) => state.partnerInfo,
    getPartners: (state) => state.partners,
    getPartnerPaymentLink: (state) => state.paymentLink,
    getTrial: (state) => state.trial,
  },
  actions: {
    setProfile(data) {
      this.data = data;
    },
    setCurrentAccount(data) {
      this.currentAccount = data;
    },
    setTrial(data) {
      this.trial = data;
    },
    fetchPaymentLink() {
      return new Promise((resolve, reject) => {
        try {
          Data.getPaymentLink().then((res) => {
            this.paymentLink = res;
            resolve(res);
          }).catch((error) => {
            reject(error);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    fetchPartnerInfo() {
      return new Promise((resolve, reject) => {
        try {
          Data.getPartnerInformation().then((res) => {
            this.partners = res.partners;

            const partnerId = localStorage.getItem('partner_id');
            if (!partnerId) {
              this.partnerInfo = res?.partners[0];
              localStorage.setItem('partner_id', this.partnerInfo.id);
            } else {
              const production = localStorage.getItem('production');
              if (production === 'false') {
                this.partnerInfo = res?.partners?.find(
                  (partner) => partner.sandbox_id === partnerId,
                );
              } else {
                this.partnerInfo = res?.partners?.find((partner) => partner.id === partnerId);
              }
            }
            resolve(this.partnerInfo);
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
});
