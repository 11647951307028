<template>
  <div v-if="!isLoading" class="page--overview">
    <div class="card full" id="onboardingInsightsKeyMetrics">
    <app-key-insights
      :current="insightsMetrics?.this_week"
      :previuos="insightsMetrics?.last_week"
      :currency="currency"
    ></app-key-insights>
  </div>
  <div class="card">
    <div class="card__head">
      <h5>Working users income distribution of this week</h5>
    </div>
    <app-histogram-income-graph
      :data="insightsPlots?.plot_json_weekly_earning_distribution_this_week"
      text="Users with no earnings this week"
      color="#0061FF"
      :percent="insightsMetrics?.this_week.this_week_percentage_not_working_driver"
      :currency="currency"
    >
    </app-histogram-income-graph>
  </div>
  <div class="card">
    <div class="card__head">
      <h5>Working users income distribution of last week</h5>
    </div>
    <app-histogram-income-graph
      :data="insightsPlots?.plot_json_weekly_earning_distribution_last_week"
      text="Users with no earnings last week"
      :percent="insightsMetrics?.last_week.last_week_percentage_not_working_driver"
      :currency="currency"
      color="#253858"
      :bars="500"
    >
    </app-histogram-income-graph>
  </div>
  <div class="card">
    <app-list-performence
      icon="top"
      title="Top users"
      :week="insightsTables?.top_10_drivers_this_week"
      :previous="insightsTables?.top_10_drivers_last_week"
      :currency="currency"
    >
    </app-list-performence>
  </div>
  <div class="card">
    <app-list-performence
      icon="noEarning"
      title="No earning users"
      :week="insightsTables?.not_working_driver_list_this_week"
      :previous="insightsTables?.not_working_driver_list_last_week"
      :currency="currency"
    >
    </app-list-performence>
  </div>
  <div class="card up full">
    <app-total-earnings
      :data="insightsPlots?.plots_total_earning_and_driver_number"
      :currency="currency"
    ></app-total-earnings>
  </div>
</div>
<r-loading v-else></r-loading>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { insightsStore } from '@/store/insights';
import AppKeyInsights from '@/components/Insights/KeyInsights.vue';
import AppHistogramIncomeGraph from '@/components/Insights/HistogramIncomeGraph.vue';
import AppListPerformence from '@/components/Insights/ListPerformance.vue';
import AppTotalEarnings from '@/components/Insights/TotalEarnings.vue';
import RLoading from '@/components/RLoading.vue';
import useEmitter from '@/composables/useEmitter';

const useInsights = insightsStore();
const insightsMetrics = computed(() => useInsights.getMetrics);
const insightsTables = computed(() => useInsights.getTables);
const insightsPlots = computed(() => useInsights.getPlots);
const isLoading = computed(() => useInsights.getIsLoading);
const currency = computed(() => useInsights.getCurrencySymbol);

const emitter = useEmitter();

onMounted(() => {
  useInsights.fetchOverview();
  emitter.on('insightSampleData', () => {
    useInsights.fetchOverview();
  });
});

onBeforeUnmount(() => {
  emitter.off('insightSampleData');
});
</script>

<style scoped lang="scss">
/* Global Dashboard layout */
.page--overview {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 24px;
  grid-template-rows: 222px auto fit-content() auto;
  row-gap: 28px;
  padding: 20px 30px 0 36px;
}
</style>
