<template>
  <ul class="tabbed-nav">
    <li class="tabbed-nav__item" v-for="(item, index) in items" :key="index">
        <router-link :to="{name: item.to , query: item.query}">
            <component :is="item.icon" v-if="item.icon"></component>
            {{ item.text }}
        </router-link>
    </li>
  </ul>
</template>

<script>
import ConversionIcon from '@/components/icons/ConversionIcon.vue';
import DataIcon from '@/components/icons/DataIcon.vue';
import MailUnreadIcon from '@/components/icons/MailUnreadIcon.vue';
import RefreshIcon from '@/components/icons/RefreshIcon.vue';
import CarIcon from '@/components/icons/CarIcon.vue';
import PieChartFillIcon from '@/components/icons/PieChartFillIcon.vue';
import AllUsersIcon from '@/components/icons/AllUsersIcon.vue';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  components: {
    ConversionIcon,
    DataIcon,
    MailUnreadIcon,
    RefreshIcon,
    CarIcon,
    PieChartFillIcon,
    AllUsersIcon,
  },
  watch: {
    // watch route changed
    route: {
      immediate: true,
      handler() {
        switch (this.$route.path) {
          case '/insights/drivers':
            window.analytics?.track('my_users_insights');
            break;
          case '/insights/overview':
            window.analytics?.track('overview_insights');
            break;
          case '/settings/data/update':
            window.analytics?.track('data_updates_settings');
            break;
          case '/settings/email/customization':
            window.analytics?.track('email_template_settings');
            break;
          case '/insights/drivers/:id/performance':
            window.analytics?.track('historical_perf_insights_users_details');
            break;
          case '/insights/drivers/:id/details':
            window.analytics?.track('current_perf_insights_users_details');
            break;
          default:
            // console.log(this.$route.path);
            window.analytics?.track('header_nav_clicked');
            break;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.tabbed-nav {
    display: flex;
    list-style-type: none;

    &__item {
        a {
            display: flex;
            align-items: center;
            gap: 12px;
            font-weight: 600;
            padding: 20px 28px;
            text-decoration: none;
            font-size: 15px;
            color: var(--grey-dark, #7A808B);

            svg path {
                fill: #A8AFBC;
            }

            &.router-link-exact-active {
                color: var(--dark-main, #253858);
                border-bottom: 2px solid var(--color-blue-main);
                svg {
                    path {
                        fill: var(--color-blue-main) !important;
                    }
                }
            }
        }
    }
}
</style>
