<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group">
    <path id="Vector" d="M6.66667 3.33317H17.5V4.99984H6.66667V3.33317ZM3.75 5.4165C3.41848
    5.4165 3.10054 5.28481 2.86612 5.05039C2.6317 4.81597 2.5 4.49802 2.5 4.1665C2.5 3.83498
    2.6317 3.51704 2.86612 3.28262C3.10054 3.0482 3.41848 2.9165 3.75 2.9165C4.08152 2.9165
    4.39946 3.0482 4.63388 3.28262C4.8683 3.51704 5 3.83498 5 4.1665C5 4.49802 4.8683
    4.81597 4.63388 5.05039C4.39946 5.28481 4.08152 5.4165 3.75 5.4165ZM3.75 11.2498C3.41848
    11.2498 3.10054 11.1181 2.86612 10.8837C2.6317 10.6493 2.5 10.3314 2.5 9.99984C2.5
    9.66832 2.6317 9.35037 2.86612 9.11595C3.10054 8.88153 3.41848 8.74984 3.75
    8.74984C4.08152 8.74984 4.39946 8.88153 4.63388 9.11595C4.8683 9.35037 5
    9.66832 5 9.99984C5 10.3314 4.8683 10.6493 4.63388 10.8837C4.39946 11.1181
    4.08152 11.2498 3.75 11.2498ZM3.75 16.9998C3.41848 16.9998 3.10054 16.8681
    2.86612 16.6337C2.6317 16.3993 2.5 16.0814 2.5 15.7498C2.5 15.4183 2.6317
    15.1004 2.86612 14.866C3.10054 14.6315 3.41848 14.4998 3.75 14.4998C4.08152
    14.4998 4.39946 14.6315 4.63388 14.866C4.8683 15.1004 5 15.4183 5 15.7498C5
    16.0814 4.8683 16.3993 4.63388 16.6337C4.39946 16.8681 4.08152 16.9998 3.75
    16.9998ZM6.66667 9.1665H17.5V10.8332H6.66667V9.1665ZM6.66667
    14.9998H17.5V16.6665H6.66667V14.9998Z" fill="#0061FF"/>
  </g>
  </svg>
</template>
