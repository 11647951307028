<template>
  <div class="input"
       :class="{
      'textarea--icon-left': leftIcon,
      'textarea--icon-right': rightIcon,
      'textarea--error': error,
      'textarea--rounded': rounded}">
    <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
    <label v-if="label" :for="labelWithoutSpace">
      {{label}}
      <r-tool-tip class="tool-tip" v-if="tooltipText">
        <template #tooltip-text>
            <span>
              {{tooltipText}}
            </span>
        </template>
      </r-tool-tip>
    </label>
    <textarea
      :id="labelWithoutSpace"
      :name="labelWithoutSpace"
      :placeholder="placeholder"
      ref="input"
      :rows="rows"
      :value="modelValue"
      @click="onClick"
      @change="onChange($event)"
      @input="onChange($event)">
    </textarea>
    <i v-if="rightIcon"
       class="input__icon input__icon--right noselect" :class="rightIcon">
    </i>
    <i v-if="leftIcon"
       class="input__icon input__icon--left noselect" :class="leftIcon">
    </i>
    <p class="input__error">{{error}}</p>
  </div>
</template>
<script>
import RToolTip from '@/components/RToolTip.vue';

export default {
  name: 'RTextArea',
  components: {
    RToolTip,
  },
  props: {
    label: String,
    placeholder: String,
    rightIcon: String,
    leftIcon: String,
    error: String,
    rounded: Boolean,
    tooltipText: String,
    defaultColor: String,
    rows: {
      type: Number,
      default: 2,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentValue: this.defaultColor,
    };
  },
  emits: ['update:modelValue'],
  computed: {
    labelWithoutSpace() {
      return this.label ? this.label.replace(/\s/g, '').toLowerCase() : '';
    },
  },
  methods: {
    onChange(e) {
      this.currentValue = e.target.value;
      this.$emit('update:modelValue', e.target.value);
    },
    colorPopup() {
      this.$refs.input.focus();
      this.$refs.input.click();
    },
  },
};
</script>
<style scoped lang="scss">
.input {
  width: 100%;
  position: relative;
  margin-bottom: 32px;
  textarea {
    width: inherit;
    resize: none;
    min-height: var(--dim-input-height);
    padding: 13px 16px 11px 16px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-black);
    border: 1px solid var(--color-grey-light);
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    &::placeholder {
      color: var(--color-grey-light);
      font-weight: 500;
    }
    &:disabled {
      background-color: #fff;
    }
  }
  label {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-dark-main);
    padding-bottom: 6px;
    & .tool-tip {
      margin-left: 4px;
    }
  }
  i {
    font-size: 18px;
  }
  &__error {
    position: absolute;
    width: inherit;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-red-error);
    padding-top: 6px;
  }
  &__icon {
    &--left {
      position: absolute;
      bottom: 15px;
      left: 16px;
      z-index: 2;
      color: var(--color-grey-light);
    }
    &--right {
      position: absolute;
      bottom: 15px;
      z-index: 2;
      right: 16px;
      color: var(--color-grey-dark);
    }
  }
  &--error {
    input {
      border-color: var(--color-red-error);
    }
  }
  &--rounded {
    margin-bottom: 4px;
    input {
      border-radius: 999px;
    }
  }
  &--icon {
    &-left {
      input {
        padding-left: 44px;
      }
    }
    &-right {
      input {
        padding-right: 44px;
      }
    }
  }
  &--cursor {
    user-select: none;
    cursor: pointer;
    input {
      cursor: pointer;
      padding-left: 52px;
    }
    i {
      bottom: 20px;
    }
  }
  &:hover, &:active, &:focus, &:focus-within {
    &:not(.input--error) {
      textarea {
        border: 1px solid var(--color-blue-main);
      }
    }
  }
  &__color {
    position: absolute;
    bottom: 17px;
    left: 18px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-grey-outline);
    border-radius: 4px;
    &-name {
      position: absolute;
      bottom: 17px;
      font-size: 16px;
      font-weight: 600;
      color: var(--color-black);
      left: 52px;
      text-transform: uppercase;
    }
  }
}
</style>
