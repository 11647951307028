<template>
  <div class="modal__backdrop">
    <div class="modal">
      <div class="modal__header">
        <h2>Remove {{ props.data.value.config?.name }}</h2>
        <CloseIcon @click="$emit('close')" />
      </div>
      <div class="modal__content">
        <p class="modal__desc">
          Are you sure you would like to remove this customization of your Connect?
          The change is permanent and can’t be reverted.
        </p>
      </div>
      <div class="modal__actions">
        <div></div>
        <RButton type="secondary" @click="$emit('close')">Cancel</RButton>
        <RButton type="warn"
                 :disabled="disabled"
                 @click="submit()"
        >Remove
        </RButton>
      </div>
    </div>
  </div>
</template>
<script setup>
import RButton from './RButton.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['close', 'submit']);

const submit = () => {
  emit('submit');
};

</script>
<style lang="scss" scoped>
.modal {
  &__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    backdrop-filter: blur(1px);
    background-color: rgba(255, 255, 255, 0.4);
  }
}
</style>
