<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div class="key key--key-metrics">
        <div class="card__head">
            <h5>Key insights metrics</h5>
              <RComboButton
                    :text="label">
                  <li
                  :class="{active: selected === 'lastWeek'}"
                  @click="dropdownAction('lastWeek')">
                      Last Week
                      <CheckMarkIcon v-if="selected === 'lastWeek'"/>
                  </li>
                  <li
                  :class="{active: selected === 'thisWeek'}"
                  @click="dropdownAction('thisWeek')">
                      This Week
                      <CheckMarkIcon v-if="selected === 'thisWeek'"/>
                  </li>
              </RComboButton>
        </div>
        <div class="key__tab">
            <div class="key__tab-container">
                <div class="key__tab-item">
                    <div class="key__tab-icon">
                        <div class="key__tab-iconBackground blue"></div>
                        <CheckMarkOutlinedIcon class="blue"/>
                    </div>
                    <span class="key__tab-label">
                        No. of Connected Users
                        <!-- <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    No. of users with successful fetch during last 30 days.
                                    Without deleted users.
                                </span>
                            </template>
                        </r-tool-tip> -->
                    </span>
                    <div class="key__tab-details">
                        <h3 class="key__tab-count">
                          {{ data.conctedDrivers || '--' }}
                        </h3>
                        <!-- <small
                        class="key--tab__change key--tab__change--up">
                        + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
                <div class="key__tab-item">
                    <span class="key__tab-icon">
                        <div class="key__tab-iconBackground green"></div>
                        <CarIcon/>
                    </span>
                    <span class="key__tab-label">
                        No. of working Users
                        <!-- <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    Total number of users with successful fetch during last 30 days.
                                    Without deleted users.
                                </span>
                            </template>
                        </r-tool-tip> -->
                    </span>
                    <div class="key__tab-details">
                      <h3 class="key__tab-count">{{ data.workingDrivers || '--' }}</h3>
                    </div>
                </div>
                <div class="key__tab-item">
                    <span class="key__tab-icon">
                        <div class="key__tab-iconBackground black"></div>
                        <WalletIcon/>
                    </span>
                    <span class="key__tab-label">
                        Total Gross Earnings
                        <!-- <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    Percentage of all users (including all data sources) that
                                    completedsuccessfully connection of their work account out of
                                    the total number of users in a chosen date range.
                                </span>
                            </template>
                        </r-tool-tip> -->
                    </span>
                    <div class="key__tab-details">
                        <h3 class="key__tab-count">
                            {{ numberFormat(data.gross) }} {{ props.currency }}
                        </h3>
                        <!-- <small
                        class="sections--tab__change sections--tab__change--down">
                            + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, reactive } from 'vue';
import { numberFormat } from '@/util/helpers';
import RComboButton from '@/components/RComboButton.vue';
import CheckMarkOutlinedIcon from '../icons/CheckMarkOutlinedIcon.vue';
import CarIcon from './icons/CarIcon.vue';
import WalletIcon from './icons/WalletIcon.vue';
import CheckMarkIcon from './icons/CheckMarkIcon.vue';

const props = defineProps(['current', 'previuos', 'currency']);

const data = reactive({
  conctedDrivers: null,
  workingDrivers: null,
  gross: null,
});

const selected = ref('thisWeek');
const label = ref('Last Week');

watch(selected, (val) => {
  if (val === 'thisWeek') {
    label.value = 'This Week';
    data.conctedDrivers = props.current?.this_week_total_driver;
    data.workingDrivers = props.current?.this_week_working_driver;
    data.gross = props.current?.this_week_total_earning;
    return;
  }
  label.value = 'Last Week';
  data.conctedDrivers = props.previuos?.last_week_total_driver;
  data.workingDrivers = props.previuos?.last_week_working_driver;
  data.gross = props.previuos?.last_week_total_earning;
}, {
  immediate: true,
});

const dropdownAction = (week) => {
  selected.value = week;
};

</script>

<style scoped>
.key__tab {
        position: relative;
    }

    .key__tab-container {
            display: flex;
            flex-wrap: wrap;
            margin-top: 20px;
            margin-bottom: 12px;
        }
        .key__tab-item {
            width: calc(100% / 4);
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        .key__tab-icon {
            width: 36px;
            height: 36px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 999px;
            position: relative;
        }
        .key__tab-iconBackground {
            position: absolute;
            width: 36px;
            height: 36px;
            border-radius: 999px;
            opacity: 0.08;
            background-color: var(--color-dark-main);

        }

        .key__tab-icon .blue{
            color: var(--color-blue-main);
        }

        .key__tab-iconBackground.blue {
            background-color: var(--color-blue-main);
        }
        .key__tab-iconBackground.green {
            background-color: var(--color-green-success);
        }
        .key__tab-iconBackground.black {
            background-color: var(--color-dark-main);
        }
        .key__tab-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        .key__tab-label {
            color: var(--color-grey-dark, #7A808B);
            font-weight: 500;
            font-size: 15px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
        .key__tab-count {
            color: var(--color-dark-main, #253858);
            font-size: 40px;
            font-weight: 500;
        }
        .key__tab-error{
          color: var(--color-dark-main, #253858);
          font-size: 24px;
          font-weight: 500;
        }
        .key__tab-change {
            font-size: 14px;
            font-weight: 600;
        }
.key {
    max-width: 100% !important;
}
</style>
