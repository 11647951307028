<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0001 18.3332C5.39758 18.3332 1.66675 14.6023 1.66675 9.99984C1.66675 5.39734
5.39758 1.6665 10.0001 1.6665C14.6026 1.6665 18.3334 5.39734 18.3334 9.99984C18.3334
14.6023 14.6026 18.3332 10.0001 18.3332ZM10.0001 16.6665C11.7682 16.6665 13.4639
15.9641 14.7141 14.7139C15.9644 13.4636 16.6667 11.7679 16.6667 9.99984C16.6667
8.23173 15.9644 6.53604 14.7141 5.28579C13.4639 4.03555 11.7682 3.33317 10.0001
3.33317C8.23197 3.33317 6.53628 4.03555 5.28604 5.28579C4.03579 6.53604 3.33341
8.23173 3.33341 9.99984C3.33341 11.7679 4.03579 13.4636 5.28604 14.7139C6.53628
15.9641 8.23197 16.6665 10.0001 16.6665ZM9.16925 13.3332L5.63341 9.79734L6.81175
8.619L9.16925 10.9765L13.8826 6.26234L15.0617 7.44067L9.16925 13.3332Z" fill="currentColor"/>
</svg>
</template>
