<template>
  <div class="modal__wrapper onboardingHiglightConnectLoadingModal" id="onboardingConnectModal">
    <div class="modal__icon">
      <div v-if="props.state === 'loading'" class="modal__animation" id="approvalAnimation"></div>
      <i class="ri-time-fill"></i>
    </div>
    <h2 class="modal__title">
      {{ connectState.title }}
    </h2>
    <p class="modal__text">
      {{ connectState.text }}
    </p>
    <div class="modal__actions" >
      <r-button
        v-if="props.state === 'success'"
        type="flat"
        @click="onSkip"
        :disabled="props.state === 'loading'"
      >
        Skip tour
      </r-button>
      <r-button
        v-if="props.state === 'success'"
        type="primary"
        afterIcon="ri-arrow-right-line"
        @click="onNext"
        :disabled="props.state === 'loading'"
      >
        Next
      </r-button>
    </div>
  </div>
</template>
<script setup>
import { skipOnboarding, setOnboardingStep, onboardingStepsMap } from '@/util/onboardingControl';
import {
  computed, onDeactivated, onMounted, reactive,
} from 'vue';
import { useRouter } from 'vue-router';
import RButton from './RButton.vue';

const router = useRouter();
const props = defineProps({
  state: {
    type: String,
    required: true,
    default: 'success',
  },
});

let bodymovinInstance = reactive(null);

const setupMovinAnimation = async () => {
  // eslint-disable-next-line
    bodymovinInstance = await bodymovin.loadAnimation({
    container: document.getElementById('approvalAnimation'),
    renderer: 'svg',
    // eslint-disable-next-line import/extensions,global-require,import/no-unresolved
    animationData: require('@/../public/animations/loading.json'),
    name: 'fetchAnimation',
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid meet',
      progressiveLoad: true,
      runExpressions: true,
    },
  });
};

const connectState = computed(() => {
  if (props.state === 'loading') {
    return {
      title: 'Collecting the data...',
      text: 'We’re connecting to your fleet account. The process might take up to 1 - 3 minutes. Please wait.',
    };
  }
  if (props.state === 'success') {
    return {
      title: 'Account successfully connected',
      text: 'You successfully connected the account. Click “Next” to see all your fleet drivers connected in the Users tab.',
    };
  }
  if (props.state === 'error') {
    return {
      title: 'Error!',
      text: 'We could not connect to your fleet account. Please try again.',
    };
  }
  return '';
});

const onNext = () => {
  router.push({ name: 'Data View' }).then(() => {
    setOnboardingStep(onboardingStepsMap.userList);
  });
};

const onSkip = () => {
  skipOnboarding();
  router.push('Connect Live');
};

onMounted(() => {
  setupMovinAnimation();
});

onDeactivated(() => {
  bodymovinInstance.destroy();
});
</script>
<style scoped lang="scss">
.modal{
  &__wrapper{
    width: 440px;
    height: 330px;
    border-radius: 12px;
    position: relative;
    background-color: var(--color-white);
    z-index: 100;
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    padding: 40px 40px 12px 40px;
    box-sizing: border-box;
  }
  &__icon{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 999px;
    border: 1px solid var(--color-grey-outline);
    height: 48px;
    width: 48px;
    box-shadow: 0px 6px 8px rgba(20, 20, 20, 0.08);
    position: relative;
    & i{
      font-size: 36px;
      color: var(--color-blue-main);
    }
  }

  &__animation{
    position: absolute;
    top: -23px;
    left: -24px;
    width: 200%;
    height: 200%;
  }
  &__title{
    color: var(--color-black);
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
  }

  &__text{
    color: var(--color-grey-dark);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__actions{
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>
