<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Vector" d="M9.16675 1.70835V3.38502C5.87841 3.79502 3.33341 6.60002
    3.33341 10C3.33341 13.6817 6.31841 16.6667 10.0001 16.6667C11.5409 16.6667
    12.9584 16.1442 14.0884 15.2667L15.2742 16.4525C13.8376 17.6275 12.0001
    18.3334 10.0001 18.3334C5.39758 18.3334 1.66675 14.6025 1.66675 10C1.66675
    5.67919 4.95591 2.12585 9.16675 1.70835ZM18.2917 10.8334C18.1251 12.5092
    17.4634 14.0392 16.4526 15.2734L15.2667 14.0884C15.9834 13.1659 16.4634
    12.0509 16.6151 10.8334H18.2926H18.2917ZM10.8351 1.70835C14.7717 2.09919
    17.9017 5.23002 18.2934 9.16669H16.6159C16.2401 6.15169 13.8501 3.76169
    10.8351 3.38502V1.70752V1.70835Z" fill="#0061FF"/>
    </g>
  </svg>
</template>
