<template>
  <div class="metadata__wrapper">
    <div class="metadata__section">
      <h3 class="metadata__section-title">
        Editable fields
        <span>
          Last updated: {{ formatDate(user?.updated_at, dataFormat) }}
        </span>
      </h3>
      <p class="metadata__section-info">
        Apply required changed
      </p>
      <div class="metadata__form">
        <template :key="field.name" v-for="field in metadata.fields">
          <div class="metadata__form-input">
            <RList
              v-if="field.type === 'array'"
              :label="field.name"
              :items="field.possible_values"
              :value="metadata.data[field.name]"
              @action="metadata.data[field.name] = $event.id; metadata.disabled = false"/>
          </div>
          <div class="metadata__form-input">
            <RInput
            v-if="field.type === 'text' || field.type === 'number'"
            :type="field.type"
            :label="field.name"
            @change="metadata.disabled = false"
            v-model="metadata.data[field.name]"/>
          </div>
        </template>
        <div class="metadata__form-action">
          <RButton
            :disabled="metadata.disabled"
            @click="metadata.showModal = true">
            Update
          </RButton>
        </div>
      </div>
    </div>
    <div class="metadata__section">
      <h3 class="metadata__section-title">Non-editable fields</h3>
      <div class="metadata__form down">
        <div
          class="metadata__form-input"
          :key="dynamic.name"
          v-for="dynamic in dynamicData">
          <RInput
          :label="dynamic.name"
          :placeholder="dynamic?.result || ''"
          :disabled="true"
        />
        </div>
      </div>
    </div>
    <RModalDynamic
      v-if="metadata.showModal"
      @close="metadata.clear()"
      title="Changing value">
      <template #content>
        <div>
          <p class="modal__desc">
            Are you sure you would like to change a values?
            This may affect your estimations and calculations.
          </p>
        </div>
      </template>
      <template #actions>
        <RButton
          type="secondary"
          @click="metadata.clear()">
            Cancel
        </RButton>
        <RButton
          type="warn"
          :disabled="metadata.loading"
          @click="metadata.onUpdateMetadata()">
          Update
        </RButton>
      </template>
    </RModalDynamic>
  </div>
</template>
<script setup>
import RInput from '@/components/RInput.vue';
import RButton from '@/components/RButton.vue';
import RList from '@/components/RList.vue';
import RModalDynamic from '@/components/RModalDynamic.vue';
import { usersStore } from '@/store/users';
import {
  computed,
  onMounted, reactive, ref,
} from 'vue';
import { useRouter } from 'vue-router';
import { profileStore } from '@/store/profile';
import { formatDate } from '@/util/helpers';

const userStore = usersStore();
const dynamicData = computed(
  () => userStore?.getCurrentUser?.user.total_income?.dynamic_data_points || [],
);
const router = useRouter();
const { id } = router.currentRoute.value.params;
const { startDate, endDate } = router.currentRoute.value.query;
const dataFormat = {
  day: '2-digit', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit',
};
const user = ref(userStore?.getCurrentUser?.user);

const metadata = reactive({
  showModal: false,
  model: {},
  fields: [],
  data: {},
  disabled: true,
  loading: false,
  onUpdateMetadata() {
    metadata.loading = true;
    usersStore().putUserMetadata(id, this.data).then(() => {
      usersStore().fetchUserById(id, {
        start_date: startDate,
        end_date: endDate,
        only_income: true,
      }).then(() => {
        metadata.clear();
      });
    });
  },
  clear() {
    metadata.showModal = false;
    metadata.disabled = true;
    metadata.loading = false;
  },
});

onMounted(() => {
  // check if user is not fetched
  if (!user.value) {
    router.push({ name: 'DataViewSingle', params: { id }, query: router.currentRoute.value.query });
    return;
  }
  metadata.fields = profileStore().getPartnerInfo?.metadata_parameters?.map((item) => {
    // Create model for each field
    metadata.model[item.name] = null;
    // Set value for each field
    if (user.value?.metadata) {
      metadata.data[item.name] = user.value?.metadata[item.name] || null;
    }
    if (item.type === 'array') {
      return {
        ...item,
        possible_values: item.possible_values.map((value) => ({ title: value, id: value })),
      };
    }
    return item;
  });
});
</script>

<style lang="scss" scoped>
.metadata{
  &__wrapper{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 115px;
    margin-right: 125px;
  }

  &__section{
    &-title{
      display: flex;
      justify-content: space-between;
      color: var(--color-black);
      font-family: Gilroy;
      font-size: 18px;
      font-weight: 600;
      line-height: 160%; /* 28.8px */

      & span{
        color: var(--color-grey-dark, #7A808B);
        font-size: 14px;
        font-weight: 500;
        line-height: 160%; /* 24px */
      }
    }

    &-info{
      color: var(--color-grey-dark, #7A808B);
      font-size: 15px;
      font-weight: 500;
      line-height: 160%; /* 24px */

    }
  }

  &__form{
    margin-top: 20px;
    &-input{
      margin-bottom: 20px;
    }

    &-action{
      display: flex;
      justify-content: flex-end;
    }
    &.down{
      margin-top: 40px;
    }
  }
}
</style>
