import { defineStore } from 'pinia';
import Data from '../api/data';

// eslint-disable-next-line import/prefer-default-export
export const customizationStore = defineStore({
  id: 'customizationStore',
  state: () => ({
    data: null,
    currentCustomization: null,
    datasources: null,
  }),
  getters: {
    getDatasources: (state) => state.datasources,
    getAllCustomizations: (state) => state.data,
    getCurrentCustomization: (state) => state.currentCustomization,
  },
  actions: {
    fetchDatasources() {
      return new Promise((resolve, reject) => {
        Data.getDatasources().then((data) => {
          this.datasources = data.client_datasources;
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    fetchCustomizations(params) {
      return new Promise((resolve, reject) => {
        Data.getConnectCustomizations(params).then((data) => {
          this.data = data.customizations;
          resolve(this.data);
        }).catch((err) => {
          this.data = null;
          reject(err);
        });
      });
    },
    fetchCustomization(id) {
      return new Promise((resolve, reject) => {
        Data.getConnectCustomization(id).then((data) => {
          this.currentCustomization = data.config;
          resolve(this.data);
        }).catch((err) => {
          this.currentCustomization = null;
          reject(err);
        });
      });
    },
    createCustomization(params) {
      return new Promise((resolve, reject) => {
        Data.createCustomization(params).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    updateCustomization(params) {
      return new Promise((resolve, reject) => {
        Data.updateCustomization(params).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    removeCustomization(params) {
      return new Promise((resolve, reject) => {
        Data.removeCustomization(params).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    enableCustomizationLink(customizationId) {
      return new Promise((resolve, reject) => {
        Data.enableCustomizationLink(customizationId).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    disableCustomizationLink(customizationId) {
      return new Promise((resolve, reject) => {
        Data.disableCustomizationLink(customizationId).then((data) => {
          resolve(data);
        }).catch((err) => {
          reject(err);
        });
      });
    },
    clearCurrentCustomization() {
      this.currentCustomization = null;
    },
    clearDatasources() {
      this.datasources = null;
    },
  },
});
