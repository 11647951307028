<template>
  <div class="sections">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Income</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div v-if="income" class="sections__content">
      <div class="sections__image sections__image--small">
        <img :src="platform.logo" alt="">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Platform</h3>
          <h1>{{ platform.name }}</h1>
        </div>
        <div class="sections__total">
          <p v-if="income.gross_earnings">
            Total Gross
            <r-tool-tip class="tool-tip">
              <template #tooltip-text>
            <span>
              Gross Earnings = Fare + Bonus + Tips
            </span>
              </template>
            </r-tool-tip>
            <b>{{ numberFormat(income.gross_earnings) }} {{ income.currency }}</b>
          </p>
          <p class="primary" v-if="income.net_amount">
            Total Net
            <r-tool-tip class="tool-tip">
              <template #tooltip-text>
                <span>
                  Net Earnings = Gross Earnings - Platform Fees
                </span>
              </template>
            </r-tool-tip>
            <b>{{ numberFormat(income.net_amount) }} {{ income.currency }}</b>
          </p>
        </div>
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr v-if="checkIfPropertyExists(income, 'taxes')">
                <td>Taxes</td>
                <td>{{ numberFormat(income.taxes) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'bonus_amount')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'fare')">
                <td>Fare</td>
                <td>{{ numberFormat(income.fare) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'tips')">
                <td>Tips</td>
                <td>{{ numberFormat(income.tips) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'expenses')">
                <td>Expenses</td>
                <td>
                  {{ numberFormat(income?.expenses) }} {{ income.currency }}
                </td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'reimbursements')">
                <td>Reimbursements</td>
                <td>
                  {{ numberFormat(income?.reimbursements) }} {{ income.currency }}
                </td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'currency')">
                <td>Currency</td>
                <td>
                  {{ income.currency }}
                </td>
              </tr>
            </table>
          </div>
          <div class="sections__table-part">
            <table>
              <tr v-if="checkIfPropertyExists(income, 'platform_fee')">
                <td>Platform Fees</td>
                <td>
                  {{ numberFormat(income.platform_fee) }} {{ income.currency }}
                </td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'card_earnings')">
                <td>Card Earnings</td>
                <td>
                  {{ numberFormat(income.card_earnings) }} {{ income.currency }}
                </td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'cash_earnings')">
                <td>Cash Earnings</td>
                <td>
                  {{ numberFormat(income.cash_earnings) }} {{ income.currency }}
                </td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'advertisement_bonus')">
                <td>Advertisement bonus</td>
                <td>{{ numberFormat(income.advertisement_bonus) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'other_amount')">
                <td>Other</td>
                <td>{{ numberFormat(income.other_amount) }} {{ income.currency }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income.bonus_amount_details, 'boost_payment')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount) }}
                    {{ income.bonus_amount_details.boost_payment }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(bonus_amount_details, 'new_hire_benefit_payment')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount) }}
                    {{ income.bonus_amount_details.new_hire_benefit_payment }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(bonus_amount_details, 'quest_payment')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount) }}
                    {{ income.bonus_amount_details.quest_payment }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(bonus_amount_details, 'referral_payment')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount) }}
                    {{ income.bonus_amount_details.referral_payment }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(bonus_amount_details, 'scoring_payment')">
                <td>Bonus</td>
                <td>{{ numberFormat(income.bonus_amount)}}
                    {{ income.bonus_amount_details.scoring_payment }}</td>
              </tr>
              <tr v-if="checkIfPropertyExists(income, 'electric_mobility_fee')">
                <td>Electric Mobility Fee</td>
                <td>{{ numberFormat(income.electric_mobility_fee) }} {{ income.currency }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { numberFormat } from '@/util/helpers';
import RComboButton from '@/components/RComboButton.vue';
import RToolTip from '@/components/RToolTip.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['download']);

const income = computed(() => props.data);

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'income' });
};

const checkIfPropertyExists = (obj, property) => obj && property in obj;
</script>
<style lang="scss" scoped>
.chart {
  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__total {
    display: grid;
    justify-items: end;
    text-align: end;
    padding-right: 60px;
    table {
      min-width: 260px;
      td {
        &:first-child {
          text-align: left;
        }
      }
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-grey-dark);
      line-height: 15px;
      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 160%;
        color: var(--color-dark-main);
      }
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 12px;
        vertical-align: top;
      }
      &.gross {
        &:before {
          background: #66A0FF;
        }
      }
      &.net {
        &:before {
          background: var(--color-blue-main);
        }
      }
    }
  }
  &__selector {
    font-size: 15px;
    line-height: 22px;
    padding-left: 16px;
    cursor: pointer;
    i {
      font-size: 22px;
      line-height: 22px;
      vertical-align: middle;
      color: var(--color-dark-main);
    }
    &-option {
      justify-self: end;
    }
  }
};
.tool-tip{
  transform: translateY(3px);
  margin-left: 2px;
}
</style>
