<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group">
  <path id="Vector" d="M9.99992 3.33325C15.0624 3.33325 19.1666 5.57159 19.1666
  8.33325V11.6666C19.1666 14.4283 15.0624 16.6666 9.99992 16.6666C5.02742 16.6666
  0.979919 14.5074 0.837419 11.8141L0.833252 11.6666V8.33325C0.833252 5.57159
  4.93742 3.33325 9.99992 3.33325ZM9.99992 13.3333C6.89992 13.3333 4.15825
  12.4941 2.49992 11.2083V11.6666C2.49992 13.2349 5.73575 14.9999 9.99992
  14.9999C14.1749 14.9999 17.3649 13.3083 17.4958 11.7649L17.4999 11.6666L17.5007
  11.2083C15.8424 12.4933 13.1008 13.3333 9.99992 13.3333ZM9.99992 4.99992C5.73575
  4.99992 2.49992 6.76492 2.49992 8.33325C2.49992 9.90159 5.73575 11.6666 9.99992
  11.6666C14.2641 11.6666 17.4999 9.90159 17.4999 8.33325C17.4999 6.76492 14.2641
  4.99992 9.99992 4.99992Z" fill="#0061FF"/>
  </g>
  </svg>
</template>
