<template>
  <div class="selection" :class="{'selection--block': block, 'selection--space': space}">
    <ul>
      <li
        class="selection__item"
        :class="{ 'selection__item--active': state.selected === option.name,
                  'selection__item--disabled': option.disabled }"
        v-for="option in options"
        :key="option" @click="handleChange(option)"
        @keyDown="handleChange(option)">
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>
<script>
import { reactive } from 'vue';

export default {
  name: 'RSelection',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    block: {
      type: Boolean,
      default: false,
    },
    space: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const state = reactive({
      selected: props.value,
    });

    // eslint-disable-next-line consistent-return
    const handleChange = (value) => {
      if (value.disabled) return false;
      state.selected = value.name;
      emit('update:value', value.name);
    };

    return {
      state,
      handleChange,
    };
  },
};
</script>
<style lang="scss" scoped>
.selection {
  position: relative;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  &__item {
    display: inline-block;
    color: var(--color-grey-dark);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    width: 100px;
    line-height: 34px;
    border-bottom: 1px solid #E2E8F0;
    cursor: pointer;
    transition: var(--transition-default);
    z-index: 9;
    &--active {
      color: var(--color-dark-main);
      border-bottom: 2px solid #0061FF !important;
    }
    &--disabled {
      color: var(--color-grey-light);
      cursor: not-allowed;
      user-select: none;
    }
  }
  &--block {
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: 2px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #E2E8F0;
    }
    .selection__item {
      border-color: transparent;
    }
  }
  &--space {
    margin-bottom: 24px;
  }
}
</style>
