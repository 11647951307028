export default {
  onboarding: {
    next: 'Suivant',
    back: 'Retour',
    skip: 'Passer le tour',
    start: 'Commençons',
    step: {
      connectLive: {
        name: 'CONNEXION EN DIRECT',
        title: 'Fleet Connect vous permet de connecter manuellement des comptes de flotte',
        description: `Utilisez Fleet Connect pour accéder directement à vos données de flotte à partir des plateformes de covoiturage
        et commencer à prendre de meilleures décisions grâce à un accès à l'activité des conducteurs,
        aux revenus, au profil, aux gains en temps réel, aux bonus, aux taxes, aux frais et à l'historique de travail.`,
      },
      connect: {
        name: 'CONNEXION FLOTTE',
        title: 'Connectez un compte de flotte en quelques clics',
        description: `Pour accéder aux revenus, à l'activité, au profil, aux gains en temps réel, à l'historique de travail,
        aux trajets, aux bonus, aux frais et plus encore de vos conducteurs, connectez votre compte de flotte via Fleet Connect.`,
      },
      connectLoading: {
        name: 'CONNEXION FLOTTE',
        title: 'Votre compte de flotte est en cours de connexion',
        description: `Votre compte de flotte est actuellement en cours de connexion. La finalisation de la connexion peut prendre un certain temps.
          Une fois les données prêtes, vous les trouverez affichées dans la section Utilisateurs de la vue des données.
          N'hésitez pas à poursuivre le reste du tour pendant que nous terminons le processus de collecte des données.`,
      },
      drivers: {
        name: 'CONDUCTEURS CONNECTÉS',
        title: 'Une fois la connexion d\'un compte de flotte établie, toutes les données de vos conducteurs apparaîtront dans l\'onglet Utilisateurs.',
        description: 'Pour voir les données détaillées de votre conducteur, cliquez sur un conducteur spécifique dans la vue en grille du tableau.',
      },
      driversFilter: {
        name: 'PARAMÈTRES DE VUE UTILISATEURS',
        title: 'Détails du profil, des revenus, de l\'emploi, de l\'historique de travail et bien plus encore...',
        description: 'Dans la vue des détails de l\'utilisateur, vous pouvez voir tous les points de terminaison détaillés, choisir une plage de dates, télécharger le CSV de chaque point de terminaison.',
      },
      insights: {
        name: 'APERÇUS CONDUCTEUR',
        title: 'Agrégrez les données de vos conducteurs provenant de plusieurs sources en quelques secondes.',
        description: 'Comprenez les performances globales de vos conducteurs grâce à des rapports standardisés de leurs gains, de leur activité et plus encore sur toutes les applications de covoiturage.',
      },
      insightsNavigation: {
        name: 'APERÇU DE LA FLOTTE ET DES CONDUCTEURS',
        title: 'Découvrez et testez le tableau de bord avec un ensemble de données d\'exemple',
        description: 'Visualisez les performances globales de vos conducteurs grâce à des rapports standardisés de leurs gains, de leur activité et plus encore sur toutes les applications de covoiturage.',
      },
      insightsSampleData: {
        name: 'TABLEAU DE BORD D\'EXEMPLE',
        title: 'Découvrez et testez le tableau de bord avec un ensemble de données d\'exemple',
        description: 'Visualisez les performances globales de vos conducteurs grâce à des rapports standardisés de leurs gains, de leur activité et plus encore sur toutes les applications de covoiturage.',
      },
      insightsMetrics: {
        name: 'MÉTRIQUES FLOTTE',
        title: 'Une fois la connexion d\'un compte de flotte établie, toutes les données apparaîtront dans l\'onglet Utilisateurs.',
        description: 'Pour voir les données détaillées de votre flotte, cliquez sur un compte de flotte spécifique dans la vue en grille du tableau.',
      },
    },
  },
};
