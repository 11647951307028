import { profileStore } from '@/store/profile';

const mapFeatureFlags = {
  DriverInsights: 'driverInsights',
};

const isFeatureFlaged = (to, from, next) => {
  const useprofileStore = profileStore();
  const profile = useprofileStore.getProfile;

  const featureFlags = Object.keys(profile?.canAccess || {});
  // Check if the feature flag is not in the list of flags and allow access
  if (!featureFlags.includes(mapFeatureFlags[to.meta.route])) {
    next();
  }
  // Check if the feature flag is in the list of flags
  if (profile?.canAccess[mapFeatureFlags[to.meta.route]]) {
    next();
    return;
  }
  // If no from route name, redirect to the Get Started page
  if (!from.name) {
    next({ name: 'Get Started' });
    return;
  }
  // If the feature flag is not set to true, redirect to the Get Started page
  next(false);
};

export default {
  isFeatureFlaged,
};
