<template>
  <div v-if="!isLoading" class="page--historical">
    <div class="card">
      <HistoricalActivity
      :currency="currency"
      :data="historicalPerformance?.metrics"
      :graps="historicalPerformance?.plots" />
    </div>
    <div class="card">
      <HistoricalEarnings
      :currency="currency"
      :data="historicalPerformance?.metrics"
      :graps="historicalPerformance?.plots"
      />
    </div>
    <div class="card">
      <HistoricalWorkload
      :currency="currency"
      :data="historicalPerformance?.metrics"
      :graps="historicalPerformance?.plots"
      />
    </div>
  </div>
  <RLoading v-else></RLoading>
</template>
<script setup>
import { insightsStore } from '@/store/insights';
import { computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import HistoricalActivity from '@/components/Insights/HistoricalActivity.vue';
import HistoricalEarnings from '@/components/Insights/HistoricalEarnings.vue';
import HistoricalWorkload from '@/components/Insights/HistoricalWorkload.vue';
import RLoading from '@/components/RLoading.vue';
import useEmitter from '@/composables/useEmitter';

const useInsights = insightsStore();
const router = useRouter();
const emitter = useEmitter();

const historicalPerformance = computed(() => useInsights.getDriverHistoricalPerformance);
const currency = computed(() => useInsights.getCurrencySymbol);
const isLoading = computed(() => useInsights.getIsLoading);

onMounted(() => {
  if (!historicalPerformance.value?.metrics && !historicalPerformance.value?.plots) {
    useInsights.fetchDriverDetails(router.currentRoute.value.params.id).then(() => {
      useInsights.setDriverName(router.currentRoute.value.params.name);
    });
  }

  emitter.on('insightSampleData', () => {
    // Check if the driver details are already fetched if not fetch them
    useInsights.fetchDriverDetails(router.currentRoute.value.params.id).then(() => {
      useInsights.setDriverName(router.currentRoute.value.params.name);
    });
  });
});
onBeforeUnmount(() => {
  emitter.off('insightSampleData');
});
</script>
<style scoped lang="scss">
.page{
  &--historical {
  display: grid;
  grid-template-rows: auto;
  row-gap: 28px;
  padding: 32px 30px 32px 36px;

  & .card {
    padding: 16px 32px;
  }

  &-actions {
    display: grid;
    grid-template-columns: 48px auto 1fr;
    align-items: center;
    padding-top: 5px;
  }
  &-driver {
    h3 {
      color: var(--color-grey-light);
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 17px;
      letter-spacing: 2.4px;
    }

    p {
      color: var(--color-dark-main);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 25.5px;
    }
  }

  &-date {
    margin-left: 28px;
    transform: translateY(7px);

    span {
      color: var(--color-grey-light);
      font-family: Gilroy;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 19px;
      &:first-child {
        margin-right: 30px;
        &::after {
          content: "";
          height: 3px;
          width: 3px;
          border-radius: 999px;
          display: inline-block;
          background-color: var(--color-grey-light);
          transform: translate(15px, -2px);
        }
      }
    }
  }
}
}
</style>
