<template>
  <div class="file__wrapper">
      <label class="file" for="upload">
        <UploadIcon/>
        <span class="file__title">
          Bulk Invitation via CSV
        </span>
        <r-tool-tip>
          <template #tooltip-text>
              <span>
                <!-- eslint-disable-next-line -->
                In uploaded file the first column’s rows must contain the values with a proper email addresses format.
              </span>
              <div class="tooltip__table">
                <div class="tooltip__table--row">
                  <span class="tooltip__table--number">1</span>
                  <span class="tooltip__table--cell">john.doe@domain.com</span>
                </div>
                <div class="tooltip__table--row">
                  <span class="tooltip__table--number">2</span>
                  <span class="tooltip__table--cell">john.sante@domain.com</span>
                </div>
                <div class="tooltip__table--row">
                  <span class="tooltip__table--number">3</span>
                  <span class="tooltip__table--cell">john.ford@domain.com</span>
                </div>
              </div>
          </template>
        </r-tool-tip>
        <input
          id="upload"
          type="file"
          accept=".csv, text/csv"
          @click="resetForm"
          @change="file.onChange($event)"
          ref="inputRef">
      </label>
      <div class="file__details" v-if="file.name">
        <p class="file__name">{{file.name}}</p>
        <span>
          CSV file with
          <span v-if="file.length">
            ({{file.length}})
          </span>
          emails successfully added
        </span>
        <div class="file__details-icon" @click="file.onRemove">
          <DeleteIcon  />
        </div>
      </div>
    <div class="file__details" v-if="file.error">
      <p class="file__name">{{file.name}}</p>
      <span>
          CSV file with
          emails could not be added
        </span>
    </div>
  </div>
</template>

<script>
import UploadIcon from '@/components/icons/UploadIcon.vue';
import DeleteIcon from '@/components/icons/DeleteIcon.vue';

import { reactive, ref } from 'vue';
import { validateEmail } from '@/util/validations';
import RToolTip from '@/components/RToolTip.vue';

export default {
  name: 'RInvite',
  components: {
    RToolTip,
    UploadIcon,
    DeleteIcon,
  },
  emits: ['invited'],
  setup(props, { emit }) {
    const inputRef = ref(null);

    const file = reactive({
      name: undefined,
      length: undefined,
      error: false,
      fileType: ['csv'],
      inviteList: [],
      onChange(event) {
        this.inviteList = [];
        this.error = false;
        const [uploadedFile] = event.target.files;
        const reader = new FileReader();

        // Check if file extension is allowed
        const extension = uploadedFile.name.split('.').pop().toLowerCase();
        const isAllowed = this.fileType.indexOf(extension) > -1;

        reader.onload = (e) => {
          if (!isAllowed) {
            this.error = true;
            return;
          }
          // Cleanup file and split content
          const fileMails = e.target.result.replace(/(\r\n|\n|\r)/gm, ',').split(',');
          fileMails.forEach((mail) => {
            if (!validateEmail(mail)) {
              return;
            }
            this.inviteList.push(mail);
          });
          this.length = this.inviteList.length;
          this.name = uploadedFile.name;
          emit('invited', this.inviteList);
        };

        window.analytics?.track('bulk_data_view_invite_users', {
          file: uploadedFile.name,
        });

        reader.onerror = () => {
          this.error = true;
          setTimeout(() => {
            this.error = false;
          }, 3000);
        };

        reader.readAsText(uploadedFile);
      },
      onRemove() {
        this.name = undefined;
        this.inviteList = [];
        this.length = undefined;
        emit('invited', this.inviteList);
        // eslint-disable-next-line no-use-before-define
        resetForm();
      },
    });

    const resetForm = () => {
      // Reset File input value
      inputRef.value.value = '';
    };
    return {
      file,
      inputRef,
      resetForm,
    };
  },
};
</script>

<style scoped lang="scss">
  .file{
    display: grid;
    grid-template-columns: 17px fit-content(170px) 20px;
    column-gap: 8px;
    align-items: center;
    cursor: pointer;
    color: var(--color-dark-main);

    &__wrapper{
      margin-top: 20px;
    }

    & input[type="file"]{
      display: none;
    }

    &__title{
      font-weight: 700;
      font-size: 16px;
      transform: translateY(1px);
    }

    &__details{
      padding: 17.5px 0;
      display: grid;
      grid-template-columns: 1fr auto 52px;
      align-items: center;
      border-bottom: 1px solid var(--color-grey-outline);
      & span{
        line-height: 17px;
        font-size: 14px;
        color: var(--color-grey-dark);
        text-align: end;
      }

      &-icon{
        justify-self: center;
        cursor: pointer;
      }
    }

    &__name{
      font-size: 14px;
      line-height: 17px;
      font-weight: 600;
      color: var(--color-dark-main)
    }
  }

  .tooltip{
    &__table{
      border-radius: 0px 2px 0px 0px;
      border: 1px solid var(--color-grey-light);
      background: rgba(255, 255, 255, 0.06);
      display: grid;
      grid-template-rows: repeat(3, 21px);
      margin-top: 6px;
      &--row{
        display: grid;
        grid-template-columns: 22px auto;
        border-bottom: 1px solid var(--color-grey-light);
        color: var(--white, #FFF);
        font-size: 11px;
        font-weight: 500;
        line-height: 17.5px;
        &:last-child{
          border-bottom: unset;
        }
      }

      &--number{
        display: flex;
        justify-content: center;
        align-items: flex-end;
        background-color: var(--color-blue-main);
        font: inherit;
      }
      &--cell{
        font: inherit;
        padding: 0 11px;
        display: flex;
        align-items: center;
      }
    }
  }
</style>
