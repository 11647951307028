<template>
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5240_47329)">
          <!-- eslint-disable-next-line max-len -->
            <path d="M4.90514 2.33331H24.9505C25.2459 2.33331 25.5292 2.45064 25.738 2.65949C25.9468 2.86834 26.0642 3.15159 26.0642 3.44695V21.2651C26.0642 21.5604 25.9468 21.8437 25.738 22.0525C25.5292 22.2614 25.2459 22.3787 24.9505 22.3787H4.90514C4.60978 22.3787 4.32653 22.2614 4.11768 22.0525C3.90883 21.8437 3.7915 21.5604 3.7915 21.2651V3.44695C3.7915 3.15159 3.90883 2.86834 4.11768 2.65949C4.32653 2.45064 4.60978 2.33331 4.90514 2.33331V2.33331ZM12.7563 11.2424C12.8527 10.7687 13.0709 10.3283 13.3892 9.96457C13.7075 9.60081 14.1151 9.32619 14.5718 9.16774C15.0284 9.0093 15.5185 8.97249 15.9937 9.06093C16.4689 9.14938 16.913 9.36003 17.2821 9.67216L19.1763 8.41041C18.5259 7.70187 17.6852 7.1955 16.7548 6.95174C15.8243 6.70798 14.8434 6.73714 13.9291 7.03573C13.0147 7.33432 12.2057 7.88973 11.5984 8.63566C10.9912 9.38158 10.6114 10.2865 10.5045 11.2424H9.35967V13.4696H10.5034C10.6101 14.4258 10.9898 15.331 11.5971 16.0771C12.2045 16.8232 13.0137 17.3788 13.9283 17.6774C14.8428 17.9761 15.824 18.0051 16.7546 17.7612C17.6852 17.5172 18.5259 17.0105 19.1763 16.3016L17.2821 15.0388C16.913 15.3509 16.4691 15.5616 15.994 15.6501C15.5188 15.7386 15.0288 15.7019 14.5721 15.5435C14.1155 15.3852 13.7079 15.1107 13.3895 14.7471C13.0711 14.3835 12.8529 13.9432 12.7563 13.4696L18.2687 13.4708V11.2435H12.7563V11.2424Z" fill="url(#paint0_linear_5240_47329)" />
            <!-- eslint-disable-next-line max-len -->
            <rect x="6.9165" y="8.75" width="10.5" height="12.25" rx="1" fill="url(#paint1_linear_5240_47329)" />
            <!-- eslint-disable-next-line max-len -->
            <path d="M2.86363 5.00006H22.909C23.2044 5.00006 23.4876 5.11739 23.6965 5.32624C23.9053 5.53508 24.0227 5.81834 24.0227 6.11369V23.9318C24.0227 24.2272 23.9053 24.5104 23.6965 24.7193C23.4876 24.9281 23.2044 25.0455 22.909 25.0455H2.86363C2.56828 25.0455 2.28502 24.9281 2.07618 24.7193C1.86733 24.5104 1.75 24.2272 1.75 23.9318V6.11369C1.75 5.81834 1.86733 5.53508 2.07618 5.32624C2.28502 5.11739 2.56828 5.00006 2.86363 5.00006ZM10.7147 13.9091C10.8112 13.4355 11.0294 12.9951 11.3477 12.6313C11.666 12.2676 12.0736 11.9929 12.5303 11.8345C12.9869 11.676 13.477 11.6392 13.9522 11.7277C14.1796 11.77 14.3998 11.8403 14.6081 11.9363C15.0126 12.1227 15.4957 12.1689 15.8664 11.922L16.1623 11.7249C16.6732 11.3846 16.7602 10.6599 16.2518 10.3159C15.7844 9.99958 15.2646 9.76293 14.7133 9.61849C13.7828 9.37473 12.8019 9.40389 11.8876 9.70248C10.9732 10.0011 10.1642 10.5565 9.55691 11.3024C8.94967 12.0483 8.56991 12.9532 8.46298 13.9091H8.4318C7.81676 13.9091 7.31817 14.4077 7.31817 15.0228V15.0228C7.31817 15.6378 7.81676 16.1364 8.4318 16.1364H8.46187C8.56861 17.0925 8.94832 17.9977 9.55564 18.7438C10.163 19.49 10.9722 20.0456 11.8868 20.3442C12.8013 20.6428 13.7825 20.6719 14.7131 20.4279C15.2649 20.2832 15.7851 20.0462 16.2527 19.7295C16.7608 19.3853 16.6738 18.661 16.1633 18.3206L15.8666 18.1229C15.4958 17.8757 15.0125 17.9219 14.6078 18.1083C14.3997 18.2043 14.1796 18.2745 13.9525 18.3168C13.4773 18.4053 12.9873 18.3686 12.5306 18.2103C12.074 18.052 11.6664 17.7775 11.348 17.4138C11.0296 17.0502 10.8114 16.61 10.7147 16.1364L15.1135 16.1373C15.7286 16.1374 16.2272 15.6388 16.2272 15.0238V15.0238C16.2272 14.4088 15.7287 13.9102 15.1137 13.9102H10.7147V13.9091Z" fill="url(#paint2_linear_5240_47329)" />
        </g>
        <defs>
          <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint0_linear_5240_47329" x1="54.8332" y1="45.5" x2="13.2709" y2="53.7172" gradientUnits="userSpaceOnUse">
                <stop stop-color="#017BFE" />
                <stop offset="1" stop-color="#0061FF" stop-opacity="0.28" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint1_linear_5240_47329" x1="25.5" y1="24.5" x2="9.17434" y2="10.8822" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint2_linear_5240_47329" x1="7" y1="8.86969" x2="17.178" y2="26.0312" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="#0061FF" />
            </linearGradient>
            <clipPath id="clip0_5240_47329">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>
