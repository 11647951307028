<template>
  <div class="tab-container">
    <div class="tab-container__tabs" ref="tabs"
    :style="{ '--tab-count': tabCount }">
      <slot name="tab-titles"></slot>
      <div
        class="tab-container__active-underline"
        :style="{ left: `${underlineLeft}px`, width: `${underlineWidth}px` }"
      ></div>
    </div>
    <div class="tab-container__content">
      <slot :name="`tab-content-${activeTabIndex}`"></slot>
    </div>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, defineExpose, onMounted, watch, nextTick } from 'vue';

const activeTabIndex = ref(0);
const underlineLeft = ref(0);
const underlineWidth = ref(0);
const tabs = ref(null);
const tabCount = ref(0); // Initialize tabCount

const setActiveTab = (index) => {
  nextTick(() => {
    if (!tabs?.value?.children) {
      return
    }
    activeTabIndex.value = index;
    const tabElements = Array.from(tabs?.value?.children).filter(el => !el.classList.contains('tab-container__active-underline'));
    const tabElement = tabElements[index];
    if (tabElement) {
      underlineLeft.value = tabElement.offsetLeft;
      underlineWidth.value = tabElement.offsetWidth;
    }
  });
};

const updateTabClasses = () => {
  if (!tabs?.value?.children) {
      return
    }
  const tabElements = Array.from(tabs?.value?.children).filter(el => !el.classList.contains('tab-container__active-underline'));
  tabElements.forEach((tab, index) => {
    if (index === activeTabIndex.value) {
      tab.classList.remove('tab--inactive');
    } else {
      tab.classList.add('tab--inactive');
    }
  });
};

defineExpose({ setActiveTab, activeTabIndex });

onMounted(() => {
  nextTick(() => {
    if (!tabs?.value?.children) {
      return
    }
    const tabElements = Array.from(tabs?.value?.children).filter(el => !el.classList.contains('tab-container__active-underline'));
    tabCount.value = tabElements.length; // Correct tab count
    setActiveTab(activeTabIndex.value);
    updateTabClasses();
  });
});

watch(activeTabIndex, () => {
  setActiveTab(activeTabIndex.value);
  updateTabClasses();
});
</script>

<style lang="scss">
.tab-container {
  position: relative;
  width: 100%;

  &__tabs {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #EDEEF1;

    .tab {
        width: calc(100% / var(--tab-count));
        flex: 1;
        cursor: pointer;
        padding: 8px 32px;
        font-weight: 600;

        &--inactive {
            h5, h6 {
                color: var(--color-grey-dark, #7A808B) !important;
            }
        }
        h6 {
            font-size: 15px !important;
            font-weight: 600 !important;
        }
        h5 {
            font-size: 28px !important;
            font-weight: 600 !important;
            margin-top: 5px;
        }
        span {
            font-size: 15px;
            color: var(--color-green-success, #8FB571);
        }
        &:not(.tab--inactive) {
            h5 {
                color: var(--color-dark-main, #253858);
            }
            h6 {
                color: var(--color-dark-main, #253858) !important;
            }
            span {
                color: var(--color-green-success, #8FB571);
            }
        }
    }
  }

  &__active-underline {
    position: absolute;
    bottom: -3px;
    left: 0;
    height: 2px;
    background-color: var(--color-blue-main);
    transition: left 0.3s ease, width 0.3s ease;
  }
}
</style>
