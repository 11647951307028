<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <h2 class="asection__title">
      <KnowledgeIcon />Knowledge Base
    </h2>
    <div class="asection__wrapper asection__wrapper--half">
      <div class="asection asection--clickable" ref="section" id="test" @click="goto('https://developers.getrollee.com/docs/connect-sdk')" @keyup="none">
        <div class="asection__image">
          <img src="@/assets/tile-1.svg" alt="" />
        </div>
        <div class="asection__content">
          <h3>Customize Connect with SDKs</h3>
            <p>
              Create your Connect customization to
              easily decide how Connect appears in your application or website.
            </p>
          <a href="https://developers.getrollee.com/docs/connect-sdk" target="_blank">Learn more</a>
        </div>
      </div>
      <div class="asection asection--clickable" @click="goto('https://developers.getrollee.com/docs')" @keyup="none">
        <div class="asection__image">
          <img src="@/assets/tile-2.svg" alt="" />
        </div>
        <div class="asection__content">
          <h3>Developer Documentation</h3>
          <p>
            Explore the Rollee Connect and learn
            what to expect when users connect their work accounts.
          </p>
          <a href="https://developers.getrollee.com/docs" target="_blank">Learn more</a>
        </div>
      </div>
      <div class="asection asection--clickable" @click="goto('https://developers.getrollee.com/recipes')" @keyup="none">
        <div class="asection__image">
          <img src="@/assets/tile-3.svg" alt="" />
        </div>
        <div class="asection__content">
          <h3>Recipes</h3>
          <p>
            This Recipe guides you through three simple steps which will let you use Rollee Connect.
          </p>
          <a href="https://developers.getrollee.com/recipes" target="_blank">Learn more</a>
        </div>
      </div>
      <div class="asection asection--clickable" @click="routeTo('Coverage')" @keyup="none">
        <div class="asection__image">
          <img src="@/assets/tile-4.svg" alt="" />
        </div>
        <div class="asection__content">
          <h3>Platform Coverage</h3>
          <p>
            We've built platform coverage across
            all major platforms so you can focus on building your product.
          </p>
          <a @click="routeTo('Coverage')" >Learn more</a>
        </div>
      </div>
    </div>
</template>
<script setup>
import KnowledgeIcon from '@/components/icons/KnowledgeIcon.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const section = ref(null);

const router = useRouter();

const goto = (url) => {
  window.open(url, '_blank');
};

const routeTo = (name) => {
  router.push({ name });
};
</script>
