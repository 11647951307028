<template>
  <div class="sections">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Income description</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div v-if="incomeArray">
      <div class="sections__content sections__content--no-space"></div>
      <div v-for="(income, index) in incomeArray" :key="income.name" class="sections__content"
        :class="{'sections__content--no-border': index == 0}">
        <div class="sections__image sections__image--small">
          <img :src="platform.logo" alt="">
        </div>
        <div class="sections__data">
          <div class="sections__title">
            <h3>Organization</h3>
            <h1>{{ income.name }}</h1>
          </div>
          <div class="sections__total">
            <p v-if="income.gross_earnings">
              Total Gross
              <r-tool-tip class="tool-tip">
                <template #tooltip-text>
              <span>
                Gross Earnings = Fare + Bonus + Tips
              </span>
                </template>
              </r-tool-tip>
              <b>{{ numberFormat(income.gross_earnings) }} {{ income.currency }}</b>
            </p>
            <p class="primary" v-if="income.net_amount">
              Total Net
              <r-tool-tip class="tool-tip">
                <template #tooltip-text>
                  <span>
                    Net Earnings = Gross Earnings - Platform Fees
                  </span>
                </template>
              </r-tool-tip>
              <b>{{ numberFormat(income.net_amount) }} {{ income.currency }}</b>
            </p>
          </div>
          <div class="sections__table">
            <div class="sections__table-part">
              <table>
                <tr v-if="checkIfPropertyExists(income, 'taxes')">
                  <td>Taxes</td>
                  <td>{{ numberFormat(income.taxes) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'bonus_amount')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'fare')">
                  <td>Fare</td>
                  <td>{{ numberFormat(income.fare) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'tips')">
                  <td>Tips</td>
                  <td>{{ numberFormat(income.tips) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'expenses')">
                  <td>Expenses</td>
                  <td>
                    {{ numberFormat(income?.expenses) }} {{ income.currency }}
                  </td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'reimbursements')">
                  <td>Reimbursements</td>
                  <td>
                    {{ numberFormat(income?.reimbursements) }} {{ income.currency }}
                  </td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'currency')">
                  <td>Currency</td>
                  <td>
                    {{ income.currency }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="sections__table-part">
              <table>
                <tr v-if="checkIfPropertyExists(income, 'platform_fee')">
                  <td>Platform Fees</td>
                  <td>
                    {{ income.platform_fee.toFixed(2) }} {{ income.currency }}
                  </td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'card_earnings')">
                  <td>Card Earnings</td>
                  <td>
                    {{ numberFormat(income.card_earnings) }} {{ income.currency }}
                  </td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'cash_earnings')">
                  <td>Cash Earnings</td>
                  <td>
                    {{ numberFormat(income.cash_earnings) }} {{ income.currency }}
                  </td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'advertisement_bonus')">
                  <td>Advertisement bonus</td>
                  <td>{{ numberFormat(income.advertisement_bonus) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income, 'other_amount')">
                  <td>Other</td>
                  <td>{{ numberFormat(income.other_amount) }} {{ income.currency }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(income.bonus_amount_details, 'boost_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }}
                      {{ income.bonus_amount_details.boost_payment }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(bonus_amount_details, 'new_hire_benefit_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }}
                      {{ income.bonus_amount_details.new_hire_benefit_payment }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(bonus_amount_details, 'quest_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }}
                      {{ income.bonus_amount_details.quest_payment }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(bonus_amount_details, 'referral_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }}
                      {{ income.bonus_amount_details.referral_payment }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(bonus_amount_details, 'scoring_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.bonus_amount) }}
                      {{ income.bonus_amount_details.scoring_payment }}</td>
                </tr>
                <tr v-if="checkIfPropertyExists(electric_mobility_fee, 'scoring_payment')">
                  <td>Bonus</td>
                  <td>{{ numberFormat(income.electric_mobility_fee) }}
                      {{ income.electric_mobility_fee }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <template v-for="(period, i) in sortDestribution(income?.distribution)" :key="i">
          <div class="period__date" v-if="period.time_period_start_date">
            <p>{{ monthAndYearDate(period.time_period_start_date) }}</p>
          </div>
          <div class="sections__table period__section">
            <div class="sections__table-part">
              <table>
                <tr v-if="period?.gross_earnings">
                  <td>Gross Earnings</td>
                  <td>
                    {{ currencySymbol(period?.currency) }}
                    {{ numberFormat( period.gross_earnings ) }}
                  </td>
                </tr>
                <tr v-if="period?.net_amount">
                  <td>Net Earnings</td>
                  <td>
                    {{ currencySymbol(period?.currency) }}
                    {{ numberFormat( period.net_amount ) }}
                  </td>
                </tr>
                <tr v-if="period?.net_amount_after_vat">
                  <td>Net Amount after VAT</td>
                  <td>
                    {{ currencySymbol(period?.currency) }}
                    {{ numberFormat( period.net_amount_after_vat ) }}
                  </td>
                </tr>
                <tr v-if="period?.material_fee">
                  <td>Material Fee</td>
                  <td>
                    {{ currencySymbol(period?.currency) }}
                    {{ numberFormat( period.material_fee ) }}
                  </td>
                </tr>
                <tr v-if="period?.employer_fiscal_id">
                  <td>Employer Fiscal ID</td>
                  <td>
                    {{ period.employer_fiscal_id }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="sections__table-part">
              <table>
                <tr v-if="period?.taxes">
                  <td>Taxes</td>
                  <td>
                    {{ currencySymbol(period?.currency) }}
                    {{ numberFormat( period.taxes ) }}
                  </td>
                </tr>
                <tr v-if="period?.time_period_start_date">
                  <td>Start Date</td>
                  <td>
                    {{ formatDate( period.time_period_start_date ) }},
                    {{ yearDate(period.time_period_start_date) }}
                  </td>
                </tr>
              </table>
            </div>
          </div>

        </template>
      </div>
    </div>

  </div>
</template>
<script setup>
import RComboButton from '@/components/RComboButton.vue';
import RToolTip from '@/components/RToolTip.vue';
import { numberFormat, formatDate, currencySymbol } from '@/util/helpers';
import { computed } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const incomeArray = computed(() => Object.keys(props.data).map((key) => ({
  name: key,
  ...props.data[key],
})));

const emits = defineEmits(['download']);

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'income_by_organization_name' });
};
// Sort the distribution by date in descending order as array
const sortDestribution = (distribution) => {
  if (!distribution) return [];
  return distribution.sort(
    (a, b) => new Date(b.time_period_start_date) - new Date(a.time_period_start_date),
  );
};

const monthAndYearDate = (dateString) => {
  const options = { month: 'short', year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const yearDate = (dateString) => {
  const options = { year: 'numeric' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};
const checkIfPropertyExists = (obj, property) => obj && property in obj;

</script>
<style lang="scss" scoped>
.chart {
  &__options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  &__total {
    display: grid;
    justify-items: end;
    text-align: end;
    padding-right: 60px;
    table {
      min-width: 260px;
      td {
        &:first-child {
          text-align: left;
        }
      }
    }
    &-title {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-grey-dark);
      line-height: 15px;
      span {
        font-size: 20px;
        font-weight: 700;
        line-height: 160%;
        color: var(--color-dark-main);
      }
      &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 12px;
        vertical-align: top;
      }
      &.gross {
        &:before {
          background: #66A0FF;
        }
      }
      &.net {
        &:before {
          background: var(--color-blue-main);
        }
      }
    }
  }
  &__selector {
    font-size: 15px;
    line-height: 22px;
    padding-left: 16px;
    cursor: pointer;
    i {
      font-size: 22px;
      line-height: 22px;
      vertical-align: middle;
      color: var(--color-dark-main);
    }
    &-option {
      justify-self: end;
    }
  }
};
.tool-tip{
  transform: translateY(3px);
  margin-left: 2px;
}
.period{
  &__date{
    padding-top: 26px;
    color: var(--color-grey-light);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }

  &__section{
    border-top: 1px solid var(--color-grey-outline);
    padding: 20px 0 8px 0;
  }
}
</style>
