<template>
  <div class="profile__wrapper">
    <div class="profile" :style=" {'width': props.size +'px', 'height': props.size + 'px' }">
      <img v-if="props.src" :src="props.src" alt="">
      <div
        v-else
        class="profile__initials"
        :style="{ 'width': props.size + 'px',
                  'height': props.size + 'px',
                  'line-height': props.size + 'px',
                  'font-size': props.size / 3 + 'px',
                }">
        {{ initials }}
      </div>
      <div class="status" v-if="props.statusIcon">
        <i class="ri-checkbox-circle-fill"></i>
      </div>
    </div>
    <div v-if="!props.avatar" class="profile__values">
      <div>
        <p class="profile__name">
          <span>{{ props.name }}</span>
        </p>
        <p
          v-if="props.status"
          class="profile__status"
          :class="{ 'profile__status--disconnected': status === 'Disconnected' }"
        >
          {{ status }}
          <span v-if="props.metadata" class="profile__meta">
            <i class="ri-error-warning-line"></i>
            <a href="#" @click="onMetadata">See User Info</a>
          </span>
        </p>
        <p class="profile__text" v-if="props.text">
          {{ props.text }}
        </p>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  src: {
    type: String,
    required: false,
  },
  name: {
    type: String,
    required: true,
    default: '-',
  },
  text: {
    type: String,
    required: false,
  },
  size: {
    type: Number,
    required: false,
    default: 60,
  },
  statusIcon: {
    type: Boolean,
    default: true,
  },
  status: {
    type: String,
    required: false,
  },
  avatar: {
    type: Boolean,
    default: false,
  },
  metadata: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

const initials = computed(() => props.name && props.name
  .split(' ')
  .map((n) => {
    if (n[0] !== '#') {
      return n[0];
    }
    return '';
  })
  .join('')
  .slice(0, 2));

const status = computed(() => {
  if (props.status) {
    return props.status.charAt(0).toUpperCase() + props.status.slice(1);
  }
  return '';
});

const onMetadata = () => {
  router.push({ name: 'UserMetadata', query: router.currentRoute.value.query });
};
</script>
<style lang="scss" scoped>
.profile {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 999px;
  border: 2px solid rgba(0, 97, 255, 0.16);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 999px;
    object-fit: cover;
  }
  &__initials {
    position: relative;
    background-color: #EBF2FF;
    color: var(--color-dark-main);
    font-weight: 600;
    text-align: center;
    font-size: 18px;
    border-radius: 999px;

  }
  &__name {
    font-size: 15px;
    font-weight: 500;
    line-height: 160%;
    height: 22px;
    color: var(--color-black);
  }

  &__text{
    color: var(--color-grey-light);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
  }
  &__meta {
    margin-left: 32px;
    color: var(--color-grey-light);

    & i {
      margin-right: 8px;
      font-size: 18px;
      height: 18px;
      width: 18px;
      transform: translateY(4px);
      display: inline-block;
    }
    & a{
      color: var(--color-grey-light);
      text-decoration: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%; /* 16.8px */
    }
  }
  &__status {
    margin-top: 4px;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-blue-main);
    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 999px;
      background-color: var(--color-blue-main);
      margin-right: 4px;
      position: relative;
      top: -1px;
    }

    &--disconnected {
      color: var(--color-yellow-warning);
      &:before {
        background-color: var(--color-yellow-warning);
      }
    }
  }
  &__wrapper {
    display: grid;
    grid-template-columns: 52px auto;
    gap: 8px;
  }
  .status {
    position: absolute;
    width: 24px;
    height: 24px;
    line-height: 24px;
    border: 1px solid var(--color-grey-outline);
    border-radius: 999px;
    z-index: 2;
    background: #fff;
    box-shadow: 0px 6px 8px rgba(20, 20, 20, 0.08);
    right: -6px;
    bottom: -6px;
    display: grid;
    align-items: center;
    justify-content: center;
    i {
      line-height: 24px;
      font-size: 16px;
      color: var(--color-blue-main);
    }
  }
}
</style>
