<template>
  <div class="sections">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Employee</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div v-if="employee" class="sections__content">
      <div class="sections__image sections__image--small">
        <img :src="platform.logo" alt="">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Platform</h3>
          <h1>{{ platform.name }}</h1>
        </div>
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr v-for="item in dataOds" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
          <div class="sections__table-part">
            <table>
              <tr v-for="item in dataEven" :key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.value }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import RComboButton from '@/components/RComboButton.vue';
import { computed, reactive } from 'vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const dataOds = reactive([]);
const dataEven = reactive([]);

Object.keys(props.data).forEach((key, idx) => {
  let name = key.replace(/_/g, ' ');
  name = name.charAt(0).toUpperCase() + name.slice(1);
  const sentenceKey = name.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
  if (idx % 2 === 0) {
    dataOds.push({
      id: idx, key, name: sentenceKey, value: props.data[key],
    });
  } else {
    dataEven.push({
      id: idx, key, name: sentenceKey, value: props.data[key],
    });
  }
});

const emits = defineEmits(['download']);

const employee = computed(() => props.data);

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'employee' });
};
</script>
