<template>
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M4.16665 7.50008C4.38766 7.50008 4.59962 7.58788
4.7559 7.74416C4.91218 7.90044 4.99998 8.1124 4.99998 8.33341C6.30926
8.33149 7.5808 8.77197 8.60831 9.58341H10.4166C11.5275 9.58341 12.525
10.0667 13.2116 10.8334H15.8333C16.621 10.8332 17.3927 11.0563 18.0588
11.4768C18.7248 11.8973 19.2581 12.498 19.5966 13.2092C17.6258 15.8101
14.435 17.5001 10.8333 17.5001C8.50831 17.5001 6.54165 16.9976 4.94998
16.1184C4.89168 16.2793 4.78518 16.4183 4.64499 16.5164C4.50481 16.6145
4.33776 16.667 4.16665 16.6667H1.66665C1.44563 16.6667 1.23367 16.579 1.07739
16.4227C0.92111 16.2664 0.833313 16.0544 0.833313 15.8334V8.33341C0.833313
8.1124 0.92111 7.90044 1.07739 7.74416C1.23367 7.58788 1.44563 7.50008 1.66665
7.50008H4.16665ZM5.00081 10.0001L4.99998 14.1851L5.03748 14.2117C6.53331 15.2617
8.48165 15.8334 10.8333 15.8334C13.3366 15.8334 15.6658 14.8701 17.3625 13.2251L17.4733
13.1142L17.3733 13.0309C16.9798 12.7227 16.5028 12.5398 16.0041 12.5059L15.8333
12.5001H14.0741C14.1341 12.7684 14.1666 13.0467 14.1666 13.3334V14.1667H6.66665V12.5001L12.325
12.4992L12.2966 12.4342C12.1369 12.1005 11.8913 11.8153 11.5849 11.608C11.2785 11.4006 10.9225
11.2785 10.5533 11.2542L10.4166 11.2501H7.97498C7.58774 10.854 7.12521 10.5394 6.61458
10.3247C6.10396 10.1101 5.55556 9.99968 5.00165 10.0001H5.00081ZM3.33331
9.16675H2.49998V15.0001H3.33331V9.16675ZM15 4.16675C15.663 4.16675 16.2989
4.43014 16.7677 4.89898C17.2366 5.36782 17.5 6.00371 17.5 6.66675C17.5 7.32979
17.2366 7.96567 16.7677 8.43451C16.2989 8.90336 15.663 9.16675 15 9.16675C14.3369
9.16675 13.7011 8.90336 13.2322 8.43451C12.7634 7.96567 12.5 7.32979 12.5 6.66675C12.5
6.00371 12.7634 5.36782 13.2322 4.89898C13.7011 4.43014 14.3369 4.16675 15 4.16675ZM15
5.83341C14.779 5.83341 14.567 5.92121 14.4107 6.07749C14.2544 6.23377 14.1666 6.44573
14.1666 6.66675C14.1666 6.88776 14.2544 7.09972 14.4107 7.256C14.567 7.41228 14.779 7.50008 15
7.50008C15.221 7.50008 15.433 7.41228 15.5892 7.256C15.7455 7.09972 15.8333 6.88776 15.8333
6.66675C15.8333 6.44573 15.7455 6.23377 15.5892 6.07749C15.433 5.92121 15.221 5.83341 15
5.83341ZM9.16665 1.66675C9.82969 1.66675 10.4656 1.93014 10.9344 2.39898C11.4033 2.86782
11.6666 3.50371 11.6666 4.16675C11.6666 4.82979 11.4033 5.46567 10.9344 5.93451C10.4656
6.40336 9.82969 6.66675 9.16665 6.66675C8.50361 6.66675 7.86772 6.40336 7.39888 5.93451C6.93004
5.46567 6.66665 4.82979 6.66665 4.16675C6.66665 3.50371 6.93004 2.86782 7.39888 2.39898C7.86772
1.93014 8.50361 1.66675 9.16665 1.66675ZM9.16665 3.33341C8.94563 3.33341 8.73367 3.42121 8.57739
3.57749C8.42111 3.73377 8.33331 3.94573 8.33331 4.16675C8.33331 4.38776 8.42111 4.59972 8.57739
4.756C8.73367 4.91228 8.94563 5.00008 9.16665 5.00008C9.38766 5.00008 9.59962 4.91228 9.7559
4.756C9.91218 4.59972 9.99998 4.38776 9.99998 4.16675C9.99998 3.94573 9.91218 3.73377 9.7559
3.57749C9.59962 3.42121 9.38766 3.33341 9.16665 3.33341Z" fill="#0061FF"/>
</g>
</svg>
</template>
