<template>
  <component :is="layout"> </component>
  <OnboardingOverlay
        v-if="isOnboardingActive"
        :id="getOnboardingData?.id"
        :offsetX="getOnboardingData?.offSetX"
        :offsetY="getOnboardingData?.offSetY"
        :position="getOnboardingData?.position"
        :target="getOnboardingData?.target"
        :interactive="getOnboardingData?.interactive">
        <template #tooltip>
          <div class="onboarding__step">
            <h2>{{getOnboardingData.data?.stepNumber}}</h2>
          </div>
          <h3 class="onboarding__step-name">{{ i18n.t(getOnboardingData.data?.stepName) }}</h3>
          <h4 class="onboarding__step-title">
            {{ i18n.t(getOnboardingData.data?.stepTitle) }}
          </h4>
          <p class="onboarding__step-text">
            {{ i18n.t(getOnboardingData.data?.stepDescription) }}
          </p>
          <div class="onboarding__step__actions">
            <RButton
              type="flat"
              @click="getOnboardingData.actions.skip.onClick"
              >
              {{ i18n.t(getOnboardingData.actions.skip?.label) }}
            </RButton>
            <div class="onboarding__step__actions-buttons">
              <RButton
                v-if="getOnboardingData.actions.back"
                type="secondary"
                @click="getOnboardingData.actions.back?.onClick"
                >
                 {{ i18n.t(getOnboardingData.actions.back?.label) }}
              </RButton>
              <RButton
                v-if="getOnboardingData.actions.next"
                type="primary"
                afterIcon="ri-arrow-right-line"
                @click="getOnboardingData.actions.next.onClick"
                >
                {{ i18n.t(getOnboardingData.actions.next.label) }}
              </RButton>
            </div>
          </div>
        </template>
      </OnboardingOverlay>
</template>
<script>
import {
  watch, shallowRef, defineAsyncComponent, computed,
} from 'vue';
import { useRouter } from 'vue-router';
import { appStore as useAppStore } from '@/store/app';
import OnboardingOverlay from '@/components/Onboarding/OnboardingOverlay.vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useI18n } from 'vue-i18n';
import RButton from './components/RButton.vue';
// eslint-disable-next-line import/no-cycle
import { setOnboardingStep, onboardingStepsMap } from './util/onboardingControl';

export default {
  name: 'App',
  components: {
    OnboardingOverlay,
    RButton,
  },
  setup() {
    const router = useRouter();
    const layout = shallowRef();
    const appStore = useAppStore();
    const isOnboardingActive = computed(() => appStore.getOnboarding);
    const getOnboardingData = computed(() => appStore.getOnboardingStep);
    const i18n = useI18n();
    watch(isOnboardingActive, (value) => {
      if (value) {
        router.push({ name: 'Connect Live' }).then(() => {
          setOnboardingStep(onboardingStepsMap.onboardingConnectLive);
        });
      }
    });

    if (localStorage.getItem('production') === 'false') {
      appStore.setState(false);
    }

    watch(
      () => router.currentRoute.value.name,
      () => {
        if (router.currentRoute.value.meta.layout) {
          layout.value = defineAsyncComponent(() => import(`@/layouts/${router.currentRoute.value.meta.layout}.vue`));
        }
        document.title = `Rollee Dashboard - ${router.currentRoute.value.name}`;
        window.analytics?.page(`Visited ${router.currentRoute.value.name}`);
      },
      { immediate: true },
    );

    return {
      layout,
      OnboardingOverlay,
      RButton,
      isOnboardingActive,
      getOnboardingData,
      i18n,
    };
  },
};
</script>
