<template>
  <div class="modal__background">
    <div class="modal__body">
      <RButton
      @click="onClose"
      class="modal--close"
      type="flat"
      beforeIcon="ri-close-line">
        Close
      </RButton>
      <div>
        <CheckCircleIcon/>
      </div>
      <h3>Account successfully connected</h3>
      <p>You successfully connected fleet account.
        Once we finish collecting the data will appear in the Users tab.
      </p>
      <RButton @click="onConnectOtherFleetAccount">Connect other fleet account</RButton>
    </div>
  </div>
</template>
<script setup>
import RButton from '@/components/RButton.vue';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';

const emits = defineEmits(['close', 'connectOtherFleetAccount']);

const onClose = () => {
  emits('close');
};

const onConnectOtherFleetAccount = () => {
  emits('connectOtherFleetAccount');
};
</script>

<style scoped lang="scss">
.modal{
  &__background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-left: 111px;
  }
  &__body{
    background-color: white;
    padding: 8px 28px 28px 28px;
    border-radius: 10px;
    box-sizing: border-box;
    width: 437px;
    height: 360px;
    margin-top: 120px;
    display: grid;
    grid-template-rows: 24px 1fr 48px;
    align-items: center;

    h3{
      color: var(--color-black, #141414);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
    p {
      color: var(--color-grey-dark);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 28px;
    }
  }
  &--close{
    justify-self: flex-end;
    text-align: end;
    padding: 0;
    height: 16px;
  }
}
</style>
