<template>
  <div class="sections">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Trips</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div class="sections__content">
      <div class="sections__image sections__image--small">
        <img :src="platform.logo" alt="">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Company</h3>
          <h1>{{ platform.name }}</h1>
        </div>
      </div>
    </div>
    <div class="sections__content"
    :class="{'sections__content--no-border': index == 0}"
    v-for="(item, index) in data" :key="item.id">
      <div class="sections__image sections__image--small">
        <div class="sections__rowno">
          <p>{{ (index + 1) }}</p>
        </div>
      </div>
      <div class="sections__data">
        <div class="sections__table">
          <div class="sections__table-part">
            <table>
              <tr v-if="item.payment_type">
                <td>Payment</td>
                <td>{{ item.payment_type }}</td>
              </tr>
              <tr v-if="item.fare">
                <td>Fare</td>
                <td>{{ numberFormat(item.fare) }} {{ item.currency }}</td>
              </tr>
              <tr v-if="item.fees">
                <td>Fees</td>
                <td>{{ numberFormat(item.fees) }} {{ item.currency }}</td>
              </tr>
              <tr v-if="item.bonus">
                <td>Bonus</td>
                <td>
                  {{ numberFormat(item.bonus) }} {{ item.currency }}
                </td>
              </tr>
              <tr v-if="item.other">
                <td>Other</td>
                <td>
                  {{ numberFormat(item.other) }} {{ item.currency }}
                </td>
              </tr>
              <tr v-if="item?.number_of_orders">
                <td>Number of Orders</td>
                <td>{{ item.number_of_orders }}</td>
              </tr>
              <tr></tr>
              <tr></tr>
            </table>
          </div>
          <div class="sections__table-part">
            <table>
              <tr v-if="item.duration">
                <td>Duration</td>
                <td>{{ item.duration }}</td>
              </tr>
              <tr v-if="item.distance">
                <td>Distance</td>
                <td>{{ item.distance }} {{ item.distance_measure }}</td>
              </tr>
              <!-- <tr>
                <td>Start Location</td>
                <td>Drancy, France</td>
              </tr>
              <tr>
                <td>End Location</td>
                <td>
                  Paris, France
                </td>
              </tr> -->
              <tr v-if="item.trip_type">
                <td>Type</td>
                <td>
                  {{ item.trip_type }}
                </td>
              </tr>
              <tr v-if="item.trip_ts">
                <td>Start Date</td>
                <td>
                  {{ item.trip_ts }}
                </td>
              </tr>
              <tr v-if="item.trip_status">
                <td>Trip Status</td>
                <td>
                  {{ item.trip_status }}
                </td>
              </tr>
              <tr></tr>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="section__action" v-if="showLoadMore">
      <RButton type="flat" @click="loadMore">
        <i class="ri-loader-2-line section__action-loader"></i>
          Load more
        </RButton>
    </div>
  </div>
</template>
<script setup>
import {
  computed, ref, defineProps, defineEmits,
  onUnmounted,
  watch,
} from 'vue';
import { numberFormat } from '@/util/helpers';
import RComboButton from '@/components/RComboButton.vue';
import RButton from '@/components/RButton.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const platform = computed(() => props.platform);

const emits = defineEmits(['download']);

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'trips' });
};

const data = ref([]);
const loadMore = () => {
  const moreData = props.data.slice(data.value.length, data.value.length + 50);
  data.value = data.value.concat(moreData);
};

watch(() => props.data, () => {
  if (props.data === 0) {
    return;
  }
  data.value = props.data.slice(0, 50);
}, { immediate: true });

// check data length and show load more button
const showLoadMore = computed(() => data.value.length < props.data.length);

onUnmounted(() => {
  data.value = [];
});
</script>
<style lang="scss" scoped>
.section{
  &__action{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;

    &-loader {
      display: flex;
      align-items: center;
      justify-content: center;
      user-select: none;
      border-radius: 1000px;
      height: 20px;
      width: 20px;
      max-height: 20px;
      max-width: 20px;
      font-size: 20px;
      animation: rotate 1s ease-in-out infinite;
      margin: 0 8px 4px 0;

    }
  }
}

:deep{
  & .button{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
</style>
