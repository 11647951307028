<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4763_43310)">
            <!-- eslint-disable-next-line max-len -->
            <path d="M8.00004 14.6666C4.31804 14.6666 1.33337 11.682 1.33337 7.99998C1.33337 4.31798 4.31804 1.33331 8.00004 1.33331C11.682 1.33331 14.6667 4.31798 14.6667 7.99998C14.6667 11.682 11.682 14.6666 8.00004 14.6666ZM7.33337 7.33331V11.3333H8.66671V7.33331H7.33337ZM7.33337 4.66665V5.99998H8.66671V4.66665H7.33337Z" fill="#A8AFBC"/>
        </g>
        <defs>
            <clipPath id="clip0_4763_43310">
                <rect width="16" height="16" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
