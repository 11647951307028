import { defineStore } from 'pinia';
import { appStore } from '@/store/app';
import Insights from '../api/insights';

// eslint-disable-next-line import/prefer-default-export
export const insightsStore = defineStore({
  id: 'insightsStore',
  state: () => ({
    metrics: undefined,
    plots: undefined,
    tables: undefined,
    currencySymbol: undefined,
    driverDetails: undefined,
    currentPerformance: undefined,
    historicalPerformance: undefined,
    driverJoinDate: undefined,
    driverName: undefined,
    driverConnectionStatus: undefined,
    isLoading: false,
    isSampleData: false,
  }),
  getters: {
    getDrivers: (state) => (state.tables?.driver_list
      ? state.tables.driver_list : []),
    getMetrics: (state) => (state.metrics ? state.metrics : undefined),
    getPlots: (state) => (state.plots ? state.plots : undefined),
    getTables: (state) => (state.tables ? state.tables : undefined),
    getCurrencySymbol: (state) => state.currencySymbol,
    getDriverDetails: (state) => state.driverDetails,
    getDriverCurrentPerformance: (state) => state.currentPerformance,
    getDriverHistoricalPerformance: (state) => state.historicalPerformance,
    getDriverJoinDate: (state) => state.driverJoinDate,
    getDriverName: (state) => state.driverName,
    getDriverConnectionStatus: (state) => (state.driverConnectionStatus ? 'Connected' : 'Disconnected'),
    getIsLoading: (state) => state.isLoading,
    getSampleData: (state) => state.isSampleData,
  },
  actions: {
    fetchOverview() {
      this.isLoading = true;
      this.tables = undefined;
      return new Promise((resolve) => {
        Insights.getOverview(this.isSampleData).then((data) => {
          this.metrics = data.metrics;
          this.plots = data.plots;
          this.tables = data.tables;
          // eslint-disable-next-line prefer-destructuring
          this.currencySymbol = new Intl.NumberFormat('en', { style: 'currency', currency: data.currency }).format(0)[0];
          resolve(data);
        }).catch((err) => {
          console.error(err);
          appStore().setNotification({
            type: 'error',
            message: 'Error while fetching Insights',
          });
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },
    fetchDriverDetails(id) {
      this.isLoading = true;
      return new Promise((resolve) => {
        Insights.getDriverDetails(id, this.isSampleData).then((data) => {
          // TODO Remove driver details from the store
          this.driverDetails = data;
          this.currentPerformance = data.current_performance;
          this.historicalPerformance = data.historical_performance;
          this.driverConnectionStatus = data.connection_status;
          this.driverJoinDate = data.join_date;
          // eslint-disable-next-line prefer-destructuring
          this.currencySymbol = new Intl.NumberFormat('en', { style: 'currency', currency: data.currency }).format(0)[0];
          resolve(data);
        }).catch((err) => {
          console.error(err);
          appStore().setNotification({
            type: 'error',
            message: 'Error while fetching the driver details',
          });
        }).finally(() => {
          this.isLoading = false;
        });
      });
    },
    setDriverName(name) {
      // Set driver name from id param to be displayed in the header
      this.driverName = name;
    },
    clearDriverOverview() {
      this.metrics = undefined;
      this.plots = undefined;
      this.tables = undefined;
      this.currencySymbol = undefined;
    },
    clearDriverDetails() {
      this.driverDetails = undefined;
      this.driverPerformanceMetrics = undefined;
      this.driverPerformancePlots = undefined;
      this.driverJoinDate = undefined;
      this.driverName = undefined;
      this.driverConnectionStatus = undefined;
    },
    setSampleData(value) {
      this.isSampleData = value;
    },
  },
});
