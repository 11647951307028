<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_7844_34726)">
      <!-- eslint-disable-next-line max-len -->
      <path d="M11.796 7.26456L10.7355 6.20406L3.75 13.1896V14.2501H4.8105L11.796 7.26456ZM12.8565 6.20406L13.917 5.14356L12.8565 4.08306L11.796 5.14356L12.8565 6.20406ZM5.4315 15.7501H2.25V12.5678L12.3263 2.49156C12.4669 2.35096 12.6576 2.27197 12.8565 2.27197C13.0554 2.27197 13.2461 2.35096 13.3868 2.49156L15.5085 4.61331C15.6491 4.75396 15.7281 4.94469 15.7281 5.14356C15.7281 5.34244 15.6491 5.53317 15.5085 5.67381L5.43225 15.7501H5.4315Z"
        fill="#A8AFBC" />
    </g>
    <defs>
      <clipPath id="clip0_7844_34726">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>

</template>
