export default {
  onboarding: {
    next: 'Next',
    back: 'Back',
    skip: 'Skip tour',
    start: 'Let’s start',
    step: {
      connectLive: {
        name: 'CONNECT LIVE',
        title: 'Fleet Connect allows you to manually connect sample and real fleet account(s)',
        description: `Use Fleet Connect to access your fleet data directly from ride-hailing
        platforms and start making better decisions with an access to drivers activity,
        income, profile, real-time earnings, bonuses, taxes, fees and work history.`,
      },
      connect: {
        name: 'FLEET CONNECT',
        title: 'Connect fleet account with a few clicks',
        description: `In order to access your drivers’ income, activity, profile, real-time earnings, work history,
        trips, bonuses, fees and more connect your fleet account through Fleet Connect.`,
      },
      connectLoading: {
        name: 'FLEET CONNECT',
        title: 'Your Fleet Account is Connecting',
        description: `Your Fleet Account is currently being linked. It may take some time for the connection to finalize.
          Once the data is prepared, you will find it displayed in the Users section of the data view.
          Feel free to continue with the rest of the tour while we complete the data collection process. `,
      },
      drivers: {
        name: 'CONNECTED DRIVERS',
        title: 'Once a successful connection of a fleet account is established, all data of your drivers will appear in Users tab.',
        description: 'In order to see your driver’s detailed data click on a specific driver in a table grid view.',
      },
      driversFilter: {
        name: 'USERS VIEW SETTINGS',
        title: 'Profile, income, employment, work history details and much more...',
        description: 'In user’s details view you can see all detailed endpoints, pick a date range, download CSV of each endpoint.',
      },
      insights: {
        name: 'DRIVER INSIGHTS',
        title: 'Aggregate your drivers’ data incoming from multiple sources in seconds.',
        description: 'Understand your drivers’ overall performance through standardized reports of their earnings, activity and more across all ride-sharing apps.',
      },
      insightsNavigation: {
        name: 'OVERVIEW OF FLEET AND DRIVERS',
        title: 'Experience and test the dashboard with a sample data set',
        description: 'Visualize your drivers’ overall performance through standardized reports of their earnings, activity and more across all ride-sharing apps.',
      },
      insightsSampleData: {
        name: 'SAMPLE DASHBOARD',
        title: 'Experience and test the dashboard with a sample data set',
        description: 'Visualize your drivers’ overall performance through standardized reports of their earnings, activity and more across all ride-sharing apps.',
      },
      insightsMetrics: {
        name: 'FLEET METRICS',
        title: 'Once a successful connection of a fleet account is established, all data will appear in Users tab.',
        description: 'In order to see your fleet detailed data click on a specific fleet account in a table grid view.',
      },
    },
  },
};
