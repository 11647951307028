<template>
    <div class="container" v-if="getEmailCustomization">
      <div class="card">
        <div class="header">
          <img
            v-if="getEmailCustomization.cover_image"
            :src="getEmailCustomization.cover_image"
            :alt="getEmailCustomization.cover_image" />
          <img v-else src="../../assets/template-cover-header.png" alt="Cover image">
          <h1>
            {{getEmailCustomization.subject}}
          </h1>
        </div>
        <div class="content">
          <div class="partner">
            <div class="icon">
              <span>
                {{
                  getEmailCustomization.partnerName &&
                  getEmailCustomization.partnerName.charAt(0)
                  .toUpperCase()
                  .concat(getEmailCustomization.partnerName.charAt(1).toUpperCase())
                }}
              </span>
            </div>
            <div class="partner-content">
              <p>{{getEmailCustomization.partnerName}}</p>
              <span>{{getEmailCustomization.email}}</span>
            </div>
          </div>
          <h2>{{getEmailCustomization.title}}</h2>
          <p>
            {{getEmailCustomization.message}}
          </p>
            <a class="button"
               :style="{
            'background-color':getEmailCustomization.button_color,
             color: getEmailCustomization.button_text_color
             } ">{{getEmailCustomization.button_text}}</a>
        </div>
    </div>
    </div>
</template>

<script setup>
import { settingsStore } from '@/store/settings';
import { profileStore } from '@/store/profile';
import { computed } from 'vue';

const profile = profileStore();
const profileData = profile.getProfile;

const store = settingsStore();

const getEmailCustomization = computed(() => ({
  subject: store.getEmailCustomization?.subject || store.getPlaceholders?.subject,
  title: store.getEmailCustomization?.title || store.getPlaceholders?.title,
  cover_image: store.getEmailCustomization?.cover_image || store.getPlaceholders?.cover_image,
  message: store.getEmailCustomization?.message || store.getPlaceholders?.message,
  email: store.getEmailCustomization?.email || profileData.email,
  partnerName: store.getEmailCustomization?.partnerName || profileData.partnerName,
  button_text: store.getEmailCustomization?.button_text || store.getPlaceholders?.button_text,
  button_color: store.getEmailCustomization?.button_color || store.getPlaceholders?.button_color,
  button_text_color: store.getEmailCustomization?.button_text_color
    || store.getPlaceholders?.button_text_color,
}));

</script>

<style scoped lang="scss">
  .container {
    justify-self: center;
    align-self: start;
  }
  $color_1: rgb(0, 97, 255);
$color_2: #141414;
$color_3: #7A808B;
$color_4: #A8AFBC;
$color_5: #fff;
$color_6: #FFFFFF;
.container {
  width: 100%;
  max-width: 600px;
  margin: 32px auto;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #EDF2F8;
  box-sizing: border-box;
}

h1 {
  >span {
    color: $color_1;
  }
}

h2 {
  color: $color_2;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  margin-bottom: 20px;
}

p {
  color: $color_3;
  line-height: 160%;
  font-size: 15px;
  margin-bottom: 40px;
  font-weight: 400;

  b {
    color: $color_2;
  }
}

p.small {
  color: $color_4;
  font-size: 12px;
  line-height: 19px;
  text-align: center;
  padding-top: 20px;
  margin: 0;
}

.header {
  border-bottom: 1px solid #EDF2F8;

  img {
    height: 160px;
    width: 598px;
  }

  h1 {
    color: $color_2;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 20px 0 20px 24px;
  }
}

a.button {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 17px 20px;
  color: $color_5;
  border-radius: 8px;
  border: none;
  text-decoration: none;
  background: #141414;
}

.card {
  border-bottom: 1px solid #EDF2F8;

  p {
    color: $color_2;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
  }

  .hero {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .signature {
    margin-top: 22px;
    margin-bottom: 0px;
  }
}

.content {
  padding: 32px 24px;
}

.partner {
  margin-bottom: 32px;

  div {
    display: inline-block;
  }

  .icon {
    height: 36px;
    width: 36px;
    border-radius: 999px;
    background: #A8AFBC;
    color: $color_6;
    margin-right: 12px;
  }
}

.partner-content {
  p {
    margin-bottom: 0px;
    color: $color_2;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }

  span {
    color: $color_3;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
}

.icon {
  span {
    line-height: 36px;
    display: block;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 1.2px;
    padding-top: 2px;
  }
}

.footer {
  width: 100%;
  max-width: 600px;
  margin: 28px auto;
  text-align: center;

  img {
    height: 36px;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    color: $color_4;
    margin-bottom: 20px;
  }

  a {
    color: $color_4;
    text-decoration: none;
  }
}

.social {
  img {
    height: 28px;
    margin-left: 8px;
    margin-right: 8px;
  }
}

.partner {
  display: flex;
}

.header img {
  object-fit: cover;
}

</style>
