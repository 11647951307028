<template>
  <div class="tooltip-container">
    <div
      @mouseover="handleMouseOver"
      @focus="handleMouseOver"
      ref="container"
      @blur="showTooltip = false"
      @mouseleave="showTooltip = false">
      <slot>
        <tooltip-outline-icon v-if="props.outline" ref="icon"></tooltip-outline-icon>
        <tooltip-icon v-else ref="icon"></tooltip-icon>
      </slot>
    </div>
    <transition name="fade">
      <Teleport to="body">
        <div class="tooltip" v-if="showTooltip" ref="tooltip">
          <slot name="tooltip-text"></slot>
        </div>
      </Teleport>
    </transition>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import TooltipIcon from '@/components/icons/TooltipIcon.vue';
import TooltipOutlineIcon from '@/components/icons/TooltipOutlineIcon.vue';

const showTooltip = ref(false);

const container = ref(null);
const tooltip = ref(null);

const props = defineProps({
  outline: {
    type: Boolean,
    default: false,
  },
});

const handleMouseOver = () => {
  showTooltip.value = true;
  setTimeout(() => {
    const containerRect = container.value.getBoundingClientRect();
    const tooltipRect = tooltip.value?.getBoundingClientRect();
    if (!tooltipRect) return;
    const tooltipHeight = tooltipRect?.height;
    // const containerWidth = containerRect.width;
    // const containerHeight = containerRect.height;
    const containerLeft = containerRect.left;
    const containerTop = containerRect.top;
    const tooltipLeft = containerLeft;
    const tooltipTop = containerTop - tooltipHeight - 8;
    tooltip.value.style.left = `${tooltipLeft}px`;
    tooltip.value.style.top = `${tooltipTop}px`;
  }, 100);
};

</script>

<style scoped>
.tooltip-container {
  /* position: relative; */
  display: inline-block;
  height: 16px;
  cursor: pointer;
}

.tooltip {
  position: fixed;
  min-width: 64px;
  max-width: 320px;
  width: max-content;
  min-height: 18px;
  height: max-content;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 4px;
  background: var(--color-dark-main, #253858);
  color: white;
  padding: 4px 8px;
  border-radius: 3px;
  color: var(--color-white, #FFF);
  font-size: 12px;
  line-height: 160%;
  /* white-space: nowrap; */
  font-weight: 500;
  z-index: 9999;
}
</style>
