<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div class="card__head">
        <div class="card__head-icon">
            <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
            <HandIcon/>
        </div>
        <h5>Summary</h5>
        <RComboButton
            :text="label">
            <li @click="dropdownAction('lastWeek')"
                :class="{active: selected === 'lastWeek'}">
                Last Week
                <CheckMarkIcon v-if="selected === 'lastWeek'"/>
            </li>
            <li @click="dropdownAction('thisWeek')"
                :class="{active: selected === 'thisWeek'}"
                >
                This Week
                <CheckMarkIcon v-if="selected === 'thisWeek'"/>
            </li>
        </RComboButton>
    </div>
    <div class="card__content">
        <div class="summary__data-section">
            <div class="summary__data-item">
                <h3>Max. daily earnings</h3>
                <p>{{ numberFormat(summary.maxDailyIncome)}} {{ props.currency }}</p>
            </div>
            <div class="summary__data-item">
                <h3>Min. daily earnings</h3>
                <p>{{ numberFormat(summary.minDailyIncome)}} {{ props.currency }}</p>
            </div>
            <div class="summary__data-item">
                <h3>Days without earnings </h3>
                <p>{{ nullToDash(summary.daysWihooutEarning)}}</p>
            </div>
        </div>
    </div>
</template>

<script setup>
import { nullToDash, numberFormat } from '@/util/helpers';
import { ref, watch } from 'vue';
import RComboButton from '@/components/RComboButton.vue';
import HandIcon from './icons/HandIcon.vue';
import CheckMarkIcon from './icons/CheckMarkIcon.vue';

const props = defineProps(['data', 'currency']);

const selected = ref('thisWeek');
const label = ref('Last Week');

const dropdownAction = (week) => {
  selected.value = week;
};

const summary = ref({
  maxDailyIncome: null,
  minDailyIncome: null,
  daysWihooutEarning: null,
});
watch(selected, (val) => {
  if (val === 'thisWeek') {
    label.value = 'This Week';
    summary.value.maxDailyIncome = props.data.this_week_max_daily_income;
    summary.value.minDailyIncome = props.data.this_week_min_daily_income;
    summary.value.daysWihooutEarning = props.data.this_week_days_without_earning;
    return;
  }
  label.value = 'Last Week';
  summary.value.maxDailyIncome = props.data.last_week_max_daily_income;
  summary.value.minDailyIncome = props.data.last_week_min_daily_income;
  summary.value.daysWihooutEarning = props.data.last_week_days_without_earning;
}, {
  immediate: true,
});
</script>
<style scoped>

h3{
    color: var(--color-grey-dark, #7A808B);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}
.card__head{
    gap: 15px;
}

.card__content{
    margin-top: 28px;
}
.summary__data-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    justify-content: space-between;
    padding-bottom: 24px;
}

.summary__data{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    justify-content: space-between;
    margin-top: 24px;
}

.summary__data-item p{
    color: var(--color-dark-main);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    margin-top: 10px;
}

.summary__graphs{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.summary__graphs-item h3{
    margin-bottom: 10px;
}
</style>
