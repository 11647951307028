<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
    <div v-if="!isLoading" class="data__wrapper">
        <div class="data__form">
            <div class="data__form-section">
                <h3>Updates Frequency
                  <span class="data__form-history">{{ fetchHistory }} of history</span>
                </h3>
                <p>Choose how frequently you want to trigger a data update</p>
                <div class="data__form-input">
                    <RComboOutlinedbutton
                    label="Frequency"
                    v-model="robotsSettings.frequency"
                    :disabled="partnerType === 'Fleet'"
                    :leftIcon="'ri-calendar-line'"
                    :items="updateFrequncyItems">
                    </RComboOutlinedbutton>
                </div>
                <template v-if="robotsSettings.frequency === 'weekly'">
                  <div class="data__form-input">
                      <RComboOutlinedbutton
                        :leftIcon="'ri-calendar-line'"
                        label="Frequency Starts on"
                        v-model="robotsSettings.day_of_week"
                        :items="dailyUpdateFrequncyItems">
                      </RComboOutlinedbutton>
                  </div>
                </template>
                <template v-if="robotsSettings.frequency === 'monthly'">
                  <div class="data__form-input">
                      <RComboOutlinedbutton
                        :leftIcon="'ri-calendar-line'"
                        label="Frequency Starts on"
                        v-model="robotsSettings.day_of_month"
                        :items="monthleUpdateFrequncyItems">
                      </RComboOutlinedbutton>
                  </div>
                </template>
            </div>
            <div class="data__form-section">
                <h3>Endpoints</h3>
                <p>
                  List of endpoints which will be updated during next fetch
                </p>
                <div class="data__form-list">
                    <div
                    class="data__form-item"
                    :key="robot.id"
                    v-for="robot in activeEndpoints">
                      <p> {{ mapEnpointName(robot.name) }}</p>
                        <r-tool-tip
                        :outline="true"
                        v-if="robot.datapoints_definitions"
                        class="tool-tip">
                              <template #tooltip-text>
                                    <span
                                    :key="index"
                                    v-for="(tooltip, index) in
                                    mapTooltipKeys(robot.datapoints_definitions)"
                                    >
                                      {{tooltip}},
                                    </span>
                              </template>
                            </r-tool-tip>
                    </div>
                </div>
            </div>
            <div class="data__form-action">
                <RButton :disabled="partnerType === 'Fleet'" @click="onUpdate">Update</RButton>
            </div>
        </div>
    </div>

    <RLoading v-else/>
  </template>

<script setup>
import {
  computed,
  onUnmounted,
  onMounted,
  ref,
  reactive,
  watch,
} from 'vue';
import { appStore } from '@/store/app';
import { settingsStore } from '@/store/settings';
import { profileStore } from '@/store/profile';
import RComboOutlinedbutton from '@/components/RComboOutlinedbutton.vue';
import RButton from '@/components/RButton.vue';
import RToolTip from '@/components/RToolTip.vue';
import RLoading from '@/components/RLoading.vue';

const store = settingsStore();
const profile = profileStore();
const availableDataEndpoints = computed(() => store.getDataUpdateAvailableEnpoints);
// Set default settings for Robots
const robotsSettings = computed(() => store.getDataUpdateRobots);
const partnerType = computed(() => profile.getProfile.partnerType);
const fetchHistory = computed(() => store.getFirstConnectionDataHistory);
const isLoading = ref(false);

const activeEndpoints = reactive([]);
const updateFrequncyItems = [
  {
    value: 'daily',
    text: 'Daily',
  },
  {
    value: 'weekly',
    text: 'Weekly',
  },
  {
    value: 'monthly',
    text: 'Monthly',
  },
];

const dailyUpdateFrequncyItems = [
  {
    value: 1,
    text: 'Monday',
  },
  {
    value: 2,
    text: 'Tuesday',
  },
  {
    value: 3,
    text: 'Wednesday',
  },
  {
    value: 4,
    text: 'Thursday',
  },
  {
    value: 5,
    text: 'Friday',
  },
  {
    value: 6,
    text: 'Saturday',
  },
  {
    value: 0,
    text: 'Sunday',
  },
];

const monthleUpdateFrequncyItems = Array(28).fill(undefined).map((_, i) => ({ value: i + 1, text: `${i + 1} day in the Month` }));

const onUpdate = () => {
  store.postRobotsSettings()
    .then(() => {
      appStore().setNotification({
        type: 'success',
        message: 'Your preferences have been updated',
      });
      window.analytics?.track('update_settings');
    }).catch(() => {
      appStore().setNotification({
        type: 'error',
        message: 'Error while updating your Data Update settings',
      });
    }).finally(() => {
      store.fetchRobotsList();
    });
};

const mapEnpointName = (name) => {
  switch (name) {
    case 'banking_info':
      return 'Banking';
    default:
      return name;
  }
};

// watcher changes on robotsSettings frequency

watch(() => robotsSettings.value.frequency, (newValue) => {
  window.analytics?.track('update_frequency', { frequency: newValue });
});

const mapTooltipKeys = (tooltip) => Object.keys(tooltip);

const filterAvailableEndpoints = () => {
  // Get list of endpoints that are in robots settings from /documentation/endpoints
  availableDataEndpoints.value.forEach((endpoint) => {
    // Check if endpoint is in robots settings for that Partner
    if (robotsSettings.value.endpoints.includes(endpoint.name)) {
      activeEndpoints.push({ ...endpoint });
    }
  });
};

onMounted(() => {
  isLoading.value = true;
  Promise.all([
    store.fetchEnpointsList(),
    store.fetchRobotsList(),
  ]).then(() => {
    filterAvailableEndpoints();
    isLoading.value = false;
  });
});

onUnmounted(() => {
  store.resetRobotsSettings();
});
</script>

<style scoped lang="scss">
    .data{
        &__wrapper{
            display: grid;
            grid-template-columns: minmax(450px,40%) auto;
            padding: 32px 30px 0 30px;
            height: calc(100% - 13px);
            overflow-y: auto;

            h3{
                color: var(--color-black);
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 29px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
            p {
                color: var(--color-grey-dark);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px
            }
        }

        &__form{
            display: flex;
            flex-direction: column;
            padding-bottom: 30px;
            &-input{
                margin-top: 20px;
            }
            &-section:first-of-type{
                margin-bottom: 29px;
            }
            &-list{
                margin-top: 20px;
                display: grid;
                grid-template-columns: repeat(2,1fr);
                row-gap: 16px;
                column-gap: 75px;
                label{
                    text-transform: capitalize;
                }

            }
            &-item{
              display: flex;
              align-items: center;
              p{
                color: var(--color-dark-main);
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                text-transform: capitalize;
              }
            }
            &-action{
                align-self: flex-end;
                margin-top: 40px;
            }

            &-history{
              color: var(--color-grey-light);
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
            }
        }
      }
  .tool-tip{
    display: flex;
    margin-left: auto;
    height: 20px;
    :deep svg{
      height: 20px;
      width: 20px;
    }
  }
</style>
