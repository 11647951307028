<template>
  <OnClickOutside @trigger="openMenu = false" :options="{ ignore: ['.dropdown__list'] }">
    <div class="dropdown__wrapper">
      <div
        v-if="!props.company" ref="dropdownRef" class="dropdown"
        @click="openMenu = !openMenu" @keyDown="openMenu = !openMenu">
        <p>{{ props.title }}</p>
        <div class="dropdown__icon">
          <ArrowIcon />
        </div>
      </div>
      <div v-else @click="openMenu = !openMenu" @keyDown="openMenu = !openMenu">
        <ArrowIcon />
      </div>
      <div ref="dropdownList" class="dropdown__list" :class="{
        'dropdown__list--hidden': !openMenu,
      }">
        <ul>
          <li v-for="item in props.items"
            :key="item.title" @click="callAction(item)" @keydown="callAction(item)">
            <i v-if="item.icon" :class="item.icon"></i>
            {{ item.title }}
          </li>
        </ul>
      </div>
    </div>
  </OnClickOutside>
</template>
<script setup>
import { ref, watch } from 'vue';
import {
  ArrowIcon,
} from '@/components/icons';
import { OnClickOutside } from '@vueuse/components';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  items: {
    type: Array,
    required: true,
  },
  value: {
    type: String,
    required: false,
  },
  company: {
    type: Boolean,
    required: false,
  },
});

const emit = defineEmits(['action']);

const openMenu = ref(false);
const dropdownRef = ref(null);
const dropdownList = ref(null);
const callAction = (item) => {
  emit('action', item, props.value);
  openMenu.value = false;
};
// Check if dropdown with the dropdown__list class should be opened top or bottom
const checkPosition = () => {
  const body = document.querySelector('.ag-layout-normal');
  if (!body) {
    return;
  }
  const position = body.getBoundingClientRect().height
  - dropdownRef.value.getBoundingClientRect().top;
  if (position < 0) {
    dropdownList.value.style.top = 'auto';
    dropdownList.value.style.bottom = '100%';
  }
};

watch(openMenu, (val) => {
  if (!val) {
    return;
  }
  checkPosition();
});
</script>
<style lang="scss" scoped>
.dropdown {
  width: fit-content;
  display: grid;
  padding: 4px 12px;
  grid-template-columns: auto 8px;
  cursor: pointer;
  background: var(--color-grey-outline);
  border-radius: 4px;
  &__wrapper {
    position: relative;
  }
  p {
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    margin-right: 12px;
    color: var(--color-blue-main);
    user-select: none;
  }
  &__icon {
    transform: translateY(1px);
  }
  :deep svg path {
    fill: var(--color-blue-main);
  }
  &__list {
    &--hidden {
      display: none;
    }
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    width: 100%;
    max-width: 200px;
    min-width: 120px;
    background: #fff;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    z-index: 1;
    padding: 8px;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        display: grid;
        grid-template-columns: 24px auto;
        gap: 12px;
        padding: 12px 16px;
        font-size: 15px;
        line-height: 15px;
        font-weight: 600;
        color: var(--color-grey-dark);
        cursor: pointer;
        user-select: none;
        border-radius: 8px;
        &:hover {
          background: var(--color-blue-background);
          color: var(--color-blue-main);
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}
</style>
