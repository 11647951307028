<template>
  <div class="card__head">
        <div class="card__head-icon">
            <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
            <CoinIcon/>
        </div>
        <h5>Earnings</h5>
        <p class="card__head-info">Last 90 days</p>
    </div>
    <div class="card__content">
          <r-tab ref="tabRef">
                <template #tab-titles>
                    <div
                    @click="setActiveTabIndex(0)"
                    class="tab"
                    @keydown.enter="setActiveTabIndex(0)"
                    @keydown.space="setActiveTabIndex(0)">
                        <h6 class="tab__title">Total earnings</h6>
                        <span class="tab__value">
                          {{ numberFormat(props.data?.total_earning_last_3mth) }}
                          <i>{{ props.currency }}</i>
                        </span>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(1)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(1)"
                    @click="setActiveTabIndex(1)">
                        <h6 class="tab__title">Tips collected</h6>
                        <span class="tab__value">
                          {{ numberFormat(props?.data?.total_tips_last_3mth) }}
                          <i>{{ props.currency }}</i>
                        </span>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(2)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(2)"
                    @click="setActiveTabIndex(2)">
                        <h6 class="tab__title">Cash collected</h6>
                        <span class="tab__value">
                          {{ numberFormat(props?.data?.total_cash_last_3mth) }}
                          <i>{{ props.currency }}</i>
                        </span>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(3)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(3)"
                    @click="setActiveTabIndex(3)">
                        <h6 class="tab__title">% of cash vs. total earnings</h6>
                        <span class="tab__value">
                          {{percentile(props.data?.ratio_cash_over_total_earnings_last_3mth)}}
                          <i>%</i>
                        </span>
                    </div>
                </template>
                <template #tab-content-0>
                    <div class="graph__wrapper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="400"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
                <template #tab-content-1>
                  <div class="graph__wrapper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="400"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
                <template #tab-content-2>
                  <div class="graph__wrapper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="400"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
                <template #tab-content-3>
                  <div class="graph__wrapper">
                    <vue-apex-charts
                      v-if="chartSeries.length > 0"
                      :height="400"
                      :options="options"
                      :series="chartSeries">
                    </vue-apex-charts>
                    <NoDataAvailable v-else/>
                  </div>
                </template>
            </r-tab>
      </div>
</template>
<script setup>
import CoinIcon from '@/components/Insights/icons/CoinIcon.vue';
import { percentile, numberFormat, formatDate } from '@/util/helpers';
import RTab from '@/components/RTab.vue';
import { onBeforeMount, ref } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['currency', 'data', 'graps']);
const tabRef = ref(0);

const chartSeries = ref([]);
const options = ref({});

const barGraphOptions = {
  chart: {
    id: 'basic-line-chart',
    width: '100%',
    height: 345,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      hideOverlappingLabels: true,
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        return formatDate(val);
      },
    },
    title: {
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        if (val !== Number.POSITIVE_INFINITY) {
          return `${numberFormat(val)} ${props.currency}`;
        }
        return undefined;
      },
    },
    title: {
      text: 'Weekly earnings',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 600,
      },
    },
  },
  colors: ['#0061FF', '#C5CEE0'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};

const stackedBarGraphOptions = {
  chart: {
    id: 'basic-line-chart',
    width: '100%',
    height: 300,
    type: 'bar',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      hideOverlappingLabels: true,
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        return formatDate(val);
      },
    },
    title: {
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        if (val !== Number.POSITIVE_INFINITY) {
          return `${numberFormat(val)} ${props.currency}`;
        }
        return undefined;
      },
    },
    title: {
      text: 'Weekly earnings',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 600,
      },
    },
  },
  colors: ['#0061FF', '#C5CEE0'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};
const setOptions = (tabIndex) => {
  switch (tabIndex) {
    // Total earnings
    case 0:
      options.value = barGraphOptions;
      chartSeries.value = props.graps?.plot_net_and_gross_earning_history || [];
      break;
    // Tips collected
    case 1:
      options.value = barGraphOptions;
      chartSeries.value = props.graps.plot_tips_history || [];
      break;
    // Cash collected
    case 2:
      options.value = barGraphOptions;
      chartSeries.value = props.graps.plot_cash_history || [];
      break;
    // % of cash vs. total earnings
    case 3:
      options.value = stackedBarGraphOptions;
      options.value.colors = ['#0061FF', '#253858'];
      chartSeries.value = props.graps.plot_cash_ratio_history || [];
      break;
    default:
      options.value = barGraphOptions;
      chartSeries.value = [];
  }
};

const setActiveTabIndex = (index) => {
  tabRef.value.setActiveTab(index);
  setOptions(index);
};

onBeforeMount(() => {
  setOptions(0);
});
</script>
<style scoped lang="scss">
.card{
  &__content{
    transform: translateX(-32px);
    width: calc(100% + 32px);
  }
}

.tab{
  padding: 16px 32px;
  &-container{

    &__content{
      padding: 21px 26px;
    }
  }

  &__title{
    margin-bottom: 8px;
  }

  &__value{
    color: var(--color-grey-dark) !important;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    & i{
      color: var(--color-grey-light);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}

.graph{
  &__wrapper{
    margin-top: 30px;
    margin-left: 30px;
  }
}
</style>
