<template>
  <div class="sections">
    <div class="sections__header">
      <p class="sections__header-title">Employment</p>
    </div>
    <div class="sections__content" v-for="item in props.data" :key="item">
      <div class="sections__image sections__image--small">
        <!-- <img :src='require(`@/assets/uber_logo.png`)' alt="company logo"> -->
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Platform</h3>
          <h1>{{ item.organization_name }}</h1>
        </div>
        <div class="sections__table">
          <table>
            <tr v-if="item?.employment_type">
              <td>Employment Type</td>
              <td>{{ item.employment_type }}</td>
            </tr>
            <tr v-if="item?.start_date">
              <td>Start Date</td>
              <td>{{ item.start_date }}</td>
            </tr>
            <tr v-if="item?.end_date">
              <td>End Date</td>
              <td>{{ item.end_date }}</td>
            </tr>
            <tr v-if="item?.income">
              <td>Income</td>
              <td>{{ item.income }} {{ item?.currency }}</td>
            </tr>
            <tr v-if="item?.income_description">
              <td>Income Description</td>
              <td>{{ item.income_description }}</td>
            </tr>
            <tr v-if="item?.status">
              <td>Status</td>
              <td>{{ item.status }}</td>
            </tr>
            <tr v-if="item?.organization_address">
              <td>Organization Address</td>
              <td>{{ item.organization_address }}</td>
            </tr>
            <tr v-if="item?.organization_fiscal_id">
              <td>Organization Fiscal ID</td>
              <td>{{ item.organization_fiscal_id }}</td>
            </tr>
            <tr v-if="item?.duration_days">
              <td>Duration</td>
              <td>{{ formatDurationDays(item.duration_days) }}</td>
            </tr>

            <!-- <tr>
              <td>Status</td>
              <td class="success with--icon"> <i class="ri-checkbox-circle-line"></i> Active</td>
            </tr> -->
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const formatDurationDays = (days) => {
  if (days < 30) {
    return `${days} days`;
  } if (days < 365) {
    const months = Math.floor(days / 30);
    const remainingDays = days % 30;
    return `${months} month(s) ${remainingDays > 0 ? `${remainingDays} day(s)` : ''}`.trim();
  }
  const years = Math.floor(days / 365);
  const remainingMonths = Math.floor((days % 365) / 30);
  const remainingDays = (days % 365) % 30;
  return `${years} year(s) ${remainingMonths > 0 ? `${remainingMonths} month(s)` : ''} ${remainingDays > 0 ? `${remainingDays} day(s)` : ''}`.trim();
};
</script>
