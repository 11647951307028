<template>
    <svg :width="width" :height="height" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5240_47377)">
          <!-- eslint-disable-next-line max-len -->
            <path d="M6.53045 1.75C5.75725 1.75 5.13045 2.3768 5.13045 3.15V7.38447V23.17C5.13075 23.4666 5.24877 23.7509 5.4586 23.9605C5.66842 24.1702 5.95287 24.2879 6.24945 24.2879H24.2955C24.5915 24.2858 24.8747 24.1674 25.0841 23.9583C25.2935 23.7491 25.4122 23.466 25.4146 23.17V2.86788C25.4146 2.25034 24.9131 1.75 24.2955 1.75H6.53045ZM15.2725 12.4555C14.5253 12.4555 13.8087 12.1587 13.2804 11.6303C12.7521 11.102 12.4553 10.3854 12.4553 9.63826C12.4553 8.89108 12.7521 8.17451 13.2804 7.64618C13.8087 7.11784 14.5253 6.82103 15.2725 6.82103C16.0197 6.82103 16.7363 7.11784 17.2646 7.64618C17.7929 8.17451 18.0897 8.89108 18.0897 9.63826C18.0897 10.3854 17.7929 11.102 17.2646 11.6303C16.7363 12.1587 16.0197 12.4555 15.2725 12.4555ZM20.3131 18.6534H10.2319C10.3685 17.412 10.9584 16.2647 11.8885 15.4313C12.8187 14.5979 14.0236 14.1371 15.2725 14.1371C16.5214 14.1371 17.7263 14.5979 18.6565 15.4313C19.5866 16.2647 20.1765 17.412 20.3131 18.6534Z" fill="url(#paint0_linear_5240_47377)" />
            <!-- eslint-disable-next-line max-len -->
            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.625 5.30415C2.625 4.58618 3.20703 4.00415 3.925 4.00415H21.7901C22.4076 4.00415 22.9091 4.50449 22.9091 5.12203V25.4242C22.9067 25.7201 22.7881 26.0032 22.5787 26.2124C22.3693 26.4216 22.086 26.54 21.7901 26.542H3.74401C3.44742 26.542 3.16297 26.4243 2.95315 26.2147C2.74333 26.0051 2.6253 25.7207 2.625 25.4242V9.63862V5.30415Z" fill="url(#paint1_linear_5240_47377)" />
            <!-- eslint-disable-next-line max-len -->
            <path d="M12.6334 15.9476C13.137 15.9291 13.5135 16.3646 13.5135 16.8685V20.3892C13.5135 20.9414 13.0658 21.3892 12.5135 21.3892H8.38809C7.83584 21.3892 7.37885 20.9378 7.47965 20.3948C7.55917 19.9665 7.69002 19.5481 7.87022 19.1488C8.18802 18.4447 8.65201 17.8163 9.23137 17.3053C9.81074 16.7943 10.4922 16.4124 11.2305 16.1851C11.687 16.0445 12.1587 15.965 12.6334 15.9476ZM12.8329 15.2638C10.5768 15.2638 8.74937 13.4364 8.74937 11.1803C8.74937 8.92413 10.5768 7.09674 12.8329 7.09674C15.0891 7.09674 16.9165 8.92413 16.9165 11.1803C16.9165 13.4364 15.0891 15.2638 12.8329 15.2638ZM16.7756 19.9694L18.7003 18.0447C18.9661 17.779 19.3969 17.7789 19.6628 18.0446V18.0446C19.9288 18.3104 19.9289 18.7415 19.663 19.0074L16.9943 21.6761C16.8735 21.7969 16.6777 21.7969 16.5569 21.6761L14.8505 19.9698C14.5846 19.7039 14.5847 19.2727 14.8507 19.0069V19.0069C15.1166 18.7412 15.5474 18.7413 15.8132 19.0071L16.7756 19.9694Z" fill="url(#paint2_linear_5240_47377)" />
        </g>
        <defs>
          <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint0_linear_5240_47377" x1="51.0416" y1="32.9583" x2="53.1045" y2="1.28392" gradientUnits="userSpaceOnUse">
                <stop stop-color="#017BFE" />
                <stop offset="1" stop-color="#0061FF" stop-opacity="0.28" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint1_linear_5240_47377" x1="6.00004" y1="6" x2="22.0989" y2="26.7008" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="#0061FF" />
            </linearGradient>
            <!-- eslint-disable-next-line max-len -->
            <linearGradient id="paint2_linear_5240_47377" x1="20.3438" y1="21.8948" x2="10.0637" y2="9.27344" gradientUnits="userSpaceOnUse">
                <stop stop-color="#02B3FB" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
            <clipPath id="clip0_5240_47377">
                <rect width="28" height="28" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>
<script>
/* eslint-disable */
export default {
    props: {
      height: {
          default: '28',
        },
        width: {
          default: '28',
        },
    }
}
</script>