<template>
  <div class="card__head">
      <div class="card__head-icon">
          <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
          <DoubleChartIcon/>
      </div>
      <h5>Workload & working efficiency</h5>
      <p class="card__head-info">Last 90 days</p>
  </div>
  <div class="card__content">
    <div class="data__sections">
      <div class="data">
        <h6 class="data__title">Total tips</h6>
        <span class="data__value">
          {{ toFixedNumber(nullToDash(props.data?.total_tips_last_3mth), 0) }}
        </span>
      </div>
      <div class="data">
        <h6 class="data__title">Total time on trips</h6>
        <span class="data__value">
          {{ toFixedNumber(nullToDash(props.data?.time_on_trip_last_3mth), 0) }}
          <i>min</i>
        </span>
      </div>
      <div class="data">
        <h6 class="data__title">Total time online for Uber & Bolt</h6>
        <span class="data__value">
          {{ toFixedNumber(
            nullToDash(props.data?.time_online_last_3mth), 0
            )
          }}
          <i>min</i>
        </span>
      </div>
    </div>
    <div class="data__sections">
      <div class="graph__wrapper">
        <h6 class="data__title">Trips completed per platform</h6>
        <vue-apex-charts
          v-if="props.graps?.plot_num_of_trip_completed_per_platform_last_3mth?.values?.length > 0"
          :height="400"
          :options="mapDonutGraph(props.graps?.plot_num_of_trip_completed_per_platform_last_3mth)"
          :series="props.graps?.plot_num_of_trip_completed_per_platform_last_3mth
          .values">
        </vue-apex-charts>
        <NoDataAvailable v-else/>
      </div>
      <div class="graph__wrapper">
        <h6 class="data__title">Time on trips per platform</h6>
        <vue-apex-charts
          v-if="props.graps?.plot_time_on_trip_per_platform_last_3mth?.values?.length > 0"
          :height="400"
          :options="mapDonutGraph(props.graps?.plot_time_on_trip_per_platform_last_3mth)"
          :series="props.graps?.plot_time_on_trip_per_platform_last_3mth
          .values">
        </vue-apex-charts>
        <NoDataAvailable v-else/>
      </div>
      <div class="graph__wrapper">
        <h6 class="data__title">Time online per platform</h6>
        <vue-apex-charts
          v-if="props.graps?.plot_time_online_per_platform_last_3mth?.values"
          :height="400"
          :options="mapDonutGraph(props.graps?.plot_time_online_per_platform_last_3mth)"
          :series="props.graps?.plot_time_online_per_platform_last_3mth
          .values">
        </vue-apex-charts>
        <NoDataAvailable v-else/>
      </div>
    </div>
    <div class="data__sections">
      <div class="graph__wrapper">
        <h6 class="data__title">Trip slot time perference</h6>
        <vue-apex-charts
          v-if="props.graps?.plot_trip_slot_distribution_last_3mth?.values"
          :height="400"
          :options="mapDonutGraph(props.graps?.plot_trip_slot_distribution_last_3mth)"
          :series="props.graps?.plot_trip_slot_distribution_last_3mth
          .values">
        </vue-apex-charts>
        <NoDataAvailable v-else/>
      </div>
      <div class="graph__wrapper">
        <h6 class="data__title">Distance distribution</h6>
        <vue-apex-charts
          v-if="barGraphData.series[0].data?.length > 0"
          :height="400"
          :options="barGraphData.options"
          :series="barGraphData.series">
        </vue-apex-charts>
        <NoDataAvailable v-else/>
      </div>
    </div>
  </div>
</template>
<script setup>
import { nullToDash, toFixedNumber } from '@/util/helpers';
import VueApexCharts from 'vue3-apexcharts';
import { cloneDeep } from 'lodash';
import { onBeforeMount, ref } from 'vue';
import DoubleChartIcon from './icons/DoubleChartIcon.vue';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['currency', 'data', 'graps']);

const barGraphOptions = {
  chart: {
    id: 'basic-line-chart',
    width: 500,
    height: 500,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    title: {
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    title: {
      text: 'Number of Trips',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 600,
      },
    },
  },
  colors: ['#0061FF', '#FE9543'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};
const barGraphData = ref({
  options: barGraphOptions,
  series: [{
    name: 'Trips',
    data: [],
  }],
});

const donutOptions = {
  colors: ['#253858', '#F3C96B', '#DE6E6A', '#84BFDB', '#0061FF', '#FE9543'],
  chart: {
    width: 365,
    height: '100%',
    type: 'donut',
    offsetX: -35,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: '45%',
        background: 'transparent',
      },
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'left',
    floating: false,
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 45,
    labels: {

      colors: undefined,
      useSeriesColors: false,
    },
  },
};
const mapDonutGraph = (data) => {
  const options = cloneDeep(donutOptions);
  options.labels = data.labels;
  return options;
};

const mapBarGraph = (data) => {
  if (!data) return;
  const options = cloneDeep(barGraphOptions);
  options.labels = data?.labels;
  barGraphData.value.options = options;
  barGraphData.value.series[0].data = data?.values;
};
onBeforeMount(() => {
  mapBarGraph(props.graps?.plot_trip_distance_distribution_last_3mth);
});
</script>
<style scoped lang="scss">
.data{
  display: grid;
  row-gap: 12px;

  &__sections{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 24px 0 24px 0;
    border-bottom: 1px solid var(--color-grey-outline);
    box-shadow: 0px 8px 20px 0px rgba(20, 20, 20, 0.02);

    &:last-child{
      border-bottom: none;
      box-shadow: none;
      padding: 24px 0 0 0;
    }
  }
  &__title{
    color: var(--color-grey-dark);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  &__value{
    color: var(--color-dark-main);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    & i{
      color: var(--color-grey-light);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
</style>
