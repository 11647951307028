<template>
  <div class="combo__button-wrapper"
       :class="{open: menuOpen}"
       @click="menuOpen = !menuOpen" @keydown="menuOpen = !menuOpen">
    <div
      v-if="props.text"
      class="combo__button-text">
      <span class="combo__button-label" v-if="label"
      :class="{'outlined__lable': props.type === 'outlined'}"
      >{{ label }}</span>
      <span class="combo__button-value"
      :class="{outlined: props.type === 'outlined'}">
        {{ props.text }}
        <ArrowIcon/>
      </span>
    </div>
    <div v-if="props.icon" class="combo__button-icon" :class="{left: iconPosition === 'left'}">
      <component :is="iconName"></component>
    </div>
    <ArrowIcon v-if="props.icon" />
    <OnClickOutside
    @trigger="menuOpen = false"
    :options="{ ignore: ['.header__profile'] }"
    v-show="menuOpen"
        class="combo__button-menu">
        <ul class="combo__button-items">
          <slot/>
        </ul>
    </OnClickOutside>
  </div>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
import { ArrowIcon } from '@/components/icons';
import { defineAsyncComponent, ref } from 'vue';

const menuOpen = ref(false);

const props = defineProps({
  text: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: false,
  },
  icon: {
    type: String,
    required: false,
  },
  iconPosition: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
});

const iconName = defineAsyncComponent(() => import(`./icons/${props.icon}Icon.vue`));
</script>

<style scoped lang="scss">
.outlined{
  border: 1px solid var(--color-grey-light);
  color: var(--color-black);
  border-radius: 8px;
  padding: 15px 16px 13px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s;
  box-sizing: border-box;
  &:hover{
    border: 1px solid var(--color-blue-main)
  }

  &__lable{
    font-size: 14px;
    font-weight: 700;
    color: var(--color-dark-main);
    margin-right: 8px;
    margin-bottom: 4px;
  }
}
</style>
