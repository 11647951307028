<template>
  <div
    class="pill"
    :class="{
    'pill--active': props.active,
  }">
    <component :is="iconName" />
    <p>{{ props.title }}</p>
  </div>
</template>
<script setup>

import {
  defineAsyncComponent,
} from 'vue';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const iconName = defineAsyncComponent(() => import(`./icons/${props.icon}Icon.vue`));

</script>
<style scoped lang="scss">
.pill {
  display: inline-grid;
  grid-template-columns: 30px auto;
  width: fit-content;
  padding: 7px 20px;
  border: 1px solid #A8AFBC;
  border-radius: 999px;
  font-size: 15px;
  color: var(--color-grey-dark);
  font-weight: 600;
  gap: 14px;
  cursor: pointer;
  transition: var(--transition-default);
  margin-bottom: 16px;
  margin-right: 16px;
  &:hover:not(.pill--active) {
    background: #F8FBFD;
    border-color: #7A808B;
  }
  p {
    line-height: 28px;
    user-select: none;
  }
  svg {
    transition: var(--transition-default);
    opacity: 0.7;
  }
  &--active {
    color: var(--color-dark-main);
    border-color: #0061FF;
    svg {
      opacity: 1;
    }
  }
}
</style>
