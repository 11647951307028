function traverseAndFlatten(currentNode, target, flattenedKey) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined || flattenedKey === '0') {
        newKey = key;
      } else {
        newKey = `${flattenedKey}.${key}`;
      }

      const value = currentNode[key];
      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        // eslint-disable-next-line no-param-reassign
        target[newKey] = value;
      }
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export function flatten(obj) {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
}
// Convert seconds to minutes and seconds
export function secToMinutes(totalSeconds) {
  // get the number of full minutes
  const minutes = Math.floor(totalSeconds / 60);
  // get the remainder of the seconds
  const seconds = Math.floor(totalSeconds % 60);
  function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }
  // format as MM:SS
  const result = `${padTo2Digits(minutes)}m ${padTo2Digits(seconds)}s`;
  return result;
}

export function percentile(number) {
  return (number * 100).toFixed(2);
}

// Number formatting las .00
export function numberFormat(number) {
  if (number === null || number === undefined) {
    return '--';
  }
  if (Number.isInteger(number)) {
    return new Intl.NumberFormat('en-GB').format(
      number,
    );
  }
  return new Intl.NumberFormat('en-GB', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(
    number,
  );
}

// null to --
export function nullToDash(value) {
  return value === null || value === undefined ? '--' : value;
}

export function toFixedNumber(value, decimals = 2) {
  if (typeof value !== 'number') return value;
  return value.toFixed(decimals);
}

export const formatDate = (dateString, options = { day: '2-digit', month: 'short' }, local = 'en-US') => {
  const date = new Date(dateString);
  return date.toLocaleDateString(local, options);
};

export const currencySymbol = (currency) => new Intl.NumberFormat('en', { style: 'currency', currency }).format(0)[0];

export const fetureCheck = (ENVNAME) => (process.env[ENVNAME] === 'TRUE');
