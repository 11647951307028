<template>
    <div class="empty-state">
        <div class="empty-state__wrapper">
            <h5>Not enough data for a selected date range</h5>
            <h6>More data collected is needed for analysis purposes</h6>
        </div>
    </div>
</template>

<script setup>
</script>

<style lang="scss" scoped>
.empty-state {
    width: 100%;
    background: var(--color-white, #FFF);;
    position: absolute;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 4px;

        h5 {
            font-family: Gilroy;
            font-size: 15px;
            font-weight: 600;
            color: var(--color-dark-main, #253858);
        }
        h6 {
            color: var(--color-grey-dark, #7A808B);
            font-size: 12px;
            font-weight: 500;
        }
    }
}
</style>
