<template>
    <!-- eslint-disable  -->
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect opacity="0.08" width="36" height="36" rx="18" fill="#8FB571"/>
        <g clip-path="url(#clip0_4378_52201)">
        <path d="M18 26.3333C13.3975 26.3333 9.66663 22.6025 9.66663 18C9.66663 13.3975 13.3975 9.66667 18 9.66667C22.6025 9.66667 26.3333 13.3975 26.3333 18C26.3333 22.6025 22.6025 26.3333 18 26.3333ZM18 24.6667C19.7681 24.6667 21.4638 23.9643 22.714 22.714C23.9642 21.4638 24.6666 19.7681 24.6666 18C24.6666 16.2319 23.9642 14.5362 22.714 13.286C21.4638 12.0357 19.7681 11.3333 18 11.3333C16.2318 11.3333 14.5362 12.0357 13.2859 13.286C12.0357 14.5362 11.3333 16.2319 11.3333 18C11.3333 19.7681 12.0357 21.4638 13.2859 22.714C14.5362 23.9643 16.2318 24.6667 18 24.6667ZM17.1691 21.3333L13.6333 17.7975L14.8116 16.6192L17.1691 18.9767L21.8825 14.2625L23.0616 15.4408L17.1691 21.3333Z" fill="#8FB571"/>
        </g>
        <defs>
        <clipPath id="clip0_4378_52201">
        <rect width="20" height="20" fill="white" transform="translate(8 8)"/>
        </clipPath>
        </defs>
    </svg>
</template>
