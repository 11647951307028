<template>
  <p v-if="!hideRow" class="custom__action">
      {{ formatData }}
  </p>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { numberFormat } from '@/util/helpers';

const data = ref(null);

const cell = defineProps(['params']);
const hideRow = computed(() => cell.params.node.uiLevel === 1);

const formatData = computed(() => {
  switch (true) {
    case typeof data.value === 'boolean':
      return cell.params.node.allLeafChildren[0].data.status === 'active' ? 'Active' : 'Inactive';
    case typeof data.value === 'string':
      return data.value;
    case typeof data.value === 'number':
      return numberFormat(data.value);
    default:
      return data.value;
  }
});

onMounted(() => {
  if (!hideRow.value) {
    data.value = cell.params.node.allLeafChildren[0].data[cell.params.colDef.field] || null;
  }
});

</script>
