<template>
  <svg width="24" height="24" viewBox="-3 -3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.6C0 0.268629 0.268629 0 0.6 0H7.4C7.73137 0 8 0.268629 8 0.6V7.4C8
    7.73137 7.73137 8 7.4 8H0.6C0.268629 8 0 7.73137 0 7.4V0.6Z" fill="#0093FD"/>
    <path d="M10 0.6C10 0.268629 10.2686 0 10.6 0H17.4C17.7314 0 18 0.268629 18 0.6V7.4C18
    7.73137 17.7314 8 17.4 8H10.6C10.2686 8 10 7.73137 10 7.4V0.6Z" fill="#0093FD"/>
    <path d="M0 10.6C0 10.2686 0.268629 10 0.6 10H7.4C7.73137 10 8 10.2686 8 10.6V17.4C8
    17.7314 7.73137 18 7.4 18H0.6C0.268629 18 0 17.7314 0 17.4V10.6Z" fill="#A9DAFD"/>
    <path d="M10 10.6C10 10.2686 10.2686 10 10.6 10H17.4C17.7314 10 18 10.2686 18 10.6V17.4C18
    17.7314 17.7314 18 17.4 18H10.6C10.2686 18 10 17.7314 10 17.4V10.6Z" fill="#0061FF"/>
  </svg>

</template>
