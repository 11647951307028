<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="Group">
<path id="Vector" d="M10 17.4164L14.125 13.2914C14.9408 12.4756 15.4963
11.4362 15.7213 10.3047C15.9463 9.17313 15.8308 8.00027 15.3892 6.9344C14.9477
5.86854 14.2 4.95753 13.2408 4.31658C12.2815 3.67563 11.1537 3.33353 10 3.33353C8.8463
3.33353 7.71851 3.67563 6.75924 4.31658C5.79997 4.95753 5.05229 5.86854 4.61076
6.9344C4.16923 8.00027 4.05368 9.17313 4.27871 10.3047C4.50374 11.4362 5.05926
12.4756 5.875 13.2914L10 17.4164ZM10 19.7731L4.69667 14.4698C3.64779 13.4209
2.93349 12.0845 2.64411 10.6296C2.35473 9.17479 2.50326 7.6668 3.07092 6.29636C3.63858
4.92592 4.59987 3.75458 5.83324 2.93048C7.0666 2.10637 8.51665 1.6665 10 1.6665C11.4834
1.6665 12.9334 2.10637 14.1668 2.93048C15.4001 3.75458 16.3614 4.92592 16.9291
6.29636C17.4968 7.6668 17.6453 9.17479 17.3559 10.6296C17.0665 12.0845 16.3522
13.4209 15.3033 14.4698L10 19.7731ZM10 10.8331C10.442 10.8331 10.866 10.6575
11.1785 10.345C11.4911 10.0324 11.6667 9.60847 11.6667 9.16644C11.6667 8.72441
11.4911 8.30049 11.1785 7.98793C10.866 7.67537 10.442 7.49977 10 7.49977C9.55798
7.49977 9.13405 7.67537 8.82149 7.98793C8.50893 8.30049 8.33334 8.72441 8.33334
9.16644C8.33334 9.60847 8.50893 10.0324 8.82149 10.345C9.13405 10.6575 9.55798
10.8331 10 10.8331ZM10 12.4998C9.11595 12.4998 8.2681 12.1486 7.64298 11.5235C7.01786
10.8983 6.66667 10.0505 6.66667 9.16644C6.66667 8.28238 7.01786 7.43454 7.64298
6.80942C8.2681 6.1843 9.11595 5.83311 10 5.83311C10.8841 5.83311 11.7319 6.1843
12.357 6.80942C12.9821 7.43454 13.3333 8.28238 13.3333 9.16644C13.3333 10.0505
12.9821 10.8983 12.357 11.5235C11.7319 12.1486 10.8841 12.4998 10 12.4998Z" fill="#0061FF"/>
</g>
</svg>
</template>
