<template>
  <div class="asection__wrapper">
      <div class="asection asection--gradient">
        <div class="asection__image">
          <img src="@/assets/question.svg" alt="">
        </div>
        <div class="content">
          <h2 class="content__title">
            Not sure where to start? We’re here to help you!
          </h2>
          <p>
            Need a custom solution or additional connectors to improve your<br />
            experience? Let's talk!
          </p>
          <div class="content__action">
            <slot name="contactSectionActions"></slot>
          </div>
        </div>
      </div>
    </div>
</template>
