<template>
  <div v-if="!hideRow" class="custom" :class="{'ag-grid__actions--hidden': hideRow}">
    <div style="position: relative">
      <!-- eslint-disable-next-line -->
      <input
        v-if="editMode"
        type="text"
        class="custom__input"
        @blur="saveRow()"
        @keydown.enter="saveRow()"
        :id="`inputEl_${id}`"
        v-model="customData" />
      <p
        v-else
        class="custom__action"
        @keydown="editCell()"
        @click="editCell()">
        {{ customData }}
      </p>
    </div>
    <div class="custom__loader" v-if="loading">
      <img src="@/assets/loading.gif" alt="loading">
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
import { computed, ref } from 'vue';
import { usersStore } from '@/store/users';
import { debounce } from 'lodash';

export default {
  setup(props) {
    const router = useRouter();
    let id = null;
    let customAttributes = null;

    const inputEl = ref(null);

    const hideRow = computed(() => props.params.node.uiLevel === 1);

    if (props.params.node.allLeafChildren) {
      id = computed(() => props.params.node.allLeafChildren[0].data.user_id);
      customAttributes = computed(
        () => props.params.node.allLeafChildren[0].data.custom_attributes.params,
      );
    }

    const editMode = ref(false);

    const customData = ref('');

    const loading = ref(false);

    if (!hideRow.value) {
      customData.value = customAttributes.value || '';
    }

    const debouncedSaveRow = debounce(() => {
      loading.value = true;
      usersStore().setUserCustomAttribute(id.value, customData.value).then(() => {
        editMode.value = false;
        loading.value = false;
      });
    }, 1000);

    const saveRow = () => {
      debouncedSaveRow();
    };

    const editCell = () => {
      editMode.value = true;
      setTimeout(() => {
        document.querySelector(`#inputEl_${id.value}`).focus();
      }, 0);
    };

    const buttonClicked = () => {
      router.push({
        name: 'User',
        params: { id: id.value },
      });
    };

    return {
      id,
      buttonClicked,
      saveRow,
      hideRow,
      customData,
      editMode,
      editCell,
      customAttributes,
      inputEl,
      loading,
    };
  },
};
</script>
<style lang="scss" scoped>
.ag-grid__actions--hidden {
  display: none;
}

.custom {
  &__action {
    width: 100%;
    height: 38px;
    cursor: text;
    font-family: inherit;
    padding: 0 8px;
  }
  &__input {
    width: 100%;
    height: 34px;
    border: none;
    appearance: none;
    outline: none;
    padding: 2px 8px;
    font-family: inherit;
    background: #fff;
    font-size: 15px;
  }
  &__loader {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    z-index: 9999;
    img {
      width: 38px;
      height: 38px;
    }
  }
}
</style>
