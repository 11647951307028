<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8177_60561)">
      <path d="M12.0001 25.3333H16.0001V17.256L10.6667 12.6053L5.33341
      17.256V25.3333H9.33342V20H12.0001V25.3333ZM28.0001 28H4.00008C3.64646 28
      3.30732 27.8595 3.05727 27.6095C2.80722 27.3594 2.66675 27.0203 2.66675
      26.6667V16.6493C2.66673 16.4588 2.70754 16.2705 2.78642 16.0971C2.86531
      15.9237 2.98045 15.7692 3.12408 15.644L8.00008 11.392V5.33333C8.00008 4.97971
      8.14056 4.64057 8.39061 4.39052C8.64065 4.14048 8.97979 4 9.33342 4H28.0001C28.3537
      4 28.6928 4.14048 28.9429 4.39052C29.1929 4.64057 29.3334 4.97971 29.3334
      5.33333V26.6667C29.3334 27.0203 29.1929 27.3594 28.9429 27.6095C28.6928
      27.8595 28.3537 28 28.0001 28ZM21.3334 14.6667V17.3333H24.0001V14.6667H21.3334ZM21.3334
      20V22.6667H24.0001V20H21.3334ZM21.3334 9.33333V12H24.0001V9.33333H21.3334ZM16.0001
      9.33333V12H18.6667V9.33333H16.0001Z" fill="#0061FF" fill-opacity="0.5"/>
    </g>
    <defs>
      <clipPath id="clip0_8177_60561">
        <rect width="32" height="32" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
