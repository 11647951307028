<template>
    <div class="details__wrapper" v-if="companyData">
      <RSection title="Company identity">
        <div class="sections__content">
          <div class="details__icon">
            <CompanyIcon/>
          </div>
          <div class="sections__title">
            <h3>Dénomination</h3>
            <h1>{{companyData?.denomination}}</h1>
          </div>
          <div class="sections__info underline">
            <p>Basic info</p>
          </div>
          <div class="sections__data">
            <div class="sections__table">
              <table v-if="companyData">
                <tr :key="index" v-for="(keys, index) in Object.keys(basicInfoMap)">
                  <td>{{basicInfoMap[keys]}}</td>
                  <td v-if="keys === 'montant_capital'">
                    {{companyData[keys]}} {{companyData.devise_capital}}
                  </td>
                  <td v-else>{{ companyData[keys] }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="sections__content"
             :key="index"
             v-for="(beneficiary, index) in companyData.beneficiaires">
          <div class="sections__info" :class="{underline : index === 1}">
            <p v-if="index === 0">Dirigeants et associés</p>
          </div>
          <div class="sections__data">
            <div class="sections__table">
              <table>
                <tr>
                  <td>Nom, Prénom(s)</td>
                  <td>
                    {{beneficiary.nom}},
                    <span class="sections__table-names"
                          :key="prenoms"
                          v-for="prenoms in beneficiary.prenoms">
                      {{prenoms}}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>Date de naissance (mm/aaaa)</td>
                  <td>
                    {{beneficiary.date_de_naissance}}
                  </td>
                </tr>
                <tr>
                  <td>Pays de résidence</td>
                  <td>
                    {{beneficiary.adresse}}
                  </td>
                </tr>
                <tr>
                  <td>Capital total</td>
                  <td>
                    {{beneficiary.parts_total}}%
                  </td>
                </tr>
                <tr>
                  <td>Total des droits de vote</td>
                  <td>
                    {{beneficiary.votes_total}}%
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="sections__content">
          <div class="sections__info underline">
            <p>Établissements</p>
          </div>
          <div class="sections__data">
            <div class="sections__table">
              <table>
                <tr>
                  <td>Type d'établissement</td>
                  <td>Principal</td>
                </tr>
                <tr>
                  <td>Siret</td>
                  <td>{{companyData.etablissement_principal.siret}}</td>
                </tr>
                <tr>
                  <td>Adresse</td>
                  <td>{{companyData.etablissement_principal.adresse}}</td>
                </tr>
                <template
                  :key="index"
                  v-for="(activites, index) in companyData.etablissement_principal.activites">
                  <tr :key="keys" v-for="(keys) in Object.keys(esteblishmentActivitiesMap)" >
                    <td>{{esteblishmentActivitiesMap[keys]}}</td>
                    <td>{{activites[keys]
                      ? activites[keys]
                      : '-'}}</td>
                  </tr>
                </template>
              </table>
            </div>
            <div class="sections__table"
                 :key="secondaryEstablishments"
                 v-for="secondaryEstablishments in companyData.etablissements_secondaires">
              <table>
                <tr>
                  <td>Type d'établissement</td>
                  <td>Secondaire</td>
                </tr>
                <tr>
                  <td>Siret</td>
                  <td>{{secondaryEstablishments.siret}}</td>
                </tr>
                <tr>
                  <td>Adresse</td>
                  <td>{{secondaryEstablishments.adresse}}</td>
                </tr>
                <template
                  :key="index"
                  v-for="(activites, index) in secondaryEstablishments.activites">
                  <tr :key="keys" v-for="(keys) in Object.keys(esteblishmentActivitiesMap)" >
                    <td>{{esteblishmentActivitiesMap[keys]}}</td>
                    <td>{{activites[keys]
                      ? activites[keys]
                      : '-'}}</td>
                  </tr>
                </template>
              </table>
            </div>
          </div>
        </div>
      </RSection>
      <KeyMetrics/>
    </div>
</template>
<script setup>

import KeyMetrics from '@/components/Verify/KeyMetrics.vue';
import RSection from '@/components/RSection.vue';
import CompanyIcon from '@/components/icons/Verify/CompanyIcon.vue';
import { verificationStore } from '@/store/verification';
import { computed, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';

const store = verificationStore();
const router = useRouter();
const companyData = computed(() => store.getCompanyData);
const basicInfoMap = {
  siren: 'SIREN',
  date_immatriculation: 'Date d\'immatriculation',
  duree_personne_morale: 'Début d’activité',
  nature_entreprise: 'Nature de l\'entreprise',
  forme_jurique: 'Forme juridique',
  montant_capital: 'Montant capital',
};

const esteblishmentActivitiesMap = {
  date_debut: 'Date début d’activité',
  date_fin: 'Date fin',
  code_ape: 'Code ape',
  description: 'Description',
};
onBeforeMount(() => {
  if (!companyData.value) {
    router.back();
  }
});
</script>

<style scoped lang="scss">
.details{
  &__wrapper{
    display: grid;
    grid-template-columns: 1fr 345px;
    column-gap: 24px;
    min-height: 100%;
  }

  &__icon{
    height: 60px;
    width: 60px;
    border-radius: 999px;
    background-color: #EBF2FF;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.sections{
  &__info {
    &.underline{
      position: relative;
      &::after{
        content: "";
        position: absolute;
        bottom: -31px;
        left: -31px;
        width: calc(100% + 45px);
        height: 1px;
        background-color: var(--color-grey-outline);
      }
    }
  };
  &__table{
    &-names{
      margin-right: 5px;
    }
  }
}
</style>
