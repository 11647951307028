<template>
    <div class="coverage">
       <div class="coverage__info" v-if="coverage.notes">
           <span><InfoIcon />Source info: </span>
           <span>{{ coverage.notes }}</span>
       </div>
        <div class="sections sections--coverage">
            <div class="sections__header">
              <div class="sections__header-title">
                <p>Data field coverage</p>
                <div class="status-bar">
                  <span>
                    <span class="indicator indicator--available"></span>
                    available
                  </span>
                  <span>
                    <span class="indicator indicator--sometimes"></span>
                    sometimes available
                  </span>
                  <span>
                    <span class="indicator indicator--unavailable"></span>
                    unavailable
                  </span>
                </div>
              </div>
            </div>
            <div class="sections__content">
                <div class="sections__data" v-if="!isFetching">
                  <DataSource
                    v-for="endpoint in coverage.endpoints_details"
                    :key="endpoint.name"
                    :icon="getIcon(endpoint.name)"
                    :data="getEndpointTooltip(endpoint.name)"
                    :name="endpoint.name"
                    :properties="endpoint.stats || []"
                  />
                </div>
                <div class="sections__loading" v-else>
                    <RLoading/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/components/icons/InfoIcon.vue';
/* eslint-disable vue/no-unused-components */
import ProfileIcon from '@/components/icons/ProfileIcon.vue';
import IncomeIcon from '@/components/icons/IncomeIcon.vue';
import TripsIcon from '@/components/icons/TripsIcon.vue';
import DocumentIcon from '@/components/icons/DocumentIcon.vue';
import ActivityIcon from '@/components/icons/ActivityIcon.vue';
import DataSource from '@/components/Coverages/DataSource.vue';
import RLoading from '@/components/RLoading.vue';
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { coveragesStore } from '@/store/coverages';

export default {
  components: {
    InfoIcon,
    ProfileIcon,
    DataSource,
    IncomeIcon,
    DocumentIcon,
    TripsIcon,
    ActivityIcon,
    RLoading,
  },
  setup() {
    const route = useRoute();

    const isFetching = ref(false);
    const coverage = ref({});

    const slugToText = (slug) => slug.replace(/-/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());

    const getEndpointTooltip = (name) => {
      if (name in coverage.value.datapoints_definitions) {
        return coverage.value.datapoints_definitions[name];
      }
      return '';
    };

    const getCoverage = async () => {
      const name = slugToText(route.params.id);
      isFetching.value = true;
      // eslint-disable-next-line
      coveragesStore().fetchCoverage(name).then(data => {
        coverage.value = coveragesStore().getCoverage;
      }).finally(() => {
        isFetching.value = false;
      });
    };
    const shouldRenderEndpoint = (endpoint) => coverage.value
     && coverage.value.endpoints
     && coverage.value.endpoints.includes(endpoint);

    const getIcon = (endpointName) => {
      switch (endpointName) {
        case 'profile':
          return 'ProfileIcon';
        case 'income':
          return 'IncomeIcon';
        case 'documents':
          return 'DocumentIcon';
        case 'trips':
          return 'TripsIcon';
        case 'activity':
          return 'ActivityIcon';
        case 'employment':
          return 'DocumentIcon';
        case 'vehicles':
          return 'ProfileIcon'; // Update this if you have a specific icon for vehicles
        default:
          return 'DocumentIcon';
      }
    };

    onMounted(() => {
      getCoverage();
    });

    return {
      isFetching,
      coverage,
      shouldRenderEndpoint,
      getIcon,
      getEndpointTooltip,
    };
  },
};
</script>
<style lang="scss" scoped>
.coverage {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: -8px;

    &__info {
        display: flex;
        justify-content: flex-end;
        gap:8px;
        svg {
          fill: #aaa;
        }

        span {
            font-family: Gilroy;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 220%;
            &:nth-child(1) {
                color: var(--grey-dark, #7A808B);
                display: flex;
                align-items: center;
                gap: 6px;
            }
            &:nth-child(2) {
                color: var(--dark-main, #253858);
            }
        }
    }
}
.sections {
    &--coverage {
        max-width: 100% !important;
    }
    &__header-title {
        color: var(--dark-main, #253858);
        font-family: Gilroy;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 32px */
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status-bar {
          color: var(--color-dark-main, #253858);
          font-size: 13px;
          font-weight: 500;
          position: relative;
          display: flex;
          align-items: center;
          gap: 28px;

          span {
            display: flex;
            align-items: center;
            gap: 8px;
            &.indicator {
              width: 7px;
              height: 7px;
              border-radius: 7px;
              display: flex;
              background: var(--color-grey-light, #A8AFBC);

              &--rarely {
                background: #FE601F;
              }
              &--sometimes {
                background: var(--color-yellow-warning, #FE9543);
              }
              &--available {
                background: var(--color-blue-main, #0061FF);
              }
            }
          }
        }
    }
    &__content {
        display: flex;
    }
    &__data {
        display: flex;
        flex-wrap: wrap;
        gap: 60px 144px;
        width: 100%;
    }
    &__loading {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
}
</style>
