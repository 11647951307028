<template>
  <div class="sections sections--key-metrics">
    <div class="sections__head">
      <h5>Errors breakdown</h5>
      <r-tool-tip>
        <template #tooltip-text>
                    <span>
                       <!-- eslint-disable-next-line  -->
                       Most common error type that occurs and affects your users during the Connect process.
                    </span>
        </template>
      </r-tool-tip>
    </div>
    <div class="sections__tab">
      <div v-if="props.isLoading">
        <r-loading></r-loading>
      </div>
      <div v-else class="sections__tab-container">
          <vue-apex-charts
            type="donut" :options="chartOptions" :series="chartSeries">
          </vue-apex-charts>
      </div>
      <empty-state v-if="showEmptyState && !props.isLoading"></empty-state>
    </div>
  </div>
</template>
<script setup>

import RLoading from '@/components/RLoading.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import VueApexCharts from 'vue3-apexcharts';
import {
  computed,
  ref, watch,
} from 'vue';
import RToolTip from '@/components/RToolTip.vue';
import { groupErrors } from '@/util/mappers';

const props = defineProps(['isLoading', 'data']);

const chartOptions = ref({
  chart: {
    type: 'donut',
  },
  labels: [],
  legend: {
    position: 'bottom',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  colors: ['#DE6E6A', '#84BFDB', '#FE9543'],
  responsive: [{
    breakpoint: 480,
    options: {
      chart: {
        width: 200,
      },
      legend: {
        position: 'bottom',
      },
    },
  }],
  tooltip: {
    fillSeriesColor: true,
    custom: (data) => `
        <div class="custom-tooltip">
          <div class="custom-tooltip__item">
             <span class="custom-tooltip--pill" style="background-color: ${data.w.globals.colors[data.seriesIndex]}">  </span>
             <h6>${chartOptions.value.labels[data.seriesIndex]}</h6>
             <span class="custom-tooltip--highlight">${data.series[data.seriesIndex]}</span>
          </div>
        </div>
      `,
  },
});
const chartSeries = ref([]);
const mapData = () => {
  const mappedErrors = {
    'Credentials Errors': 0,
    'MFA Errors': 0,
    'Other Errors': 0,
  };
  chartOptions.value.labels = [];
  chartSeries.value = [];
  props.data.forEach((err) => {
    if (mappedErrors[groupErrors(err.error)]) {
      mappedErrors[groupErrors(err.error)] += err.total;
      return;
    }
    mappedErrors[groupErrors(err.error)] = err.total;
  });
  const allErrors = Object.entries(mappedErrors);
  allErrors.forEach((err) => {
    chartOptions.value.labels.push(err[0]);
    chartSeries.value.push(err[1]);
  });
};

const showEmptyState = computed(() => props.data.length === 0);
watch(() => props.data, () => {
  mapData();
}, {
  immediate: true,
});
</script>
<style scoped lang="scss">
.sections {
  max-width: 100%;
  .sections__head {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__tab {
    padding-right: 46px;
    margin-top: 4px;
    position: relative;
  }
  h5 {
    color: var(--color-black, #141414);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 28.8px */
  }
}

:deep {
  .custom-tooltip {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    border: 1px solid #E2E3E3;
    background: var(--white, #FFF);
    &__item {
      padding: 8px;
      color: var(--black, #141414);
      font-size: 16px;
      font-style: normal;
      line-height: 17px;
      display: grid;
      grid-template-columns: 10px auto 1fr;
      align-items: center;
      column-gap: 8px;
      text-align: start;
      & h6{
        transform: translateY(2px);
        font-weight: 500;
      }
    }
    &--pill{
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 999px;
    }
    &--highlight{
      color: var(--black, #141414);
      text-align: right;
      font-size: 13px;
      font-weight: 600;
      line-height: 15px;
      transform: translateY(1px);
    }
  }
  .apexcharts-legend{
    overflow: hidden;
    &-text{
      transform: translateY(2px);
    }
  }
}

</style>
