<template>
    <!-- eslint-disable -->
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#FFF7F0"/>
        <g clip-path="url(#clip0_4378_52201)">
        <path d="M18 26.3333C13.3975 26.3333 9.66669 22.6025 9.66669 18C9.66669 13.3975 13.3975 9.66667 18 9.66667C22.6025 9.66667 26.3334 13.3975 26.3334 18C26.3334 22.6025 22.6025 26.3333 18 26.3333ZM18 24.6667C19.7681 24.6667 21.4638 23.9643 22.7141 22.714C23.9643 21.4638 24.6667 19.7681 24.6667 18C24.6667 16.2319 23.9643 14.5362 22.7141 13.286C21.4638 12.0357 19.7681 11.3333 18 11.3333C16.2319 11.3333 14.5362 12.0357 13.286 13.286C12.0357 14.5362 11.3334 16.2319 11.3334 18C11.3334 19.7681 12.0357 21.4638 13.286 22.714C14.5362 23.9643 16.2319 24.6667 18 24.6667ZM17.1667 20.5H18.8334V22.1667H17.1667V20.5ZM17.1667 13.8333H18.8334V18.8333H17.1667V13.8333Z" fill="#FE9543"/>
        </g>
        <defs>
        <clipPath id="clip0_4378_52201">
        <rect width="20" height="20" fill="white" transform="translate(8 8)"/>
        </clipPath>
        </defs>
    </svg>
</template>
