<template>
  <div class="sections" v-if="carLogos">
    <div class="sections__header sections--splited">
      <p class="sections__header-title">Vehicles</p>
      <RComboButton icon="Download">
        <li @click="onDownload('json')" @keydown="onDownload('json')">
          JSON
        </li>
        <li @click="onDownload('csv')" @keydown="onDownload('csv')">
          CSV
        </li>
        <li @click="onDownload('xls')" @keydown="onDownload('xls')">
          XLS
        </li>
      </RComboButton>
    </div>
    <div class="sections__content" v-for="car in data" :key="car.vin">
      <div class="sections__image sections__image--logo">
        <img v-if="getLogo(car.make)" :src="getLogo(car.make)" alt="car logo">
      </div>
      <div class="sections__data">
        <div class="sections__title">
          <h3>Vehicle</h3>
          <h1>{{ car.make }} {{ car.model }}</h1>
        </div>
        <div class="sections__table">
          <table>
            <tr v-if="car.make">
              <td>Brand</td>
              <td>{{ car.make }}</td>
            </tr>
            <tr v-if="car.model">
              <td>Model</td>
              <td>{{ car.model }}</td>
            </tr>
            <tr v-if="car.year">
              <td>Year</td>
              <td>{{ car.year }}</td>
            </tr>
            <tr v-if="car.vin">
              <td>VIN</td>
              <td>{{ car.vin }}</td>
            </tr>
            <tr v-if="car.license_plate">
              <td>License Plate</td>
              <td>{{ car.license_plate }}</td>
            </tr>
            <tr v-if="car.vehicle_type">
              <td>Vehicle Type</td>
              <td>{{ car.vehicle_type }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { usersStore } from '@/store/users';
import RComboButton from '@/components/RComboButton.vue';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  platform: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['download']);

const data = computed(() => props.data);

const carLogos = computed(() => usersStore().getCarLogos);

const getLogo = computed(() => (make) => {
  const logo = carLogos.value.find((item) => item.name === make);
  return logo ? logo.image.thumb : '';
});

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'vehicles' });
};

</script>
