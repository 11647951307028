<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="customization">
    <div class="customization__header">
      <h2 class="customization__title">Customizations</h2>
      <RButton
        before-icon="ri-add-line"
        @click="createNew()">
        Create New
      </RButton>
    </div>
    <table class="r-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Last Update</th>
          <th>Customize ID</th>
          <th class="table__link-header">URL Link</th>
          <th>Link Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in customizations" :key="item.id">
          <td>{{ item.config?.name || '-' }}</td>
          <td>{{ format(new Date(item.updated_at), 'MMM dd, yyyy, HH:mm a') }}</td>
          <td>{{ item.id }}</td>
          <td
            class="table__link"
            :class="{'disabled': item.enabled === false || item.enabled === null}">
            <p>
              {{ item.magic_link }}
            </p>
            <i
              v-if="item.magic_link"
              class="ri-file-copy-line"
              @click="copyLink(item.magic_link, item.enabled)">
            </i>
          </td>
          <td class="table__status">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label class="switch" v-if="item.magic_link">
              <input type="checkbox"
              :checked="item.enabled"
              :disabled="!customizationEnabled"
              @change="onChangeLinkStatus(item)">
              <span class="slider round"></span>
            </label>
            <p
              v-if="item.magic_link"
              class="switch__title"
              :class="{ 'active': item?.enabled }">{{ item?.enabled ? 'Active' : 'Inactive' }}</p>
          </td>
          <td class="table__actions">
            <RDropdown
              v-if="item?.default !== true"
              title="Action"
              :items="actionList"
              @action="dropdownAction"
              :value="item"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <RModal
      v-if="errorModal"
      :data="currentCustomization"
      @submit="removeCustomization()"
      @close="errorModal = false" />
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router';
import RButton from '@/components/RButton.vue';
import { customizationStore } from '@/store/customizations';
import { appStore } from '@/store/app';
import {
  computed, onMounted, ref, reactive, nextTick,
} from 'vue';
import RDropdown from '@/components/RDropdown.vue';
import useEmitter from '@/composables/useEmitter';
import RModal from '@/components/RModal.vue';
import { format } from 'date-fns';

const router = useRouter();

const customizationEnabled = ref(true);
const loadCustomizations = () => {
  customizationStore().fetchCustomizations().then((res) => {
    appStore().setHeaderTitleInfo(`${res?.length || 0} in total`);
  });
};

const copyLink = (link, enabled) => {
  if (!enabled) {
    return;
  }
  navigator.clipboard.writeText(link).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Link copied to clipboard',
    });
  });
};

const onChangeLinkStatus = (item) => {
  customizationEnabled.value = false;
  if (item.enabled) {
    customizationStore().disableCustomizationLink(item.id).then(() => {
      appStore().setNotification({
        type: 'success',
        message: 'Link status updated successfully',
      });

      setTimeout(async () => {
        loadCustomizations();
        customizationEnabled.value = true;

        await nextTick();
      }, 500);
    });
    return;
  }
  customizationStore().enableCustomizationLink(item.id).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Link status updated successfully',
    });
    setTimeout(async () => {
      loadCustomizations();
      customizationEnabled.value = true;

      await nextTick();
    }, 500);
  });
};

onMounted(() => {
  loadCustomizations();
  const emitter = useEmitter();
  emitter.on('reload', () => {
    loadCustomizations();
  });
  window.analytics?.track('select_customization');
});

const errorModal = ref(false);

const customizations = computed(() => customizationStore().getAllCustomizations);

const createNew = () => {
  window.analytics?.track('create_new_customization');
  router.push('/connect/customization/create');
};

const gotoEdit = (id) => {
  router.push(`/connect/customization/edit/${id}`);
  window.analytics?.track('edit_customization');
};

const actionList = [
  {
    title: 'Edit',
    icon: 'ri-pencil-line',
  },
  {
    title: 'Remove',
    icon: 'ri-delete-bin-7-line',
  },
];

const currentCustomization = reactive({});

const dropdownAction = (item, value) => {
  if (item.title === 'Edit') {
    gotoEdit(value.id);
  }
  if (item.title === 'Remove') {
    currentCustomization.value = value;
    errorModal.value = true;
  }
};

const removeCustomization = () => {
  customizationStore().removeCustomization(currentCustomization.value.id).then(() => {
    errorModal.value = false;
    loadCustomizations();
  }).then(() => {
    appStore().setNotification({
      type: 'success',
      message: 'Customization removed successfully',
    });
    window.analytics?.track('remove_customization');
  });
};

</script>
<style scoped lang="scss">
.customization {
  &__header {
    display: grid;
    grid-template-columns: auto 160px;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 12px;
  }
  &__title {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 32px;
    color: var(--color-dark-main);
  }
}
.switch {
  &__title{
    color: var(--grey-dark, #7A808B);
    font-size: 14px;
  }
  &__title.active{
    color: var(--color-green-success);
  }
}
</style>
