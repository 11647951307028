<template>
  <div>
    <div class="card__head">
        <div class="card__head-icon">
            <div
            class="card__head-background"
            :style="{'background-color': renderIcon.color}">
            </div>
            <template v-if="props.icon === 'top'">
                <app-trophy-icon></app-trophy-icon>
            </template>
            <template v-else>
                <app-circle-info-icon></app-circle-info-icon>
            </template>
        </div>
        <h5>{{ props.title }}</h5>
        <RComboButton
                :text="label">
                <li
                :class="{active: selected === 'lastWeek'}"
                @click="dropdownAction('lastWeek')"
                @keydown.enter="dropdownAction('lastWeek')">
                    Last Week
                    <CheckMarkIcon v-if="selected === 'lastWeek'"/>
                </li>
                <li
                :class="{active: selected === 'thisWeek'}"
                @click="dropdownAction('thisWeek')"
                @keydown.enter="dropdownAction('lastWeek')">
                    This Week
                    <CheckMarkIcon v-if="selected === 'thisWeek'"/>
                </li>
            </RComboButton>
    </div>
    <div v-if="listDrivers.values.length > 0" class="card__content">
        <div class="list__head">
            <span>No.</span>
            <span>Name</span>
            <span>Earnings</span>
        </div>
        <div class="list__items">
            <div class="list__item" :key="index" v-for="(driver, index) in listDrivers.values">
                <p>{{ index + 1 }}</p>
                <p>
                  <span class="list__item-avatar">{{ getIntials(driver.full_name) }}</span>
                  {{ driver.full_name }}
                </p>
                <p>{{ numberFormat(driver.overall_earnings) }} {{ props.currency }}</p>
            </div>
        </div>
    </div>
    <div class="card__content" v-else>
      <NoDataAvailable/>
    </div>
  </div>
</template>

<script setup>
import {
  ref, watch, computed, reactive,
} from 'vue';
import { numberFormat } from '@/util/helpers';
import RComboButton from '@/components/RComboButton.vue';
import AppTrophyIcon from './icons/TrophyIcon.vue';
import AppCircleInfoIcon from './icons/CircleInfoIcon.vue';
import CheckMarkIcon from './icons/CheckMarkIcon.vue';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['week', 'previous', 'title', 'icon', 'currency']);

const selected = ref('thisWeek');
const label = ref('Last Week');

const listDrivers = reactive([]);
const renderIcon = computed(() => {
  if (props.icon === 'noEarning') {
    return {
      color: '#FE9543',
    };
  }
  return {
    color: '#0061FF',
  };
});

const getIntials = (fullname) => fullname.match(/(\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('')
  .toUpperCase();

watch(selected, (val) => {
  listDrivers.values = [];
  if (val === 'thisWeek') {
    label.value = 'This Week';
    if (props.week) {
      listDrivers.values.push(...props.week);
    }
    return;
  }
  label.value = 'Last Week';
  if (props.previous) {
    listDrivers.values.push(...props.previous);
  }
}, {
  immediate: true,
  deep: true,
});

const dropdownAction = (week) => {
  selected.value = week;
};
</script>

<style scoped>
.card__head{
    gap: 15px;
}

.card__content{
    height: 500px;
}
.list__head{
    display: grid;
    grid-template-columns: 100px 1fr 160px;
    padding: 16px 0;
    transform: translateX(-33px);
    width: calc(100% + 33px + 33px);
}
.list__head span{
    color: var(--color-grey-dark);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
}

.list__head span:first-of-type {
    padding-left: 33px;
}
.list__item{
    display: grid;
    grid-template-columns: 100px 1fr 160px;
    padding: 0;
    border-top: 1px solid var(--color-grey-outline);
    min-height: 56px;
    align-items: center;
}

.list__item-avatar{
    height: 32px;
    width: 32px;
    border-radius: 999px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-grey-outline, #EDF2F8);
    background: var(--blue-background, #F8FBFD);
    margin-right: 8px;
    color: var(--color-dark-main);
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.list__items{
    overflow-y: auto;
    height: calc(100% - 40px);
    transform: translateX(-33px);
    width: calc(100% + 33px + 33px);
}

.list__item p {
    color: var(--color-dark-main);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    transform: translateY(2px);
    display: flex;
    align-items: center;
}
.list__item p:first-of-type {
    padding-left: 33px;
}
</style>
