/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Keycloak from 'keycloak-js';
import { profileStore } from '@/store/profile';
import { appStore } from '@/store/app';
import Popper from 'vue3-popper';
import mitt from 'mitt';
import { createI18n } from 'vue-i18n';
import App from './App.vue';
import { router } from './router';
import 'remixicon/fonts/remixicon.css';
import onboardingEn from './i18n/onboarding.en';
import onboardingFr from './i18n/onboarding.fr';

const app = createApp(App);
(async () => {
  const emitter = mitt();

  const i18n = createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    legacy: false,
    messages: {
      en: onboardingEn,
      fr: onboardingFr,
    },
  });

  const initOptions = {
    url: process.env.VUE_APP_KEYCLOAK_URL,
    clientId: 'dashboard',
    realm: 'rollee',
    onLoad: 'login-required',
    checkLoginIframe: false,
    responseMode: 'query',
    redirectUri: window.location.origin,
  };

  // eslint-disable-next-line
  const keycloak = new Keycloak({
    realm: initOptions.realm,
    url: initOptions.url,
    clientId: initOptions.clientId,
  });

  // eslint-disable-next-line
  const kc_options = {
    onLoad: initOptions.onLoad,
    checkLoginIframe: initOptions.checkLoginIframe,
    flow: 'standard',
  };

  await keycloak.init(kc_options);

  localStorage.setItem('keycloak-token', keycloak.token);
  localStorage.setItem('keycloak-refresh-token', keycloak.refreshToken);

  if (localStorage.getItem('production') === null) {
    localStorage.setItem('production', 'true');
  }

  app.use(router);
  app.use(createPinia());
  app.use(i18n);
  app.use(VueAxios, axios);

  app.config.globalProperties.keycloak = keycloak;
  app.config.globalProperties.emitter = emitter;

  await profileStore().fetchPartnerInfo();

  appStore().getDashboardConfiguration().then((res) => {
    i18n.global.locale.value = profileStore().getPartnerInfo?.lang || 'en';
    if (profileStore().getPartnerInfo?.type === 'Fleet') {
      appStore().setOnboarding(res.data[keycloak?.tokenParsed?.email].onboarding);
    }
  });

  profileStore().setProfile({
    email: keycloak?.tokenParsed?.email,
    familyName: keycloak?.tokenParsed?.family_name,
    givenName: keycloak?.tokenParsed?.given_name,
    preferredUsername: keycloak?.tokenParsed?.preferred_username,
    partnerName: profileStore().getPartnerInfo?.name || 'Rollee',
    partnerType: profileStore().getPartnerInfo?.type || 'Regular',
    canAccess: {
      driverInsights: profileStore().getPartnerInfo?.features.driver_insights,
    },
  });
  app.mount('#app');
  app.component('Popper', Popper);
})();
