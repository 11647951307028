<template>
    <div class="sections sections--rate-analysis">
        <div class="sections__head">
            <h5>Conversion rate analysis</h5>
            <r-tool-tip>
                <template #tooltip-text>
                    <span>
                       <!-- eslint-disable-next-line  -->
                       The conversion rate is the number of conversions divided by the total number of visitors. If your Connect receives 200 users in a month and has 50 successful connections, the conversion rate would be 50 divided by 200, or 25%.
                    </span>
                </template>
            </r-tool-tip>
        </div>
        <div class="sections__tab">
            <r-tab ref="tabRef">
                <template #tab-titles>
                    <div
                    @click="setActiveTabIndex(0)"
                    class="tab"
                    @keydown.enter="setActiveTabIndex(0)"
                    @keydown.space="setActiveTabIndex(0)">
                        <h6>Connected Users</h6>
                        <div>
                            <h5 v-if="props.isLoading">--</h5>
                            <h5 v-else>
                                {{
                                props.data && props.data.metrics_for_period
                                    && props.data.metrics_for_period.connected_users
                                }}
                            </h5>
                        </div>
                        <!-- <span>+ 28%</span> -->
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(1)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(1)"
                    @click="setActiveTabIndex(1)">
                        <h6>Reconnected Users</h6>
                        <div>
                            <h5 v-if="isLoading">--</h5>
                            <h5 v-else>
                                {{
                                props.data && props.data.metrics_for_period
                                    && props.data.metrics_for_period.reconnected_users
                                }}
                            </h5>
                        </div>
                        <!-- <span>+ 28%</span> -->
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(2)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(2)"
                    @click="setActiveTabIndex(2)">
                        <h6>Avg. time to convert</h6>
                        <div>
                            <h5 v-if="props.isLoading">--</h5>
                            <h5 v-else>
                                {{
                                calculateAverageConversion(data)
                                }}
                            </h5>
                        </div>
                    </div>
                </template>
                <template #tab-content-0>
                    <r-loading v-if="props.isLoading"></r-loading>
                    <div v-else>
                        <conversion-rate-connected
                        :metricsPerDay="data"></conversion-rate-connected>
                    </div>
                </template>
                <template #tab-content-1>
                    <r-loading v-if="props.isLoading"></r-loading>
                    <div v-else>
                        <conversion-rate-reconnected
                        :metricsPerDay="data"></conversion-rate-reconnected>
                    </div>
                </template>
                <template #tab-content-2>
                    <r-loading v-if="props.isLoading"></r-loading>
                    <div v-else>
                        <avg-time-to-convert
                        :metricsPerDay="data"></avg-time-to-convert>
                    </div>
                </template>
            </r-tab>
            <empty-state v-if="showEmptyState && !props.isLoading"></empty-state>
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, computed } from 'vue';
import RToolTip from '@/components/RToolTip.vue';
import RTab from '@/components/RTab.vue';
import RLoading from '@/components/RLoading.vue';
import ConversionRateConnected from '@/components/Analytics/Conversion/Graphs/ConversionRateConnected.vue';
import ConversionRateReconnected from '@/components/Analytics/Conversion/Graphs/ConversionRateReconnected.vue';
import AvgTimeToConvert from '@/components/Analytics/Conversion/Graphs/AvgTimeToConvert.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import { secToMinutes } from '@/util/helpers';

const tabRef = ref(null);
/* eslint-disable vue/no-setup-props-destructure */
const props = defineProps(['isLoading', 'data']);

const showEmptyState = computed(() => {
  if (props.data && Object.keys(props.data).length > 0) {
    return Object.values(props.data.metrics_for_period || {}).every((value) => value === 0);
  }
  return true;
});

const data = computed(() => props.data.metrics_per_day);
const setActiveTabIndex = (index) => {
  tabRef.value.setActiveTab(index);
};

const calculateAverageConversion = (t) => {
  const averages = t.map((metric) => metric.avg_time_to_convert);
  const average = averages.reduce((a, b) => a + b, 0) / averages.length;
  return secToMinutes(average);
};
</script>

<style lang="scss" scoped>
.sections {
    max-width: 100%;
    margin-bottom: unset !important;
    &--rate-analysis {
        .sections__head {
            padding: 16px 32px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    &__tab {
        padding-right: 46px;
        margin-top: 4px;
        position: relative;
    }
    h5 {
        color: var(--color-black, #141414);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }
}
:deep {
    .vue-apexcharts {
        padding-left: 32px;
        .apexcharts-toolbar {
            display: none;
        }
        .apexcharts-gridline {
            stroke-dasharray: 1, 1; /* Dash length, Gap length */
            stroke-width: 1; /* Stroke width */
            stroke: #EDF2F8; /* Stroke color */
        }
        .apexcharts-yaxis-texts-g, .apexcharts-xaxis-texts-g {
            tspan {
                fill: var(--color-grey-light, #A8AFBC);
                font-size: 12px;
                font-weight: 600;
                font-family: Gilroy;
            }
        }
        .apexcharts-tooltip {
            top: 100px !important;
        }
        .apexcharts-xcrosshairs {
            stroke: #A8AFBC;
        }
    }
    .tab-container__content {
        padding-top: 20px;
    }
    .custom-tooltip {
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
        width: 180px;

        &__header {
            border-radius: 4px 4px 0px 0px;
            border: 1px solid #E2E3E3;
            background: #EDEEF1;
            color:var(--color-black, #141414);
            padding: 4px 8px;
            font-size: 12px;
            font-weight: 500;
        }
        &__item {
            display: flex;
            padding: 8px;
            justify-content: space-between;
            align-items: center;
            span {
                display: flex;
                height: 12px;
                width: 12px;
                border-radius: 12px;
                background: var(--color-blue-main, #0061FF);

                &.transparent {
                    background: transparent;
                }
            }
            div {
                display: flex;
                gap: 8px;
                align-items: center;
                h6 {
                    color: var(--color-black, #141414);
                    font-size: 12px;
                    font-weight: 500;
                }
            }
            h6 {
                color: var(--color-black, #141414);
                font-size: 12px;
                font-weight: 700;
            }
        }
    }
}
</style>
