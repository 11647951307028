<template>
  <div class="sections">
    <div class="sections__header">
      <p class="sections__header-title">Accounts</p>
    </div>
    <div class="sections__content" v-for="item in data" :key="item">
      <div class="sections__image sections__image--small">
        <!-- <img :src='require(`@/assets/revolut_logo.png`)' alt="company logo"> -->
      </div>
      <div class="sections__data">
        <!-- <div class="sections__title">
          <h3>Account</h3>
          <h1>Revolut</h1>
        </div> -->
        <div class="sections__table">
          <table>
            <tr v-if="item.recipient">
              <td>Recipient</td>
              <td>{{ item.recipient }}</td>
            </tr>
            <tr v-if="item.iban">
              <td>IBAN</td>
              <td class="grey-light with--icon"
                @click="copyData(item.iban)" @keydown="copyData(item.iban)">
                <i class="ri-file-copy-line"></i> {{ item.iban }}
              </td>
            </tr>
            <tr v-if="item.swift">
              <td>SWIFT</td>
              <td>{{ item.swift }}</td>
            </tr>
            <tr v-if="item.account_type">
              <td>Bank</td>
              <td>{{ item.account_type }}</td>
            </tr>
            <tr v-if="item.account_number">
              <td>Account Number</td>
              <td>{{ item.account_number }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { appStore } from '@/store/app';

export default {
  name: 'UserAccounts',
  props: {
    data: Object,
  },
  methods: {
    copyData(data) {
      navigator.clipboard.writeText(data);
      appStore().setNotification({
        message: 'Copied successfully',
      });
    },
  },
};
</script>
