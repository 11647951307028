<template>
  <div v-if="!hideRow" class="custom">
    <div style="position: relative" lang="en-US">
      <!-- eslint-disable-next-line -->
      <input
        v-if="editMode"
        :type="inputType === 'number' ? 'number' : 'text'"
        class="custom__input"
        @blur="saveRow()"
        min="0.01"
        ref="inputRef"
        @keydown.enter="saveRow()"
        @keydown.esc="editMode = false"
        v-model="data" />
      <p
        v-else
        class="custom__action"
        @keydown="editCell()"
        @click="editCell()">
        {{ formatedValue }}
      </p>
    </div>
    <div class="custom__loader" v-if="loading">
      <img src="@/assets/loading.gif" alt="loading">
    </div>
  </div>
</template>
<script setup>
import { debounce } from 'lodash';
import { computed, onMounted, ref } from 'vue';
import { numberFormat } from '../util/helpers';

const data = ref(null);
const cell = defineProps(['params']);

const editMode = ref(false);

const loading = ref(false);

const hideRow = computed(() => cell.params.node.uiLevel === 1);

const inputType = ref(null);

const inputRef = ref(null);

const formatInput = (value) => {
  if (inputType.value === 'number' && value === '') {
    return null;
  }
  return value;
};

const debouncedSaveRow = debounce(() => {
  editMode.value = false;
  const userID = cell.params.node.allLeafChildren[0]?.data?.user_id;
  const body = { [cell.params.colDef.field]: formatInput(data.value) };
  cell.params.colDef.params.onUpdateMetadata(userID, body);
}, 1000);

const formatedValue = computed(() => {
  if (inputType.value === 'number') {
    return numberFormat(data.value);
  }
  return data.value;
});
const saveRow = () => {
  debouncedSaveRow();
};

const editCell = () => {
  editMode.value = true;
  setTimeout(() => {
    inputRef.value.focus();
  }, 0);
};

onMounted(() => {
  if (!hideRow.value) {
    const value = cell.params.node.allLeafChildren[0].data[cell.params.colDef.field];
    data.value = value === null || value === undefined ? null : value;
    inputType.value = cell.params.colDef.params.type;
  }
});
</script>
<style lang="scss" scoped>
.ag-grid__actions--hidden {
  display: none;
}

.custom {
  width: calc(100% + 16px);
  transform: translateX(-16px);
  &__action {
    width: 100%;
    height: 38px;
    cursor: text;
    font-family: inherit;
    padding: 0 8px;
  }
  &__input {
    width: 100%;
    height: 34px;
    border: none;
    appearance: none;
    outline: none;
    padding: 2px 8px;
    font-family: inherit;
    background: #fff;
    font-size: 15px;
  }
  &__loader {
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 38px;
    display: flex;
    justify-content: center;
    z-index: 9999;
    img {
      width: 38px;
      height: 38px;
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
