<template>
    <!-- eslint-disable  -->
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="36" height="36" rx="18" fill="#EBF2FF"/>
        <g clip-path="url(#clip0_4378_52201)">
        <path d="M19.6667 19.8767V21.6183C18.9125 21.3517 18.1053 21.2699 17.3129 21.3798C16.5205 21.4898 15.766 21.7882 15.1129 22.2502C14.4598 22.7121 13.927 23.324 13.5593 24.0345C13.1917 24.745 12.9999 25.5333 13 26.3333L11.3334 26.3325C11.3331 25.3149 11.5658 24.3108 12.0136 23.397C12.4614 22.4832 13.1125 21.6841 13.9169 21.0609C14.7213 20.4376 15.6577 20.0068 16.6543 19.8014C17.651 19.596 18.6814 19.6214 19.6667 19.8758V19.8767ZM18 18.8333C15.2375 18.8333 13 16.5958 13 13.8333C13 11.0708 15.2375 8.83333 18 8.83333C20.7625 8.83333 23 11.0708 23 13.8333C23 16.5958 20.7625 18.8333 18 18.8333ZM18 17.1667C19.8417 17.1667 21.3334 15.675 21.3334 13.8333C21.3334 11.9917 19.8417 10.5 18 10.5C16.1584 10.5 14.6667 11.9917 14.6667 13.8333C14.6667 15.675 16.1584 17.1667 18 17.1667ZM23 22.1667V19.6667H24.6667V22.1667H27.1667V23.8333H24.6667V26.3333H23V23.8333H20.5V22.1667H23Z" fill="#0061FF"/>
        </g>
        <defs>
        <clipPath id="clip0_4378_52201">
        <rect width="20" height="20" fill="white" transform="translate(8 8)"/>
        </clipPath>
        </defs>
    </svg>
</template>
