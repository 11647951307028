<template>
  <div class="settings__wrapper">
    <r-header ref="header">
      <template #content>
          <r-nav :items="navItems"></r-nav>
      </template>
    </r-header>
    <router-view></router-view>
  </div>
</template>

<script setup>
import RNav from '@/components/Layouts/RNav.vue';
import RHeader from '@/components/Layouts/RHeader.vue';
import { ref } from 'vue';

const navItems = ref([
  { text: 'Data Updates', to: 'Data Update', icon: 'RefreshIcon' },
  { text: 'Email Template', to: 'EmailCustomization', icon: 'MailUnreadIcon' },
]);
</script>

<style scoped lang="scss">
.settings{
  &__wrapper{
    margin: -40px -30px;
    height: 100%;
  }
}
</style>
