<template>
  <OnClickOutside @trigger="hideAutocompleteList()">
    <div class="input"
      :class="{
        'input--cursor': type === 'color',
        'input--icon-left': leftIcon,
        'input--icon-right': rightIcon,
        'input--error': error,
        'input--compact': compact,
        'input--rounded': rounded}">
      <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
      <label v-if="label" :for="labelWithoutSpace">
        {{label}}
        <r-tool-tip class="tool-tip" v-if="tooltipText">
          <template #tooltip-text>
              <span>
                {{tooltipText}}
              </span>
          </template>
        </r-tool-tip>
      </label>
      <input
        type="text"
        :id="labelWithoutSpace"
        :name="labelWithoutSpace"
        :placeholder="placeholder"
        :disabled="disabled"
        @click="onClick"
        @focus="onFocus"
        ref="input"
        v-model="updateModelValue"
        :maxlength="maxlength"
      >

      <i v-if="rightIcon"
        class="input__icon input__icon--right noselect" :class="rightIcon">
      </i>

      <i v-if="autocomplete"
        @click="clearInput()"
        @keypress="clearInput()"
        class="input__icon input__icon--right ri-close-circle-fill"
        :class="{
          'active': updateModelValue,
        }"
        >
      </i>

      <i v-if="leftIcon"
        class="input__icon input__icon--left noselect" :class="leftIcon">
      </i>
      <i
        v-if="type==='color'"
        @keyup="colorPopup()"
        @click="colorPopup()"
        class="input__icon input__icon--right noselect ri-arrow-down-s-fill">
      </i>
      <div
        v-if="type==='color'"
        @keyup="colorPopup()"
        @click="colorPopup()"
        class="input__color"
        :style="`background: ${currentValue}`">
      </div>
      <p
        v-if="type==='color'"
        @keyup="colorPopup()"
        @click="colorPopup()"
        class="input__color-name">
        {{currentValue}}
      </p>
      <div v-if="autocomplete" class="input__autocomplete" :class="{
        'input__autocomplete--show': showAutocompleteList,
      }">
        <ul>
          <li v-for="item in autocompleteList" :key="item.id"
            @click="selectedEmit(item)" @keyup="selectedEmit(item)">
            {{ item.name }}
          </li>
        </ul>
      </div>
      <p v-if="!compact" class="input__error">{{error}}</p>
    </div>
  </OnClickOutside>
</template>
<script>
import RToolTip from '@/components/RToolTip.vue';
import { OnClickOutside } from '@vueuse/components';

export default {
  name: 'RInput',
  components: {
    RToolTip,
    OnClickOutside,
  },
  props: {
    label: String,
    placeholder: String,
    rightIcon: String,
    leftIcon: String,
    error: String,
    rounded: Boolean,
    tooltipText: String,
    compact: Boolean,
    maxlength: Number,
    disabled: Boolean,
    autocomplete: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: 'text',
      validator: (value) => 'text|number|email|password|color|search|url|tel'.split('|').indexOf(value) > -1,
    },
    defaultColor: String,
    modelValue: {
      default: '',
    },
  },
  data() {
    return {
      currentValue: this.defaultColor,
      autocompleteList: null,
      showAutocompleteList: false,
    };
  },
  emits: ['update:modelValue', 'selected'],
  computed: {
    labelWithoutSpace() {
      return this.label ? this.label.replace(/\s/g, '').toLowerCase() : '';
    },
    updateModelValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.currentValue = value;
        if (this.autocomplete?.value) {
          // eslint-disable-next-line max-len
          this.autocompleteList = this.autocomplete.value.filter((item) => item.name.toLowerCase().includes(value.toLowerCase()));
        }
      },
    },
  },
  watch: {
    'autocomplete.value': {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.autocompleteList = newValue;
        }
      },
    },
  },
  mounted() {
    this.$refs.input.type = this.type;
    if (this.autocomplete?.value) {
      this.autocompleteList = this.autocomplete.value;
    }
  },
  methods: {
    colorPopup() {
      this.$refs.input.focus();
      this.$refs.input.click();
    },
    onFocus() {
      if (this.autocomplete) {
        this.showAutocompleteList = true;
      }
    },
    hideAutocompleteList() {
      if (this.autocomplete) {
        this.showAutocompleteList = false;
      }
    },
    selectedEmit(item) {
      this.$emit('selected', item);
      this.updateModelValue = item.name;
      this.hideAutocompleteList();
    },
    clearInput() {
      this.updateModelValue = '';
      this.$emit('selected', '');
    },
  },
};
</script>
<style scoped lang="scss">
.input {
  width: 100%;
  position: relative;
  margin-bottom: 32px;
  &--compact {
    margin-bottom: 0;
  }
  &__autocomplete {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
    max-height: 364px;
    overflow-y: auto;
    z-index: 999;
    &--show {
      display: block;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      li {
        padding: 16px 16px;
        font-size: 14px;
        font-weight: 500;
        color: var(--color-black);
        border-bottom: 1px solid var(--grey-outline, #EDF2F8);
        cursor: pointer;
        &:hover {
          background: var(--blue-background, #F8FBFD);
        }
      }
    }
  }
  input {
    width: inherit;
    min-height: var(--dim-input-height);
    padding: 13px 16px 11px 16px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 500;
    color: var(--color-black);
    border: 1px solid var(--color-grey-light);
    border-radius: 8px;
    outline: none;
    box-sizing: border-box;
    &::placeholder {
      color: var(--color-grey-light);
      font-weight: 500;
    }
    &:disabled {
      background-color: var(--color-grey-disabled);
    }
    &[type=color] {
      appearance: none;
      -webkit-appearance: none;
      min-height: var(--dim-input-height);
      background: var(--color-input-background);
      &::-webkit-color-swatch-wrapper {
        padding: 0;
      }
      &::-webkit-color-swatch {
        border: none;
        display: none;
      }
    }
    &[type=number]{
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
  }
  label {
    display: flex;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-dark-main);
    padding-bottom: 6px;
    & .tool-tip {
      margin-left: 4px;
    }
  }
  i {
    font-size: 18px;
  }
  &__error {
    position: absolute;
    width: inherit;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-red-error);
    padding-top: 6px;
  }
  &__icon {
    &--left {
      position: absolute;
      bottom: 15px;
      left: 16px;
      z-index: 2;
      color: var(--color-grey-light);
    }
    &--right {
      position: absolute;
      bottom: 15px;
      z-index: 2;
      right: 16px;
      color: var(--color-grey-dark);
      &.ri-close-circle-fill {
        display: none;
        color: #A8AFBC;
        cursor: pointer;
        &:hover {
          color: var(--color-grey-dark);
        }
        &.active {
          display: block;
        }
      }
    }
  }
  &--error {
    input {
      border-color: var(--color-red-error);
    }
  }
  &--rounded {
    margin-bottom: 4px;
    input {
      border-radius: 999px;
    }
  }
  &--icon {
    &-left {
      input {
        padding-left: 44px;
      }
    }
    &-right {
      input {
        padding-right: 44px;
      }
    }
  }
  &--cursor {
    user-select: none;
    cursor: pointer;
    input {
      cursor: pointer;
      padding-left: 52px;
    }
    i {
      bottom: 20px;
    }
  }
  &:hover, &:active, &:focus, &:focus-within {
    &:not(.input--error) {
      input {
        &:not([disabled]) {
          border-color: var(--color-blue-main);
        }
      }
    }
  }
  &__color {
    position: absolute;
    bottom: 17px;
    left: 18px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--color-grey-outline);
    border-radius: 4px;
    &-name {
      position: absolute;
      bottom: 17px;
      font-size: 16px;
      font-weight: 600;
      color: var(--color-black);
      left: 52px;
      text-transform: uppercase;
    }
  }
}
// input::-webkit-outer-spin-button,
// input::-webkit-inner-spin-button {
//     /* display: none; <- Crashes Chrome on hover */
//     -webkit-appearance: none;
//     margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
// }
</style>
