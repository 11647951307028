import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';

const getVerification = async (taxId) => {
  const DOMAIN = DOMAIN_PATH;
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await http.get(`${DOMAIN}/inpi/company/${taxId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
      },
    });

    return response.data;
  } catch (err) {
    throw err;
  }
};

export default {
  getVerification,
};
