<template>
  <div class="sections sections--key-metrics">
    <div class="sections__head">
      <h5>Error Occurrences</h5>
      <r-tool-tip>
        <template #tooltip-text>
                    <span>
                       <!-- eslint-disable-next-line  -->
                       Most common error type that occurs and affects your users during the Connect process.
                    </span>
        </template>
      </r-tool-tip>
    </div>
    <div class="sections__tab">
      <div v-if="props.isLoading">
        <r-loading></r-loading>
      </div>
      <div v-else class="sections__tab-container">
        <div>
          <vue-apex-charts
            type="line" :options="chartOptions" :series="chartSeries"></vue-apex-charts>
        </div>
      </div>
      <empty-state v-if="showEmptyState && !props.isLoading"></empty-state>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref, watch,
} from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import RLoading from '@/components/RLoading.vue';
import RToolTip from '@/components/RToolTip.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import { groupErrors } from '@/util/mappers';

/* eslint-disable vue/no-setup-props-destructure */
const props = defineProps(['data', 'isLoading']);

/* eslint-disable */
const chartOptions = ref({
  chart: {
    id: 'basic-line-chart',
  },
  xaxis: {
    categories: ['01 Jul', '01 Aug', '01 Sep'],
  },

  colors:['#DE6E6A', '#84BFDB', '#FE9543'],
  stroke: {
    width: 3.4,
    dashArray: [0, 0, 8]
  },
  toolbar: {
    show: false,
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2 // Stroke width of the marker when hovering
    }
  },
  yaxis: {
    min: 0,
    tickAmount: 5,
    labels: {
      formatter: function (val) {
        return Math.ceil(val);
      }
    }
  },
});


const chartSeries = ref([

]);

const formatDate = (dateString) => {
  const options = { day: '2-digit', month: 'short' };
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', options);
};

const populateChart = () => {
  // Get All dates of errors
  const allDates = props.data.map((err)=> err.date);
  // Get uniq dates
  const uniqDates = [
    ...new Set([
      ...allDates
    ]),
  ];
  const errorData = [
    {
      name: 'Credentials Errors',
      data: []
    },
    {
      name: 'MFA Errors',
      data: []
    },
    {
      name: 'Other Errors',
      data: []
    },
  ]
  uniqDates.forEach((date, index)=> {
    const errorsOnThatDay = props.data.filter((d)=> date === d.date);
    // For every accruing date push 0 value to that error
    errorData.forEach((err)=>{
      err.data.push(0);
    })
    // For every found error accruing on that date replace 0 with total
    errorsOnThatDay.forEach((err)=>{
      const error = errorData.find((u) => u.name === groupErrors(err.error));
      error.data[index] += err.total;
    })
  })

  chartSeries.value = errorData;
  chartOptions.value.xaxis.categories = uniqDates?.map(date => formatDate(date));
  chartOptions.value.yaxis.max = getMaxErrorOccurrences(errorData) + 5;
};

const showEmptyState = computed(() => props.data.length === 0);

const getMaxErrorOccurrences = (errorData) => {
  const totals = errorData.map((data) => data.data);
  const flatten = totals.flat(Infinity)
  let max = 0
  flatten.map((total)=>{
    max = Math.max(max, total)
  })
  return max;
}


watch(()=> props.data, ()=>{
  populateChart();
}, {
  immediate: true,
})
</script>

<style lang="scss" scoped>
.sections {
  max-width: 100%;
  .sections__head {
    padding: 16px 32px;
    display: flex;
    align-items: center;
    gap: 8px;
    position: absolute;
    z-index: 99;
  }
  &__tab {
    padding-right: 46px;
    margin-top: 23px;
    position: relative;
  }
  h5 {
    color: var(--color-black, #141414);
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 28.8px */
  }
}
:deep {
  .vue-apexcharts {
    padding-left: 32px;
    .apexcharts-toolbar {
      display: none;
    }
    .apexcharts-gridline {
      stroke-dasharray: 1, 1; /* Dash length, Gap length */
      stroke-width: 1; /* Stroke width */
      stroke: #EDF2F8; /* Stroke color */
    }
    .apexcharts-yaxis-texts-g, .apexcharts-xaxis-texts-g {
      tspan {
        fill: var(--color-grey-light, #A8AFBC);
        font-size: 12px;
        font-weight: 600;
        font-family: Gilroy;
      }
    }
    .apexcharts-tooltip {
      top: 100px !important; /* Position it at the top */
    }
    .apexcharts-xcrosshairs {
      stroke: #A8AFBC;
    }
  }
  .tab-container__content {
    padding-top: 32px;
  }
  .custom-tooltip {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    width: 180px;

    &__header {
      border-radius: 4px 4px 0px 0px;
      border: 1px solid #E2E3E3;
      background: #EDEEF1;
      color:var(--color-black, #141414);
      padding: 4px 8px;
      font-size: 12px;
      font-weight: 500;
    }
    &__item {
      display: flex;
      padding: 8px;
      justify-content: space-between;
      align-items: center;
      span {
        display: flex;
        height: 12px;
        width: 12px;
        border-radius: 12px;
        background: var(--color-blue-main, #0061FF);

        &.transparent {
          background: transparent;
        }
      }
      div {
        display: flex;
        gap: 8px;
        align-items: center;
        h6 {
          color: var(--color-black, #141414);
          font-size: 12px;
          font-weight: 500;
        }
      }
      h6 {
        color: var(--color-black, #141414);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
  .apexcharts-legend{
    overflow: hidden;
    &-text{
      display: inline-block;
      transform: translateY(-2px);
    }
  }
}
</style>
