import { defineStore } from 'pinia';
import AppAPI from '@/api/app';

// eslint-disable-next-line import/prefer-default-export
export const verificationStore = defineStore({
  // id is required so that Pinia can connect the store to the devtools
  id: 'verificationStore',
  state: () => ({
    data: null,
    searchValue: null,
    isLoading: false,
    metrics: {
      financial: {
        score: 80,
        inconsistentReporting: 32,
        unusualFinancialRatios: 30,
      },
      ownershipStructure: {
        score: 20,
        ownerShipChanges: 20,
        directorAndShareholders: 60,
        opaqueOwnerShip: 50,
      },
      operations: {
        score: 80,
        physicalPresence: 100,
        businessActivity: 0,
        digitalFootprint: 0,
      },
    },
  }),
  getters: {
    getCompanyData: (state) => state.data,
    getIsLoading: (state) => state.isLoading,
    getSearchValue: (state) => state.searchValue,
    getMetrics: (state) => state.metrics,
  },
  actions: {
    getVerification(taxId) {
      this.isLoading = true;
      return new Promise((resolve, reject) => {
        AppAPI.getVerification(taxId).then((data) => {
          this.data = data;
          this.isLoading = false;
          resolve(data);
        }).catch((err) => {
          this.isLoading = false;
          reject(err);
        });
      });
    },
    setSearchValue(company) {
      this.searchValue = company;
    },
    verificationStatus(percent) {
      switch (true) {
        case percent >= 80:
          return 'danger';
        case percent < 80 && percent >= 40:
          return 'alert';
        default:
          return 'verified';
      }
    },
    verificationStatusColors(value) {
      switch (this.verificationStatus(value)) {
        case 'alert':
          return '#FE9543';
        case 'danger':
          return '#F52922';
        default:
          return '#8FB571';
      }
    },
  },
});
