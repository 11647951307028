import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';
const DOMAIN_SAND_PATH = process.env.VUE_APP_API_SAND_URL || '/api';

const getHeaders = () => {
  const currentPartner = localStorage.getItem('partner_id');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  const clientId = new URLSearchParams(window.location.search).get('client_id') || currentPartner;
  if (clientId) {
    headers['Client-Id'] = clientId;
  }
  return headers;
};

const saveEmailCustomization = async (params) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const PARAMS = params;
  const response = await http.post(`${DOMAIN}/email_customizations`, { config: PARAMS }, {
    headers: getHeaders(),
  });
  return response;
};

const updateEmailCustomization = async (params) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.put(`${DOMAIN}/email_customizations/${params.id}`, { config: params.data }, {
    headers: getHeaders(),
  });
  return response;
};

const fetchEmailCustomization = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.get(`${DOMAIN}/email_customizations`, {
    headers: getHeaders(),
  });
  return response;
};

const uploadImage = async (params) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.post(`${DOMAIN}/upload_image`, params, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
    },
  });
  return response;
};

const getRobotsList = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.get(`${DOMAIN}/data_settings`, {
    headers: getHeaders(),
  });
  return response;
};

const getEndpointsList = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.get(`${DOMAIN}/documentation/endpoints`, {
    headers: getHeaders(),
  });
  return response;
};

const postRobotsUpdate = async (data) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  try {
    const response = await http.post(`${DOMAIN}/data_settings`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getTrialPaymentInfo = async () => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.get(`${DOMAIN}/amount_to_charge`, {
    headers: getHeaders(),
  });
  return response;
};

export default {
  saveEmailCustomization,
  fetchEmailCustomization,
  updateEmailCustomization,
  uploadImage,
  getRobotsList,
  getEndpointsList,
  postRobotsUpdate,
  getTrialPaymentInfo,
};
