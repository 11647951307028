<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.1666 3.99996H17.3333V5.66663H15.6666V16.5C15.6666
    16.721 15.5788 16.9329 15.4225 17.0892C15.2663 17.2455 15.0543 17.3333 14.8333
    17.3333H3.16663C2.94561 17.3333 2.73365 17.2455 2.57737 17.0892C2.42109 16.9329 2.33329
    16.721 2.33329 16.5V5.66663H0.666626V3.99996H4.83329V1.49996C4.83329 1.27895 4.92109
    1.06698 5.07737 0.910704C5.23365 0.754423 5.44561 0.666626 5.66663 0.666626H12.3333C12.5543
    0.666626 12.7663 0.754423 12.9225 0.910704C13.0788 1.06698 13.1666 1.27895 13.1666
    1.49996V3.99996ZM14 5.66663H3.99996V15.6666H14V5.66663ZM6.49996
    8.16663H8.16663V13.1666H6.49996V8.16663ZM9.83329 8.16663H11.5V13.1666H9.83329V8.16663ZM6.49996
    2.33329V3.99996H11.5V2.33329H6.49996Z" fill="#7A808B"/>
  </svg>
</template>
