<template>
  <div class="asection__wrapper" v-if="showSection">
    <div class="asection asection--progress asection--no-shadow">
      <CloseIcon class="asection__close" @click="updateShowSection()"  />
      <img src="@/assets/get-started.svg" alt="Get Started"/>
      <div class="content">
        <h2 class="content__title">
          Start your set up just in <b>three</b> quick steps.
        </h2>
        <p>
          Access real-time data directly
          from sources make better decisions
          with access<br /> to users’ activity, income,
          profile, real-time earnings and work history.
        </p>
        <img class="content__steps" src="@/assets/stepper-static.svg" alt="Steps" />
      </div>
    </div>
  </div>
</template>

<script setup>

import { CloseIcon } from '@/components/icons';
import { ref } from 'vue';

const showSection = ref(window.localStorage.getItem('get-started') !== 'true');
const updateShowSection = () => {
  window.localStorage.setItem('get-started', 'true');
  showSection.value = false;
};
</script>

<style lang="scss" scoped>

</style>
