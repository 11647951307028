<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_7346_127229)">
<path d="M15.8334 16.6668H4.16675V17.5002C4.16675 17.7212 4.07895 17.9331 3.92267
18.0894C3.76639 18.2457 3.55443 18.3335 3.33341 18.3335H2.50008C2.27907 18.3335 2.06711
18.2457 1.91083 18.0894C1.75455 17.9331 1.66675 17.7212 1.66675 17.5002V9.16683L3.73341
4.3435C3.86202 4.0435 4.07588 3.78786 4.34846 3.60831C4.62104 3.42876 4.94035 3.33321
5.26675 3.3335H14.7334C15.0595 3.33353 15.3785 3.42924 15.6507 3.60877C15.923 3.7883
16.1366 4.04376 16.2651 4.3435L18.3334 9.16683V17.5002C18.3334 17.7212 18.2456 17.9331
18.0893 18.0894C17.9331 18.2457 17.7211 18.3335 17.5001 18.3335H16.6667C16.4457 18.3335
16.2338 18.2457 16.0775 18.0894C15.9212 17.9331 15.8334 17.7212 15.8334 17.5002V16.6668ZM16.6667
10.8335H3.33341V15.0002H16.6667V10.8335ZM3.48008 9.16683H16.5201L14.7342 5.00016H5.26675L3.48091
 9.16683H3.48008ZM5.41675 14.1668C5.08523 14.1668 4.76729 14.0351 4.53286 13.8007C4.29844 13.5663
 4.16675 13.2484 4.16675 12.9168C4.16675 12.5853 4.29844 12.2674 4.53286 12.0329C4.76729 11.7985
 5.08523 11.6668 5.41675 11.6668C5.74827 11.6668 6.06621 11.7985 6.30063 12.0329C6.53505 12.2674
 6.66675 12.5853 6.66675 12.9168C6.66675 13.2484 6.53505 13.5663 6.30063 13.8007C6.06621 14.0351
 5.74827 14.1668 5.41675 14.1668ZM14.5834 14.1668C14.2519 14.1668 13.934 14.0351 13.6995
 13.8007C13.4651 13.5663 13.3334 13.2484 13.3334 12.9168C13.3334 12.5853 13.4651 12.2674
 13.6995 12.0329C13.934 11.7985 14.2519 11.6668 14.5834 11.6668C14.9149 11.6668 15.2329
 11.7985 15.4673 12.0329C15.7017 12.2674 15.8334 12.5853 15.8334 12.9168C15.8334 13.2484
 15.7017 13.5663 15.4673 13.8007C15.2329 14.0351 14.9149 14.1668 14.5834 14.1668Z" fill="#8FB571"/>
</g>
<defs>
<clipPath id="clip0_7346_127229">
<rect width="20" height="20" fill="white"/>
</clipPath>
</defs>
</svg>

</template>
