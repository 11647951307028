<template>
  <div class="file__wrapper">
    <p class="file__title">
      {{ label }}
      <r-tool-tip class="tool-tip" v-if="tooltipText">
        <template #tooltip-text>
            <span>
              {{tooltipText}}
            </span>
        </template>
      </r-tool-tip>
    </p>
    <div class="file">
      <!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
      <input
      :accept="allowedFileTypes"
      type="file"
      @click="$event.target.value=null"
      @change="onChange($event)"
      ref="input">
      <div v-if="!imageSrc" class="file__upload">
        <i class="ri-add-circle-fill"></i>
        <p class="file__upload-text">
          {{text}} <a href="">upload a file</a>
        </p>
        <p>
          {{info}}
        </p>
      </div>
      <div class="file__preview" v-show="imageSrc">
        <div v-if="isFile" class="file__name">
          <div class="file__name-icon">
            <i class="ri-file-line"></i>
          </div>
          <h1>{{ fileName }}</h1>
        </div>
        <img v-show="!isFile" :src="imageSrc" ref="preview" alt="" />
        <i @keyup="clearImage()" @click="clearImage()" class="ri-delete-bin-7-line"></i>
        <i @keyup="onClick()" @click="onClick()" class="ri-upload-cloud-2-line upload"></i>
      </div>
    </div>
  </div>
</template>
<script>
import RToolTip from '@/components/RToolTip.vue';
import Data from '@/api/settings';
import { appStore } from '@/store/app';

export default {
  name: 'RFileUpload',
  components: { RToolTip },
  emits: ['image'],
  props: {
    tooltipText: String,
    text: {
      type: String,
      default: 'Drop a horizontal logo here or',
    },
    info: {
      type: String,
      default: 'JPG, PNG or SVG supported.',
    },
    data: {
      type: String,
      default: '',
    },
    upload: {
      type: Boolean,
      default: false,
    },
    isFile: {
      type: Boolean,
      default: false,
    },
    allowedFileTypes: {
      type: Array,
    },
    label: {
      type: String,
      default: 'Branding Logo',
    },
  },
  data() {
    return {
      imageExist: false,
      localData: null,
      fileName: '',
    };
  },
  computed: {
    imageSrc() {
      return this.data === '/img/part/rollee-logo.png' ? false : this.data;
    },
  },
  methods: {
    onChange(e) {
      const file = e.target.files[0];
      const maxSize = 500 * 1024;
      if (!file) return;
      if (this.isFile) {
        const selectedFile = new File([file], file.name, { type: file.type });
        this.fileName = file.name;
        this.$emit('image', selectedFile);
        return;
      }

      if (file.size > maxSize) {
        // Display an error message or handle the oversized file case
        console.error('File size exceeds the limit');
        appStore().setNotification({
          type: 'error',
          message: 'Maximum file size is 500KB.',
        });
        return;
      }

      if (!this.upload) {
        const reader = new FileReader();
        reader.onload = (test) => {
          console.log(test);
          const output = this.$refs.preview;
          console.log(reader);
          output.src = reader.result;
          this.localData = reader.result;
          this.$emit('image', reader.result);
        };
        reader.readAsDataURL(file);
      } else {
        const formData = new FormData();
        formData.append('file', file);
        Data.uploadImage(formData)
          .then((res) => {
            const output = this.$refs.preview;
            output.src = res.data.url;
            this.$emit('image', res.data.url);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onClick() {
      this.$refs.input.click();
    },
    clearImage() {
      this.imageExist = false;
      this.$refs.preview.src = '';
      this.$emit('image', null);
    },
  },
  mounted() {
    const output = this.$refs.preview;
    output.src = this.data;
  },
};
</script>
<style lang="scss" scoped>
.file {
  display: grid;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 136px;
  border: 1px solid var(--color-grey-outline);
  border-radius: 8px;
  margin-bottom: 40px;
  background: #fff;
  position: relative;
  transition: var(--transition-default);
  &:hover {
    border-color: var(--color-blue-main);
  }
  &__title {
    font-size: 14px;
    font-weight: 700;
    color: var(--color-dark-main);
    margin-bottom: 6px;
    display: flex;
    & .tool-tip{
      margin-left: 4px;
    }
  }
  &__upload {
    display: grid;
    justify-items: center;
    i {
      font-size: 23px;
      color: var(--color-grey-light);
      margin-bottom: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-grey-light);
      margin-top: 6px;
      &.file__upload-text {
        font-weight: 600;
        color: var(--color-dark-main);
        a {
          color: var(--color-blue-main);
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
  input {
    width: 100%;
    height: 100%;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
  }
  &__preview {
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 4px;
      object-fit: contain;
      box-sizing: border-box;
      border-radius: 8px;
      background-color: #fff;
    }
    i {
      border-radius: 50%;
      position: absolute;
      top: 12px;
      right: 12px;
      width: 24px;
      height: 24px;
      font-size: 22px;
      color: var(--color-grey-light);
      cursor: pointer;
      text-align: center;
      line-height: 22px;
      &.upload {
        right: 48px;
      }
    }
  }

  &__name {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 8px;
    &-icon {
      i {
        position: relative !important;
        top: 0 !important;
        right: 0 !important;
        font-size: 20px;
        color: var(--color-grey-light);
      }
    }
    h1 {
      font-size: 14px;
      font-weight: 500;
      color: var(--color-dark-main);
    }
  }
}
</style>
