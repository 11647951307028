<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div class="card__head">
        <div class="card__head-icon">
            <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
            <BarIcon/>
        </div>
        <h5>User income evolution</h5>
    </div>
    <div class="card__content">
      <vue-apex-charts
        v-if="props.graphs?.plot_json_earning_evolution_and_industry_average"
        :height="450"
        :options="barGraphOptions"
        :series="props.graphs.plot_json_earning_evolution_and_industry_average">
      </vue-apex-charts>
      <NoDataAvailable v-else/>
    </div>
</template>
<script setup>
import VueApexCharts from 'vue3-apexcharts';
import { numberFormat } from '@/util/helpers';
import BarIcon from './icons/BarIcon.vue';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['data', 'graphs', 'currency']);
const formatDate = (val) => {
  if (!val) return '';
  const date = new Date(val);
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  return `${day} ${month}`;
};
const barGraphOptions = {
  chart: {
    id: 'basic-line-chart',
    width: '100%',
    height: 345,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      hideOverlappingLabels: true,
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        return formatDate(val);
      },
    },
    title: {
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        if (val !== Number.POSITIVE_INFINITY) {
          // eslint-disable-next-line radix
          return `${numberFormat(val)} ${props.currency}`;
        }
        return undefined;
      },
    },
    title: {
      text: 'Daily earnings',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 600,
      },
    },
  },
  colors: ['#0061FF', '#C5CEE0'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};
</script>

<style scoped>

h3{
    color: var(--color-grey-dark, #7A808B);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}

.card{
    height: calc(100% - 55px);
}
.card__head{
    gap: 15px;
}

.card__content{
    margin-top: 60px;
    transform: translateY(-55px);
    height: 450px;
}
.summary__data-section{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #EDF2F8;
}

.summary__data{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    justify-content: space-between;
    margin-top: 24px;
}

.summary__data-item p{
    color: var(--color-dark-main);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    margin-top: 10px;
}

.summary__graphs{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.summary__graphs-item h3{
    margin-bottom: 10px;
}
</style>
