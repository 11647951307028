<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
    <path id="Vector" d="M10.0001 1.66675C14.6001 1.66675 18.3334
    5.40008 18.3334 10.0001C18.3334 14.6001 14.6001 18.3334 10.0001
    18.3334C5.40008 18.3334 1.66675 14.6001 1.66675 10.0001C1.66675
    5.40008 5.40008 1.66675 10.0001 1.66675ZM10.0001 3.33341C6.32091
    3.33341 3.33341 6.32091 3.33341 10.0001C3.33341 13.6792 6.32091
    16.6667 10.0001 16.6667C13.6792 16.6667 16.6667 13.6792 16.6667
    10.0001C16.6667 6.32091 13.6792 3.33341 10.0001 3.33341ZM10.0001
    5.00008C12.3009 5.00008 14.1667 7.23842 14.1667 10.0001C14.1667
    12.7617 12.3009 15.0001 10.0001 15.0001C7.69925 15.0001 5.83342
    12.7617 5.83342 10.0001C5.83342 7.23842 7.69925 5.00008 10.0001
    5.00008ZM11.9376 7.89341L8.96925 13.0376C9.28341 13.2276 9.63258
    13.3334 10.0001 13.3334C11.3809 13.3334 12.5001 11.8417 12.5001
    10.0001C12.5001 9.20091 12.2892 8.46758 11.9376 7.89341ZM10.0001
    6.66675C8.61925 6.66675 7.50008 8.15841 7.50008 10.0001C7.50008
    10.7992 7.71092 11.5326 8.06258 12.1067L11.0309 6.96258C10.721
    6.77106 10.3644 6.6687 10.0001 6.66675Z" fill="#FE9543"/>
    </g>
    </svg>
</template>
