<template>
  <svg width="122" height="122" viewBox="0 0 122 122" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="61" cy="61" r="30" fill="url(#paint0_linear_8073_59046)" fill-opacity="0.4"/>
    <circle cx="61" cy="61" r="40" stroke="#02B3FB"
            stroke-opacity="0.48" stroke-linecap="round" stroke-dasharray="1 6"/>
    <circle cx="61" cy="61" r="50" stroke="#02B3FB"
            stroke-opacity="0.28" stroke-linecap="round" stroke-dasharray="1 6"/>
    <circle cx="61" cy="61" r="60" stroke="#02B3FB"
            stroke-opacity="0.12" stroke-linecap="round" stroke-dasharray="1 6"/>
    <circle cx="61.0001" cy="61.0002" r="22.2222" fill="url(#paint1_linear_8073_59046)"/>
    <g clip-path="url(#clip0_8073_59046)">
      <path d="M50.3484 50.2188L61 47.8517L71.6517 50.2188C71.9396 50.2828 72.197 50.443
      72.3815 50.673C72.5661 50.903 72.6667 51.1891 72.6667 51.484V64.4301C72.6666 65.7105
      72.3504 66.971 71.7462 68.0999C71.142 69.2288 70.2684 70.191 69.203 70.9012L61
      76.3703L52.7971 70.9012C51.7319 70.1911 50.8584 69.2291 50.2542 68.1005C49.65
      66.9719 49.3337 65.7116 49.3334 64.4314V51.484C49.3334 51.1891 49.434 50.903
      49.6185 50.673C49.8031 50.443 50.0605 50.2828 50.3484 50.2188V50.2188Z"
            fill="url(#paint2_linear_8073_59046)"/>
    </g>
    <defs>
      <linearGradient id="paint0_linear_8073_59046" x1="3.5"
                      y1="1.83333" x2="76.8333" y2="91" gradientUnits="userSpaceOnUse">
        <stop stop-color="#02B3FB"/>
        <stop offset="1" stop-color="#0061FF"/>
      </linearGradient>
      <linearGradient id="paint1_linear_8073_59046" x1="18.4075"
                      y1="17.173" x2="72.7284" y2="83.2224" gradientUnits="userSpaceOnUse">
        <stop stop-color="#02B3FB"/>
        <stop offset="1" stop-color="#0061FF"/>
      </linearGradient>
      <linearGradient id="paint2_linear_8073_59046" x1="58.4153"
                      y1="50.5282" x2="72.63" y2="78.9076" gradientUnits="userSpaceOnUse">
        <stop stop-color="white"/>
        <stop offset="1" stop-color="white" stop-opacity="0"/>
      </linearGradient>
      <clipPath id="clip0_8073_59046">
        <rect width="31.1111" height="31.1111" fill="white" transform="translate(45.4445 46.5554)"/>
      </clipPath>
    </defs>
  </svg>
</template>
