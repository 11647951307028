<!-- eslint-disable no-nested-ternary -->
<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <OnClickOutside
    @trigger="open = false"
    :options="{ ignore: ['.selected.open'] }"
    v-if="!hideRow" class="custom-select"
    @blur="open = false">
      <div ref="dropdownRef" class="selected" :class="{ open: open }" @click="open = !open">
        <span>{{ selected }}</span>
      </div>
      <ul ref="dropdownList" class="items" :class="{ selectHide: !open }">
        <li
          class="item"
          :class="{ active: selected === option }"
          v-for="(option, i) of options"
          :key="i"
          @click="
            handleChange(option)
          "
        >
          {{ option }}
          <CheckMarkIcon v-if="selected === option" />
        </li>
      </ul>
    </OnClickOutside>
</template>
<script setup>
import {
  computed, onMounted, reactive, ref, watch,
} from 'vue';
import { OnClickOutside } from '@vueuse/components';
import CheckMarkIcon from './Insights/icons/CheckMarkIcon.vue';

const data = ref('--');
const cell = defineProps(['params']);
const options = reactive(cell.params.colDef.params.values || []);
const open = ref(false);
const selected = ref(
  // eslint-disable-next-line no-nested-ternary
  data.value,
);

const dropdownRef = ref(null);
const dropdownList = ref(null);
const hideRow = computed(() => cell.params.node.uiLevel === 1);

const handleChange = (option) => {
  selected.value = option;
  data.value = option;
  open.value = false;
  const userID = cell.params.node.allLeafChildren[0].data.user_id;
  const body = { [cell.params.colDef.field]: data.value };
  cell.params.colDef.params.onUpdateMetadata(userID, body);
};

const checkPosition = () => {
  const body = document.querySelector('.ag-layout-normal');
  if (!body) {
    return;
  }
  const position = body.getBoundingClientRect().height
  - dropdownRef.value.getBoundingClientRect().top;
  if (position < 0) {
    dropdownList.value.style.top = 'auto';
    dropdownList.value.style.bottom = '100%';
    dropdownList.value.style.transform = '';
  }
};

watch(open, (val) => {
  if (!val) {
    return;
  }
  checkPosition();
});

onMounted(() => {
  if (!hideRow.value) {
    data.value = cell.params.node.allLeafChildren[0].data[cell.params.colDef.field] || null;
    if (data.value) {
      selected.value = data.value;
    }
  }
});
</script>
<style lang="scss" scoped>
.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 100%;
  cursor: pointer;

  .selected {
    padding: 0 8px;
    & span {
      transform: translateY(2px);
    }
  }

  &.open {
    border-radius: 6px 6px 0px 0px;
  }

  &:after {
    position: absolute;
    content: "";
    top: 20px;
    right: 1em;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-color: var(--color-grey-dark) transparent transparent transparent;
  }

  .items {
    border-radius: 8px;
    padding: 16px 8px;
    background: var(--white, #FFF);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.12);
    position: absolute;
    left: 0;
    right: 0;
    bottom: auto;
    z-index: 1;
    transform: translateY(6px);

    .item {
      color: var(--color-grey-dark, #7A808B);
      font-size: 15px;
      font-weight: 600;
      padding: 8px 16px;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 19px;
    }
  }
}

.custom-select .items div:hover {
  // background-color: #ad8225;
}

.selectHide {
  display: none;
}
.active{
  color: var(--color-dark-main) !important;
}
</style>
