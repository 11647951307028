<template>
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_6509_48691)">
            <!-- eslint-disable-next-line max-len -->
        <path d="M6.24996 9.48244L11.995 3.73682L12.8793 4.62057L6.24996 11.2499L2.27246 7.27244L3.15621 6.38869L6.24996 9.48244Z" :fill="fill"/>
        </g>
        <defs>
        <clipPath id="clip0_6509_48691">
        <rect width="15" height="15" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
  props: {
    fill: {
      required: false,
      default: '#0061FF',
    },
  },
};
</script>
