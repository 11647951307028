<template>
  <div class="card__sections">
    <div class="card__sections-main">
      <div class="card__head">
        <div class="card__head-icon">
            <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
            <PinIcon/>
        </div>
        <h5>Activity</h5>
        <p class="card__head-info">Last 90 days</p>
      </div>
      <div class="card__content">
          <r-tab ref="tabRef">
                <template #tab-titles>
                    <div
                    @keydown.enter="setActiveTabIndex(0)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(0)"
                    @click="setActiveTabIndex(0)">
                        <h6 class="tab__title">Trips completed </h6>
                        <span class="tab__value">
                          {{ nullToDash(props?.data?.num_of_trips_completed_last_3mth) }}
                        </span>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(1)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(1)"
                    @click="setActiveTabIndex(1)">
                        <h6 class="tab__title">Trips cancelled</h6>
                        <span class="tab__value">
                          {{ nullToDash(props?.data?.num_of_trips_canceled_last_3mth) }}
                        </span>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(2)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(2)"
                    @click="setActiveTabIndex(2)">
                        <h6 class="tab__title">Avg. acceptance rate </h6>
                        <span class="tab__value">
                          {{ nullToDash(
                            calculateAverage(props.data?.average_weekly_acceptance_rate_last_3mth)
                            ) }}
                          <i>%</i>
                        </span>
                    </div>
                </template>
                <template #tab-content-0>
                    <div class="graph__wrappper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="375"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
                <template #tab-content-1>
                    <div class="graph__wrappper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="375"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
                <template #tab-content-2>
                    <div class="graph__wrappper">
                      <vue-apex-charts
                        v-if="chartSeries.length > 0"
                        :height="375"
                        :options="options"
                        :series="chartSeries">
                      </vue-apex-charts>
                      <NoDataAvailable v-else/>
                    </div>
                </template>
            </r-tab>
      </div>
    </div>
    <div class="card__sections-side">
      <div class="card__head">
        <div class="card__head-icon">
          <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
          <ListIcon/>
        </div>
        <h5>Other</h5>
        <p class="card__head-info">Last 90 days</p>
      </div>
      <div class="card__content">
        <div class="side-items">
          <div class="side-item">
            <h6>
              Avg. weekly income rank vs. other drivers
            </h6>
            <span class="side-item-value">
              {{props.data?.income_rank_internal_last_3mth}}
              <i>%</i>
            </span>
          </div>
          <div class="side-item">
          <h6>Driver rating
            <r-tool-tip class="side-item-tooltip">
                <template #tooltip-text>
                    <span>
                      Average rating is calculated for Bolt only
                    </span>
                </template>
              </r-tool-tip>
          </h6>
          <span class="side-item-value">
            {{ nullToDash(props.data?.latest_driver_rate_last_3mth) }}
          </span>
          </div>
          <div class="side-item">
            <h6>Weeks without earnings</h6>
            <span class="side-item-value">
              {{ nullToDash(props.data?.num_of_week_without_earnings) }}
            </span>
          </div>
          <div class="side-item">
            <h6>No. of days since last activity</h6>
            <span class="side-item-value">
              {{ nullToDash(props.data?.days_to_last_activity) }}
            </span>
          </div>
          <div class="side-item">
            <h6>Total trips distance</h6>
            <span class="side-item-value">
              {{ toFixedNumber(numberFormat(props.data?.total_distance_last_3mth), 0)  }}
              <i>km</i>
            </span>
          </div>
        </div>
      </div>
    </div>
</div>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue';
import RTab from '@/components/RTab.vue';
import RToolTip from '@/components/RToolTip.vue';
import {
  formatDate, nullToDash, numberFormat, toFixedNumber,
} from '@/util/helpers';
import VueApexCharts from 'vue3-apexcharts';
import { cloneDeep } from 'lodash';
import NoDataAvailable from './NoDataAvailable.vue';
import PinIcon from './icons/PinIcon.vue';
import ListIcon from './icons/ListIcon.vue';

const props = defineProps(['currency', 'data', 'graps']);
const tabRef = ref(0);
const chartSeries = ref(null);
const options = ref({});

const singleLineChartOptions = {
  chart: {
    height: 350,
    type: 'line',
    stacked: true,
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        return formatDate(val);
      },
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: '#A8AFBC',
          fontSize: '12px',
          fontFamily: 'Gilroy',
          fontWeight: 500,
        },
        formatter(val) {
          // eslint-disable-next-line radix
          return parseInt(val);
        },
      },
    },
  ],
  colors: ['#0061FF'],
  stroke: {
    width: 3.4,
    dashArray: [0, 8],
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};

const multipleChartOptions = {
  chart: {
    height: 350,
    type: 'line',
    stacked: false,
    toolbar: {
      show: false,
    },
  },
  xaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
      formatter(val) {
        return formatDate(val);
      },
    },
  },
  yaxis: [
    {
      axisTicks: {
        show: true,
      },
      labels: {
        style: {
          colors: '#A8AFBC',
          fontSize: '12px',
          fontFamily: 'Gilroy',
          fontWeight: 500,
        },
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`;
        },
      },
    },
  ],
  colors: ['#A8AFBC', '#0061FF'],
  stroke: {
    width: [3.4, 3.4],
    dashArray: [0, 8],
  },
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};
const setOptions = (tabIndex) => {
  switch (tabIndex) {
    case 0:
      options.value = cloneDeep(singleLineChartOptions);
      chartSeries.value = props.graps?.plot_trips_completed_history || [];
      break;
    case 1:
      options.value = cloneDeep(singleLineChartOptions);
      chartSeries.value = props.graps?.plot_trips_canceled_history || [];
      break;
    case 2:
      if (props.graps?.plot_weekly_acceptance_rate_last_3mth?.length === 0) {
        chartSeries.value = [];
        return;
      }
      // eslint-disable-next-line no-case-declarations
      const formated = props.graps?.plot_weekly_acceptance_rate_last_3mth.map((platform) => (
        {
          name: platform.name,
          data: Object.entries(platform.data).map(([key, value]) => ({
            x: value.x,
            y: value.y ? value.y * 100 : 0,
            i: key,
          })),
        }
      ));
      chartSeries.value = formated;
      options.value = cloneDeep(multipleChartOptions);
      break;
    default:
      options.value = singleLineChartOptions;
      chartSeries.value = [];
  }
};

const setActiveTabIndex = (index) => {
  tabRef.value.setActiveTab(index);
  setOptions(index);
};

const calculateAverage = (data) => {
  if (!data) {
    return null;
  }
  const values = Object.values(data);
  const sum = values.reduce((acc, curr) => acc + curr, 0);
  const average = (sum / values.length) * 100;
  return average.toFixed(2);
};

onBeforeMount(() => {
  setOptions(0);
});
</script>
<style scoped lang="scss">
.card{
  &__sections{
    display: grid;
    grid-template-columns: 70% 30%;

    &-main{
      & .card{
        &__content{
          transform: translateX(-32px);
          width: calc(100% + 32px);
        }

        &__head{
          &-info{
            padding-right: 32px;
          }
        }
      }
    }

    &-side{
      background: var(--color-app-background);
      height: calc(100% - 32px);
      padding: 16px 32px;
      border-left: 1px solid var(--color-grey-outline);
      position: absolute;
      right: 0px;
      top: 0;
      width: 25%;
      & .card{
        &__content{
          margin-top: 40px;
          height: calc(100% - 63px);
        }
      }
    }
  }
}

.side{

  &-items{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &-item{
    border-bottom: 1px solid var(--color-grey-outline);
    margin-bottom: 12px;
    height: 78px;
    &:last-child{
      border-bottom: none;
      margin-bottom: 0;
    }
    h6{
      color: var(--color-grey-dark);
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      display: flex;
      align-items: center;
      & .hover{
        transform: translateY(3px);
      }
    }
    &-tooltip{
      margin-left: 4px;
    }
    &-value{
      margin-top: 12px;
      color: var(--color-dark-main);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 45px;

      i {
        color: var(--color-grey-light);
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 140%;
      }
    }
  }
}
.tab{
  padding: 16px 32px;
  &-container{

    &__content{
      padding: 21px 26px;
    }
  }

  &__title{
    margin-bottom: 8px;
  }

  &__value{
    color: var(--color-grey-dark) !important;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    & i{
      color: var(--color-grey-light);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}
// Remove when graph is ready
.data{
  display: grid;
  row-gap: 12px;

  &__sections{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 16px 0 24px 0;
    &:first-child{
      border-bottom: 1px solid var(--color-grey-outline);
      box-shadow: 0px 8px 20px 0px rgba(20, 20, 20, 0.02);
    }
  }
  &__title{
    color: var(--color-grey-dark);
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
  }
  &__value{
    color: var(--color-dark-main);
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    & i{
      color: var(--color-grey-light);
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
    }
  }
}

.graph{
  &__wrappper{
    margin-top: 30px;
    margin-left: 30px;
    min-height: 400px;
  }
}
</style>
