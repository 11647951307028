<template>
  <div class="verify__wrapper">
    <div class="verify__content">
      <div class="verify__icon">
        <GuardIcon />
      </div>
      <h2>Spot <span>fraudulent</span> and <span>payroll forgery</span> </h2>
      <p class="verify__content-title">
        Scan with Rollee Verify if company is a real legal entity and get <br/>
        an access to the latest information about it.</p>

      <div class="verify__notFound" v-if="notFound">
        <div class="verify__notFound-warning">
          <i class="ri-error-warning-fill"></i>
          <p class="verify__notFound-title">
            There is no information available about
            {{store.getSearchValue}}
          </p>
        </div>

        <p class="verify__notFound-text">Try again by typing different name or tax ID</p>
      </div>
      <div class="verify__actions" v-if="!store.getIsLoading">
        <RInput placeholder="Search a company by name or tax ID"
                v-model="company"
                left-icon="ri-search-line"
                @keydown.enter="onSend"/>
        <RButton :disabled="store.getIsLoading" @click="onSend" >Verify Now</RButton>
      </div>
      <div class="verify__loading" v-else>
        <r-loading></r-loading>
        <p class="verify__content-title">Verifying now...</p>
      </div>
    </div>
  </div>
</template>
<script setup>

import RInput from '@/components/RInput.vue';
import RButton from '@/components/RButton.vue';
import GuardIcon from '@/components/icons/Verify/GuardIcon.vue';
import { ref } from 'vue';
import { verificationStore } from '@/store/verification';
import { useRouter } from 'vue-router';
import RLoading from '@/components/RLoading.vue';

const store = verificationStore();
const router = useRouter();
const company = ref(undefined);
const notFound = ref(false);
const onSend = () => {
  store.setSearchValue(company.value);
  notFound.value = false;
  store.getVerification(company.value).then(() => {
    router.push({ name: 'Fraud Analysis Details' });
  }, () => {
    notFound.value = true;
  }).finally(() => {
    company.value = undefined;
  });
};

</script>
<style scoped lang="scss">
.verify{
  &__wrapper{
    border: 1px solid var(--color-grey-outline);
    box-shadow: 0px 8px 20px rgba(20, 20, 20, 0.02);
    border-radius: 8px;
    background-color: #fff;
    height: calc(100% - 2px);
    display: flex;
    justify-content: center;
  }
  &__icon{
    margin-bottom: 16px;
  }
  &__notFound {
    margin-top: 30px;
    &-title{
      color: var(--color-yellow-warning);
      margin-left: 12px;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      transform: translatey(1px);
    }
    &-text {
      text-align: center;
      color: var(--color-dark-grey, #7A808B);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 21px;
    }
    &-warning {
      display: flex;
      padding: 12px 20px;
      border-radius: 400px;
      justify-content: center;
      align-items: center;
      background-color: #FFF7F0;
    }
    & i {
      font-size: 24px;
      color: var(--color-yellow-warning);
    }
  }
  &__content{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    & h2{
      color: var(--color-grey-dark);
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: 38.5px;
    }
    & span{
      color: var(--color-dark-main);
    }
    &-title {
      color: var(--color-grey-dark);
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 160%; /* 24px */
    }
  }
  &__actions{
    margin-top: 32px;
    display: grid;
    grid-template-columns:422px 125px;

    :deep{
      & input{
        background-color: var(--color-blue-background);
      }

      & button{
        transform: translateX(-10px);
      }
    }
  }
  &__loading{
    margin-top: 26px;
  }
}
</style>
