<template>
    <div class="sections sections--connector-stats">
        <div class="sections__head">
            <h5>Connector statistics</h5>
            <r-tool-tip>
                <template #tooltip-text>
                    <span v-if="activeTabIndex == 0">
                    <!-- eslint-disable-next-line  -->
                        The highest conversion means the number of successful connection by yours users connected with their accounts through specific data source.
                    <!-- eslint-disable-next-line  -->
                    Highest drop off means number of users who abandoned Connect flow while logging to a particupar data source (Login, Connecting...).
                    </span>
                    <span v-else>
                    <!-- eslint-disable-next-line  -->
                        The drop off rate is calculated by dividing the number of users who abandon the Connect process by the total number of users who initiated it. For example, if 2000 users started the procss but only 200 users make a successful connection, the drop off rate would be 90%.
                    </span>
                </template>
            </r-tool-tip>
        </div>
        <div class="sections__tab">
            <r-tab ref="tabRef">
                <template #tab-titles>
                    <div
                    @click="setActiveTabIndex(0)"
                    class="tab"
                    @keydown.enter="setActiveTabIndex(0)"
                    @keydown.space="setActiveTabIndex(0)">
                        <h6>Highest conversion</h6>
                    </div>
                    <div
                    @keydown.enter="setActiveTabIndex(1)"
                    class="tab"
                    @keydown.space="setActiveTabIndex(1)"
                    @click="setActiveTabIndex(1)">
                        <h6>Highest drop off</h6>
                    </div>
                </template>
                <template #tab-content-0>
                    <div v-if="isLoading">
                        <r-loading></r-loading>
                    </div>
                    <div v-else class="scrollable-table">
                        <table  class="connector-table">
                            <thead>
                                <tr class="connector-table__header">
                                <th class="connector-table__cell">Connector</th>
                                <th class="connector-table__cell">Conversion</th>
                                <th class="connector-table__cell">Users</th>
                                </tr>
                            </thead>
                            <tbody v-if="data && data.highest_conversion">
                                <tr v-for="(item, index) in data.highest_conversion" :key="index"
                                class="connector-table__row">
                                    <td class="connector-table__cell">
                                        <img :src="item && item.platform && item.platform.logo"
                                        :alt="item && item.platform && item.platform.name"/>
                                        {{item && item.platform && item.platform.name}}
                                    </td>
                                    <td class="connector-table__cell">
                                        {{ percentile(item.conversion_rate)}}%
                                    </td>
                                    <td class="connector-table__cell">
                                    {{ item.converted_users }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
                <template #tab-content-1>
                    <div v-if="isLoading">
                        <r-loading></r-loading>
                    </div>
                    <div v-else class="scrollable-table">
                        <table class="connector-table">
                            <thead>
                                <tr class="connector-table__header">
                                <th class="connector-table__cell">Connector</th>
                                <th class="connector-table__cell">Drop Off</th>
                                <th class="connector-table__cell">Users</th>
                                </tr>
                            </thead>
                            <tbody v-if="data && data.highest_drop_off">
                                <tr v-for="(item, index) in data.highest_drop_off" :key="index"
                                class="connector-table__row">
                                    <td class="connector-table__cell">
                                        <img :src="item && item.platform && item.platform.logo"
                                        :alt="item && item.platform && item.platform.name"/>
                                        {{item && item.platform && item.platform.name}}
                                    </td>
                                    <td class="connector-table__cell">
                                        {{ percentile(item.drop_off_rate)}}%
                                    </td>
                                    <td class="connector-table__cell">
                                    {{ item.drop_off_users }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </r-tab>
            <empty-state
            v-if="(data.highest_conversion?.length == 0 || data.highest_drop_off.length == 0)
            && !isLoading"
            ></empty-state>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import RToolTip from '@/components/RToolTip.vue';
import RTab from '@/components/RTab.vue';
import RLoading from '@/components/RLoading.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import { percentile } from '@/util/helpers';

const tabRef = ref(null);
/* eslint-disable vue/no-setup-props-destructure */
const { isLoading, data } = defineProps(['isLoading', 'data']);

const activeTabIndex = ref(0);

const setActiveTabIndex = (index) => {
  tabRef.value.setActiveTab(index);
  activeTabIndex.value = index;
};
</script>

<style lang="scss" scoped>
.sections {
    max-width: 100%;
    margin-bottom: unset !important;
    &--connector-stats {
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
        .sections__head {
            padding: 16px 32px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    &__tab {
        margin-top: 4px;
        position: relative;
        height: 100%;

      & h6 {
        text-align: center;
      }
    }
    h5 {
        color: var(--color-black, #141414);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }

}
.scrollable-table {
    min-height: 150px;
    max-height: 608px;
    overflow-y: auto;
}
.connector-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;

    &__header {
        color: var(--color-grey-dark, #7A808B);
        font-size: 15px;
        font-weight: 600;
        border-bottom: 1px solid var(--color-grey-outline, #EDF2F8);
    }

    &__cell, &__row {
        text-align: left;
        padding: 8px;

        &:nth-child(odd) {
            padding: 16px 32px;
        }
    }
    &__row {
        td {
            color: var(--color-dark-main, #253858);
            font-weight: 500;
            font-size: 15px;

            &:nth-child(1) {
                display: flex;
                align-items: center;
                gap: 8px;
                img {
                    height: 32px;
                    width: 32px;
                    border-radius: 32px;
                }
            }
        }

        &:not(:last-child) {
            border-bottom: 1px solid var(--color-grey-outline, #EDF2F8);
        }
    }
}
</style>
