<template>
  <div class="insights__wrapper">
    <r-header ref="header" id='onboardingInsightsHeader'>
      <template #content>
          <r-nav :items="navItems"></r-nav>
      </template>
      <template #actions>
        <div class="insights__wrapper-switch" id="onboardingSampleData">
            <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
            <label class="switch">
              <input type="checkbox" ref="sampelInput"
              :value="useInsights.getSampleData"
              v-model="toggleSwitch">
              <span class="slider round"></span>
            </label>
            <p
              class="switch__title"
              @keyup="$refs.sampelInput.click()"
              @click="$refs.sampelInput.click()">Show sample data</p>
          </div>
      </template>
    </r-header>
    <router-view></router-view>
  </div>
</template>

<script setup>
import RNav from '@/components/Layouts/RNav.vue';
import RHeader from '@/components/Layouts/RHeader.vue';
import useEmitter from '@/composables/useEmitter';

import { ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { insightsStore } from '@/store/insights';

const route = useRoute();
const overviewNavigation = [
  { text: 'Overview', to: 'Insights Overview', icon: 'PieChartFillIcon' },
  { text: 'My Users', to: 'Insights Drivers', icon: 'AllUsersIcon' },
];

const detailsNavigation = [
  { text: 'Current Performance', to: 'Insights Details', query: { ...route.query } },
  { text: 'Historical Performance', to: 'Insights Performance', query: { ...route.query } },
];

const emitter = useEmitter();

const navItems = ref(overviewNavigation);
const useInsights = insightsStore();
const router = useRouter();
const toggleSwitch = ref(useInsights.getSampleData);

watch(() => router.currentRoute.value.name, (name) => {
  if (localStorage.getItem('sampleData') === 'true') {
    window.analytics?.track('sample_on_insights_users');
    toggleSwitch.value = true;
  } else {
    window.analytics?.track('sample_off_insights_users');
    toggleSwitch.value = false;
  }
  switch (name) {
    case 'Insights Overview':
    case 'Insights Drivers':
      navItems.value = overviewNavigation;
      useInsights.clearDriverDetails();
      break;
    default:
      navItems.value = detailsNavigation;
      break;
  }

  useInsights.setSampleData(toggleSwitch.value);
}, { immediate: true });

watch(() => toggleSwitch.value, (value) => {
  localStorage.setItem('sampleData', value);
  useInsights.setSampleData(value);
  useInsights.clearDriverDetails();
  useInsights.clearDriverOverview();
  if (router.currentRoute.value.name === 'Insights Details'
    || router.currentRoute.value.name === 'Insights Performance') {
    router.push({ name: 'Insights Overview' });
    return;
  }
  emitter.emit('insightSampleData', value);
});
</script>

<style scoped lang="scss">
.insights{
  &__wrapper{
    margin: -40px -30px;
    height: 100%;
    &-switch{
      padding: 3px 3px;
    }
  }
}
.switch {
  &__title{
    color: var(--grey-dark, #7A808B);
  }
}
</style>
