<template>
  <div
    class="colorChecker"
    :class="{'colorChecker--success' : contrast.didPass, 'colorChecker--error': !contrast.didPass}">
    <p class="colorChecker__title">
      {{text}}
    </p>
    <r-tool-tip class="tool-tip" v-if="tooltipText">
      <template #tooltip-text>
            <span>
              {{tooltipText}}
            </span>
      </template>
    </r-tool-tip>
    <p class="colorChecker__contrast">
      <span class="maxLevel">{{contrast.maxLevel || 'FAIL'}}</span>
      <span class="ratio">{{contrast.contrastRatio}}:1</span>
      <i v-show="contrast.didPass" class="ri-checkbox-circle-fill"></i>
      <i v-show="!contrast.didPass" class="ri-close-circle-fill"></i>
    </p>
  </div>
</template>
<script>
import RToolTip from '@/components/RToolTip.vue';

export default {
  name: 'RColorChecker',
  components: { RToolTip },
  props: {
    colorForeground: String,
    colorBackground: String,
    text: String,
    tooltipText: String,
  },
  data() {
    return {
      WCAG_MINIMUM_RATIOS: [
        ['AA Large', 3],
        ['AA', 4.5],
        ['AAA', 7],
      ],
      contrast: null,
    };
  },
  methods: {
    luminance(r, g, b) {
      const [lumR, lumG, lumB] = [r, g, b].map((component) => {
        const proportion = component / 255;

        return proportion <= 0.03928
          ? proportion / 12.92
          : ((proportion + 0.055) / 1.055) ** 2.4;
      });

      return 0.2126 * lumR + 0.7152 * lumG + 0.0722 * lumB;
    },
    meetsMinimumRequirements(ratio) {
      let didPass = false;
      let maxLevel = null;

      // eslint-disable-next-line no-restricted-syntax
      for (const [level, minRatio] of this.WCAG_MINIMUM_RATIOS) {
        if (ratio < minRatio) break;

        didPass = true;
        maxLevel = level;
      }

      return { didPass, maxLevel };
    },
    contrastRatio(luminance1, luminance2) {
      const lighterLum = Math.max(luminance1, luminance2);
      const darkerLum = Math.min(luminance1, luminance2);

      return (lighterLum + 0.05) / (darkerLum + 0.05);
    },
    checkContrast(color1, color2) {
      const [luminance1, luminance2] = [color1, color2].map((color) => {
        const colorTemp = color.startsWith('#') ? color.slice(1) : color;

        const r = parseInt(colorTemp.slice(0, 2), 16);
        const g = parseInt(colorTemp.slice(2, 4), 16);
        const b = parseInt(colorTemp.slice(4, 6), 16);

        return this.luminance(r, g, b);
      });

      return this.contrastRatio(luminance1, luminance2);
    },
  },
  watch: {
    colorForeground: {
      immediate: true,
      handler(color) {
        const contrastRatio = this.checkContrast(color, this.colorBackground);
        const { didPass, maxLevel } = this.meetsMinimumRequirements(contrastRatio);

        this.contrast = {
          didPass,
          maxLevel,
          contrastRatio: contrastRatio.toFixed(2),
        };
      },
    },
    colorBackground: {
      immediate: true,
      handler(color) {
        const contrastRatio = this.checkContrast(color, this.colorForeground);
        const { didPass, maxLevel } = this.meetsMinimumRequirements(contrastRatio);

        this.contrast = {
          didPass,
          maxLevel,
          contrastRatio: contrastRatio.toFixed(2),
        };
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.colorChecker {
  margin-bottom: 32px;
  display: flex;
  align-items: center;
  &--success {
    .colorChecker__contrast {
      background-color: rgba(143, 181, 113, 0.08);
    }
  }
  &--error {
    .colorChecker__contrast {
      background-color: rgba(255, 0, 0, 0.08);
      span {
        &.maxLevel {
           color: var(--color-red-error);
        }
      }
      i {
        color: var(--color-red-error);
      }
    }
  }
  &__title {
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    color: var(--color-dark-main);
  }
  &__contrast {
    position: relative;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    padding: 0px 7px 3px 7px;
    border-radius: 6px;
    color: var(--color-dark-main);
    margin-left: 24px;
    i {
      position: relative;
      top: 4px;
      font-size: 20px;
      margin-left: 3px;
      color: var(--color-green-success);
    }
    span {
      padding: 0 2px;
      &.maxLevel {
        color: var(--color-green-success);
      }
      &.ratio {
        color: var(--color-dark-main);
      }
    }
  }
  .tool-tip{
    margin-left: 4px;
  }
}
</style>
