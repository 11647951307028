<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g id="Group">
  <path id="Vector" d="M11 2.0498V12.9998H21.95C21.449 18.0528 17.185 21.9998
  12 21.9998C6.477 21.9998 2 17.5228 2 11.9998C2 6.8148 5.947 2.5508 11
  2.0498ZM13 2.0498C15.295 2.281 17.4396 3.29823 19.0706 4.92924C20.7016
  6.56025 21.7188 8.70482 21.95 10.9998H13V2.0498Z" fill="#0061FF"/>
  </g>
  </svg>
</template>
