<template>
  <div>
    <div class="users__header">
      <div class="users__subheader users__subheader--left">
        <div class="users__autocomplete">
          <RInput
            v-model="search"
            left-icon="ri-search-line"
            compact
            v-on:input="onFilterTextBoxChanged()"
            placeholder="Search by any column" />
        </div>
        <div class="users__status">
          <transition name="fade">
            <div v-if="partnerStatusShow === true" class="wrapper">
              <img src="@/assets/loading.gif" alt="loading">
              Collecting data
            </div>
          </transition>
        </div>
        <div
        v-if="multipleMetadata.selected?.length > 0"
        class="multiple__actions">
          <div
            class="multiple__actions__item"
            :key="action.field"
            v-for="action in multipleMetadata.dynamicActions"
            >
            <RComboButton
            :text="multipleMetadata.actionsLabel[action?.field]" :label="action.field">
              <li
                :key="i"
                v-for="(value , i) in action.values"
                :class="{'active': value === multipleMetadata.actionsLabel[action?.field] }"
                @click="multipleMetadata.dropdownSelected(action.field, value)"
                @keydown="multipleMetadata.dropdownSelected( action.field, value)">
                {{ value }}
                <CheckOutlineIcon
                    class="multiple__actions__item-icon"
                    fill="#8FB571"
                    v-if="value === multipleMetadata.actionsLabel[action?.field]"/>
              </li>
            </RComboButton>
          </div>
        </div>
        <div
          v-show="multipleMetadata.selected?.length === 0"
          class="users__subheader users__subheader--right">
          <RDatePicker
            label="Date Range" @updateDate="setSelectedDate" :value="getSelectedDateValue" />
          <RButton
            v-if="hasInviteUsers"
            before-icon="ri-add-line"
            @click="modal.show = true"
            >Invite Users</RButton>
          <RButton
            v-if="profileStore.partnerInfo.type === 'Fleet'"
            before-icon="ri-upload-cloud-line"
            type="secondary"
            @click="csvModal.show = true"
            >Upload File</RButton>
        </div>
      </div>
    </div>
    <div class="users__stats">
      <p>Applied filters:</p>
      <div class="users__filter">
        <TransitionGroup name="fade" tag="div">
          <div class="filter" v-for="item in appliedFilters" :key="item.key">
            <p>{{ item.key }}:</p>
            <span v-for="v in item.value.values" :key="v">
              {{ v }}
            </span>
            <CloseFilledIcon @click="removeAppliedFilter(item)" />
          </div>
        </TransitionGroup>
      </div>
    </div>
    <div class="users__table">
      <RLoading v-if="!gridReady" class="transparent"  />
      <ag-grid-vue
        v-if="!loadingUsers"
        class="ag-theme-alpine"
        :aggFuncs="aggFuncs"
        :columnDefs="columnDefs.columns"
        :rowData="rowData.value"
        :groupRowsSticky="true"
        :suppressAggFuncInHeader="true"
        :suppressColumnVirtualisation="true"
        :suppressScrollOnNewData="true"
        :sideBar="sideBar"
        :defaultColDef="defaultColDef"
        :autoGroupColumnDef="columnDefs.columns.autoGroupColumnDef"
        rowSelection="multiple"
        :suppressRowClickSelection="true"
        :grid-options="gridOptions"
        :groupAggFiltering="true"
        @grid-ready="onGridReady"
        @body-scroll="onBodyScroll"
        @cell-clicked="onCellClicked"
        @row-selected="onRowSelected"
      />
      <Transition name="bounce">
        <RLoadMoreButton
          v-show="showLoadMore"
          :loading="loadSpinner"
          @loadMore="loadMoreUsers">
        </RLoadMoreButton>
      </Transition>
    </div>
  </div>
  <Transition name="fade">
    <RInvitationModal
      v-if="modal.show"
      title="Invite New Users"
      :disabled="modal.sendDisabled()"
      @cta="onSubmit"
      @close="modal.show = false">
      <div>
        <br>
        <h3 class="modal__heading">Users to invite</h3>
        <p class="modal__desc">
          Request your users to connect their work accounts by simple invitation via email.</p>
        <br>
        <RInput
            label="Email Address"
            ref="emailInput"
            type="email"
            :key="singleEmail.key"
            :error="modal.error"
            v-model="singleEmail.value"
            placeholder="Type Enter to add address"
            @keyup.enter="modal.addInvitedUser();"
        />
        <br>
        <h3 class="modal__heading">
          Invited users
          <span v-if="modal.invitedUsers.length > 0"> ({{ modal.invitedUsers.length }})</span></h3>
        <div class="modal__invited">
          <div class="modal__invited-users" v-for="user in modal.invitedUsers" :key="user">
            <p>{{ user }}</p>
            <CloseFilledIcon @click="modal.removeInvitedUser(user)" />
          </div>
        </div>
        <BulkInviteUsers @invited="fileInvite"/>
        <br>
        <div v-if="customizationList.length > 0">
          <hr class="modal__line" />
          <br>
          <h3 class="modal__heading">Connect customization</h3>
          <br>
          <div>
            <RList
              title="Action"
              :items="customizationList"
              @action="dropdownAction"
              :value="item"
            />
          </div>
        </div>
        <br>
      </div>
    </RInvitationModal>
  </Transition>
  <Transition name="fade">
    <SyncModal title="Update Data" v-if="syncModal.show" @close="syncModal.show = false;" />
  </Transition>
  <Transition name="fade">
    <RModalDynamic
    v-if="multipleMetadata.showModal"
    @close="multipleMetadata.clear()"
    title="Changing cell value">
      <template #content>
        <div>
          <p class="modal__desc">
            Are you sure you would like to change a value of Contract Type cell?
            This may affect your estimations and calculations.
          </p>
        </div>
      </template>
      <template #actions>
        <RButton
          type="secondary"
          @click="multipleMetadata.clear()">
            Cancel
        </RButton>
        <RButton
          type="warn"
          :disabled="multipleMetadata.disabled"
          @click="multipleMetadata.onUpdateMetadata()">
          Update
        </RButton>
      </template>
    </RModalDynamic>
  </Transition>
  <Transition name="fade">
    <RModalDynamic
    v-if="csvModal.show"
    @close="csvModal.resetModal()"
    title="Upload File">
      <template #content>
        <div>
          <p class="modal__desc">
            We support CSV and XLSX files from Glovo, Wolt and Stuart
          </p>
          <div class="csv__modal-section">
            <RComboButton
              type="outlined"
              :text="csvModal.selectedFleet ? csvModal.selectedFleet.charm : 'Select Fleet'"
              :label="'Fleet'">
              <li
                class="csv__modal-section__item"
                :key="i"
                v-for="(value , i) in csvModal.availableFleets"
                :class="{'active': value === csvModal.availableFleets }"
                @click="csvModal.fleetSelected(value)"
                @keydown="csvModal.fleetSelected(value)">
                <p>{{ value.charm }}</p>
                <div>
                  <span
                  v-if="value.manager"
                  class="csv__modal-manager"
                  >Manager: {{ value.manager }}</span>

                  <span>User: {{ value.user }}</span>
                </div>
              </li>
              <li
                class="csv__modal-section__add csv__modal-section__item"
                :class="{'active': value === csvModal.availableFleets }"
                @click="csvModal.fleetSelected({charm: 'New Fleet', uuid: ''})"
                @keydown="csvModal.fleetSelected({charm: 'New Fleet', uuid: ''})">
                <i class="ri-add-fill"></i>
                <p>Create a new Fleet</p>
              </li>
            </RComboButton>
            <RComboButton
              v-if="csvModal.selectedFleet?.uuid === ''"
              type="outlined"
              :text="csvModal.selectedDataSource ?
              csvModal.selectedDataSource.title :
              'Select a platform'"
              :label="'Platform'">
              <li
                class="csv__modal-section__item--platform"
                :key="i"
                v-for="(value , i) in csvModal.fleetsDataSources"
                :class="{'active': value === csvModal.fleetsDataSources }"
                @click="csvModal.dataSourceSelected(value)"
                @keydown="csvModal.dataSourceSelected(value)">
                <img
                    class="csv__modal-icon"
                    :src="value.logo"
                    :alt="value.name"/>
                  {{ value.title }}
              </li>
            </RComboButton>
            <RFileUpload
              label="File"
              text="Drop a CSV or XLSX file here or upload a file"
              info="Only CSV or XLSX files from Glovo, Wolt and Stuart supported"
              :data="csvModal.file"
              ref="csvFleetFile"
              :isFile="true"
              :allowedFileTypes="['.csv','.xlsx']"
              @image="csvModal.odAddedFile($event)" />
          </div>
        </div>
      </template>
      <template #actions>
        <RButton
          type="secondary"
          @click="csvModal.resetModal()">
            Cancel
        </RButton>
        <RButton
          :disabled="csvModal.disabled || csvModal.loading"
          @click="csvModal.onUpload()">
          Upload
        </RButton>
      </template>
    </RModalDynamic>
  </Transition>
</template>
<script>

import {
  reactive, watch, onMounted, ref, computed, onBeforeUnmount,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { usersStore } from '@/store/users';
import { profileStore as useProfileStore } from '@/store/profile';
import { appStore as useAppStore } from '@/store/app';
import { settingsStore } from '@/store/settings';
import RButton from '@/components/RButton.vue';
import RInput from '@/components/RInput.vue';
import RDatePicker from '@/components/RDatePicker.vue';
import RInvitationModal from '@/components/RInvitationModal.vue';
import RModalDynamic from '@/components/RModalDynamic.vue';
import RLoading from '@/components/RLoading.vue';
import RLoadMoreButton from '@/components/RLoadMoreButton.vue';
import BulkInviteUsers from '@/components/users/BulkInviteUsers.vue';
import RList from '@/components/RList.vue';
import RFileUpload from '@/components/RFileUpload.vue';
import { numberFormat, nullToDash, fetureCheck } from '@/util/helpers';
import CloseFilledIcon from '@/components/icons/CloseFilledIcon.vue';
import { AgGridVue } from 'ag-grid-vue3';
import { format, getUnixTime } from 'date-fns';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import * as AGGrid from 'ag-grid-enterprise';
import { validateEmail } from '@/util/validations';
// import RSelect from '@/components/RSelect.vue';
import SyncModal from '@/components/users/SyncModal.vue';
import useEmitter from '@/composables/useEmitter';
import getSymbolFromCurrency from 'currency-symbol-map';
import RComboButton from '@/components/RComboButton.vue';
import CheckOutlineIcon from '@/components/icons/CheckOutlineIcon.vue';
import AgGridCustomInput from '@/components/AgGridCustomInput.vue';
import AgGridProfileCell from '@/components/AgGridProfileCell.vue';
import AgGridInput from '@/components/AgGridInput.vue';
import AgGridDropDown from '@/components/AgGridDropDown.vue';
import AgGridMetadataHeader from '@/components/AgGridMetadataHeader.vue';
import AgGridTopLevelCell from '@/components/AgGridTopLevelCell.vue';
import { customizationStore } from '@/store/customizations';
import AgGridCustomHeader from '@/components/AgGridCustomHeader.vue';
import { isObject } from 'lodash';

// eslint-disable-next-line
AGGrid.LicenseManager.setLicenseKey('Using_this_{AG_Grid}_Enterprise_key_{AG-053293}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Rollee}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Dashboard}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Dashboard}_need_to_be_licensed___{Dashboard}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{13_March_2025}____[v3]_[01]_MTc0MTgyNDAwMDAwMA==7bc0d51f7c7de4de44584336d8e22ae0');

export default {
  name: 'Users',
  components: {
    RButton,
    AgGridVue,
    RInvitationModal,
    RInput,
    CloseFilledIcon,
    BulkInviteUsers,
    RLoading,
    RLoadMoreButton,
    SyncModal,
    RDatePicker,
    RComboButton,
    CheckOutlineIcon,
    RModalDynamic,
    RFileUpload,
    RList,
    // eslint-disable-next-line
    AgGridCustomInput,
    // eslint-disable-next-line
    AgGridCustomHeader,
    // eslint-disable-next-line
    AgGridProfileCell,
    // eslint-disable-next-line
    AgGridInput,
    // eslint-disable-next-line
    AgGridDropDown,
    // eslint-disable-next-line
    AgGridMetadataHeader,
    // eslint-disable-next-line
    AgGridTopLevelCell,
  },
  setup() {
    /* eslint-disable */
    const appStore = useAppStore();
    const profileStore = useProfileStore();
    const singleEmail = reactive({
      value: '',
      key: 0,
    });
    const route = useRoute();
    const router = useRouter();
    const emitter = useEmitter();

    const getSelectedDateValue = computed(() => appStore.getDateSelectValue);


    const multipleMetadata = reactive(
      {
        showModal: false,
        disabled: false,
        selected: [],
        intervalCount: 0,
        actionsLabel: {},
        mapObject: {},
        selectedValue: '',
        field: '',
        dynamicActions: [],
        interval: null,
        defaultValue(){
          this.dynamicActions.forEach(action => {
            const values = this.selected.map((item) => item.allLeafChildren[0].data[action.field]? item.allLeafChildren[0].data[action.field] : '--');
            const allEqual = values.every((val, i, arr) => val === arr[0]);
            this.actionsLabel[action.field] = allEqual ? values[0] : 'Multiple';
          })
        },
        dropdownSelected(field ,selectedValue) {
          this.selectedValue = selectedValue;
          this.field = field;
          this.mapObject = {[field]: selectedValue};
          this.showModal = true;
        },
        onUpdateMetadata() {
          this.disabled = true;
            // SET SElECTED VALUE IN rid metadata
            if(!this.selected[this.intervalCount]?.allLeafChildren[0].data.user_id){
              return;
            }
            const id = this.selected[this.intervalCount]?.allLeafChildren[0].data.user_id;
            usersStore().putUserMetadata(id, this.mapObject)
            .then(()=> {
              if(this.selected[this.intervalCount]){
                this.selected[this.intervalCount].allLeafChildren[0].data[this.field] = this.selectedValue;
              }
              usersStore().fetchUserById(id, {
                start_date: usersPagination.startDate,
                end_date: usersPagination.endDate,
                only_income: true,
                }).then((res) => {
                  let row = this.selected[this.intervalCount]?.allLeafChildren[0].data
                  const object = res.user.total_income.dynamic_data_points
                  .reduce((obj, item) => Object.assign(obj, { [item.name]: item.result }), {});
                  row = Object.assign(row, {...row, ...object, ...res.user.metadata});
                  this.next();
                  if (this.intervalCount === this.selected.length) {
                    setTimeout(() => {
                      gridApi.value.redrawRows({force: true});
                      gridApi.value.deselectAll();
                    }, 100);
                    this.clear();
                  }
                });
            });
        },
        next() {
          this.intervalCount++;
          multipleMetadata.onUpdateMetadata();
        },
        clear() {
          clearInterval(this.interval);
          this.selected = [];
          this.intervalCount = 0;
          this.selectedValue = '';
          this.field = '';
          this.mapObject = {};
          this.actionsLabel = {},
          this.showModal = false;
          this.disabled = false;
        },
      }
    );


    const hasInviteUsers = computed(() =>{
      if(profileStore.partnerInfo.type === 'Fleet'){
        return false;
      }
      if(!SAND){
        return false;
      }
      return true;
    }
    );
    const modal = reactive({
      show: false,
      loading: false,
      invitedUsers: [],
      fileInvitedUsers: [],
      error: undefined,
      sendDisabled() {
        return !validateEmail(singleEmail.value)
          && this.invitedUsers.length === 0 && this.fileInvitedUsers.length === 0;
      },
      addInvitedUser() {
        if (singleEmail.value) {
          if (!validateEmail(singleEmail.value)) {
            this.error = 'Incorrect email format. Please try again';
            return;
          }

          if (modal.invitedUsers.includes(singleEmail.value)) {
            this.error = 'Email already exists';
            return;
          }
          modal.invitedUsers.push(singleEmail.value);
          singleEmail.key += 1;
          setTimeout(() => {
            document.querySelector('#emailaddress').focus();
          }, 100);
          window.analytics?.track('email_data_view_invite_users');
        }
      },
      removeInvitedUser(email) {
        modal.invitedUsers = modal.invitedUsers.filter((user) => user !== email);
      },
      resetModal() {
        this.show = false;
        this.error = undefined;
        this.loading = false;
        this.invitedUsers = [];
        this.fileInvitedUsers = [];
        singleEmail.value = '';
      },
    });

    /// Fleets CSV uplaod modal

    const csvFleetFile = ref(null);

    const csvModal = reactive({
      show: false,
      loading: false,
      disabled: computed(() => {
        if(typeof csvModal.selectedFleet?.uuid !== 'string' || !csvModal.file){
          return true;
        }
        if(csvModal.selectedFleet?.uuid === '' && !csvModal.selectedDataSource?.id){
          return true;
        }
      }),
      file: null,
      availableFleets:[],
      selectedFleet: null,
      fleetsDataSources: [],
      selectedDataSource: null,
      fleetSelected(fleet) {
        this.selectedFleet = fleet;
      },
      dataSourceSelected(dataSource) {
        this.selectedDataSource = dataSource;
      },
      odAddedFile(file) {
        this.file = file;
      },
      onUpload() {
        this.loading = true;
        const formData = new FormData();

        formData.append('csv_file', this.file);
        if(!this.selectedDataSource?.id){
          formData.append('fleet_id', this.selectedFleet.uuid);
        }
        if(this.selectedDataSource?.id){
          formData.append('new_fleet', 'on');
          formData.append('datasource_id', this.selectedDataSource?.id);
        }
        usersStore().uploadFleetCSV(formData).then(() => {
          appStore.setNotification({
            type: 'success',
            message: 'The data from CSV file has been uploaded',
          });
          this.resetModal();
        }).catch((err) => {
          appStore.setNotification({
            message: err.response?.data?.error || 'An error occurred',
            type: 'error',
          });
          this.resetModal();
        });
      },
      resetModal() {
        this.show = false;
        this.loading = false;
        this.file = null;
        this.selectedFleet = null;
        this.selectedDataSource = null;
      },
    });

    watch(() => csvModal.show, () => {
      if (csvModal.show) {
        usersStore().fetchUsersFleets().then((res) => {
          csvModal.availableFleets = res;
        });
        usersStore().fetchFleetsDataSources().then((res) => {
          csvModal.fleetsDataSources = res.client_datasources;
        });
      }
    });


    const gridReady = ref(false);

    const customizationID = ref('');

    const triggeredEvents = reactive({
      datehandleChange: 0,
      filters_data_view: 0,
      columns_data_view: 0,
      users_grid_layout: 0,
    })

    const dropdownAction = (e) => {
      customizationID.value = e.id;
      window.analytics?.track('customization_data_view_invite_users', {
        customizationID: e.id,
      });
    };

    const syncModal = reactive({
      show: false,
    });

    const fileInvite = (list) => {
      modal.fileInvitedUsers = list;
    };

    watch(() => singleEmail.value, () => {
      modal.error = undefined;
    });

    const onSubmit = () => {
      modal.loading = true;
      // Merge manually invited and file invited users and filter out duplicated entries
      // with spread new Set
      const invites = [
        ...new Set([
          ...modal.invitedUsers,
          ...modal.fileInvitedUsers,
          singleEmail.value,
        ]),
      ];
      window.analytics?.track('send_data_view_invite_users');

      const emailCustomizationId = settingsStore().getEmailCustomizationsFetch[0]?.id;

      const allInvitedUsers = {
        customization_id: customizationID.value,
        email_customization_id: emailCustomizationId,
        users: invites.map((invite) => ({
          email: invite,
        })),
      };

      if (allInvitedUsers.users.length === 0) {
        return;
      }
      modal.resetModal();
      usersStore().sendUsersInvite(allInvitedUsers).then(() => {
        appStore.setNotification({
          type: 'success',
          message: 'Invitations sent',
        });
      }).catch((err) => {
        appStore.setNotification({
          message: err.response?.data.error,
          type: 'error',
        });
        modal.resetModal();
      });
      window.analytics?.track('Users Invited', {
        users: allInvitedUsers.users,
      });
    };

    const rowData = reactive({}); // Set rowData to Array of Objects, one Object per Row

    const showLoadMore = ref(false);

    const dateRange = ref({
      startDate: null,
      endDate: null,
    });

    const onCellClicked = (params) => {
      if (params.column.colDef.headerName === 'Name' && params.value) {
        const userID = params.node.allLeafChildren[0].data.user_id;
        const selectedOption = appStore.dateSelectValue;
        window.analytics?.track('User Clicked', {
          name: params.value,
          userID,
        });
        window.analytics?.track('pick_user_data_view');
        router.push(`/users/data-view/${userID}?startDate=${dateRange.value.startDate}&endDate=${dateRange.value.endDate}&selected=${selectedOption}`);
      }
    };

    const currencySign = (curr) => (getSymbolFromCurrency(curr) ? getSymbolFromCurrency(curr) : '');

    const getFirstCurrency = (params) => {
      const currentData = params.node.allLeafChildren;
      // loop and return first non empty currency found
      let currency = '';

      for (let i = 0; i < currentData.length; i += 1) {
        if (currentData[i].data.currency?.length === 3) {
          currency = currentData[i].data.currency;
        }
      }
      return currency;
    };
    const formatCurrency = (params) => {
      const { value } = params;
      if(value === null || value === undefined) {
        return numberFormat(value);
      }

      if (isObject(value) ) {
        return numberFormat(value.value);
      }

      if (params.node.allLeafChildren === undefined) {
        return `${currencySign(params.node.data.currency)} ${numberFormat(value)}`;
      }
      const currency = getFirstCurrency(params);
      return `${currencySign(currency)} ${numberFormat(value)}`;
    };

    const formatDecimal = (params) => {
      const { value } = params;
      if (isObject(value) ) {
        return numberFormat(value.value);
      }
      return `${numberFormat(value)}`;
    };

    const formatValue = (params) => {
      const { value } = params;
      if (isObject(value) ) {
        return nullToDash(value.value);
      }
      return `${nullToDash(value)}`;
    };

    const saveRow = () => {
      // console.log('SAVE ROW');
    }

    const search = ref('');

    const columnDefs = reactive({
      columns: [
        {
          headerName: '',
          field: 'checkbox',
          sortable: false,
          filter: false,
          resizable: false,
          width: 40,
          maxWidth: 40,
          suppressMenu: true,
          suppressColumnsToolPanel: true,
          suppressValues: true,
          suppressColumnFilter: true,
          suppressMovable: true,
          suppressPivotMode: true,
          suppressPivots: true,
          rowSelection:"multiple",
          cellClass: 'ag-grid__checkbox-cell',
          headerClass: 'ag-grid__checkbox-header',
          checkboxSelection: (params) => {
            if(params.node.group && multipleMetadata.dynamicActions?.length > 0) {
              return true;
            }
          },
          headerCheckboxSelection: () => {
            if(multipleMetadata.dynamicActions?.length > 0) {
              return true;
            }
          },
        },
        {
          headerName: 'Name',
          field: 'name',
          hide: true,
          cellRenderer: 'agGroupCellRenderer',
          rowGroup: true,
          sortable: true,
          filter: true,
          minWidth: 400,
          suppressMovable: true,
          suppressColumnsToolPanel: true,
          suppressColumnFilter: true,
          suppressMenu: true,
          cellRenderer: 'AgGridProfileCell',
          cellRendererParams: {
            suppressDoubleClickExpand: true,
            suppressEnterExpand: true,
          },
        },
        {
          field: 'custom',
          sortable: false,
          minWidth: 200,
          hide: true,
          cellClass: 'ag-grid__custom-cell',
          cellRenderer: 'AgGridCustomInput',
          headerComponent: 'AgGridCustomHeader',
        },
        {
          field: 'platform',
          cellRenderer: (params) => {
            const { value } = params;
            const integrations = params.node.allLeafChildren;
            const currentVal = params.node.data;
            if (value) {
              return `
                <div class="ag-grid__integration" v-if="value.id">
                  <img class="ag-grid__integration-logo" src="${currentVal.platform_logo}" alt="${currentVal.platform}" />
                  <span>${currentVal.platform}</span>
                </div>
              `;
            }
            let integrationsString = '';
            if (integrations) {
              integrations.forEach((integration) => {
                integrationsString += `
                  <div class="ag-grid__integration">
                    <img class="ag-grid__integration-logo" src="${integration?.data.platform_logo}" :alt="integration.name" />
                  </div>
                `;
              });
            }
            return integrationsString;
          },
        },
        {
          headerName: 'Gross',
          field: 'gross_earnings',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Net',
          field: 'net_amount',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          field: 'taxes',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Bonuses',
          field: 'bonus_amount',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Platform Fee',
          field: 'platform_fee',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Card Earnings',
          field: 'card_earnings',
          aggFunc: 'sum',
          minWidth: 150,
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Cash Earnings',
          field: 'cash_earnings',
          aggFunc: 'sum',
          minWidth: 150,
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Expenses',
          field: 'expenses',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Fare',
          field: 'fare',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Other amount',
          field: 'other_amount',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Payouts',
          field: 'payouts',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Reimbursements',
          field: 'reimbursements',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          headerName: 'Tips',
          field: 'tips',
          aggFunc: 'sum',
          filter: 'agNumberColumnFilter',
          // type: 'rightAligned',
          valueFormatter: formatCurrency,
        },
        {
          field: 'email',
          aggFunc: 'latestValue',
          hide: true,
          valueFormatter: formatValue,
        },
        /// activity
        {
          field: 'afternoon_trips_total',
          headerName: 'Afternoon Trips Total',
          filter: 'agNumberColumnFilter',
          hide: true,
          aggFunc: 'sum',
          // type: 'rightAligned',
          valueFormatter: formatValue,
        },
        {
          field: 'afternoon_trips_total_perc',
          headerName: 'Afternoon Trips Total %',
          filter: 'agNumberColumnFilter',
          aggFunc: 'avg', // this shoild be avg
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatDecimal
        },
        {
          field: 'currency',
          headerName: 'Currency',
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatValue,
        },
        {
          field: 'distance_per_trip',
          headerName: 'Distance Per Trip',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatDecimal,
        },
        {
          field: 'distance_total',
          headerName: 'Distance Total',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatDecimal,
        },
        {
          field: 'morning_trips_total',
          headerName: 'Morning Trips Total',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatValue,
        },
        {
          field: 'morning_trips_total_perc',
          headerName: 'Morning Trips Total %',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum', // this shoild be avg
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatDecimal
        },
        {
          field: 'night_trips_total',
          headerName: 'Night Trips Total',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatValue,
        },
        {
          field: 'night_trips_total_perc',
          headerName: 'Night Trips Total %',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum', // this shoild be avg
          hide: true,
          // type: 'rightAligned',
          valueFormatter: formatDecimal,
        },
        {
          field: 'price_total',
          headerName: 'Price Total',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatCurrency,
        },
        {
          field: 'price_per_km',
          headerName: 'Price per km',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatCurrency,
        },
        {
          field: 'price_per_trip',
          headerName: 'Price per trip',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatCurrency,
        },
        {
          field: 'price_per_online_hour',
          headerName: 'Price per working hour',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatCurrency,
        },
        {
          field: 'price_per_driving_hour',
          headerName: 'Price per driving hour',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatCurrency,
        },
        {
          field: 'trips_total',
          headerName: 'Trips Total',
          filter: 'agNumberColumnFilter',
          aggFunc: 'sum',
          // type: 'rightAligned',
          hide: true,
          valueFormatter: formatValue,
        },
        /// activity
        {
          field: 'last_update',
          aggFunc: 'latestValue',
          headerName: 'Last Update',
          valueFormatter: (params) => {
            const { value } = params;
            if (!value) return '';
            return format(new Date(value), 'MMM dd, yyyy, HH:mm a');
          },
        },
        {
          field: 'status',
          cellRenderer: (params) => {
            const { value } = params;
            const statuses = params.node.allLeafChildren;
            if (value) {
              return `
                <div class="ag-grid__status" v-if="value.id">
                  <img class="ag-grid__status-logo" src="/images/${value}.png" alt="${value}" />
                  <p>${value}</p>
                </div>
              `;
            }
            let integrationsString = '';
            if (statuses) {
              statuses.forEach((status) => {
                if (status.data.status) {
                  integrationsString += `
                    <div class="ag-grid__status">
                      <img class="ag-grid__status-logo" src="/images/${status.data.status}.png" :alt="status" />
                    </div>
                  `;
                }
              });
            }
            return integrationsString;
          },
        },
        {
          field: 'firstConnection',
          aggFunc: 'latestValue',
          headerName: 'First connection',
          cellRenderer: (params) => {
            const { value } = params;
            if (!value) return '';
            return format(new Date(value), 'MMM dd, yyyy, HH:mm a');
          },
        },
        {
          field: 'user_created_at',
          aggFunc: 'latestValue',
          headerName: 'User created at',
          valueFormatter: (params) => {
            const { value } = params;
            if (!value) return '';
            return format(new Date(value), 'MMM dd, yyyy, HH:mm a');
          },
        }
      ],
    });

    const defaultColDef = {
      sortable: true,
      filter: true,
      minWidth: 120,
      autoGroupColumnDef: {
        filter: 'agGroupColumnFilter',
      },
      flex: 1,
      resizable: true,
    };

    const sideBar = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        },
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
        },
      ],
    };

    const aggFuncs = {
      testFunc: (params) => {
        return 'helo';
      },
      latestValue: (params) => {
        const { values } = params;
        values.sort((a, b) => {
          if (a > b) {
            return -1;
          }
          if (a < b) {
            return 1;
          }
          return 0;
        });
        return values[0];
      },
      integrations: (params) => {
        const { values } = params;
        const integrations = [];
        values.forEach((value) => {
          if (value) {
            integrations.push(value);
          }
        });
        return 'integrations';
      },
      sumHours: ({ values }) => {
        if (values.length) {
          const total = values.reduce((acc, curr) => {
            if (!curr) return acc;
            acc + curr.split(':').reduce((a, c) => 60 * a + +c), 0;
          });
          const hours = Math.floor(total / 3600);
          const minutes = Math.floor((total % 3600) / 60);
          const seconds = total % 60;
          return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        }
      },
    };

    const filterState = {
      filters: {},
      columnVisibility: {},
    };

    watch(() => modal.show, (value) => {
      if (value) {
        window.analytics?.track('invite_user_data_view');
      } else {
        window.analytics?.track('close_data_view_invite_users');
      }
    });

    const gridOptions = {
      suppressScrollOnNewData: true,
      suppressPaginationPanel: true,
      suppressRowTransform: true,

      onFilterChanged: (event) => {
        const { api } = event;
        filterState.filters = api.getFilterModel();
        appStore.setDashboardConfiguration('usersGridFilters', { usersGridFilters: filterState.filters });

        if (triggeredEvents.filters_data_view > 0) {
          window.analytics?.track('filters_data_view', {
            filters: filterState.filters,
          });
        }
        triggeredEvents.filters_data_view += 1;

      },
      onColumnVisible: (event) => {
        const { columnApi } = event;
        const sortState = columnApi.getColumnState();
        appStore.setDashboardConfiguration('usersGridSort', { usersGridSort: sortState });

        if (triggeredEvents.columns_data_view > 0) {
          window.analytics?.track('columns_data_view', {
          columnVisibility: filterState.columnVisibility,
        });
        }
        triggeredEvents.columns_data_view += 1;

      },
      onDragStopped: (event) => {
        const { columnApi } = event;
        const colIds = columnApi.getAllDisplayedColumns().map(col => col.colId);
        if(colIds?.includes('checkbox')) {
          // remove checkbox from column order
          colIds.splice(colIds.indexOf('checkbox'), 1);
        }
        appStore.setDashboardConfiguration('usersGridColumnOrder', { usersGridColumnOrder: colIds });
      },
      onSortChanged: (event) => {
        const { columnApi } = event;
        const sortState = columnApi.getColumnState();
        appStore.setDashboardConfiguration('usersGridSort', { usersGridSort: sortState });

        if (triggeredEvents.users_grid_layout > 0) {
          window.analytics?.track('Users Grid Layout Change', {
            sortState,
          });
        }
        triggeredEvents.users_grid_layout += 1;
      },
    };

    const loadingUsers = ref(true);
    const loadSpinner = ref(false);

    const usersPagination = reactive({
      cursor: 0,
      limit: 10000,
      startDate: null,
      endDate: null,
    });

    const moreUsers = ref(true);
    const isGridVisible = ref(false);

    const setSelectedDate = (selectedDate) => {
      const startTimestamp = getUnixTime(selectedDate.start);
      const endTimestamp = getUnixTime(selectedDate.end);
      handleDateChange({
        startDate: startTimestamp,
        endDate: endTimestamp,
      });
      appStore.setDateSelectValue(selectedDate);
    };

    const handleDateChange = async (date, loadmore = true) => {
      const { startDate, endDate } = date;
      dateRange.value = date;

      const PARAMS = {
        start_date: startDate,
        end_date: endDate,
        limit: usersPagination.limit,
        cursor: usersPagination.cursor,
      };
      if (loadmore) {
        loadingUsers.value = true;
        PARAMS.cursor = 0;
      }
      loadSpinner.value = true;
      gridReady.value = false;

      if (triggeredEvents.datehandleChange > 0) {
        window.analytics?.track('date_picker_data_view', {
          startDate,
          endDate,
        });
      }
      triggeredEvents.datehandleChange += 1;


      try {
        if(endDate) { //endDate has to be set
          if (route.query?.users !== 'false') {
            appStore.setUsersDateSelectValue(null);
            await usersStore().fetchUsers(PARAMS);
          } else {
            router.push({ name: 'Users', query: {} });
            if (!usersStore().getUsers) {
              await usersStore().fetchUsers(PARAMS);
            }
          }
        }
        rowData.value = usersStore().getUsers.users;
        appStore.setHeaderTitleInfo(`${usersStore().getUsers.size} in total`);

        usersPagination.cursor = usersStore().getUsers.next_cursor;
        usersPagination.startDate = startDate;
        usersPagination.endDate = endDate;
        showLoadMore.value = false;
        moreUsers.value = rowData.value.length !== 0;
        // appStore.setNotification({
        //   message: 'Users loaded successfully',
        // });
        loadingUsers.value = false;
        loadSpinner.value = false;
        setTimeout(() => {
          gridReady.value = true;
        }, 1000);

      } catch (err) {
        appStore.setNotification({
          message: err.message || 'Something went wrong. Please try again later',
          type: 'error',
        });
        rowData.value = [];
        loadingUsers.value = false;
        loadSpinner.value = false;
        setTimeout(() => {
          gridReady.value = true;
        }, 1000);
      } finally {
        loadSpinner.value = false;
        setTimeout(() => {
          gridReady.value = true;
        }, 1000);
      }
    };

    const loadMoreUsers = () => {
      handleDateChange({
        startDate: usersPagination.startDate,
        endDate: usersPagination.endDate,
      }, false);
    };
    const onFirstDataRendered = () => {};

    const groupedRows = reactive({
      value: []
    });

    const triggerFilter = ref(false);

    const gridApi = ref();

    const appliedFilters = computed(() => {
      const filters = appStore.getConfig?.usersGridFilters;
      triggerFilter.value = false;
      const filtersArray = [];
      for (const key in filters) {
        if (Object.prototype.hasOwnProperty.call(filters, key)) {
          const filter = filters[key];
          filtersArray.push({
            key,
            value: filter,
          });
        }
      }
      return filtersArray;
    });

    const removeAppliedFilter = (i) => {
      const getFilters = gridApi.value.getFilterModel();
      delete getFilters[i.key];
      gridApi.value.setFilterModel(getFilters);
    }

    const onGridReady = (params) => {
      const { api, columnApi } = params;
      gridApi.value = api;
      columnApi.setColumnWidth('ag-Grid-AutoColumn', 400);

      appStore.getDashboardConfiguration().then(() => {

        // Apply filters
        const getFiltersState = appStore.getConfig?.usersGridFilters;
        const getColumnsVisibility = appStore.getConfig?.usersGridColumnVisibility;
        api.setFilterModel(getFiltersState);


        // Apply column state
        const getColumnState = appStore.getConfig?.usersGridSort;
        const checkbox = getColumnState?.find((item) => item.colId === 'checkbox');
        if(checkbox) {
          getColumnState.splice(getColumnState.indexOf(checkbox), 1);
          columnApi.applyColumnState({ state: getColumnState });
        }
        columnApi.applyColumnState({ state: getColumnState });

        // Apply column order
        const getColumnsOrder = appStore.getConfig?.usersGridColumnOrder;
        // Remove checkbox from column order
        if(getColumnsOrder?.includes('checkbox')) {
          // remove checkbox from column order
          getColumnsOrder.splice(getColumnsOrder.indexOf('checkbox'), 1);
        }
        if (getColumnsOrder?.length > 0) {
          columnApi.moveColumns(getColumnsOrder, 1);
        }

        // autosize columns
        const allColIds = columnApi.getAllDisplayedColumns().map((column) => column.colId);

        setTimeout(() => {
          gridReady.value = true;
        }, 600);
      });


      // get all grouped rows in array with name and row index
      groupedRows.value = [];

      const getGroupedRows = () => {
        api.forEachNodeAfterFilterAndSort((node) => {
          if (node.group) {
            groupedRows.value.push({
              name: node.key,
              rowIndex: node.rowIndex,
            });
          }
        });
      };

      getGroupedRows();

    };

    const onBodyScroll = ((params) => {
      if (rowData.value.length === 0) return;
      // eslint-disable-next-line
      if (params.api.gridBodyCtrl.rowRenderer.lastRenderedRow >= params.api.gridBodyCtrl.rowModel.rowsToDisplay.length - 1) {
        if (moreUsers.value
        && params.api.gridBodyCtrl.rowModel.rowsToDisplay.length < usersStore().getUsers.size) {
          // showLoadMore.value = true;
        }
      } else {
        showLoadMore.value = false;
      }
    });

    const checkCanSync = () => {
      const lastSync = localStorage.getItem('lastSync');
      if (lastSync) {
        const lastSyncDate = new Date(lastSync);
        const today = new Date();
        if (lastSyncDate.getDate() === today.getDate()) {
          return false;
        }
      }
      return true;
    };

    const showSyncModal = () => {
      if (checkCanSync()) {
        syncModal.show = true;
      }
    };

    const partnerStatus = computed(() => usersStore().getPartnerStatus);
    const partnerStatusShow = ref(false);

    let interval = null;

    const checkFetchStatus = () => {
      interval = setInterval(() => {
        if (partnerStatus.value === true || partnerStatus.value === null) {
          partnerStatusShow.value = true;
          usersStore().fetchPartnerStatus();
        } else if (partnerStatus.value === false) {
          clearInterval(interval);
          rowData.value = [];
          loadingUsers.value = true;
          usersPagination.cursor = 0;
          partnerStatusShow.value = false;
          handleDateChange({
            startDate: usersPagination.startDate,
            endDate: usersPagination.endDate,
          }, false);
        }
      }, 5000);
    };

    const onFilterTextBoxChanged = () => {
      gridApi.value.setQuickFilter(search.value);
      // Check if multiple metadata is available and when filters are applied
      // if so, trigger the multiple metadata
      if(multipleMetadata.dynamicActions?.length > 0 && multipleMetadata.selected?.length > 0) {
        onRowSelected();
      }
    };

    const createMetaDataColumns = (item) => {
      switch (item.type) {
        case 'number':
        case 'string':
          return reactive({
            headerName: item.name,
            field: item.name,
            hide: true,
            sortable: false,
            cellRenderer: 'AgGridInput',
            headerComponent: 'AgGridMetadataHeader',
            minWidth: 200,
            params: {
              onUpdateMetadata: onUpdateMetadata,
              type: item.type
            }
          });
        case 'array':
        multipleMetadata.dynamicActions.push({
          name: item.name,
          field: item.name,
          values: item.possible_values,
        });
        return reactive({
            headerName: item.name,
            field: item.name,
            hide: true,
            sortable: false,
            cellClass: 'ag-grid__dropdown-cell',
            cellRenderer: 'AgGridDropDown',
            headerComponent: 'AgGridMetadataHeader',
            minWidth: 200,
            params: {
              values: item.possible_values,
              onUpdateMetadata: onUpdateMetadata
            }
          });
        default:
        return reactive({
            headerName: item.name,
            field: item.name,
            minWidth: 200,
          },);
      }
    };

    const onUpdateMetadata = (id, data) => {

      usersStore().putUserMetadata(id, data).then(()=>{
          usersStore().fetchUserById(id, {
          start_date: usersPagination.startDate,
          end_date: usersPagination.endDate,
          only_income: true,
          }).then((res) => {
            let row = rowData.value.find((item) => item.user_id === id);
            const object = res.user.total_income.dynamic_data_points
            .reduce((obj, item) => Object.assign(obj, { [item.name]: item.result }), {});
            row = Object.assign(row, {...row, ...object, ...res.user.metadata, user_id: row.user_id});
            setTimeout(() => {
              gridApi.value.refreshCells({ force: true });
            }, 100);
          });
      })
    }

    const onRowSelected = () => {
      multipleMetadata.selected = gridApi.value?.getSelectedNodes().filter((node) => node.displayed);
      if (multipleMetadata.selected?.length > 0) {
        multipleMetadata.defaultValue()
      }
    }

    onMounted(() => {
      columnDefs.columns.autoGroupColumnDef = {
        headerName: 'Name',
        filter: true,
        minWidth: 400,
        suppressMovable: true,
        suppressColumnFilter: true,
        suppressColumnsToolPanel: true,
        suppressMenu: true,
        cellRendererParams: {
          suppressCount: true,
          width: 300,
        },
      };
      // window.analytics?.track('select_data_view');
      appStore.setSandboxSwitch(true);

      emitter.on('reload', () => {
        rowData.value = [];
        loadingUsers.value = true;
        usersPagination.cursor = 0;
        search.value = '';
        handleDateChange({
          startDate: usersPagination.startDate,
          endDate: usersPagination.endDate,
        }, false);
        clearInterval(interval);
        usersStore().fetchPartnerStatus().then((res)=> {
          if (res.bool === true) {
            partnerStatusShow.value = true;
            clearInterval(interval);
            checkFetchStatus();
          }
        });
      });
      if(fetureCheck('VUE_APP_METADATA')){
        profileStore.getPartnerInfo?.metadata_parameters?.map((item) => {
          // Based on metadata parameters, create columns
          // Base on metadata type add cell rendering
          columnDefs.columns.push(createMetaDataColumns(item)) ;
        });
        // Check if multiple metadata is available and add checkbox column
        if(multipleMetadata.dynamicActions?.length === 0) {
          // find checkbox column and remove it from the list
          const checkboxIndex = columnDefs.columns.findIndex((item) => item.field === 'checkbox');
          // find checkbox column in columnDefs.columns and remove it from the list
          columnDefs.columns.splice(checkboxIndex, 1);
        }
      }
      customizationStore().fetchCustomizations();
      settingsStore().fetchEmailCustomization();
      usersStore().fetchPartnerStatus().then((res)=> {
        if (res.bool === true) {
          clearInterval(interval);
          checkFetchStatus();
        }
      });

    });

    onBeforeUnmount(() => {
      emitter.off('reload');
      clearInterval(interval);
    });

    const customizationList = computed(() => {
      if (!customizationStore().getAllCustomizations) return [];
      customizationID.value = customizationStore().getAllCustomizations[0]?.id;
      return customizationStore().getAllCustomizations.map((item) => {
        return {
          id: item.id,
          title: item.default ? 'Default' : item.config?.name || 'Default',
        };
      });
    });

    // const SAND = ref(!appStore.getState);
    const SAND = computed(() => !appStore.getState);


    watch(()=> usersStore().getDynamicColumns, (value) => {
      if(value.length === 0) return;
      value.forEach((item) => {
          // check if column already exists
          if(columnDefs.columns.find((column) => column.field === item.name)) return;
          columnDefs.columns.push({
            headerName: item.name,
            field: item.name,
            hide: true,
            sortable: false,
            minWidth: 150,
            cellRenderer: 'AgGridTopLevelCell',
          });
        });
    });

    return {
      modal,
      columnDefs,
      rowData,
      defaultColDef,
      aggFuncs,
      singleEmail,
      fileInvite,
      hasInviteUsers,
      onSubmit,
      handleDateChange,
      loadingUsers,
      loadMoreUsers,
      gridOptions,
      loadSpinner,
      onBodyScroll,
      showLoadMore,
      SAND,
      sideBar,
      syncModal,
      showSyncModal,
      checkCanSync,
      onFirstDataRendered,
      setSelectedDate,
      onCellClicked,
      onGridReady,
      customizationList,
      dropdownAction,
      saveRow,
      gridReady,
      groupedRows,
      search,
      partnerStatus,
      partnerStatusShow,
      appliedFilters,
      removeAppliedFilter,
      getSelectedDateValue,
      onFilterTextBoxChanged,
      formatValue,
      onUpdateMetadata,
      onRowSelected,
      multipleMetadata,
      RFileUpload,
      csvModal,
      csvFleetFile,
      profileStore,
    };
  },
};
</script>
<style lang="scss" scoped>
.hidden-ag-grid {
  display: none;
}

:deep {
  .ag-grid__dropdown-cell{
    overflow: visible;
    padding: 0;
    border: none;
  }
}

.users {
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
    margin-bottom: 2px;
    vertical-align: middle;
    display: inline-block;
  }
  &__status {
    .wrapper {
      color: #7A808B;
      font-weight: 600;
      font-size: 14px;
      img {
        height: 24px;
        vertical-align: middle;
        margin-right: 6px;
      }
    }
  }
  &__autocomplete {
    :deep() {
      .input {
        min-width: 360px;
      }
    }
  }
  &__header {
    display: grid;
    gap: 12px;
    margin-bottom: 8px;
    align-items: baseline;
    margin: -40px -30px;
    padding: 0px 30px;
    background: #fefefe;
    box-shadow: 0px 0px 40px rgba(20, 20, 20, 0.04);
    border-bottom: 1px solid #EDF2F8;
  }
  &__subheader {
    display: grid;
    grid-template-columns: 380px auto auto;
    align-content: center;
    align-items: center;
    height: 63px;
    &--left {
      justify-items: start;
      text-align: left;
    }
    &--right {
      grid-template-columns: 270px auto auto;
      gap: 12px;
      justify-self: end;
      text-align: right;
      :deep() {
        .date-label {
          text-align: left;
        }
      }
    }
  }
  &__text {
    display: inline-block;
  }
  &__stats {
    display: grid;
    grid-template-columns: 100px auto;
    .users__filter {
      width: 100%;
      display: block;
      overflow-x: scroll;
      white-space: nowrap;
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
      & > div:not(.filter) {
        display: flex;
        gap: 4px;
      }
    }
    p {
      font-size: 13px;
      font-weight: 600;
      color: var(--color-grey-light);
      text-transform: capitalize;
      align-self: center;
    }
    .filter {
      display: flex;
      gap: 4px;
      place-items: center;
      padding: 4px 6px;
      background: #fff;
      border-radius: 100px;
      border: 1px solid var(--color-grey-outline);
      font-weight: 600;
      width: fit-content;
      p {
        font-size: 12px;
        color: var(--color-grey-dark);
        display: inline-block;
        margin-right: 3px;
      }
      span {
        color: var(--color-dark-main);
        font-size: 12px;
        &:after {
          content: ',';
        }
        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
      svg {
        margin-left: 4px;
        justify-self: end;
        cursor: pointer;
        transition: var(--transition-default);
        &:hover {
          filter: brightness(0.7);
        }
      }
    }
  }
  &__search {
    max-width: 440px;
  }
  &__title {
    align-self: center;
    h2 {
      font-size: 24px;
      font-weight: 400;
      color: var(--color-dark-main);
    }
  }
  &__action {
    text-align: end;
  }

  &__stats {
    margin-top: 60px;
  }
  &__table {
    height: calc(100vh - 204px);
    background-color: #fff;
    position: relative;
    margin-top: 12px;
    border-radius: 8px;
    & > * {
      font-family: inherit;
    }
    .ag-theme-alpine {
      height: 100%;
      margin-bottom: 40px;
      box-shadow: 0px 0px 20px rgba(20, 20, 20, 0.02);
    }
    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
      width: 100%;
      &.transparent {
        background: radial-gradient(circle, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 1));
      }
    }
    .loadmore {
      position: relative;
      margin: -60px auto;
    }
  }
  &__status {
    span {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      margin-right: 4px;
      vertical-align: middle;
      margin-bottom: 2px;
    }
    &--connected {
      background-color: var(--color-blue-main);
    }
    &--disconnected {
      background-color: var(--color-grey-light);
    }
    &--revoked {
      background-color: #FE9543;
    }
  }
  &__sync {
    display: grid;
    place-content: center;
    height: 100%;
    margin: 0px 20px;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      svg {
        opacity: 0.8;
      }
    }
  }
}

.multiple{
  &__actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    height: 100%;
    gap: 28px;
    &__item{
      min-width: 90px;
      &-icon{
        height: 24px;
        width: 24px;
      }
    }
  }
}

.csv{
  &__modal{
    &-section {
      width: 100%;
      margin: 20px 0;
      :deep{
        .combo{
          &__button{
            &-wrapper{
              margin-bottom: 20px;
            }
            &-text{
              width: 100%;
            }
            &-menu{
              left: 0;
            }
            &-items{
              width: calc(100% - 16px);
              max-height: 40vh;
              overflow: auto;
              .csv__modal-section__item{
                width: calc(100% - 16px - 32px);
                flex-direction: column;
                align-items: flex-start;

                p {
                  color: var(--color-grey-dark);
                  font-size: 15px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }

                span{
                  color: var(--color-dark-main);
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: normal;
                }
                &--platform{

                width: calc(100% - 16px - 32px);
                  flex-direction: row !important;
                  align-items: center !important; justify-content: space-between;
                  justify-content: unset;
                }
              }
            }
          }
        }
      }

      &__add{
        flex-direction: row !important;
        align-items: center !important;
        i{
          font-size: 24px;
        }
      }
    }
    &-manager{
      &:after{
        content: '';
        display: inline-block;
        border-radius: 999px;
        transform: translateY(-1px);
        background-color: var(--color-grey-light);
        width: 4px;
        height: 4px;
        margin: 0 4px;
      }
    }
    &-icon{
      height: 24px;
      width: 24px;
      border-radius: 999px;
      margin-right: 12px;
      color: var(--color-grey-light) !important;
    }
  }
}
</style>
