<template>
  <div>
    <div class="actions">
      <RDropdown
        title="Actions"
        :items="actionList"
        @action="dropdownAction"
      />
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { usersStore } from '@/store/users';
import RDropdown from './RDropdown.vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const actionList = ref([]);

const dataAction = ref(props.params.node.data.status);

if (dataAction.value === 'disconnected') {
  actionList.value.push(
    {
      id: 'reconnect',
      title: 'Reconnect',
      icon: 'ri-links-line',
    },
  );
}
if (dataAction.value === 'pending' || dataAction.value === 'reconnecting') {
  actionList.value.push(
    {
      id: 'resend',
      title: 'Resend invite',
      icon: 'ri-mail-send-line',
    },
  );
}

actionList.value.push(
  {
    id: 'remove',
    title: 'Remove',
    icon: 'ri-delete-bin-line',
  },
);

const dropdownAction = (item) => {
  if (item.id === 'remove') {
    usersStore().setModal({
      show: true,
      data: [props.params.data],
    });
  }
  if (item.id === 'reconnect') {
    usersStore().reconnectUser(props.params.data);
  }
  if (item.id === 'resend') {
    usersStore().resendInvite(props.params.data);
  }
};

</script>

<style lang="scss">
.actions {
  height: 52px;
  display: grid;
  align-items: center;
  justify-content: center;
  .dropdown__wrapper {
    height: 36px;
    .dropdown {
      height: 24px;
      p, .dropdown__icon {
        height: inherit;
      }
      &__list {
        right: 0;
        left: unset;
        width: 146px;
      }
      .dropdown__icon {
        transform: translateY(-16px);
      }
    }
  }

}
</style>
