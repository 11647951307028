<template>
    <div class="sections sections--dropoff-rate">
        <div class="sections__head">
            <h5>Drop off rate by Connect screen</h5>
            <r-tool-tip>
                <template #tooltip-text>
                    <span>
                        <!-- eslint-disable-next-line -->
                        Drop off funnel displays how many users successfully moved from one step to another of your Connect flow. You can identify the moment when users abandon your Connect flow.
                    </span>
                </template>
            </r-tool-tip>
        </div>
        <div class="sections__tab">
          <div v-if="isLoading">
            <r-loading></r-loading>
          </div>
          <div v-else>
            <vue-apex-charts
            type="bar" height="350" :options="chartOptions" :series="chartSeries"></vue-apex-charts>
          </div>
          <empty-state v-if="showEmptyState && !props.isLoading"></empty-state>
        </div>
    </div>
</template>

<script setup>
import {
  defineProps, ref, watch, reactive, computed,
} from 'vue';
import RToolTip from '@/components/RToolTip.vue';
import VueApexCharts from 'vue3-apexcharts';
import RLoading from '@/components/RLoading.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import { percentile, secToMinutes } from '@/util/helpers';

/* eslint-disable vue/no-setup-props-destructure */
const props = defineProps(['isLoading', 'data']);

const chartOptions = reactive({
  chart: {
    type: 'bar',
    height: '200px',
    stacked: false,
  },
  dataLabels: {
    enabled: true,
    position: 'top',
    style: {
      fontSize: '24px',
      colors: ['#253858'],
    },
    formatter: ((val) => `${val.toFixed(2)}%`),
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: '60%',
      endingShape: 'rounded',
      borderRadius: 7,
    },
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['#fff'],
  },
  xaxis: {
    categories: [],
  },
  yaxis: {
    show: false,
    max: 100,
    grid: {
      show: false,
    },
  },
  fill: {
    opacity: 1,
  },
  legend: {
    show: false,
  },
  tooltip: {
    custom: ({ dataPointIndex, w }) => {
      if (Array.isArray(props.data) && props.data.length > dataPointIndex) {
        const xValue = w.globals.labels[dataPointIndex];
        const currentData = props.data[dataPointIndex];
        return `
          <div class="custom-tooltip">
            <div class="custom-tooltip__header">${xValue}</div>
            <div class="custom-tooltip__item">
              <div>
                  <h6>Drop Off Rate</h6>
              </div>
              <h6>${percentile(currentData.drop_off_rate)}%</h6>
            </div>
            <div class="custom-tooltip__item">
              <div>
                  <h6>No of Users Dropoff</h6>
              </div>
              <h6>${currentData.users_drop_off}</h6>
            </div>
            <div class="custom-tooltip__item">
              <div>
                  <h6>Avg Session Time</h6>
              </div>
              <h6>${secToMinutes(currentData.avg_session_time)}</h6>
            </div>
          </div>
        `;
      }
      return '';
    },
  },
});

const chartSeries = ref([
  {
    name: 'Drop Off Rate',
    data: [],
  },
]);

const showEmptyState = computed(() => {
  if (Array.isArray(props.data) && props.data.length > 0) {
    return props.data.every((item) => item.users_rate === 0
      && item.total_users === 0
      && item.users_drop_off === 0);
  }
  return true;
});

watch(() => props.data, async (newData) => {
  chartSeries.value[0].data = newData.map((item) => percentile(item.users_rate));
  chartOptions.xaxis.categories = newData.map((item, index) => `${index + 1}. ${item.screen}`);
}, { immediate: true });
</script>

<style lang="scss" scoped>
.sections {
    max-width: 100% !important;
    &--dropoff-rate {
        .sections__head {
            padding: 16px 32px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    &__tab {
        margin-top: 4px;
        position: relative;
    }
    h5 {
        color: var(--color-black, #141414);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }
}
:deep {
    .vue-apexcharts {
        .apexcharts-tooltip {
            font-family: Gilroy !important;
            .apexcharts-tooltip-title {
                padding: 4px 8px;
                border-radius: 4px 4px 0px 0px;
                border: 1px solid #E2E3E3 !important;
                background: #EDEEF1 !important;
                color: var(--color-black, #141414);
                font-family: Gilroy !important;
                font-weight: 500 !important;
            }
            .apexcharts-tooltip-text {
                font-family: Gilroy !important;
            }
            .apexcharts-tooltip-text-y-label {
                font-weight: 500 !important;
            }
            .apexcharts-tooltip-text-y-value {
                font-weight: 700 !important;
            }
        }
        .apexcharts-toolbar {
            display: none !important;
        }
        .apexcharts-grid-borders {
            display: none;
        }
        .apexcharts-grid {
            display: none;
        }
        .apexcharts-bar-area {
            fill: rgba(0, 97, 255, 0.16);

            &:hover {
                fill: rgba(0, 97, 255, 0.40);
            }
        }
        .apexcharts-datalabel {
            font-family: Gilroy !important;
            font-weight: 500;
            font-size: 24px !important;
        }
        .apexcharts-xaxis-texts-g {
            tspan {
                font-size: 15px;
                font-weight: 600;
                font-family: Gilroy !important;
                fill: var(--color-dark-main, #253858);
            }
        }
      .custom-tooltip {
          box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
          width: 180px;

          &__header {
              border-radius: 4px 4px 0px 0px;
              border: 1px solid #E2E3E3;
              background: #EDEEF1;
              color:var(--color-black, #141414);
              padding: 4px 8px;
              font-size: 12px;
              font-weight: 500;
          }
          &__item {
              display: flex;
              padding: 8px;
              justify-content: space-between;
              align-items: center;
              span {
                  display: flex;
                  height: 12px;
                  width: 12px;
                  border-radius: 12px;
                  background: var(--color-blue-main, #0061FF);

                  &.transparent {
                      background: transparent;
                  }
              }
              div {
                  display: flex;
                  gap: 8px;
                  align-items: center;
                  h6 {
                      color: var(--color-black, #141414);
                      font-size: 12px;
                      font-weight: 500;
                  }
              }
              h6 {
                  color: var(--color-black, #141414);
                  font-size: 12px;
                  font-weight: 700;
              }
          }
      }
    }
}
</style>
