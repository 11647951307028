import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';

const DOMAIN_SAND_PATH = process.env.VUE_APP_API_SAND_URL || '/api';

const getHeaders = () => {
  const currentPartner = localStorage.getItem('partner_id');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  const clientId = new URLSearchParams(window.location.search).get('client_id') || currentPartner;
  if (clientId) {
    headers['Client-Id'] = clientId;
  }
  return headers;
};

const getConversionAnalytics = async (params, platformId) => {
  const production = localStorage.getItem('production');
  const DOMAIN = production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`);
  try {
    const response = await http.get(
      `${DOMAIN}/analytics/conversion?${queryString.join('&')}`,
      {
        headers: getHeaders(),
        params: {
          platform_id: platformId,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default {
  getConversionAnalytics,
};
