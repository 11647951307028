<script setup>
import { computed, ref } from 'vue';
import { appStore } from '@/store/app';
import { OnClickOutside } from '@vueuse/components';
import { PencilIcon } from './icons';

const headerName = computed(() => appStore().getConfig?.headerName);

const editMode = ref(false);

const editValue = ref('');

const pencil = ref(null);

const tooltip = ref(null);

const enableEdit = () => {
  editMode.value = true;
  editValue.value = headerName.value;
  setTimeout(() => {
    document.querySelector('#custom__input').focus();
  }, 100);
};

const onKeyup = (e) => {
  if (e.key === 'Enter') {
    appStore().setDashboardConfiguration({ headerName: editValue.value }).then(() => {
      appStore().getDashboardConfiguration();
      editMode.value = false;
    });
  }
  if (e.key === 'Escape') {
    editMode.value = false;
  }
};

const disableEdit = () => {
  editMode.value = false;
};

const enableTooltip = (bool) => {
  if (bool) {
    const pencilPosition = pencil.value.$el.getBoundingClientRect();
    tooltip.value.style.top = `${pencilPosition.top - 60}px`;
    tooltip.value.style.left = `${pencilPosition.left - 100}px`;
    tooltip.value.style.display = 'block';
  } else if (tooltip.value) {
    tooltip.value.style.display = 'none';
  }
};

</script>
<template>
  <div class="custom">
    <OnClickOutside @trigger="disableEdit()">
      <p v-if="!editMode" class="custom__title">
        {{ headerName || 'Custom' }}
        <span>
          <PencilIcon
            ref="pencil"
            @click="enableEdit()"
            @mouseover="enableTooltip(true)"
            @focusin="enableTooltip(true)"
            @mouseleave="enableTooltip(false)"
            @blur="enableTooltip(false)"
          />
          <div ref="tooltip" class="custom__tooltip">
            The column is editable.<br>
            Click to edit column’s name
          </div>
        </span>
      </p>
      <div v-else class="custom__wrapper">
        <!-- eslint-disable-next-line -->
        <input id="custom__input" class="custom__input" type="text" v-model="editValue" @keyup="onKeyup($event)">
      </div>
    </OnClickOutside>
  </div>
</template>
<style lang="scss" scoped>
.custom {
  width: 100%;
  &__title {
    line-height: 42px;
    font-weight: 600;
    position: relative;
    &:hover {
      svg {
        opacity: 1;
      }
    }
    svg {
      opacity: 0;
      position: absolute;
      cursor: pointer;
      height: 18px;
      top: 12px;
      right: 0px;
      margin-left: 6px;
      transition: var(--transition-default);
    }
  }
  &__wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% - 4px);
    height: calc(100% - 2px);
    background: #fff;
    border: 1px solid var(--color-blue-main);
  }
  &__input {
    width: inherit;
    height: 100%;
    border: none;
    appearance: none;
    outline: none;
    padding: 2px 8px;
    box-sizing: border-box;
    font-family: inherit;
    background: #fff;
    font-size: 15px;
    font-weight: 600;
  }
  &__tooltip {
    display: none;
    position: fixed;
    opacity: 0.92;
    background: var(--color-dark-main, #253858);
    color: white;
    padding: 4px 8px;
    border-radius: 3px;
    color: var(--color-white, #FFF);
    font-size: 12px;
    line-height: 160%;
    font-weight: 500;
    z-index: 999;
  }
}
</style>
