import { defineStore } from 'pinia';
import CoveragesData from '../api/coverages';

const normalizeString = (str) => str.normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .replace(/[^a-zA-Z0-9]/g, '')
  .toLowerCase();

// eslint-disable-next-line import/prefer-default-export
export const coveragesStore = defineStore({
  id: 'coveragesStore',
  state: () => ({
    data: null,
    coverage: {},
  }),
  getters: {
    getCoverages: (state) => state.data,
    getCoverage: (state) => state.coverage,
  },
  actions: {
    fetchCoverages(params) {
      return new Promise((resolve, reject) => {
        CoveragesData.getCoverages(params).then((data) => {
          this.data = data?.datasources;
          resolve(this.data);
        }).catch((err) => {
          this.data = null;
          reject(err);
        });
      });
    },
    fetchCoverage(name) {
      return new Promise((resolve, reject) => {
        if (this.data) { // pending when api is ready, search data for now
          // eslint-disable-next-line max-len
          const coverage = this.data.find((item) => normalizeString(item.title) === normalizeString(name));
          if (coverage) {
            this.coverage = coverage;
            resolve(this.coverage);
          } else {
            reject(new Error('Coverage not found'));
          }
        } else {
          this.fetchCoverages()
            .then(() => {
              // eslint-disable-next-line max-len
              const coverage = this.data.find((item) => normalizeString(item.title) === normalizeString(name));
              if (coverage) {
                this.coverage = coverage;
                resolve(this.coverage);
              } else {
                reject(new Error('Coverage not found'));
              }
            })
            .catch(() => {
              reject(new Error('Failed to fetch coverages'));
            });
        }
      });
    },
  },
});
