<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="user-profile">
    <div class="user-profile-actions"
      v-if="profile.getProfile.canAccess.driverInsights && isProduction"
      >
      <div
        :class="{'disabled': !enableInsights}"
        :disable="!enableInsights" @click="onDriverInishts()">
        <i class="ri-bar-chart-2-fill"></i>
        <p>
          See User Insights
        </p>
        <i class="ri-arrow-right-s-line"></i>
      </div>
    </div>
    <div class="sections">
      <div class="sections__header sections--splited">
        <p class="sections__header-title">Profile</p>
        <RComboButton icon="Download">
          <li @click="onDownload('json')" @keydown="onDownload('json')">
            JSON
          </li>
          <li @click="onDownload('csv')" @keydown="onDownload('csv')">
            CSV
          </li>
          <li @click="onDownload('xls')" @keydown="onDownload('xls')">
            XLS
          </li>
        </RComboButton>
      </div>
      <div class="sections__content">
        <div class="sections__info">
          <RProfile
            :name="user.value.profile.full_name"
            :src="null"
            :avatar="true"
          />
          <br>
          <p v-if="user.value.profile.first_name">Basic Info</p>
        </div>
        <div class="sections__data">
          <div class="sections__title">
            <h3>Full name</h3>
            <h1>
              {{ user.value.profile.full_name }}
            </h1>
          </div>
          <br>
          <div class="sections__data" v-if="user.value.profile.first_name">
            <div class="sections__table">
              <table>
                <tr v-if="user.value.profile.first_name">
                  <td>First Name</td>
                  <td>{{ user.value.profile.first_name }}</td>
                </tr>
                <tr v-if="user.value.profile.last_name">
                  <td>Last Name</td>
                  <td>{{ user.value.profile.last_name }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="sections__content">
        <div class="sections__info">
          <p>Contact</p>
        </div>
        <div class="sections__data">
          <div class="sections__table">
            <table>
              <tr v-if="user.value.profile.street">
                <td>Street</td>
                <td>{{ user.value.profile.street }}</td>
              </tr>
              <tr v-if="user.value.profile.city">
                <td>City</td>
                <td>{{ user.value.profile.city }}</td>
              </tr>
              <tr v-if="user.value.profile.state">
                <td>State/Provice</td>
                <td>{{ user.value.profile.state }}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{{ userCountry }}</td>
              </tr>
              <tr v-if="user.value.profile.country_phone_code">
                <td>Country phone code</td>
                <td>{{ user.value.profile.country_phone_code }}</td>
              </tr>

              <tr v-if="user.value.profile.phone">
                <td>Phone number</td>
                <td>{{ user.value.profile.phone }}</td>
              </tr>
              <tr v-if="user.value.profile.email">
                <td>Email</td>
                <td>{{ user.value.profile.email }}</td>
              </tr>
              <tr v-if="user.value.profile?.date_of_birth">
                <td>Date of Birth</td>
                <td>{{ user.value.profile.date_of_birth }}</td>
              </tr>

              <!-- <tr>
                <td>Profile URL</td>
                <td><a href=""> <i class="ri-share-line"></i> Link</a></td>
              </tr> -->
            </table>
          </div>
        </div>
      </div>
      <div class="sections__content" v-if="hasProfileProperties">
        <div class="sections__info">
          <p>Additional</p>
        </div>
        <div class="sections__data">
          <div class="sections__table">
            <table>
              <tr v-if="user.value.profile.licence_number">
                <td>Driver Licence Number</td>
                <td>{{ user.value.profile.licence_number }}</td>
              </tr>
              <tr v-if="user.value.profile.licence_expire_dt">
                <td>Driver Licence Expire Date</td>
                <td>{{ user.value.profile.licence_expire_dt }}</td>
              </tr>
              <tr v-if="user.value.profile.marital_status">
                <td>Marital Status</td>
                <td>{{ user.value.profile.marital_status }}</td>
              </tr>
              <tr v-if="user.value.profile?.fiscal_id">
                <td>Fiscal ID</td>
                <td>{{ user.value.profile.fiscal_id }}</td>
              </tr>
              <tr v-if="user.value.profile?.nationality">
                <td>Nationality</td>
                <td>{{ user.value.profile.nationality }}</td>
              </tr>
              <tr v-if="user.value.profile?.gender">
                <td>Gender</td>
                <td>{{ user.value.profile.gender }}</td>
              </tr>
              <tr v-if="user.value.profile?.number_of_dependents">
                <td> Number of dependents</td>
                <td>{{ user.value.profile.number_of_dependents }}</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue';
import { countriesStore as useCountriesStore } from '@/store/countries';
import { profileStore as useProfileStore } from '@/store/profile';
import { insightsStore as useInsightsStore } from '@/store/insights';
import RProfile from '@/components/RProfile.vue';
import RComboButton from '@/components/RComboButton.vue';
import { useRoute, useRouter } from 'vue-router';

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(['download']);

const user = computed(() => props.data);
const profile = useProfileStore();

const countriesStore = useCountriesStore();
const insightsStore = useInsightsStore();

const router = useRouter();
const route = useRoute();

const hasProfileProperties = computed(() => {
  const profileInfo = user.value.value.profile;
  return profileInfo?.licence_number || profileInfo?.licence_expire_dt
      || profileInfo?.marital_status
      || profileInfo?.fiscal_id || profileInfo?.nationality
      || profileInfo?.gender || profileInfo?.number_of_dependents;
});

const isProduction = computed(() => localStorage.getItem('production') === 'true');
const enableInsights = ref(false);

const onDriverInishts = () => {
  // check if user has access to driver insights
  if (!profile.getProfile.canAccess.driverInsights || enableInsights.value === false) {
    return;
  }
  useInsightsStore().setDriverName(user.value.value.profile.full_name);
  router.push(
    {
      name: 'Insights Details',
      params: {
        id: route.params.id,
        name: user.value.value.profile.full_name,
      },
      query: {
        ...route.query,
        id: route.params.id,
      },
    },
  );
};

const userCountry = computed(() => {
  if (user.value.value.profile.country) {
    if (user.value.value.profile.country.length === 2) {
      return countriesStore.getCountry(user.value.value.profile.country).name;
    }
    return user.value.value.profile.country;
  }
  return countriesStore.getCountry(user.value.value.country).name;
});

const onDownload = (fileType) => {
  emits('download', { fileType, section: 'profile' });
};

const checkIfUserHasInsights = (insightsData) => {
  if (profile.getProfile.canAccess.driverInsights) {
    const availableDrivers = insightsData.tables.driver_list.map((driver) => driver.person_id);
    if (availableDrivers.includes(route.params.id)) {
      enableInsights.value = true;
      return;
    }
    enableInsights.value = false;
  }
};

onMounted(() => {
  localStorage.removeItem('sampleData');
  if (profile.getProfile.canAccess.driverInsights) {
    insightsStore.fetchOverview().then((res) => {
      checkIfUserHasInsights(res);
    });
  }
});
</script>
<style lang="scss" scoped>
.user{
  &-profile{
    &-actions{
      margin-bottom: 20px;
      max-width: 960px;
      display: flex;
      justify-content: flex-end;
      div {
        color: var(--color-dark-main, #253858);
        font-family: Gilroy;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 150% */
        display: flex;
        align-items: center;
        cursor: pointer;
        p{
          margin: 0 8px;
        }
        i {
          font-size: 20px;

          transform: translateY(-2px);
          &:last-child {
            font-size: 12px;
          }
        }
      }
      .disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}
</style>
