import http from './http';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';
const DOMAIN_SAND_PATH = process.env.VUE_APP_API_SAND_URL || '/api';

const getHeaders = (isSampleData) => {
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  if (!isSampleData) {
    const currentPartner = localStorage.getItem('partner_id');
    const clientId = new URLSearchParams(window.location.search).get('client_id') || currentPartner;
    if (clientId) {
      headers['Client-Id'] = clientId;
    }
  }
  return headers;
};

const getOverview = async (isSampleData) => {
  const DOMAIN = isSampleData ? DOMAIN_SAND_PATH : DOMAIN_PATH;

  const response = await http.get(`${DOMAIN}/driver_insights/overview`, {
    headers: getHeaders(isSampleData),
  });
  return response.data;
};

const getDriverDetails = async (personId, isSampleData) => {
  const DOMAIN = isSampleData ? DOMAIN_SAND_PATH : DOMAIN_PATH;
  const response = await http.get(`${DOMAIN}/driver_insights/drivers_v2/${personId}`, {
    headers: getHeaders(isSampleData),
  });

  return response.data;
};

export default {
  getOverview,
  getDriverDetails,
};
