<template>
  <div>
    <span v-if="showAvatar">
      <img class="ag-grid__avatar" :src="data.avatar" alt="" @error="handleError" />
    </span>
    <span v-else>
      <div class="ag-grid__avatar ag-grid__avatar--initials">
        {{ data.name
          .split(' ')
          .map((n, i) => {
            if (n[0] !== '#') {
              if (i > 1) return;
              return n[0];
            }
            return '';
          })
          .join('') }}
      </div>
    </span>
    <span class="ag-grid__name">{{ data.name }}</span>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
});

const showAvatar = ref(true);

const data = computed(() => props.params.node.allLeafChildren[0].data);

const handleError = () => {
  showAvatar.value = false;
};

</script>
