<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Group">
        <path id="Vector" d="M1.66669 10.0001H3.33335V17.5001H1.66669V10.0001ZM4.16669
        11.6667H5.83335V17.5001H4.16669V11.6667ZM13.3334 6.66675H15V17.5001H13.3334V6.66675ZM15.8334
        8.33341H17.5V17.5001H15.8334V8.33341ZM7.50002 1.66675H9.16669V17.5001H7.50002V1.66675ZM10
        3.33341H11.6667V17.5001H10V3.33341Z" fill="#0061FF"/>
        </g>
    </svg>
</template>
