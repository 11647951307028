<template>
    <div class="sections sections--key-metrics">
        <div class="sections__head">
            <h5>Key conversion metrics</h5>
        </div>
        <div class="sections__tab">
            <div v-if="isLoading">
                <r-loading></r-loading>
            </div>
            <div v-else class="sections__tab-container">
                <div class="sections__tab-item">
                    <span class="sections__tab-icon">
                        <ActiveUsersIcon />
                    </span>
                    <span class="sections__tab-label">
                        Active users
                        <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    No. of users with successful fetch during last 30 days.
                                    Without deleted users.
                                </span>
                            </template>
                        </r-tool-tip>
                    </span>
                    <div class="sections__tab-details">
                        <h3 class="sections__tab-count">
                          {{ props.data && props.data.active_users }}
                        </h3>
                        <!-- <small
                        class="sections--tab__change sections--tab__change--up">
                        + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
                <div class="sections__tab-item">
                    <span class="sections__tab-icon">
                        <TotalUsersIcon />
                    </span>
                    <span class="sections__tab-label">
                        Total no. of new users
                        <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    Total number of users with successful fetch during last 30 days.
                                    Without deleted users.
                                </span>
                            </template>
                        </r-tool-tip>
                    </span>
                    <div class="sections__tab-details">
                      <h3 class="sections__tab-count">{{ props.data && props.data.new_users }}</h3>
                    <!-- <small
                    class="sections--tab__change sections--tab__change--up">
                    + 23.20% vs. last 90 days</small> -->
                    </div>
                    <div class="sections__tab-details">
                        <h3 class="sections__tab-count">
                          {{ props.data && props.data.created_users }}
                        </h3>
                        <!-- <small
                        class="sections--tab__change sections--tab__change--up">
                        + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
                <div class="sections__tab-item">
                    <span class="sections__tab-icon">
                        <ConversionRateIcon />
                    </span>
                    <span class="sections__tab-label">
                        Overall conversion rate
                        <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    <!-- eslint-disable-next-line -->
                                    Percentage of all users (including all data sources) that completed successfully connection of their work account out of the total number of users in a chosen date range.
                                </span>
                            </template>
                        </r-tool-tip>
                    </span>
                    <div class="sections__tab-details">
                        <h3 class="sections__tab-count">
                            {{ percentile(props.data && props.data.overall_conversion_rate)  }}%
                        </h3>
                        <!-- <small
                        class="sections--tab__change sections--tab__change--down">
                            + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
                <div class="sections__tab-item">
                    <span class="sections__tab-icon">
                        <ErrorTypeIcon />
                    </span>
                    <span class="sections__tab-label">
                        Most common error type
                        <r-tool-tip>
                            <template #tooltip-text>
                                <span>
                                    <!-- eslint-disable-next-line -->
                                    Most common error type that occurs and affects your users during the Connect process.
                                </span>
                            </template>
                        </r-tool-tip>
                    </span>
                    <div class="sections__tab-details">
                        <h3 class="sections__tab-error">
                            {{ groupErrors([props.data?.most_common_error_type]) }}
                        </h3>
                        <!-- <small
                        class="sections--tab__change sections--tab__change--down">
                            + 23.20% vs. last 90 days</small> -->
                    </div>
                </div>
            </div>
            <empty-state v-if="showEmptyState && !props.isLoading"></empty-state>
        </div>
    </div>
</template>

<script setup>
import RToolTip from '@/components/RToolTip.vue';
import ActiveUsersIcon from '@/components/icons/Analytics/ActiveUsersIcon.vue';
import ConversionRateIcon from '@/components/icons/Analytics/ConversionRateIcon.vue';
import ErrorTypeIcon from '@/components/icons/Analytics/ErrorTypeIcon.vue';
import TotalUsersIcon from '@/components/icons/Analytics/TotalUsersIcon.vue';
import RLoading from '@/components/RLoading.vue';
import EmptyState from '@/components/Analytics/Empty/Index.vue';
import { computed } from 'vue';
import { groupErrors } from '@/util/mappers';
import { percentile } from '../../../../util/helpers';

const props = defineProps(['isLoading', 'data']);

const showEmptyState = computed(() => {
  if (Object.keys(props.data).length > 0) {
    const areFieldsEmpty = [
      props.data.active_users,
      props.data.new_users,
      props.data.overall_conversion_rate,
      props.data.most_common_error_type,
    ].every((value) => value === null || value === 0 || value === '');
    return areFieldsEmpty;
  }
  return true;
});

</script>

<style lang="scss" scoped>
.sections {
    max-width: 100% !important;
    &--key-metrics {
        padding-bottom: 10px;
        margin-bottom: 0 !important;
        .sections__head {
            padding: 16px 32px;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
    &__tab {
        margin-top: 4px;
        padding: 20px 32px;
        position: relative;

        &-container {
            display: flex;
            flex-wrap: wrap;
        }
        &-item {
            width: calc(100% / 4);
            display: flex;
            flex-direction: column;
            gap: 12px;
        }
        &-details {
            display: flex;
            flex-direction: column;
            gap: 4px;
        }
        &-label {
            color: var(--color-grey-dark, #7A808B);
            font-weight: 600;
            display: flex;
            align-items: center;
            gap: 8px;
        }
        &-count {
            color: var(--color-dark-main, #253858);
            font-size: 40px;
            font-weight: 500;
        }
        &-error{
          color: var(--color-dark-main, #253858);
          font-size: 24px;
          font-weight: 500;
        }
        &-change {
            font-size: 14px;
            font-weight: 600;

            &--up {
                color: var(--color-green-success, #8FB571);
            }
            &--down {
                color: var(--color-red-error, #F52922);
            }
        }
    }
    h5 {
        color: var(--color-black, #141414);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 160%; /* 28.8px */
    }
}
</style>
