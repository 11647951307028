<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div class="card__head">
        <div class="card__head-icon">
            <div class="card__head-background" :style="{'background-color': '#0061FF'}"></div>
            <ChartIcon/>
        </div>
        <h5>Performance</h5>
        <RComboButton
            :text="label">
            <li @click="dropdownAction('lastWeek')"
                :class="{active: selected === 'lastWeek'}">
                Last Week
                <CheckMarkIcon v-if="selected === 'lastWeek'"/>
            </li>
            <li @click="dropdownAction('thisWeek')"
                :class="{active: selected === 'thisWeek'}"
                >
                This Week
                <CheckMarkIcon v-if="selected === 'thisWeek'"/>
            </li>
        </RComboButton>
    </div>
    <div class="card__content">
        <div class="performance__data">
            <div class="performance__data-item">
                <h3>Weekly earnings</h3>
                <p>{{ numberFormat(peformanceData.income) }} {{ props.currency }}</p>
            </div>
            <div class="performance__data-item">
                <h3>Rank among all users</h3>
                <p>top {{ nullToDash(peformanceData.rank) }}%</p>
            </div>
            <div class="performance__data-item">
                <h3>Daily avg. completed trips</h3>
                <p>{{ nullToDash(peformanceData.trips) }}</p>
            </div>
        </div>
        <div class="performance__graphs">
            <div class="performance__graphs-item">
                <h3>Earning per platform</h3>
                <vue-apex-charts
                  v-if="graphData.perPlatform?.length > 0"
                  :height="400"
                  :options="donutGraphOptions.platform"
                  :series="graphData.perPlatform">
                </vue-apex-charts>
                <NoDataAvailable v-else/>
            </div>
            <div class="performance__graphs-item">
                <h3>Trip slot time perference</h3>
                <vue-apex-charts
                  v-if="graphData.perTrip?.length > 0"
                  :height="400"
                  :options="donutGraphOptions.trip"
                  :series="graphData.perTrip">
                </vue-apex-charts>
                <NoDataAvailable v-else/>
            </div>
            <div class="performance__graphs-item">
                <h3>Distance distribution</h3>
                <vue-apex-charts
                  v-if="graphData.perDistance[0]?.data?.length > 0"
                  :height="400"
                  :options="barGraphOptions"
                  :series="graphData.perDistance">
                </vue-apex-charts>
                <NoDataAvailable v-else/>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch, reactive } from 'vue';
import VueApexCharts from 'vue3-apexcharts';
import { nullToDash, numberFormat } from '@/util/helpers';
import { cloneDeep } from 'lodash';
import RComboButton from '@/components/RComboButton.vue';
import ChartIcon from './icons/ChartIcon.vue';
import CheckMarkIcon from './icons/CheckMarkIcon.vue';
import NoDataAvailable from './NoDataAvailable.vue';

const props = defineProps(['data', 'graphs', 'currency']);

const barGraphOptions = {
  chart: {
    id: 'basic-line-chart',
    width: 500,
    height: 345,
    type: 'bar',
    toolbar: {
      show: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    title: {
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 500,
      },
    },
    title: {
      text: 'Number of Trips',
      style: {
        color: '#A8AFBC',
        fontSize: '12px',
        fontFamily: 'Gilroy',
        fontWeight: 600,
      },
    },
  },
  colors: ['#0061FF', '#FE9543'],
  legend: {
    position: 'top',
    horizontalAlign: 'right',
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    color: '#253858',
    itemMargin: {
      horizontal: 15,
      vertical: 0,
    },
  },
  markers: {
    size: 0, // Hide markers by default
    hover: {
      size: 6, // Size of the marker when hovering
      sizeOffset: 3, // Additional size to add to the marker when hovering
      fillColor: '#FF0000', // Fill color of the marker when hovering
      strokeColor: '#000000', // Stroke color of the marker when hovering
      strokeWidth: 2, // Stroke width of the marker when hovering
    },
  },
};

const donutOptions = {
  colors: ['#253858', '#F3C96B', '#DE6E6A', '#84BFDB', '#0061FF', '#FE9543'],
  chart: {
    width: 345,
    height: '100%',
    type: 'donut',
    offsetX: -35,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      endAngle: 360,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        size: '45%',
        background: 'transparent',
      },
    },
  },
  legend: {
    position: 'bottom',
    horizontalAlign: 'left',
    floating: false,
    fontSize: '12px',
    fontFamily: 'Gilroy',
    fontWeight: 500,
    inverseOrder: false,
    width: undefined,
    height: undefined,
    tooltipHoverFormatter: undefined,
    customLegendItems: [],
    offsetX: 45,
    labels: {

      colors: undefined,
      useSeriesColors: false,
    },
  },
};

const selected = ref('thisWeek');
const label = ref('Last Week');

const peformanceData = reactive({
  income: null,
  rank: null,
  trips: null,
});

const graphData = reactive({
  perPlatform: [],
  perTrip: [],
  perDistance: [],
});

const donutGraphOptions = {
  platform: cloneDeep(donutOptions),
  trip: cloneDeep(donutOptions),
};

const mapDistanceForBar = (chartData) => {
  const parsedDistances = chartData;
  const mappedDistances = [];
  if (parsedDistances?.values?.length > 0) {
    parsedDistances.values.forEach((element, i) => {
      mappedDistances.push({
        x: parsedDistances.labels[i],
        y: element,
      });
    });
  }
  return [
    {
      name: 'Trips',
      data: mappedDistances,
    },
  ];
};
const mapForDonutGraphs = (data, forDonut) => {
  if (forDonut === 'platform') {
    donutGraphOptions.platform.labels = data?.labels;
  } else {
    donutGraphOptions.trip.labels = data?.labels;
  }
  return data?.values;
};

watch(selected, (val) => {
  if (val === 'thisWeek') {
    label.value = 'This Week';
    peformanceData.income = props.data.this_week_weekly_income;
    peformanceData.rank = props.data.this_week_income_rank_internal;
    peformanceData.trips = props.data.this_week_daily_avg_trips;
    graphData.perPlatform = mapForDonutGraphs(
      props.graphs.plot_json_this_week_income_per_platform_pie,
      'platform',
    );
    graphData.perTrip = mapForDonutGraphs(
      props.graphs.plot_json_this_week_trip_slot_distribution_pie,
      'trip',
    );
    graphData.perDistance = mapDistanceForBar(
      props.graphs.plot_json_this_week_trip_distance_distribution_bar,
    );
    return;
  }
  label.value = 'Last Week';
  peformanceData.income = props.data.last_week_weekly_income;
  peformanceData.rank = props.data.last_week_income_rank_internal;
  peformanceData.trips = props.data.last_week_daily_avg_trips;
  graphData.perPlatform = mapForDonutGraphs(
    props.graphs.plot_json_last_week_income_per_platform_pie,
    'platform',
  );
  graphData.perTrip = mapForDonutGraphs(
    props.graphs.plot_json_last_week_trip_slot_distribution_pie,
    'trip',
  );
  graphData.perDistance = mapDistanceForBar(
    props.graphs.plot_json_last_week_trip_distance_distribution_bar,
  );
}, {
  immediate: true,
});

const dropdownAction = (week) => {
  selected.value = week;
};

</script>

<style scoped>

h3{
    color: var(--color-grey-dark, #7A808B);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
}
.card__head{
    gap: 15px;
}

.performance__data{
    display: grid;
    grid-template-columns: repeat(3, 1fr) ;
    justify-content: space-between;
    padding-bottom: 24px;
    border-bottom: 1px solid #EDF2F8;
}

.performance__data-item p{
    color: var(--color-dark-main);
    font-family: Gilroy;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px;
    margin-top: 10px;
}

.performance__graphs{
    margin-top: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.performance__graphs-item h3{
    margin-bottom: 10px;
}

.graph{
    height: 400px;
}
</style>
