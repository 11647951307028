import http from './http';
// import http from './fetch';

const DOMAIN_PATH = process.env.VUE_APP_API_URL || '/api';

const DOMAIN_SAND_PATH = process.env.VUE_APP_API_SAND_URL || '/api';

const getHeaders = () => {
  const currentPartner = localStorage.getItem('partner_id');
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('keycloak-token')}`,
  };
  const clientId = new URLSearchParams(window.location.search).get('client_id') || currentPartner;
  if (clientId) {
    headers['Client-Id'] = clientId;
  }
  return headers;
};

const getDomain = () => {
  const production = localStorage.getItem('production');
  return production === 'false' ? DOMAIN_SAND_PATH : DOMAIN_PATH;
};

const getUsers = async (params) => {
  const DOMAIN = getDomain();

  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`);
  try {
    const response = await http.get(
      `${DOMAIN}/users?${queryString.join('&')}`,
      {
        headers: getHeaders(),
      },
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postInviteUsers = async (params) => {
  const DOMAIN = getDomain();
  const response = await http.post(`${DOMAIN}/invite_users`, params, {
    headers: getHeaders(),
  });
  return response.data;
};

const usersRefresh = async (params) => {
  const DOMAIN = getDomain();

  const response = await http.post(`${DOMAIN}/accounts/refresh`, params, {
    headers: getHeaders(),
  });
  return response.data;
};

const getUserById = async (id, params) => {
  const DOMAIN = getDomain();
  const queryString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`);
  const response = await http.get(`${DOMAIN}/views/user/${id}?${queryString.join('&')}`, {
    headers: getHeaders(),
  });
  return response.data;
};

const createUser = async () => {
  const DOMAIN = getDomain();
  const response = await http.get(`${DOMAIN}/connect_live`, {
    headers: getHeaders(),
  });
  return response.data;
};

const getCarLogos = async () => {
  const response = await http.get('/cars/list.json');
  return response.data;
};

const getConnectCustomizations = async () => {
  const DOMAIN = getDomain();
  const response = await http.get(`${DOMAIN}/customizations`, {
    headers: getHeaders(),
  });
  return response.data;
};

const getConnectCustomization = async (id) => {
  const DOMAIN = getDomain();
  const response = await http.get(`${DOMAIN}/customizations/${id}`, {
    headers: getHeaders(),
  });
  return response.data;
};

const createCustomization = async (params) => {
  const DOMAIN = getDomain();
  const response = await http.post(`${DOMAIN}/customizations`, params, {
    headers: getHeaders(),
  });
  return response.data;
};

const removeCustomization = async (id) => {
  const DOMAIN = getDomain();
  const response = await http.delete(`${DOMAIN}/customizations/${id}`, {
    headers: getHeaders(),
  });
  return response.data;
};

const updateCustomization = async (params) => {
  const DOMAIN = getDomain();
  const ID = params.id;
  const PARAMS = params;
  delete PARAMS.id;
  const response = await http.put(`${DOMAIN}/customizations/${ID}`, PARAMS, {
    headers: getHeaders(),
  });
  return response.data;
};

const enableCustomizationLink = async (id) => {
  const DOMAIN = getDomain();
  const response = await http.put(`${DOMAIN}/customizations/${id}/enable`, null, {
    headers: getHeaders(),
  });
  return response.data;
};

const disableCustomizationLink = async (id) => {
  const DOMAIN = getDomain();
  const response = await http.put(`${DOMAIN}/customizations/${id}/disable`, null, {
    headers: getHeaders(),
  });
  return response.data;
};
const getDatasources = async () => {
  const DOMAIN = getDomain();

  const response = await http.get(`${DOMAIN}/client_datasources`, {
    headers: getHeaders(),
  });
  return response.data;
};

const setUserCustomAttribute = async (params) => {
  const DOMAIN = getDomain();
  const ID = params.id;
  const PARAMS = params;
  delete PARAMS.id;
  const response = await http.put(`${DOMAIN}/users/${ID}/custom_attributes`, { attributes: PARAMS }, {
    headers: getHeaders(),
  });
  return response;
};

const setDashboardConfiguration = async (params) => {
  const DOMAIN = getDomain();
  const PARAMS = params;
  delete PARAMS.id;
  const response = await http.post(`${DOMAIN}/dashboard_configuration`, { config: PARAMS }, {
    headers: getHeaders(),
  });
  return response;
};

const updateDashboardConfiguration = async (params) => {
  const DOMAIN = getDomain();
  const PARAMS = params;
  delete PARAMS.id;
  const response = await http.put(`${DOMAIN}/dashboard_configuration`, { ...PARAMS }, {
    headers: getHeaders(),
  });
  return response;
};

const getDashboardConfiguration = async () => {
  const DOMAIN = getDomain();
  const response = await http.get(`${DOMAIN}/dashboard_configuration`, {
    headers: getHeaders(),
  });
  return response;
};

export default {
  getUsers,
  postInviteUsers,
  usersRefresh,
  getUserById,
  createUser,
  getCarLogos,
  getConnectCustomization,
  getConnectCustomizations,
  createCustomization,
  updateCustomization,
  enableCustomizationLink,
  disableCustomizationLink,
  getDatasources,
  removeCustomization,
  setUserCustomAttribute,
  setDashboardConfiguration,
  getDashboardConfiguration,
  updateDashboardConfiguration,
};
