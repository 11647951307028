<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19 20H5V21C5 21.2652 4.89464 21.5196 4.70711 21.7071C4.51957 21.8946 4.26522
  22 4 22H3C2.73478 22 2.48043 21.8946 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V12L4.513
  5.298C4.65593 4.91669 4.91186 4.5881 5.24656 4.35615C5.58126 4.1242 5.97878 3.99995 6.386
  4H17.614C18.0212 3.99995 18.4187 4.1242 18.7534 4.35615C19.0881 4.5881 19.3441 4.91669 19.487
  5.298L22 12V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8946 21.2652 22 21
  22H20C19.7348 22 19.4804 21.8946 19.2929 21.7071C19.1054 21.5196 19 21.2652 19 21V20ZM4.136
  12H19.864L17.614 6H6.386L4.136 12ZM6.5 17C6.89782 17 7.27936 16.842 7.56066 16.5607C7.84196
  16.2794 8 15.8978 8 15.5C8 15.1022 7.84196 14.7206 7.56066 14.4393C7.27936 14.158 6.89782
  14 6.5 14C6.10218 14 5.72064 14.158 5.43934 14.4393C5.15804 14.7206 5 15.1022 5 15.5C5
  15.8978 5.15804 16.2794 5.43934 16.5607C5.72064 16.842 6.10218 17 6.5 17ZM17.5 17C17.8978 17
  18.2794 16.842 18.5607 16.5607C18.842 16.2794 19 15.8978 19 15.5C19 15.1022 18.842 14.7206
  18.5607
  14.4393C18.2794 14.158 17.8978 14 17.5 14C17.1022 14 16.7206 14.158 16.4393 14.4393C16.158
  14.7206 16 15.1022 16 15.5C16 15.8978
  16.158 16.2794 16.4393 16.5607C16.7206 16.842 17.1022 17 17.5 17Z" fill="#A8AFBC"/>
  </svg>

</template>
