<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Group">
      <path id="Vector"
            d="M16.1 3C15.9659 3.65993 15.9659 4.34007 16.1 5H4.511L12.061 11.662L17.11
            7.142C17.536 7.669 18.068 8.108 18.673 8.427L12.072 14.338L4 7.216V19H20V8.9C20.6599
            9.03406 21.3401 9.03406 22 8.9V20C22 20.2652 21.8946 20.5196 21.7071 20.7071C21.5196
            20.8946 21.2652 21 21 21H3C2.73478 21 2.48043 20.8946 2.29289 20.7071C2.10536 20.5196
            2 20.2652 2 20V4C2 3.73478 2.10536 3.48043 2.29289 3.29289C2.48043 3.10536 2.73478 3
            3 3H16.1ZM21 7C20.606 7 20.2159 6.9224 19.8519 6.77164C19.488 6.62087 19.1573 6.3999
            8.8787 6.12132C18.6001 5.84274 18.3791 5.51203 18.2284 5.14805C18.0776 4.78407 18
            4.39397 18 4C18 3.60603 18.0776 3.21593 18.2284 2.85195C18.3791 2.48797 18.6001
            2.15726 18.8787 1.87868C19.1573 1.6001 19.488 1.37913 19.8519 1.22836C20.2159
            1.0776 20.606 1 21 1C21.7956 1 22.5587 1.31607 23.1213 1.87868C23.6839 2.44129
            24 3.20435 24 4C24 4.79565 23.6839 5.55871 23.1213 6.12132C22.5587 6.68393
            21.7956 7 21 7Z"
            fill="currentColor"/>
    </g>
  </svg>

</template>
