<template>
  <svg width="17" height="17" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.99996 17.3334C4.39746 17.3334 0.666626 13.6026 0.666626 9.00008C0.666626
      4.39758 4.39746 0.666748 8.99996 0.666748C13.6025 0.666748 17.3333 4.39758 17.3333
      9.00008C17.3333 13.6026 13.6025 17.3334 8.99996 17.3334ZM8.99996 7.82175L6.64329
      5.46425L5.46413 6.64341L7.82163 9.00008L5.46413 11.3567L6.64329 12.5359L8.99996
      10.1784L11.3566 12.5359L12.5358 11.3567L10.1783 9.00008L12.5358 6.64341L11.3566
      5.46425L8.99996 7.82175Z"
      fill="#A8AFBC"/>
  </svg>
</template>
