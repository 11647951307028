import { createWebHistory, createRouter } from 'vue-router';
import guards from '@/router/guards';
import GetStarted from '@/pages/GetStarted.vue';
import Connect from '@/pages/Connect.vue';
import ConnectLive from '@/pages/Connect/ConnectLive/Index.vue';
import UsersIndex from '@/pages/Users.vue';
import User from '@/pages/Users/User.vue';
import UserMetadata from '@/pages/Users/UserMetadata.vue';
import UsersManagement from '@/pages/Users/UsersManagement.vue';
import ConnectCustomize from '@/pages/Connect/ConnectCustomize/Edit.vue';
import ConnectCustomizeIndex from '@/pages/Connect/ConnectCustomize/Index.vue';
import Coverages from '@/pages/Coverages/Index.vue';
import Coverage from '@/pages/Coverages/Coverage.vue';
// settings
import Settings from '@/pages/Settings/index.vue';
import EmailCustomization from '@/pages/Settings/EmailCustomization.vue';
import DataUpdate from '@/pages/Settings/DataUpdate.vue';
// analytics
import Analytics from '@/pages/Analytics/Index.vue';
import Conversion from '@/pages/Analytics/Conversion.vue';
import BillingData from '@/pages/Analytics/BillingData.vue';
import Users from '@/pages/Users/UsersList.vue';
// verify
import Verify from '@/pages/Verify/index.vue';
import VerifyDetails from '@/pages/Verify/details.vue';
import Insights from '@/pages/Insights/index.vue';
import InsightsOverview from '@/pages/Insights/Overview.vue';
import InsightsDrivers from '@/pages/Insights/Drivers.vue';
// Driver Insights Details
import InsightsDetails from '@/pages/Insights/DriverDetails.vue';
import HistoricalPerformance from '@/pages/Insights/HistoricalPerformance.vue';

const routerOptions = {
  routes: [
    {
      path: '/analytics',
      name: 'Analytics',
      component: Analytics,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Analytics',
        atBottom: false,
        ignoreChildren: true,
      },
      redirect: { name: 'Conversion' },
      children: [
        {
          path: '/analytics/conversion',
          name: 'Conversion',
          component: Conversion,
          meta: {
            name: 'Conversion',
            route: 'Analytics',
            headerName: 'Analytics',
            visible: true,
          },
        },
        {
          path: 'billing',
          name: 'BillingData',
          component: BillingData,
          meta: {
            name: 'Analytics',
          },
        },
      ],
    },
    {
      path: '/connect',
      name: 'Connect',
      component: Connect,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Connect',
        atBottom: false,
        index: 1,
        active: false,
      },
      children: [
        {
          path: '/connnect/live',
          name: 'Connect Live',
          component: ConnectLive,
          meta: {
            visible: true,
            route: 'ConnectLive',
            index: 1,
            onBoardingId: 'onboardingConnectLive',
          },
        },
        {
          path: '/connect/customization',
          name: 'Customization',
          component: ConnectCustomizeIndex,
          meta: {
            visible: true,
            route: 'Customize',
            index: 1,
          },
        },
        {
          path: '/connect/customization/edit/:id',
          name: 'Edit',
          component: ConnectCustomize,
          meta: {
            visible: false,
            route: 'Customize',
          },
        },
        {
          path: '/connect/customization/create',
          name: 'Create',
          component: ConnectCustomize,
          meta: {
            visible: false,
            route: 'Customize',
          },
        },
      ],
    },
    {
      path: '/users',
      name: 'Users',
      component: UsersIndex,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Users',
        atBottom: false,
        index: 2,
        active: false,
      },
      children: [
        {
          path: '/users/data-view',
          name: 'Data View',
          component: Users,
          meta: {
            visible: true,
            route: 'UsersView',
            index: 2,
            headerName: 'Users',
            onBoardingId: 'dataView',
          },
        },
        {
          path: '/users/management',
          name: 'User Management',
          component: UsersManagement,
          meta: {
            visible: true,
            route: 'UsersManagement',
            index: 2,
            headerName: 'Users Management',
          },
        },
        {
          path: '/users/data-view/:id',
          name: 'DataViewSingle',
          component: User,
          meta: {
            visible: false,
            route: 'UsersView',
          },
        },
        {
          path: '/users/data-view/:id/metadata',
          name: 'UserMetadata',
          component: UserMetadata,
          meta: {
            visible: false,
            route: 'UserMetadata',
          },
        },
      ],
    },
    {
      path: '/coverages',
      name: 'Coverage',
      component: Coverages,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Coverages',
        atBottom: false,
        index: 4,
      },
    },
    {
      path: '/fraud/analysis',
      name: 'Fraud Analysis',
      component: Verify,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Verify',
        atBottom: false,
      },
    },
    {
      path: '/fraud/analysis/details',
      name: 'Fraud Analysis Details',
      component: VerifyDetails,
      meta: {
        layout: 'MainLayout',
        visible: false,
        route: 'Verify',
        atBottom: false,
      },
    },
    {
      path: '/coverages/:id',
      name: 'CoveragePage',
      component: Coverage,
      meta: {
        layout: 'MainLayout',
        visible: false,
        route: 'Coverages',
        atBottom: false,
      },
    },
    {
      path: '/insights',
      name: 'User Insights',
      beforeEnter: [guards.isFeatureFlaged],
      component: Insights,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'DriverInsights',
        headerName: 'User Insights',
        atBottom: false,
        ignoreChildren: true,
        index: 6,
        onBoardingId: 'userInsights',
      },
      redirect: { name: 'Insights Overview' },
      children: [
        {
          path: '/insights/overview',
          name: 'Insights Overview',
          component: InsightsOverview,
          meta: {
            name: 'DriverInsights',
            route: 'DriverInsights',
            headerName: 'User Insights',
            visible: true,
          },
        },
        {
          path: '/insights/drivers',
          name: 'Insights Drivers',
          component: InsightsDrivers,
          meta: {
            name: 'DriverInsights',
            route: 'DriverInsights',
            headerName: 'User Insights',
            visible: true,
          },
        },
        {
          path: '/insights/drivers/:id/:name/details',
          name: 'Insights Details',
          component: InsightsDetails,
          meta: {
            name: 'DriverInsights',
            route: 'DriverInsights',
            headerName: 'User Insights',
            visible: true,
          },
        },
        {
          path: '/insights/drivers/:id/:name/performance',
          name: 'Insights Performance',
          component: HistoricalPerformance,
          meta: {
            name: 'DriverInsights',
            route: 'DriverInsights',
            headerName: 'User Insights',
            visible: true,
          },
        },
      ],
    },
    {
      path: '/settings',
      name: 'Settings',
      component: Settings,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'Settings',
        atBottom: false,
        ignoreChildren: true,
        index: 5,
      },
      redirect: { name: 'Data Update' },
      children: [
        {
          path: '/settings/email/customization',
          name: 'EmailCustomization',
          component: EmailCustomization,
          meta: {
            name: 'Settings',
            route: 'Settings',
            headerName: 'Settings',
            visible: true,
          },
        },
        {
          path: '/settings/data/update',
          name: 'Data Update',
          component: DataUpdate,
          meta: {
            name: 'Settings',
            route: 'Settings',
            headerName: 'Settings',
            visible: true,
          },
        },
      ],
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      redirect: '/',
      meta: {
        visible: false,
      },
    },
    {
      path: '/',
      name: 'Get Started',
      component: GetStarted,
      meta: {
        layout: 'MainLayout',
        visible: true,
        route: 'GetStarted',
        atBottom: true,
        index: 99,
      },
    },
  ],
};

const initializeRouter = () => {
  routerOptions.history = createWebHistory();
  return createRouter(routerOptions);
};

const router = initializeRouter();
// eslint-disable-next-line import/prefer-default-export
export { router };
