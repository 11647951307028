<template>
  <div class="loading">
    <img src="@/assets/loading.gif" alt="loading">
  </div>
</template>
<style lang="scss" scoped>
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  img {
    width: 60px;
    height: 60px;
  }
}
</style>
